// GENERATED FILE - DO NOT MODIFY
import { LocalizationModel } from "api/models/company/dispatch/localization-model";
import { ProjectDispatchCompleteAllModel } from "api/models/company/dispatch/project-dispatch-complete-all-model";
import { LocationModel } from "api/models/company/location-model";
import { DispatchProjectAttendanceModel } from "api/models/company/project/dispatch/dispatch-project-attendance-model";
import { DispatchProjectTimeEntrySaveModel } from "api/models/company/project/dispatch/dispatch-project-time-entry-save-model";
import { ProjectDailyEntryWeatherModel } from "api/models/company/project/dispatch/project-daily-entry-weather-model";
import { ProjectDailyEntryWorkDescriptionModel } from "api/models/company/project/dispatch/project-daily-entry-work-description-model";
import { ProjectDispatchCompleteModel } from "api/models/company/project/dispatch/project-dispatch-complete-model";
import { ProjectDispatchDateDetailsModel } from "api/models/company/project/dispatch/project-dispatch-date-details-model";
import { ProjectDispatchDateModel } from "api/models/company/project/dispatch/project-dispatch-date-model";
import { ProjectDispatchModel } from "api/models/company/project/dispatch/project-dispatch-model";
import { DispatchProjectHeaderModel } from "api/models/company/project/dispatch-project-header-model";
import { DispatchProjectItemsCountModel } from "api/models/company/project/dispatch-project-items-count-model";
import { DispatchProjectWorkScheduleModel } from "api/models/company/project/dispatch-project-work-schedule-model";
import { ProjectDispatchDateItemsCountModel } from "api/models/company/project/project-dispatch-date-items-count-model";
import { ProjectListModel } from "api/models/company/project/project-list-model";
import { ProjectModel } from "api/models/company/project/project-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class DispatchProjectProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetDispatchProjects(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectListModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectListModel[] | null = await this.get<ProjectListModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectListModel");
        return data;
    }

    public async GetDispatchProject(projectDispatchCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ProjectModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            null
        );

        const data: ProjectModel | null = await this.get<ProjectModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectModel");
        return data;
    }

    public async GetDispatchProjectHeader(projectDispatchCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectHeaderModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/header",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            null
        );

        const data: DispatchProjectHeaderModel | null = await this.get<DispatchProjectHeaderModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.DispatchProjectHeaderModel");
        return data;
    }

    public async GetDispatchProjectDates(projectDispatchCode: string | null, statuses: Array<string | null> | null, statusesToExclude: Array<string | null> | null, sortByAsc: boolean, includeReturnedEntries: boolean | undefined, minDate: Date | null | undefined, maxDate: Date | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchDateModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            { statuses: (statuses === null || statuses === undefined) ? undefined : statuses, statusesToExclude: (statusesToExclude === null || statusesToExclude === undefined) ? undefined : statusesToExclude, sortByAsc: (sortByAsc === null || sortByAsc === undefined) ? undefined : sortByAsc.toString(), includeReturnedEntries: (includeReturnedEntries === null || includeReturnedEntries === undefined) ? undefined : includeReturnedEntries.toString(), minDate: (minDate === null || minDate === undefined) ? undefined : minDate.toString(), maxDate: (maxDate === null || maxDate === undefined) ? undefined : maxDate.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectDispatchDateModel[] | null = await this.get<ProjectDispatchDateModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDispatchDateModel");
        return data;
    }

    public async GetDispatchProjectDate(projectDispatchCode: string | null, dispatchDate: Date, timeEntryMode: string | null, inChargeRequireIndicateWhenEmployeeLeftWorksite: boolean, allowEquipmentTimeEntryForSimpleUser: boolean, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchDateDetailsModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { timeEntryMode: (timeEntryMode === null || timeEntryMode === undefined) ? undefined : timeEntryMode, inChargeRequireIndicateWhenEmployeeLeftWorksite: (inChargeRequireIndicateWhenEmployeeLeftWorksite === null || inChargeRequireIndicateWhenEmployeeLeftWorksite === undefined) ? undefined : inChargeRequireIndicateWhenEmployeeLeftWorksite.toString(), allowEquipmentTimeEntryForSimpleUser: (allowEquipmentTimeEntryForSimpleUser === null || allowEquipmentTimeEntryForSimpleUser === undefined) ? undefined : allowEquipmentTimeEntryForSimpleUser.toString() }
        );

        const data: ProjectDispatchDateDetailsModel | null = await this.get<ProjectDispatchDateDetailsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDispatchDateDetailsModel");
        return data;
    }

    public async DeleteDailyEntry(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        await this.delete<void>(uri, requestConfig);
    }

    public async CheckDispatchProjectDateDoesNotExist(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/checknotexists",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: void = await this.get<void>(uri, requestConfig);
        return data;
    }

    public async GetDispatchProjectDateItemsCount(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchDateItemsCountModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/itemscount",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: ProjectDispatchDateItemsCountModel | null = await this.get<ProjectDispatchDateItemsCountModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectDispatchDateItemsCountModel");
        return data;
    }

    public async GetWeather(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<ProjectDailyEntryWeatherModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/weather",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: ProjectDailyEntryWeatherModel | null = await this.get<ProjectDailyEntryWeatherModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDailyEntryWeatherModel");
        return data;
    }

    public async PostWeather(projectDispatchCode: string | null, dispatchDate: Date, model: ProjectDailyEntryWeatherModel | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/weather",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: boolean = await this.post<ProjectDailyEntryWeatherModel | null, boolean>(uri, model, requestConfig);
        return data;
    }

    public async GetWorkDescription(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<ProjectDailyEntryWorkDescriptionModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/workdescription",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: ProjectDailyEntryWorkDescriptionModel | null = await this.get<ProjectDailyEntryWorkDescriptionModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDailyEntryWorkDescriptionModel");
        return data;
    }

    public async PostWorkDescription(projectDispatchCode: string | null, dispatchDate: Date, model: ProjectDailyEntryWorkDescriptionModel | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/workdescription",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: boolean = await this.post<ProjectDailyEntryWorkDescriptionModel | null, boolean>(uri, model, requestConfig);
        return data;
    }

    public async GetItemsCount(projectDispatchCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectItemsCountModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/itemscount",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            null
        );

        const data: DispatchProjectItemsCountModel | null = await this.get<DispatchProjectItemsCountModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.DispatchProjectItemsCountModel");
        return data;
    }

    public async GetWorkingHours(projectDispatchCode: string | null, templateCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectWorkScheduleModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/workschedule/{templateCode}",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, templateCode: (templateCode === null || templateCode === undefined) ? undefined : templateCode },
            null
        );

        const data: DispatchProjectWorkScheduleModel | null = await this.get<DispatchProjectWorkScheduleModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.DispatchProjectWorkScheduleModel");
        return data;
    }

    public async CopyDailyEntry(projectDispatchCode: string | null, sourceDateTime: Date, destinationDateTime: Date, copyTimeEntries: boolean, timeEntryMode: string | null, inChargeRequireIndicateWhenEmployeeLeftWorksite: boolean, copySubContractors: boolean, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchDateDetailsModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/copy",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            { sourceDateTime: (sourceDateTime === null || sourceDateTime === undefined) ? undefined : sourceDateTime.toString(), destinationDateTime: (destinationDateTime === null || destinationDateTime === undefined) ? undefined : destinationDateTime.toString(), copyTimeEntries: (copyTimeEntries === null || copyTimeEntries === undefined) ? undefined : copyTimeEntries.toString(), timeEntryMode: (timeEntryMode === null || timeEntryMode === undefined) ? undefined : timeEntryMode, inChargeRequireIndicateWhenEmployeeLeftWorksite: (inChargeRequireIndicateWhenEmployeeLeftWorksite === null || inChargeRequireIndicateWhenEmployeeLeftWorksite === undefined) ? undefined : inChargeRequireIndicateWhenEmployeeLeftWorksite.toString(), copySubContractors: (copySubContractors === null || copySubContractors === undefined) ? undefined : copySubContractors.toString() }
        );

        const data: ProjectDispatchDateDetailsModel | null = await this.post<void, ProjectDispatchDateDetailsModel | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDispatchDateDetailsModel");
        return data;
    }

    public async AddDispatch(projectDispatchCode: string | null, model: ProjectDispatchModel | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            null
        );

        const data: number = await this.post<ProjectDispatchModel | null, number>(uri, model, requestConfig);
        return data;
    }

    public async DeleteDispatch(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DispatchProjectAttendanceModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatches/{dispatchId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DispatchProjectAttendanceModel[] | null = await this.delete<DispatchProjectAttendanceModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.DispatchProjectAttendanceModel");
        return data;
    }

    public async ProjectDispatchHasTimeEntriesAndBonus(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatches/{dispatchId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async ProjectDispatchIsLastAttendee(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatches/{dispatchId}/is-last-attendee",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async CompleteDispatch(projectDispatchCode: string | null, dispatchDate: Date, timeEntries: Array<DispatchProjectTimeEntrySaveModel | null> | null, forceSave: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchCompleteModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/complete",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { forceSave: (forceSave === null || forceSave === undefined) ? undefined : forceSave.toString() }
        );

        const data: ProjectDispatchCompleteModel | null = await this.post<Array<DispatchProjectTimeEntrySaveModel | null> | null, ProjectDispatchCompleteModel | null>(uri, timeEntries, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDispatchCompleteModel");
        return data;
    }

    public async CompleteListDispatch(dispatchDate: Date, projectDispatchCodesList: Array<string | null> | null, timeEntryMode: string | null, inChargeRequireIndicateWhenEmployeeLeftWorksite: boolean, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchCompleteAllModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/dispatchdates/{dispatchDate}/complete",
            { dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { timeEntryMode: (timeEntryMode === null || timeEntryMode === undefined) ? undefined : timeEntryMode, inChargeRequireIndicateWhenEmployeeLeftWorksite: (inChargeRequireIndicateWhenEmployeeLeftWorksite === null || inChargeRequireIndicateWhenEmployeeLeftWorksite === undefined) ? undefined : inChargeRequireIndicateWhenEmployeeLeftWorksite.toString() }
        );

        const data: ProjectDispatchCompleteAllModel | null = await this.post<Array<string | null> | null, ProjectDispatchCompleteAllModel | null>(uri, projectDispatchCodesList, requestConfig);
        this.transformModels(data, "Company.Dispatch.ProjectDispatchCompleteAllModel");
        return data;
    }

    public async UnCompleteDispatch(projectDispatchCode: string | null, dispatchDate: Date, requestConfig?: HttpClientRequestConfig): Promise<ProjectDispatchCompleteModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/uncomplete",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        const data: ProjectDispatchCompleteModel | null = await this.post<void, ProjectDispatchCompleteModel | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.Project.Dispatch.ProjectDispatchCompleteModel");
        return data;
    }

    public async Post(projectDispatchCode: string | null, dispatchDate: Date, model: LocalizationModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/location",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        await this.post<LocalizationModel | null, void>(uri, model, requestConfig);
    }

    public async GetSubProjects(dispatchProjectCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{dispatchProjectCode}/subprojects",
            { dispatchProjectCode: (dispatchProjectCode === null || dispatchProjectCode === undefined) ? undefined : dispatchProjectCode },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectModel[] | null = await this.get<ProjectModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectModel");
        return data;
    }

    public async IsCurrentUserProjectResponsible(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/dispatch/projects/current-user-project-responsible",
            null,
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async GetProjectFromDispatchProjectCode(dispatchProjectCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ProjectModel | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{dispatchProjectCode}/project",
            { dispatchProjectCode: (dispatchProjectCode === null || dispatchProjectCode === undefined) ? undefined : dispatchProjectCode },
            null
        );

        const data: ProjectModel | null = await this.get<ProjectModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectModel");
        return data;
    }

    public async GetProjectDeliveryAddress(dispatchProjectCode: string | null, addressType: string | null, supplierId: string | null | undefined, prefix: string | null | undefined, dispatchId: number | null | undefined, workOrderId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<LocationModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/projects/{dispatchProjectCode}/project/delivery-address",
            { dispatchProjectCode: (dispatchProjectCode === null || dispatchProjectCode === undefined) ? undefined : dispatchProjectCode },
            { addressType: (addressType === null || addressType === undefined) ? undefined : addressType, supplierId: (supplierId === null || supplierId === undefined) ? undefined : supplierId, prefix: (prefix === null || prefix === undefined) ? undefined : prefix, dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: LocationModel[] | null = await this.get<LocationModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.LocationModel");
        return data;
    }
}
