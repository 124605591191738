import EmailHelper from "helpers/emailHelper";
define([
    "knockout",
    "jquery",
    "core/resx",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "services/projectService",
    "services/dailyEntryService",
    "services/contactService",
    "helpers/viewbag",
    "underscore",
    "helpers/dateHelper",
    "helpers/enumHelper"
], function (ko, jQuery, resx, routerHelper, notificationHelper, queryStringHelper, projectService, dailyEntryService, contactService, viewbag, _, dateHelper, enumHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor(emailHelper) {
            this.emailHelper = emailHelper;
            self = this;
            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.dispatchProjectCode = ko.observable();
            self.dispatchDate = ko.observable();
            self.dispatchId = ko.observable();
            self.resources = ko.observableArray();
            self.resourcesEmploye = ko.observableArray();
            self.resourcesEquipment = ko.observableArray();
            self.material = ko.observableArray();
            self.totalTime = 0;
            self.totalEmployeeTime = 0;
            self.totalEquipmentTime = 0;
            self.contactNameAvailable = ko.observable(false);
            self.additionalFields = [];
            self.signatureAvailable = ko.observable(false);
            self.readonly = true;
            self.saveAdditionalFieldsButton = false;
            self.inputBag = ko.observable({});
            self.viewbagValid = ko.observable(false);
            self.validateInput = function () {
                if (viewbag() &&
                    viewbag() !== undefined &&
                    viewbag().dispatchProjectCode !== undefined &&
                    viewbag().dispatchProjectCode === self.dispatchProjectCode()) {
                    //valid
                    self.inputBag(viewbag());
                    self.viewbagValid(true);
                } else {
                    //invalid
                    self.inputBag({});
                    self.viewbagValid(false);
                }

                //clear viewbag to fix back issue where the user could come back without respecting the flow
                viewbag(undefined);
            };
            self.loadContacts = {
                transport: function (params, success, failure) {
                    contactService.getContactLookupForDailyEntrySummary(self.dispatchProjectCode(),
                        self.dispatchDate(),
                        params.data.filter,
                        params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function (item) {
                    return { id: item.No, text: item.Name + ', ' + item.FirstName, data: item.Email };
                }
            };

            self.validatedForm = ko.validatedObservable({
                contact: ko.observable(null),
                email: ko.observable('')
                    .extend({
                        validation: {
                            validator: function (email) {
                                this.message = resx.localize("err_InvalidEmails:");
                                var invalidEmails = "";
                                if (email.length > 0) {
                                    invalidEmails = self.emailHelper.findInvalidEmailsInString(email);
                                    if (invalidEmails.length >= 1) {
                                        this.message += " " + invalidEmails.join(", ");
                                        return false;
                                    }
                                }
                                return true;
                            }
                        }
                    }),
                txtSignature: ko.observable('')
                    .extend({
                        required: {
                            params: true,
                            message: resx.localize("err_SignatureRequired")
                        }
                    }),
                clear: function () {
                    var valForm = this;
                    valForm.txtSignature('');
                    valForm.contact(null);
                    valForm.email('');
                }
            });

            self.validatedForm().contact.subscribe(function (newValue) {
                if (newValue && newValue.id !== undefined) {
                    self.validatedForm().email(newValue.data.Email);
                    self.validatedForm().txtSignature(newValue.text);
                    self.contactNameAvailable(false);
                } else {
                    self.validatedForm().email('');
                    self.validatedForm().txtSignature('');
                    self.contactNameAvailable(true);
                }
            });

            //#endregion
        }

        ctor.inject = [EmailHelper];

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, querystring) {
            self.validatedForm().clear();
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);
            self.validateInput();
        }

        function loadData() {
            var dfdSummary = dailyEntryService.getDailyEntrySummary(self.dispatchProjectCode(), self.dispatchDate());
            return jQuery.when(dfdSummary).done(initialize);
        }

        function initialize(dailySummary) {
            self.signatureAvailable = dailySummary.HasSignature;
            self.signatureContactName = (self.signatureAvailable && dailySummary.SignatureContactName) ? dailySummary.SignatureContactName : '';
            self.signatureContact = dailySummary.SignatureContactName;
            self.totalTime = dailySummary.TotalTime;
            self.totalEmployeeTime = dailySummary.TotalEmployeeTime;
            self.totalEquipmentTime = dailySummary.TotalEquipmentTime;
            self.additionalFields = dailySummary.AdditionalFields;
            self.workDescription = dailySummary.Description.WorkDescription;
            initHours(dailySummary.Resources);
            initMaterials(dailySummary.Materials);
        }

        function initHours(resources) {
            self.resourcesEmploye([
                {
                    title: resx.localize('EmployeeHours'),
                    resourceItems: _.filter(resources, function (val) { return val.EquipmentCode === null; })
                }
            ]);
            self.resourcesEquipment([
                {
                    title: resx.localize('EquipmentHours'),
                    resourceItems: _.filter(resources, function (val) { return val.EquipmentCode !== null; })
                }
            ]);
        }

        function initMaterials(material) {
            const materialItemType = enumHelper.materialItemType();

            self.material([
                {
                    title: enumHelper.materialItemType().MATERIAL.label,
                    items: _.filter(material, function (val) {
                        return val.ItemType === materialItemType.DEFAULT.id || val.ItemType === materialItemType.MATERIAL.id;
                    })
                },
                { title: materialItemType.SALARY.label, items: _.where(material, { ItemType: materialItemType.SALARY.id }) },
                { title: materialItemType.SUBCONTRACTOR.label, items: _.where(material, { ItemType: materialItemType.SUBCONTRACTOR.id }) },
                { title: materialItemType.EQUIPMENT.label, items: _.where(material, { ItemType: materialItemType.EQUIPMENT.id }) },
                { title: materialItemType.MISC.label, items: _.where(material, { ItemType: materialItemType.MISC.id }) }
            ]);
            self.material(_.filter(self.material(), function (val) { return val.items.length > 0; }));
        }

        function bindWidget() {
            self.actions = {
                getAll: function () {
                    var dfd = new jQuery.Deferred();
                    dfd.resolve(self.additionalFields);
                    return dfd;
                }
            };
        }

        //#endregion        

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
            bindWidget();
            return loadData(params.dispatchProjectCode, params.dailyEntryDate);

        };

        ctor.prototype.sign = function () {
            if (self.validatedForm.isValid()) {
                self.inputBag().txtSignature = self.validatedForm().txtSignature();
                self.inputBag().email = self.validatedForm().email();
                viewbag(self.inputBag());
                routerHelper.navigate(routerHelper.getRelativeUrl('signature') + routerHelper.addQuerystring({ readonly: false }));
            } else {
                notificationHelper.showValidationError(self.validatedForm.errors);
            }
        };

        ctor.prototype.attached = function () {
            routerHelper.hideLoading();
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
