import { singleton } from "aurelia-framework";

import { default as jQuery } from "jquery";
import { default as equipmentService } from "services/equipmentService";
import { default as recommendationService } from "services/recommendationService";

export class RecommendationEdit {
    fetchClients;
    fetchEquipments;
    saveRecommendation;
    updatePageSubtitle;

    subTitle = "";
    recommendationId = "";

    constructor() {
        
    }

    activate(params) {
        this.recommendationId = params.recommendationId;
     
        this.fetchEquipments = equipmentService.get;
        this.saveRecommendation = recommendationService.addRecommendation;
        this.updatePageSubtitle = title => { this.subTitle = title; };
    }
}
