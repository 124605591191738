import { bindable, bindingMode, autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import { AdditionalFieldType } from "api/enums/additional-field-type";

import routerHelper from "helpers/routerHelper";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { AdditionalFieldValueGetter } from "./helpers/additional-field-value-getter";
import { AdditionalFieldMapper } from "./helpers/additional-field-mapper";
import { AdditionalFieldsValidator } from "./helpers/additional-field-validator";

@autoinject()
export class AdditionalFields {
  @bindable
  public actions: any;
  @bindable
  public additionalFieldsSource: AdditionalFieldsSourceType | null = null;
  @bindable
  public readonly: boolean = false;
  @bindable
  public saveAdditionalFieldsButton: boolean = true;

  private fields: any[] = [];
  private additionalFieldMapper: AdditionalFieldMapper;
  private additionalFieldsValidator: AdditionalFieldsValidator;
  private i18n: I18N;

  private additionalFieldType: any = {
    Text: AdditionalFieldType.Text,
    Memo: AdditionalFieldType.Memo,
    Date: AdditionalFieldType.Date,
    Time: AdditionalFieldType.Time,
    NumberWithDecimals: AdditionalFieldType.NumberWithDecimals,
    Amount: AdditionalFieldType.Amount,
    ShortInteger: AdditionalFieldType.ShortInteger,
    LongInteger: AdditionalFieldType.LongInteger,
    CheckBox: AdditionalFieldType.CheckBox,
    Timestamp: AdditionalFieldType.Timestamp,
    Phone: AdditionalFieldType.Phone,
    ComboBox: AdditionalFieldType.ComboBox,
      LinkedComboBox: AdditionalFieldType.LinkedComboBox,
      Grid: AdditionalFieldType.Grid
  };

  constructor(additionalFieldMapper: AdditionalFieldMapper,
              additionalFieldValueGetter: AdditionalFieldValueGetter,
              i18n: I18N,
              additionalFieldsValidator: AdditionalFieldsValidator) {

    this.additionalFieldMapper = additionalFieldMapper;
    this.additionalFieldsValidator = additionalFieldsValidator;
    this.i18n = i18n;
  }

  public gridTypeDisplay(field: any): string {
      return `${field.description} - ${field.columnName ? field.columnName : this.i18n.tr("Column") + " " + field.columnNo} - ${field.lineName ? field.lineName : this.i18n.tr("Line") + " " + field.lineNo}`;
  }

  private async attached(): Promise<void> {
    await this.loadData();
  }

  private async loadData(): Promise<any> {
      this.actions
          .getAll()
          .done((data: any) => {
            this.fields = this.additionalFieldMapper.mapFields(data);
          });
  }

  private async save(): Promise<void> {
      // Notice user but let it go
      await this.additionalFieldsValidator.validateAdditionalFields(this.fields, this.additionalFieldsSource);

      routerHelper.showLoading();
      const mappedData = await this.additionalFieldMapper.mapDataToSave(this.fields);

      this.actions
        .setAll(mappedData)
        .done(routerHelper.navigateBack())
        .always(routerHelper.hideLoading);
  }
}
