define(["jquery", "underscore"],
    function (jQuery, _) {
        "use strict";

        function setMask(mask, jQuerySelector) {
            jQuery(jQuerySelector).mask(mask.mask, { placeholder: mask.placeholder });
        }

        var exports = {

            phone: {
                mask: "(000) 000-0000",
                placeholder: "(___) ___-____",
                get: function (val) {
                    return val.replace('(', '').replace(')', '').replace(' ', '').replace('-', '') || '';
                }
            },
            postalCode: {
                mask: "S0S 0S0",
                placeholder: "___ ___",
                get: function (val) {
                    return val.replace(' ', '') || '';
                }
            },
            amount: {
                get: function (val) {
                    return val.replace('$', '') || '';
                },
                set: function (jQuerySelector, digits) {
                    var digitsArray = new Array(+digits + 1);
                    var mask = '$-' + digitsArray.join('9') + '.00';
                    var placeholder = '$' + digitsArray.join('_') + '.__';

                    jQuery(jQuerySelector).mask(mask, {
                        translation: {
                            '-': {
                                pattern: /[\-]/, optional: true
                            },
                            '.': {
                                pattern: /[\.]/
                            }
                        },
                        placeholder: placeholder
                    });
                }
            },
            decimal: {
                get: function (val) {
                    return val;
                },
                set: function (jQuerySelector, digits, decimals) {
                    var digitsArray = new Array(+digits + 1);
                    var decimalsArray = new Array(+decimals + 1);

                    var mask = '-' + digitsArray.join('9') + '.' + decimalsArray.join('9');
                    var placeholder = digitsArray.join('_') + '.' + decimalsArray.join('_');
                    jQuery(jQuerySelector).mask(mask, {
                        translation: {
                            '-': {
                                pattern: /[\-]/, optional: true
                            },
                            '.': {
                                pattern: /[\.]/
                            }
                        },
                        placeholder: placeholder
                    });
                }
            },
            integer: {
                set: function (jQuerySelector, digits) {
                    var mask = '-' + new Array(+digits + 1).join('0');
                    jQuery(jQuerySelector).mask(mask, {
                         translation: {
                              '-': {
                                   pattern: /[\-]/, optional: true
                              }
                         }
                    });
                }
            }
        };

        _.each(exports, function (mask) {
            if (mask.set === undefined) {
                mask.set = setMask.bind(this, mask);
            }

            mask.getById = function (id) {
                return jQuery('#' + id).cleanVal();
            };
        });

        return exports;
    });
