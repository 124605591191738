import { default as recommendationService } from "services/recommendationService";

export class Recommendation {
    dispatchProjectCode;
    fetchRecommendations;

    constructor() {
    }

    activate(params) {
        this.bindViewModel(params.dispatchProjectCode);
    }

    bindViewModel(dispatchProjectCode) {
        this.dispatchProjectCode = dispatchProjectCode;
        this.fetchRecommendations = async (status, filter, page) => await recommendationService.getRecommendationsForProject(dispatchProjectCode, status, filter, page).promise();
    }
}
