define([
    "knockout",
    "core/resx",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "services/materialRequisitionService"
], function (ko, resx, dateHelper, notificationHelper, routerHelper, materialRequisitionService) {
    "use strict";

    var widget = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.routerHelper = routerHelper;
            self.readonly = false;

            self.actions = {
                getRequisitions: function() {}
            };
            self.isProjectResponsible = false;
            self.requisitions = ko.observable({});
            //#endregion
        }

        //#region Private Methods
        function bindViewModel(widgetSettings) {
            self.actions = widgetSettings.actions;
            self.isProjectResponsible = widgetSettings.isProjectResponsible || false;
            self.readonly = widgetSettings.readonly;
        }

        function loadData() {
            return self.actions.getRequisitions().done(function (data) {
                self.requisitions(data);
            });
        }
        //#endregion

        //#region Public Methods
        ctor.prototype.activate = function (widgetSettings) {
            bindViewModel(widgetSettings);
            return loadData();
        };

        ctor.prototype.deleteRequisition = function (requisition) {
            notificationHelper.showDeleteConfirmation().done(function (success) {
                if (success) {
                    if (requisition.MobileStatus !== "0") {
                        notificationHelper.showError(resx.localize('RequisitionCompletedText'));
                    } else {
                        routerHelper.showLoading();
                        materialRequisitionService.delMaterialRequisition(requisition.Id)
                            .done(function () {
                                loadData(self);
                            })
                            .always(function () {
                                routerHelper.hideLoading();
                            });
                    }
                }
            });
        };

        ctor.prototype.genUrl = function(rel) {
            return routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly });
        };

        ctor.prototype.gotoPage = function () {
            routerHelper.navigate(routerHelper.getRelativeUrl('edit') + routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly }));
        };
        //#endregion

        return ctor;
    })();

    return widget;
});
