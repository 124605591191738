define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/routerHelper",
    "services/dailyEntryService",
    "services/attendanceService",
    "services/evaluationService",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "knockout.validation",
    "widgets/evaluationCriteria",
    "widgets/addNote"
], function (ko, _, resx, routerHelper, dailyEntryService, attendanceService, evaluationService, dateHelper, notifier) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self._ = _;
            self.resx = resx;
            self.dispatchProjectCode = ko.observable("");
            self.dispatchDate = ko.observable("");
            self.loadedCriteriasValues = ko.observableArray([]);

            self.validatedForm = ko.validatedObservable({
                validator: ko.observable(),
                employees: ko.observableArray()
            });
            //#endregion
        }

        //#region Private Functions
        function mapEmployee(employee, criterias) {
            var emp = {
                criterias: ko.observableArray(loadCriterias(criterias, employee.EmployeeNumber)),
                employee: ko.observable(employee)
            };
            _.each(emp.criterias(), function (criteria) {
                self.validatedForm().validator = self.validatedForm().validator.extend({
                    validation: {
                        validator: function () {
                            if (criteria.note() && !criteria.value()) {
                                this.message = resx.localize("err_CriteriaRequiredForNote");
                                return false;
                            }
                            var criteriaInUse = _.find(emp.criterias(), function (item) {
                                return item.value() > 0;
                            });

                            var criteriaIsNotInUse = _.find(emp.criterias(), function (item) {
                                return item.value() === 0;
                            });
                            if (criteriaInUse && criteriaIsNotInUse) {
                                this.message = resx.localize("err_AllCriteriaByEmployeeRequired");
                                return false;
                            }
                            return true;
                        }
                    }
                });
            });
            return emp;
        }

        function loadCriterias(data, employeeId) {
            return _.map(data, function (item) {
                return {
                    id: item.Id, text: item.Description, value: ko.observable(getValueNote(employeeId, item.Id, true)), note: ko.observable(getValueNote(employeeId, item.Id, false))
                };
            });
        }

        function getValueNote(employeeId, criteriaId, isValue) {
            //getCriteria value for employee
            var empCriteriasvalue = getCriteriasDetails(employeeId);
            if (empCriteriasvalue.length > 0) {
                var result = _.find(empCriteriasvalue,
                    function (criteriaData) {
                        return criteriaData.CriteriaId === criteriaId;
                    });
                if (result) {
                    return (isValue) ? result.Value || 0 : result.Note || "";
                } else {
                    return (isValue) ? 0 : "";
                }
            } else {
                return (isValue) ? 0 : "";
            }
        }


        function getCriteriasDetails(employeeId) {
            var result = _.find(self.loadedCriteriasValues(), {
                EmployeeId: employeeId
            });
            if (result) {
                return result.Details;
            } else {
                return [];
            }
        }

        function mapDataToPost() {
            return _.map(self.validatedForm().employees(), function (emp) {
                return {
                    EmployeeId: emp.employee().EmployeeNumber,
                    EvaluationDate: dateHelper.getUTCDate(self.dispatchDate()),
                    Details: _.filter(_.map(emp.criterias(), function (criteria) {
                        if (criteria.value()) {
                            return {
                                CriteriaId: criteria.id,
                                Value: criteria.value(),
                                Note: criteria.note()
                            };
                        }
                    }), function (item) {
                        return item !== undefined;
                    })
                };
            });
        }


        function loadData() {
            var dfdEvalCriterias = evaluationService.getAllCriterias();
            var dfdEmpList = attendanceService.listForDateOrderByName(self.dispatchProjectCode(), self.dispatchDate(), false, false);
            var dfdCriteriasValues = evaluationService.getEmployeeEvaluation(self.dispatchDate());

            return jQuery.when(dfdEvalCriterias, dfdEmpList, dfdCriteriasValues).done(function (criterias, employees, values) {
                self.loadedCriteriasValues(values);
                employees = _.filter(employees, function (emp) {
                    return (emp.Presence === 2 || emp.Presence === 3) && emp.EmployeeNumber > 0;
                });

                employees = _.map(employees, function (emp) {
                    return mapEmployee(emp, criterias);
                });
                self.validatedForm().employees(employees);
            });
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            dailyEntryService.logPositionIfNeeded(params.dispatchProjectCode, params.dailyEntryDate);

            var paramsQueryString = routerHelper.getQuerystring(params.q);
            self.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
            self.dispatchDate(params.dailyEntryDate);
            self.dispatchProjectCode(params.dispatchProjectCode);

            return loadData();
        };

        ctor.prototype.checkNotEvaluated = function (id) {
            var entry = _.where(self.loadedCriteriasValues(), { EmployeeId: id });
            if (entry.length === 0) {
                return true;
            } else {
                return false;
            }
        };

        ctor.prototype.save = function () {
            if (self.validatedForm.isValid()) {
                routerHelper.showLoading();
                evaluationService.addEmployeeEvaluation(self.dispatchProjectCode(), self.dispatchDate(),mapDataToPost()).done(function () {
                    routerHelper.navigateBack();
                    routerHelper.hideLoading();
                });
            } else {
                notifier.showValidationError(self.validatedForm.errors);
            }
        };

        //#endregion
        return ctor;
    })();

    return viewModel;
});
