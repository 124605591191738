import { singleton } from "aurelia-framework";

import { default as contactService } from "services/contactService";
import { default as recommendationService } from "services/recommendationService";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

@singleton()
export class RecommendationEdit {
    readonly = false;
    workOrderId = "";
    recommendationId = "";

    fetchClients;
    fetchDefaultContact;
    saveRecommendation;
  
   
    constructor() {
    }

    activate(params) {
        this.bindViewModel(params.workOrderId, params.recommendationId, params.q);
    }

    bindViewModel(workOrderId, recommendationId, querystring) {
        var params = routerHelper.getQuerystring(querystring);
        this.recommendationId = recommendationId;
        this.workOrderId = workOrderId;
        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }

        this.fetchDefaultContact = contactService.getContactLookupForWorkOrder.bind(null, workOrderId);
        this.saveRecommendation = recommendationService.addRecommendationForWorkOrder.bind(null, workOrderId);
    }
}
