// GENERATED FILE - DO NOT MODIFY
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { ProcoreConnectionInfoModel } from "api/models/company/procore/procore-connection-info-model";
import { ProcoreConnectionTestResult } from "api/models/company/procore/procore-connection-test-result";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ProcoreConfigurationProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetProcoreConfiguration(requestConfig?: HttpClientRequestConfig): Promise<ProcoreCompanyConfigurationModel | null> {
        const uri = this.buildUri(
            "/procore/configuration",
            null,
            null
        );

        const data: ProcoreCompanyConfigurationModel | null = await this.get<ProcoreCompanyConfigurationModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Procore.ProcoreCompanyConfigurationModel");
        return data;
    }

    public async SaveProcoreConfiguration(configuration: ProcoreCompanyConfigurationModel | null, requestConfig?: HttpClientRequestConfig): Promise<string | null> {
        const uri = this.buildUri(
            "/procore/configuration",
            null,
            null
        );

        const data: string | null = await this.post<ProcoreCompanyConfigurationModel | null, string | null>(uri, configuration, requestConfig);
        return data;
    }

    public async TestProcoreConnection(info: ProcoreConnectionInfoModel | null, requestConfig?: HttpClientRequestConfig): Promise<ProcoreConnectionTestResult | null> {
        const uri = this.buildUri(
            "/procore/configuration/test",
            null,
            null
        );

        const data: ProcoreConnectionTestResult | null = await this.post<ProcoreConnectionInfoModel | null, ProcoreConnectionTestResult | null>(uri, info, requestConfig);
        this.transformModels(data, "Company.Procore.ProcoreConnectionTestResult");
        return data;
    }
}
