import { transient } from "aurelia-framework";
import { default as ko } from "knockout";

@transient()
export class InvalidateDateTemplate {

    public invalidateDate: any = ko.observable();

    public activate(settings: any): void {
        this.invalidateDate = settings.templateContext.invalidateDate;
    }
}
