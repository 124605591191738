import { autoinject } from "aurelia-framework";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { ServiceCallDispatchEquipmentMaintenanceMaterialService } from "services/service-call-dispatch-equipment-maintenance-material";

import { I18N } from "aurelia-i18n";

import { MaCardOptionsModel, MaCardLineModel } from "components/layout/ma-card/ma-card-options.model";
import { ServiceCallEquipmentMaintenanceMaterialModel } from "api/models/company/service/service-call-equipment-maintenance-material-model";

export interface EquipmentMaintenanceParameters {
    dispatchId: number;
    equipmentId: number;
    equipmentCode: string;
    maintenanceId: string;
    maintenanceFullDescription: string;
    readonly: boolean;
}
@autoinject
export class EquipmentMaintenanceRequiredMaterial {
    public materialCards!: MaCardOptionsModel[];

    public dispatchId: number = 0;
    public equipmentCode: string = "";
    public equipmentId: number = 0;
    public maintenanceFullDescription: string = "";

    private maintenanceId: string = "";
   
    private materials: ServiceCallEquipmentMaintenanceMaterialModel[] | null = null;

    constructor(private readonly i18N: I18N, private readonly serviceCallDispatchEquipmentMaintenanceMaterialService: ServiceCallDispatchEquipmentMaintenanceMaterialService) {
    }

    public async activate(params: EquipmentMaintenanceParameters): Promise<void> {
        this.equipmentCode = params.equipmentCode;
        this.equipmentId = params.equipmentId;
        this.maintenanceId = params.maintenanceId;
        this.maintenanceFullDescription = params.maintenanceFullDescription;
        this.dispatchId = params.dispatchId;
       
        await this.loadData();
    }

    public createCard(item: ServiceCallContractEquipmentModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            displayLines: [
                new MaCardLineModel({ isCustomTemplate: true })
            ]
        });
        
        return card;
    }

    private async loadData(): Promise<void> {
        this.materials = await this.serviceCallDispatchEquipmentMaintenanceMaterialService.get(this.dispatchId, this.equipmentCode, this.maintenanceId, this.equipmentId);
        this.materialCards = this.materials!.map(this.createCard.bind(this));
    }
}
