import { ProcoreConfigurationProxy } from "api/proxies/procore-configuration-proxy";
import { ProcoreConnectionInfoModel } from "api/models/company/procore/procore-connection-info-model";
import { ProcoreConnectionTestResult } from "api/models/company/procore/procore-connection-test-result";
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { autoinject } from "aurelia-framework";
import { ProcoreJobProxy } from "api/proxies/procore-job-proxy";
import { JobProxy } from "api/proxies/job-proxy";
import { ProcoreJobStatus } from "api/models/procore/job_-system/procore-job-status";

@autoinject()
export class ProcoreConfigurationService {
    constructor(private readonly procoreConfigurationProxy: ProcoreConfigurationProxy,
                private readonly procoreJobProxy: ProcoreJobProxy) { }

    public async GetProcoreConfiguration(): Promise<ProcoreCompanyConfigurationModel | null> {
        return await this.procoreConfigurationProxy.GetProcoreConfiguration();
    }

    public async SaveProcoreConfiguration(configuration: ProcoreCompanyConfigurationModel | null): Promise<string | null> {
        return await this.procoreConfigurationProxy.SaveProcoreConfiguration(configuration);
    }

    public async TestProcoreConnection(info: ProcoreConnectionInfoModel | null): Promise<ProcoreConnectionTestResult | null> {
        return await this.procoreConfigurationProxy.TestProcoreConnection(info);
    }

    public async GetAllJobStatuses(): Promise<ProcoreJobStatus[] | null> {
        return await this.procoreJobProxy.GetAllJobStatuses();
    }
}
