// GENERATED FILE - DO NOT MODIFY
export enum ServiceCallQuotationSortOrder {
    Date = 1,
    Status = 2,
    Type = 3,
    EmergencyLevel = 4,
    RequiredDate = 5,
}

export let nameof = "ServiceCallQuotationSortOrder";
