define([
    "underscore",
    "repositories/settingRepository"
], function (_, settings) {
    "use strict";

    var exports = {
        appendFilterPagingParams: function (url, filter, page) {
            if (filter) {
                url += ((url.indexOf("?") === -1) ? "?" : "&") + "filter=" + filter;
            }
            if (page) {
                url += ((url.indexOf("?") === -1) ? "?" : "&") + "page=" + page;
            }

            return url;
        },

        appendQueryStringParameters: function (url, params) {
            return url + ((url.indexOf("?") === -1) ? "?" : "&") + this.formatQueryStringParameters(params);
        },

        formatQueryStringParameters: function (params) {
            var queryString = '';
            if (params) {
                params = _.map(params, function (val, key) {
                    if (val !== null && val !== undefined) {
                        return key + '=' + val;
                    }
                });
                if (params && params.length) {
                    queryString += _.reject(params, function (param) { return param === undefined; }).join("&");
                }
            }

            return queryString;
        },

        getExternalWeatherApiUrl: function (position) {
            var apiKey = "d98b1ff784c57d7b235279731d677487";
            return "https://api.forecast.io/forecast/" + apiKey + "/" + position.coords.latitude + "," + position.coords.longitude + "/?exclude=hourly,minutely,daily,flags&lang=" + settings.getLanguage() + "&units=ca";
        },

        getBaseUrl: function (company) {
            return "api/companies/" + (company ? company : settings.getCompany());
        },

        getDispatchBaseUrl: function (dispatchId) {
            return this.getBaseUrl() + "/dispatch" + (dispatchId ? "/" + dispatchId : "");
        },

        getCatalogDispatchBaseUrl: function (dispatchId) {
            return this.getBaseUrl() + "/catalog/dispatch" + (dispatchId ? "/" + dispatchId : "");
        },

        getFieldServiceBaseUrl: function () {
            return this.getBaseUrl() + "/fieldservices";
        },

        getFieldServiceWorkOrderBaseUrl: function (workOrderId) {
            return this.getFieldServiceBaseUrl() + "/workorders" + (workOrderId ? "/" + workOrderId : "");
        },

        getFieldServiceWorkOrderAttendancesBaseUrl: function (workOrderId, dispatchId) {
            return this.getFieldServiceWorkOrderBaseUrl(workOrderId) + "/attendances" + (dispatchId ? "/" + dispatchId : "");
        },

        getFieldServiceWorkOrderAvailableAttendancesBaseUrl: function (workOrderId) {
            return this.getFieldServiceWorkOrderAttendancesBaseUrl(workOrderId) + "/available";
        },

        getDispatchProjectBaseUrl: function (projectDispatchCode) {
            return this.getDispatchBaseUrl() + "/projects" + (projectDispatchCode ? "/" + projectDispatchCode : "");
        },

        getProjectDailyEntryBaseUrl: function (projectDispatchCode, dispatchDate) {
            return this.getDispatchProjectBaseUrl(projectDispatchCode) + "/dispatchdates/" + dispatchDate;
        },

        getCatalogProjectDailyEntryBaseUrl: function (projectDispatchCode, dispatchDate) {
            return this.getCatalogDispatchBaseUrl() +
                "/projects" +
                (projectDispatchCode ? "/" + projectDispatchCode : "") +
                "/dispatchdates/" +
                dispatchDate;
        },

        getProjectDispatchBaseUrl: function (dispatchId) {
            return this.getDispatchProjectBaseUrl() + "/dispatches" + (dispatchId ? "/" + dispatchId : "");
        },

        getProjectDispatchEmployeesBaseUrl: function (projectDispatchCode, dispatchDate) {
            return this.getProjectDailyEntryBaseUrl(projectDispatchCode, dispatchDate) + "/employees";
        },

        getServiceBaseUrl: function (serviceType) {
            return this.getBaseUrl() + (serviceType === "S" ? "/serviceCalls/" : "/workorders/");
        },

        getCatalogServiceBaseUrl: function (serviceType) {
            return this.getBaseUrl() + "/catalog" + (serviceType === "S" ? "/serviceCalls/" : "/workorders/");
        },

        getWorkOrderBaseUrl: function (workOrderId) {
            return this.getBaseUrl() + "/workorders" + (workOrderId ? "/" + workOrderId : "");
        },

        getWorkOrderTypeBaseUrl: function (workOrderId) {
            return this.getBaseUrl() + "/work-order-type" + (workOrderId ? "/" + workOrderId : "");
        },

        getProjectBaseUrl: function (projectCode) {
            return this.getBaseUrl() + "/projects" + (projectCode ? "/" + projectCode : "");
        },

        getInspectionBaseUrl: function (inspectionId) {
            return this.getBaseUrl() + "/inspections" + (inspectionId ? "/" + inspectionId : "");
        },

        getInspectionTypeBaseUrl: function (typeId) {
            return this.getBaseUrl() + "/inspections/types" + (typeId ? "/" + typeId : "");
        },

        getWorkflowBaseUrl: function () {
            return this.getBaseUrl() + "/workflows";
        },

        getWorkflowApprovalBaseUrl: function (action) {
            return this.getBaseUrl() + "/workflows/approbations" + (action ? "/" + action : "");
        },

        getEmployeesBaseUrl: function () {
            return this.getBaseUrl() + "/employees";
        },

        getTimesheetBaseUrl: function (tsId) {
            return this.getBaseUrl() + "/timesheets" + (tsId ? "/" + tsId : "");
        },

        getEquipmentBaseUrl: function (equipmentId) {
            return `${this.getBaseUrl()}/equipments${equipmentId ? "/" + equipmentId : ""}`;
        },

        getTimesheetEquipmentCounterBaseUrl: function () {
            return `${this.getBaseUrl()}/timesheetequipmentcounters`;
        },

        getPurchaseOrderBaseUrl: function (orderId) {
            return `${this.getBaseUrl()}/purchaseorder${orderId ? "/" + orderId : ""}`;
        }
    };

    return exports;
});