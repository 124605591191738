define([
    "underscore",
    "knockout",
    "moment",
    "core/resx",
    "services/employeeService",
    "helpers/phoneHelper",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "knockout.validation"
], function (_, ko, moment, resx, employeeService, phoneHelper, dateHelper, routerHelper, notificationHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        var startTimeInit = null;
        var endTimeInit = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.employeeId = ko.observable(0);
            self.labelStart = resx.localize('StartTime');
            self.labelEnd = resx.localize('EndTime');
            self.types = {
                transport: function(params, success, failure) {
                    employeeService.getNonAvailabilityTypes(params.data.filter, params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.Id, text: item.Id + ' - ' + item.Description };
                }
            };

            self.validatedForm = ko.validatedObservable({
                selectedType: ko.observable(null),
                startTime: ko.observable(moment()),
                endTime: ko.observable(moment()),

                init: function() {
                    var selfForm = this;
                    selfForm.selectedType.extend({
                        required: {
                            message: resx.localize('err_TypeRequired')
                        }
                    });

                    selfForm.endTime.extend({
                        validation: {
                            validator: function(val) {
                                if (moment(val).isSameOrBefore(moment(selfForm.startTime()))) {
                                    this.message = self.labelEnd + resx.localize("err_GreatherThan") + self.labelStart;
                                    return false;
                                }
                                return true;
                            }
                        }
                    });
                },

                clear: function() {
                    var selfForm = this;
                    selfForm.selectedType(null);
                    selfForm.startTime(moment());
                    selfForm.endTime(moment());
                }
            });
            //#endregion
        }

        //#region Private Functions
        function clear() {
            self.validatedForm().clear();
        }

        function loadData() {
            self.validatedForm().init();
        }

        function bindViewModel(widgetSettings) {
            self.employeeId(widgetSettings.employeeId);

            if(widgetSettings.startTime) {
                startTimeInit = widgetSettings.startTime;
                endTimeInit = widgetSettings.endTime;
            }
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (widgetSettings) {
            bindViewModel(widgetSettings);

            return true;
        };

        ctor.prototype.compositionComplete = function () {
            self.validatedForm().init();

            if(startTimeInit !== null) {
                self.validatedForm().startTime(moment(startTimeInit));
                self.validatedForm().endTime(moment(endTimeInit));
            }
        };

        ctor.prototype.genUrl = function (route, param) {
            var relativeUrl = '';

            if (param !== undefined) {
                relativeUrl = routerHelper.getRelativeUrl(route, param);
            } else {
                relativeUrl = routerHelper.getRelativeUrl(route);
            }

            return relativeUrl + routerHelper.addQuerystring({ isProjectResponsible: self.isResponsible, readonly: self.readonly });
        };

        ctor.prototype.save = function() {
            if (!self.validatedForm.isValid()) {
                notificationHelper.showValidationError(self.validatedForm.errors);
            } else {
                routerHelper.showLoading();
                var data = {
                    EndDate: self.validatedForm().endTime(),
                    StartDate: self.validatedForm().startTime(),
                    TypeId: self.validatedForm().selectedType().id
                };

                employeeService.setNonAvailabilityForEmployee(self.employeeId(), data)
                    .done(routerHelper.navigateBack)
                    .always(routerHelper.hideLoading);
            }
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
