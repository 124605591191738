import { WorkOrderWorkTaskDocumentService } from "services/work-order-work-task-document-service";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

@autoinject()
export class WorkTaskEditDocumentsAdd {

    public actions: any;
    public transactionNumber: number = 0;

    constructor(private readonly i18n: I18N, private readonly workOrderWorkTaskDocumentService: WorkOrderWorkTaskDocumentService) {}

    public async activate(params: any): Promise<void> {
        this.transactionNumber = params.transactionNumber;
        this.actions = { save: (models: any): JQueryDeferred<any> => {
            const dfd = jQuery.Deferred();
            this.workOrderWorkTaskDocumentService.AddDocumentForWorkTask(this.transactionNumber, models).then(() => {
                return dfd.resolve();
            });
            return dfd;
         }};
    }
}
