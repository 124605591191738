import { DailyEntryLinkEquipmentProxy } from "api/proxies/daily-entry-link-equipment-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { PagingInfo } from "api/paging-info";

@autoinject()
export class DailyEntryLinkEquipmentService {
    constructor(private readonly dailyEntryLinkEquipmentProxy: DailyEntryLinkEquipmentProxy) {
    }

    public async getDailyEntryEquipments(dispatchProjectId: string | null, dispatchDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        return await this.dailyEntryLinkEquipmentProxy.GetDailyEntryEquipments(dispatchProjectId, dispatchDate, filter, pagingInfo, requestConfig);
    }

    public async saveNewDailyEntryLinkEquipment(dispatchId: number, newLinkEquipment: EquipmentModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return await this.dailyEntryLinkEquipmentProxy.SaveNewDailyEntryLinkEquipment(dispatchId, newLinkEquipment, requestConfig);
    }

    public async deleteDailyEntryLinkEquipment(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return await this.dailyEntryLinkEquipmentProxy.DeleteDailyEntryLinkEquipment(dispatchId, requestConfig);
    }
}
