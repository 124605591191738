import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";

define([
    "knockout",
    "services/additionalFieldsService",
    "core/resx",
    "underscore",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "moment"
], function (ko, addFieldsService, resx, _, dateHelper, routerHelper, moment) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.actions = {};
            self.dispatchDate = ko.observable();
            self.dispatchProjectCode = ko.observable();
            self.readonly = false;
            self.additionalFieldsSource = AdditionalFieldsSourceType.DailyEntry;
            self.saveAdditionalFieldsButton = true;
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, queryString) {
            var params = routerHelper.getQuerystring(queryString);
            self.readonly = !(params && params.readonly === "false");

            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dispatchDate);
        }

        function bindWidget() {
            self.actions = {
                getAll: addFieldsService.getAllForProject.bind(self, self.dispatchProjectCode(), self.dispatchDate()),
                setAll: addFieldsService.setAllForProject.bind(self, self.dispatchProjectCode(), self.dispatchDate())
        };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
            bindWidget();
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
