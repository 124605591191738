define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "helpers/enumHelper",
    "services/bonusService",
    "services/projectService",
    "services/commonService",
    "services/templateService",
    "helpers/viewbag"
], function (ko, _, resx, notificationHelper, queryStringHelper, routerHelper, enumHelper, bonusService, projectService, commonService, templateService, viewbag) {
    "use strict";

    var PAUSE_ACTIVITY_ID = "PAUSE";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.bonusId = ko.observable();
            self.dispatchProjectCode = ko.observable("");
            self.dispatchDate = ko.observable("");
            self.dispatchIds = ko.observableArray([]);
            self.activities = ko.observableArray([]);
            self.isProjectResponsible = false;
            self.readonly = true;
            self.showSubProject = ko.observable(false);
            self.subscriptions = [];
            self.unit = ko.observable("");
            self.message = ko.observable("");
            self.activityAvailable = ko.observable(false);
            self.defaultLaborActivityCode = ko.observable("");

            // default bonus
            self.applicable = ko.observable();
            self.isDefaultbonus = false;
            self.isDefaultBonusInHour = false;
            self.defaultQuantity = 0;

            self.validatedForm = ko.validatedObservable({
                activity: ko.observable(),
                bonus: ko.observable(),
                quantity: ko.observable(),
                subProject: ko.observable(),

                clear: function() {
                    this.activity(null);
                    this.bonus(null);
                    this.quantity(null);
                    this.subProject(null);
                }
            });

            self.validatedForm().bonus.extend({
                required: {
                    message: resx.localize('err_BonusRequired')
                }
            });

            self.validatedForm().quantity.extend({
                validation: {
                    validator: function() {
                        if (self.validatedForm().quantity() === "") {
                            this.message = resx.localize("err_QuantityRequired");
                            return false;
                        }

                        if (!self.isDefaultBonusInHour && self.validatedForm().quantity() <= 0) {
                            this.message = resx.localize("err_MinQuantity");
                            return false;
                        }
                        return true;
                    }
                }
            });

            self.getProjects = {
                transport: function(params, success, failure) {
                    projectService.getSubProjects(self.dispatchProjectCode(), params.data.filter, params.data.page)
                        .done(function (data) {
                            return success(data);
                        })
                        .fail(failure);
                }
            };
            //#endregion
        }

        //#region Private Functions
        async function bindViewModel(dispatchProjectCode, dispatchDate, bonusId, queryString) {
            viewbag({ isFromBonusEntry: true });
            self.activityAvailable(!templateService.getCurrentTemplateConfigs().ActivityNotAllowedInMobility);
           
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);
            self.readonly = queryStringHelper.parseReadonly(queryString);

            var ids = routerHelper.getQuerystring(queryString).dispatchIds.split(",");
            var name = routerHelper.getQuerystring(queryString).name;
            self.defaultLaborActivityCode(routerHelper.getQuerystring(queryString).defaultLaborActivityCode);
            self.dispatchIds(ids);

            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);
            self.bonusId(bonusId);

            if (ids.length > 1) {
                self.message(resx.localize("msg_DailyEntry_EnteringBonusForXEmployeesSelected").replace("{0}", ids.length));
            } else {
                self.message(name);
            }

            if (self.activityAvailable()) {
                await self.getActivities();
            }
               
        }

        function loadData() {
            var bonus;

            if (self.bonusId()) {
                bonus = bonusService.getBonus(self.bonusId());
            } else {
                bonus = jQuery.Deferred().resolve();
            }

            var hasSubProjects = projectService.hasSubProjects(self.dispatchProjectCode());
            var lastProjectUsed = projectService.getLastProjectUsed(self.dispatchProjectCode(), self.dispatchDate());

            return jQuery.when(bonus, hasSubProjects, lastProjectUsed).done(function (bonuses, hasPrj, lastPrjUsed) {
                initializeProjects(hasPrj, lastPrjUsed);
                if (bonuses) {
                    loadBonus(bonuses);
                }
            });
        }

        function initializeProjects(hasPrj, lastPrjUsed) {
            self.showSubProject(hasPrj);
            if (lastPrjUsed) {
                self.validatedForm().subProject({ id: lastPrjUsed.Id, text: lastPrjUsed.Id + ' - ' + lastPrjUsed.Description });
            }
        }

        function initActivityValidation() {
            if (self.activityAvailable()) {
                self.validatedForm().activity.extend({
                    required: {
                        message: resx.localize('err_ActivityRequired')
                    }
                });
            }
        }

        function buildBonusDto() {
            return _.map(self.dispatchIds(),
                function (dispatchId) {
                    var data = {};

                    if (self.bonusId()) {
                        data.BonusId = self.bonusId();
                    }
                    if (self.validatedForm().subProject()) {
                        //Set the project code if we have it
                        data.ProjectCode = self.validatedForm().subProject().id;
                    }
                    data.DispatchId = dispatchId;

                    if (self.validatedForm().activity()) {
                        data.ActivityCode = self.validatedForm().activity().id;
                    }

                    data.BonusCode = self.validatedForm().bonus().id;
                    data.Quantity = self.validatedForm().quantity();

                    return data;
                });
        }

        function loadBonus(data) {
            self.validatedForm().activity({ id: data.ActivityCode, text: data.ActivityCode + " - " + data.ActivityDescription });
            self.validatedForm().bonus({ id: data.BonusCode, text: data.BonusCode+ " - " +data.BonusDescription });
            self.validatedForm().quantity(data.Quantity);

            self.applicable(data.Quantity > 0);
            self.isDefaultbonus = data.DefaultBonusId && data.DefaultBonusId > 0;

            // In readonly mode, quantity in hour is show like a normal quantity
            if(!self.readonly) {
                self.isDefaultBonusInHour = self.isDefaultbonus && data.IsQuantityInHour;
                self.defaultQuantity = data.DefaultQuantity;
            }

            self.unit(data.BonusUnit);
            if (data.ProjectCode) {
                self.validatedForm().subProject({ id: data.ProjectCode, text: data.ProjectCode + " - " + data.ProjectDescription });
            }
        }

        function initialiseSubscription() {
            self.subscriptions.push(
                self.validatedForm().subProject.subscribe(function () {
                    self.validatedForm().activity(null);
                })
            );
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = async function (params) {
            self.clear();
            await bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
            return loadData();
        };

        ctor.prototype.attached = function () {
            initialiseSubscription();
            initActivityValidation();
        };

        ctor.prototype.clear = function () {
            _.each(self.subscriptions,
               function (s) {
                   s.dispose();
               });
            self.validatedForm().clear();
            self.showSubProject(false);
            self.unit("");
        };

        ctor.prototype.getActivity = {
            transport: function (params, success, failure) {

                if (!self.showSubProject()) {
                    projectService.getActivitiesForDispatchProject(self.dispatchProjectCode(), params.data.filter, params.data.page || 1, enumHelper.workOrderActivityType().LABOUR)
                        .done(function(data) {
                            // Cannot add a bonus to a break.
                            return success(_.reject(data, function(item) { return item.Id === PAUSE_ACTIVITY_ID; }));
                        })
                        .fail(failure);
                } else {
                    projectService.getActivitiesForProject(self.validatedForm().subProject().id, params.data.filter, params.data.page || 1, enumHelper.workOrderActivityType().LABOUR)
                        .done(function (data) {
                            // Cannot add a bonus to a break.
                            return success(_.reject(data, function (item) { return item.Id === PAUSE_ACTIVITY_ID; }));
                        })
                        .fail(failure);
                }
            }
        };

        ctor.prototype.getBonuses = {
            transport: function (params, success, failure) {
                bonusService.getBonusesForDispatchProject(self.dispatchProjectCode(), params.data.filter, params.data.page || 1)
                    .done(function (data) {
                        return success(data);
                    })
                    .fail(failure);
            }
        };

        ctor.prototype.onSelectBonus = function (selectedItem) {
            self.validatedForm().quantity(1);
            self.unit(selectedItem.data.Unit);

            if (self.activityAvailable()) {
                var activityCode = selectedItem.data.DefaultActivityCode;
                if (!activityCode) {
                    activityCode = self.defaultLaborActivityCode();
                }
                self.validatedForm().activity(self.createDefaultMaSelectElement(activityCode, self.activities()))
            }
        };

        ctor.prototype.createDefaultMaSelectElement = function(id, list) {
            if (!id) { return null; }

            const item = list.find((item) => item.Id === id);
            if (!item) {
                return null
            }

            return { id: item.Id, text: item.Id + " - " + item.Description };
        };

        ctor.prototype.onUnSelectBonus = function () {
            self.unit("");
        };

        ctor.prototype.save = function () {
            if (!self.validatedForm.isValid()) {
                notificationHelper.showValidationError(self.validatedForm.errors);
            } else {
                routerHelper.showLoading();
                bonusService.setBonus(buildBonusDto())
                    .done(function () {
                        routerHelper.navigateBack();
                    })
                    .always(function () {
                        routerHelper.hideLoading();
                    });
            }
        };

        ctor.prototype.applicableChanged = function() {
            self.applicable(!self.applicable());

            if(self.applicable()) {
                self.validatedForm().quantity(self.defaultQuantity);
            } else {
                self.validatedForm().quantity(0);
            }
        };

        ctor.prototype.getActivities = async function() {
            if (!self.showSubProject()) {
                projectService.getActivitiesForDispatchProject(self.dispatchProjectCode(), null, null, enumHelper.workOrderActivityType().LABOUR)
                    .done(function (data) {
                        // Cannot add a bonus to a break.
                        self.activities(_.reject(data, function (item) { return item.Id === PAUSE_ACTIVITY_ID; }));
                    });
            } else {
                projectService.getActivitiesForProject(self.validatedForm().subProject().id, null, null, enumHelper.workOrderActivityType().LABOUR)
                    .done(function (data) {
                        // Cannot add a bonus to a break.
                        self.activities(_.reject(data, function (item) { return item.Id === PAUSE_ACTIVITY_ID; }));
                    });
            }
        };

        //#endregion
        return ctor;
    })();

    return viewModel;
});
