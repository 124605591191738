import Logger from "core/logger";
import { default as ko } from "knockout";

export class ErrorHandlingKnockoutBindingProvider implements KnockoutBindingProvider {
    public original: KnockoutBindingProvider;
    public nodeHasBindings: (node: Node) => boolean;

    constructor() {
        this.original = new ko.bindingProvider();

        //determine if an element has any bindings
        this.nodeHasBindings = this.original.nodeHasBindings;
    }

    public getBindings(node: Node, bindingContext: KnockoutBindingContext): {} {
        return this.original.getBindings(node, bindingContext);
    }

    //return the bindings given a node and the bindingContext
    public getBindingAccessors(node: Node, bindingContext: KnockoutBindingContext): { [key: string]: string; } {
        let result: any | undefined = {};

        if (!this.original.getBindingAccessors) {
            return result;
        }

        //catch general errors parsing binding syntax
        try {
            result = this.original.getBindingAccessors(node, bindingContext);
        } catch (e) {
            if (Logger && Logger.error) {
                Logger.error("Error in binding syntax: " + e.message, node);
            }

            throw e;
        }

        //catch errors when actually evaluating the value of a binding
        ko.utils.objectForEach(result, (key: string, value: any) => {
            result[key] = (): any => {
                let result2: any = null;

                try {
                    result2 = value();
                } catch (e) {
                    if (Logger && Logger.error) {
                        Logger.error("Error in \"" + key + "\" binding: " + e.message, node);
                    }

                    throw e;
                }

                return result2;
            };
        });

        return result;
    }
}
