define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/queryStringHelper",
    "services/materialService"
], function (ko, _, resx, queryStringHelper, materialService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = false;
            self.workOrderId = ko.observable();
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(workOrderId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.workOrderId(workOrderId);

            self.actions = {
                deleteMaterial: materialService.deleteForWorkOrder.bind(null, workOrderId),
                getMaterials: materialService.listForWorkOrder.bind(null, workOrderId),
                getReserved: getReserved,
                setReservedMaterials: materialService.setWorkOrderReservedMaterials.bind(null, workOrderId) 
            };
        }

        function getReserved() {
            return materialService.getIsReservedMaterials(self.workOrderId());
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.q);
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
