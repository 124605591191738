import { bindable } from "aurelia-framework";
import { autoinject } from "aurelia-dependency-injection";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

@autoinject
export class Contact {
    @bindable public actions: any;
    @bindable public readonly: boolean = false;
    public contacts: any;
    public dispatchId: string = "";
    public serviceType: string = "";
    public querystringToAdd: string = "";
    public dispatchProjectCode: string = "";

    constructor(private readonly routeRepository: RouteRepository) {
    }

    public bind(bindingContext: any): any {
        this.dispatchProjectCode = bindingContext.dispatchProjectCode;
        this.actions = bindingContext.actions;
        this.querystringToAdd = routerHelper.addQuerystring({readonly: this.readonly});
        this.loadData();
    }

    public genUrl(contact: any): string {
        return  routerHelper.getRelativeUrl("detail", contact.Id) + this.querystringToAdd;
    }

    private loadData(): void {
        this.actions.getContacts().then((data: any) => {
            this.contacts = data;
        });
    }
}
