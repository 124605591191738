import EmailHelper from "helpers/emailHelper";
define([
        "underscore",
        "knockout",
        "core/resx",
        "core/val",
        "services/workOrderService",
        "services/materialService",
        "services/clientService",
        "services/workorderTimesheetService",
        "services/contactService",
        "helpers/viewbag",
        "helpers/routerHelper",
        "helpers/dateHelper",
        "helpers/labelHelper",
        "helpers/notificationHelper",
], function (_, ko, resx, val, workOrderService, materialService, clientService, workorderTimesheetService, contact, viewbag, routerHelper, dateHelper, labelHelper, notificationHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor(emailHelper) {
            this.emailHelper = emailHelper;
            self = this;

            //#region Properties
            self.resx = resx;
            self.val = val;
            self.dateHelper = dateHelper;
            self.labelHelper = labelHelper;
            self.routerHelper = routerHelper;

            self.client = ko.observable({});
            self.contactNameAvailable = ko.observable(true);
            self.contacts = ko.observableArray();
            self.workOrder = ko.observable({});
            self.emailAvailable = ko.observable(false);

            self.labor = ko.observable({});
            self.material = ko.observableArray();
            self.signatureAvailable = ko.observable(false);
            self.isFromSignature = false;

            self.loadContacts = {
                transport: function(params, success, failure) {
                    contact.getByCustomerCode(self.client().Id, params.data.filter, params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.No, text: item.Name + ', ' + item.FirstName, data: item.Email };
                }
            };

            self.validatedForm = ko.validatedObservable({
                contact: ko.observable(null),
                email: ko.observable('')
                    .extend({
                        validation: {
                            validator: function (email) {
                                this.message = resx.localize("err_InvalidEmail");
                                var invalidEmails = "";
                                if (email.length > 0) {
                                    invalidEmails = self.emailHelper.findInvalidEmailsInString(email);
                                    if (invalidEmails.length >= 1) {
                                        this.message += " " + invalidEmails.join(", ");
                                        return false;
                                    }
                                }
                                return true;
                            }
                        }
                    }),
                txtSignature: ko.observable('')
                    .extend({
                        required: {
                            params: true,
                            message: resx.localize("err_SignatureRequired")
                        }
                    }),
                clear: function() {
                    var valForm = this;
                    valForm.txtSignature('');
                    valForm.contact(null);
                    valForm.email('');
                }
            });

            self.validatedForm().contact.subscribe(function(newValue) {
                if (newValue !== null && newValue.id !== undefined) {
                    self.validatedForm().email(newValue.data.Email);
                    self.validatedForm().txtSignature(newValue.text);
                    self.contactNameAvailable(false);
                } else {
                    self.validatedForm().email('');
                    self.validatedForm().txtSignature('');
                    self.contactNameAvailable(true);
                }
            });
            //#endregion
        }

        ctor.inject = [EmailHelper];

        //#region Private Functions
        async function loadData(workOrderId) {
             initClient(await clientService.getClientInfoForWorkOrder(workOrderId).promise());

            const workOrderPromise = workOrderService.get(workOrderId).promise();
            const laborsPromise = workorderTimesheetService.listForSummary(workOrderId, self.specifiedLanguage).promise();
            const materialPromise = materialService.listForWorkOrder(workOrderId).promise();

            initLabors(await laborsPromise);
            initWorkOrder(await workOrderPromise);
            initMaterials(await materialPromise);
            initSignature();
        }

        function initClient(client){
            if (client === null) {
                self.client({ MobilitySignatureRequiredness: 0 });
                self.clientLanguage = null;
            } else {
                self.client(client);
                self.clientLanguage = labelHelper.getLanguageAcronym(client.Language);
            }

            self.specifiedLanguage = !self.isCompleted ? self.clientLanguage : null;
        }

        function initLabors(labors){
            self.labor(labors);
        }

        function initWorkOrder(workOrder) {
            self.workOrder(workOrder);
        }

        function initMaterials(material) {
            self.material([
                {
                    title: resx.localize('Material', self.specifiedLanguage),
                    items: _.filter(material, function (val) { return val.ItemType === 0 || val.ItemType === 2; })
                },
                { title: resx.localize('Salary', self.specifiedLanguage), items: _.where(material, { ItemType: 1 }) },
                { title: resx.localize('Subcontractor', self.specifiedLanguage), items: _.where(material, { ItemType: 5 }) },
                { title: resx.localize('Equipment', self.specifiedLanguage), items: _.where(material, { ItemType: 3 }) },
                { title: resx.localize('Misc', self.specifiedLanguage), items: _.where(material, { ItemType: 4 }) }
            ]);

            self.material(_.filter(self.material(), function (val) { return val.items.length > 0; }));
        }

        function initSignature() {
            if (self.isFromSignature) {
                self.signatureAvailable(!self.workOrder().IsReadOnly && self.client().MobilitySignatureRequiredness !== 2);
            } else {
                self.signatureAvailable(false);
            }

            if (self.signatureAvailable() && (self.client() && self.client().MobilityEmailAvailability !== undefined)) {
                if (self.client().MobilityEmailAvailability === 0) {
                    self.emailAvailable(self.client().GeneralConfigSaveFormWorkorder);
                } else {
                    self.emailAvailable(self.client().MobilityEmailAvailability === 1 ? true : false);
                }
            } else {
                self.emailAvailable(false);
            }


        }

        function parseQueryString(querystring) {
            var json = routerHelper.getQuerystring(querystring);

            if (!json) return;

            self.isCompleted = !(json.isCompleted === "false") || false;
        }
        //#endregion



        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            self.validatedForm().clear();
            self.isFromSignature = viewbag() && viewbag().isFromFieldServiceSignature === true;
            viewbag(null);

            parseQueryString(params.q);
            return loadData(params.workOrderId);
        };

        ctor.prototype.sign = function () {
            if (self.validatedForm.isValid()) {
                viewbag({
                    txtSignature: self.validatedForm().txtSignature(),
                    email: self.validatedForm().email(),
                    doPrintForm: true
                });
                routerHelper.navigate(routerHelper.getRelativeUrl('signature') + routerHelper.addQuerystring({ readonly: false }));
            } else {
                notificationHelper.showValidationError(self.validatedForm.errors);
            }
        };

        ctor.prototype.attached = function () {
            routerHelper.hideLoading();
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});