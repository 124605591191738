import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";
import { ServiceDispatchType } from "enums/service-dispatch-type";

define([
    "knockout",
    "services/additionalFieldsService",
    "core/resx",
    "underscore",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "moment",
    "widgets/pages/additionalFields"
], function (ko, addFieldsService, resx, _, dateHelper, routerHelper, moment) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        ctor.inject = [I18N, EventAggregator];

        function ctor(i18n, eventAggregator) {
            self = this;
            //#region Properties
            self.actions = {};
            self.serviceType = ko.observable();
            self.dispatchId = ko.observable();
            self.readonly = false;
            self.additionalFieldsSource = AdditionalFieldsSourceType.ServiceCall;
            self.saveAdditionalFieldsButton = true;
            self.eventAggregator = eventAggregator;
            self.i18n = i18n;
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(serviceType, dispatchId, queryString) {
            var params = routerHelper.getQuerystring(queryString);
            self.readonly = !(params && params.readonly === "false");

            self.serviceType(serviceType);
            self.dispatchId(dispatchId);

            if (serviceType === ServiceDispatchType.Service) {
                self.eventAggregator.publish("updateTitle", `${self.i18n.tr("pageTitle_Service_Detail_AdditionalFields")} (${self.i18n.tr("CurrentVisit")})`);
            }
        }

        function bindWidget() {
            self.actions = {
                getAll: addFieldsService.getAllForDispatch.bind(self, self.serviceType(), self.dispatchId()),
                setAll: addFieldsService.setAllForDispatch.bind(self, self.serviceType(), self.dispatchId())
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.serviceType, params.dispatchId, params.q);
            bindWidget();
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
