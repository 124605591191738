import { singleton } from "aurelia-framework";

import { default as recommendationService } from "services/recommendationService";

import "widgets/pages/recommendation_edit";

export class RecommendationEdit {
    dispatchProjectCode;
    dispatchDate;
    recommendationId = "";

    saveRecommendation;

    constructor() {
    }

    activate = function (params) {
        this.bindViewModel(params.dispatchProjectCode, params.recommendationId);
    };

    bindViewModel(dispatchProjectCode, recommendationId) {
        this.recommendationId = recommendationId;
        this.dispatchProjectCode = dispatchProjectCode;

        this.saveRecommendation = recommendationService.addRecommendationForProject.bind(null, dispatchProjectCode);
    }
}
