import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { SmsMessageModel } from "api/models/company/s-m-s/sms-message-model";
import { SmsContactModel } from "api/models/company/contact/sms-contact-model";
import { MaMessageOptionsModel } from "components/layout/ma-messages/ma-message-options.model";
import { MaCardLineModel, MaCardOptionsModel } from "components/layout/ma-card/ma-card-options.model";

import { SmsService } from "services/sms-service";
import { DailyEntrySmsContactService } from "services/daily-entry-sms-contact-service";
import { DailyEntrySmsHistoryService } from "services/daily-entry-sms-history-service";
import { default as queryStringHelper } from "helpers/queryStringHelper";

@autoinject()
export class Messages {

    public dispatchProjectCode: string = "";
    public dailyEntryDate: Date = new Date();
    public messageOptionsModel!: MaMessageOptionsModel;
    public sectionShown: string = "";

    public historyCards: MaCardOptionsModel[] = [];

    public sections: any = {
        message: "message",
        history: "history"
    };

    public dispatchTemplateCode: string = "";
    public transactionCpt: number = 0;

    constructor(private readonly i18n: I18N, private readonly routeRepository: RouteRepository, private readonly dailyEntrySmsContactService: DailyEntrySmsContactService, private readonly smsService: SmsService, private readonly dailyEntrySmsHistoryService: DailyEntrySmsHistoryService) {
    }

    public async canActivate(params: any): Promise<any> {
        this.dispatchTemplateCode = params || params.dispatchTemplateCode ? params.dispatchTemplateCode : "";
        return await this.smsService.HasValidProvider(this.dispatchTemplateCode);
    }

    public activate(params: any): any {
        this.dispatchProjectCode = params || params.dispatchProjectCode ? params.dispatchProjectCode : "";
        this.dailyEntryDate = params || params.dailyEntryDate ? params.dailyEntryDate : new Date();
        this.dispatchTemplateCode = params || params.dispatchTemplateCode ? params.dispatchTemplateCode : "";

        this.transactionCpt = params || params.q ? Number.parseInt(queryStringHelper.parse(params.q, "transactionCpt")) : 0;

        this.sectionShown = this.sections.message;

    }

    public async bind(): Promise<void> {
        this.messageOptionsModel = new MaMessageOptionsModel({
            getContactsService: this.dailyEntrySmsContactService.GetSMSContacts.bind(this.dailyEntrySmsContactService, this.dispatchProjectCode, this.dailyEntryDate),
            sendMessageService: this.smsService.SendMessage.bind(this.smsService, this.dispatchTemplateCode, this.transactionCpt),
            goToContactProfile: this.goToEmployeeProfile.bind(this)
        });
    }

    public messageTabClick(): void {
        this.sectionShown = this.sections.message;
    }

    public async historyTabClick(): Promise<void> {
        this.sectionShown = this.sections.history;

        this.historyCards = [];

        const result: SmsMessageModel[] = (await this.dailyEntrySmsHistoryService.GetSmsMessagesHistory(this.dispatchProjectCode, this.dailyEntryDate))!;

        result.forEach((x: SmsMessageModel) => {
            this.historyCards.push(this.createHistoryCard(x));
        });
    }

    private goToEmployeeProfile(employeeNumber: number, dispatchId: number): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Project_Detail_Daily_Entry_Employee.name, { dispatchProjectCode: this.dispatchProjectCode, dailyEntryDate: this.dailyEntryDate, employeeId: employeeNumber, dispatchId: dispatchId });
    }

    private createHistoryCard(history: SmsMessageModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: history,
            cardBodyClass: "py-2",
            id: "card_" + history.MessageId,
            displayLines: [
                new MaCardLineModel({ display: dateHelper.getFullTextDateTime(history.SentDate!), class: "bold" }),
                new MaCardLineModel({ display: `${this.i18n.tr("Employees")} : ${this.displayContacts(history.Contacts!)}` }),
                new MaCardLineModel({ display: history.Message! }),
            ],
        });

        return card;
    }

    private displayContacts(contacts: Array<SmsContactModel | null>): string {
        if (!contacts) { return  ""; }
        return contacts.map((x: SmsContactModel): string => `${x.EmployeeNumber} - ${x.EmployeeDescription}`).join(", ");
    }
}
