define([
    "knockout",
    "core/resx",
    "services/serviceService",
    "helpers/viewbag",
    //"plugins/router",
    "helpers/routerHelper",
    "helpers/notificationHelper"
], function (ko, resx, services, viewbag, /*router,*/ routerHelper, notifier) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.dispatchId = ko.observable();
            self.notes = ko.observable([]);
            self.readonly = false;
            self.serviceType = '';
            self.timeStamp = '';
            //#endregion
        }

        //#region Private Functions
        function loadData() {
            return services.getNotes(self.serviceType, self.dispatchId()).done(function (data) {
                self.notes(data.Notes);
                self.timeStamp = data.VisitTimestamp || data.WorkOrderTimestamp;
            });
        }
        //#endregion

        //region Public Functions
        ctor.prototype.activate = function (params) {
            var paramsQs = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQs && paramsQs.readonly === "false");
            self.serviceType = params.serviceType;
            self.dispatchId(params.dispatchId);
            return loadData(self);
        };

        ctor.prototype.deleteItem = function (item) {
            notifier.showDeleteConfirmation().done(function (success) {
                if (success) {
                    routerHelper.showLoading();
                    services.delNote(self.serviceType, self.dispatchId(), item.RowNumber, item.VisitTimestamp, item.WorkOrderTimestamp)
                        .done(function () {
                            loadData(self);
                        })
                        .concurrence(loadData)
                        .always(function () {
                            routerHelper.hideLoading();
                        });
                }
            });
        };

        ctor.prototype.gotoPage = function (note) {
            viewbag(note);
            var url = (note.RowNumber) ? routerHelper.getRelativeUrl("edit", note.RowNumber) : routerHelper.getRelativeUrl("edit");
            routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: self.readonly, ts:self.timeStamp }));
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
