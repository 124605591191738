import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { PagingInfo } from "api/paging-info";
import { EquipmentProxy } from "api/proxies/equipment-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";

@autoinject()
export class EquipmentService {
    constructor(private readonly equipmentProxy: EquipmentProxy) {
    }

    public async getEquipments(filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<EquipmentModel[]> {
        return (await this.equipmentProxy.GetEquipments(filter, pagingInfo, requestConfig)) || [];
    }
}
