import { PLATFORM } from "aurelia-pal";

define([
    "knockout",
    "underscore",
    "core/resx",
    //"plugins/router",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "helpers/dateHelper",
    "helpers/labelHelper",
    "helpers/notificationHelper",
    "services/workorderTimesheetService",
    "services/tradesService",
    "services/dispatchService"
], function (ko, _, resx, /*router,*/ routerHelper, queryStringHelper, dateHelper, labelHelper, notificationHelper, workorderTimesheetService, tradesService, dispatchService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.labelHelper = labelHelper;
            self.routerHelper = routerHelper;
            self.readonly = false;
            self.workOrderId = ko.observable();
            self.dispatchId = ko.observable();
            self.isEmployee = ko.observable(false);
            self.empId = ko.observable();
            self.id = ko.observable();
            self.trade = ko.observable();
            self.tradeCode = ko.observable();
            self.tradeDescription = ko.observable();
            self.equipmentCode = ko.observable();
            self.equipmentDescription = ko.observable();
            self.inactive = ko.observable();
            self.inactiveCssClass = ko.observable();
            self.detail = ko.observable();
            PLATFORM.moduleName('pages/fieldservices/hourEmployee_TemplateEntries.html');
            //#endregion

            self.tradeText = ko.pureComputed(function () {
                return self.tradeCode() ? `${self.tradeCode()} - ${self.tradeDescription()}` : resx.localize("SelectATrade");
            });
        }

        //#region Private Functions
        function bindViewModel(workOrderId, isEmployee, id, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);

            self.workOrderId(workOrderId);
            self.isEmployee(isEmployee === "true");
            self.id(id);
        }

        function loadData() {
            return workorderTimesheetService.getByWorkOrderIdTypeAndId(self.workOrderId(), self.isEmployee(), self.id())
                .done(function (loadedData) {
                    mapLoadedData(loadedData);
                });
        }

        function mapLoadedData(loadedData) {
            loadedData.name = self.isEmployee() ? loadedData.EmployeeId + ' - ' + loadedData.EmployeeFirstName + ' ' + loadedData.EmployeeLastName
                                                : loadedData.EquipmentCode + ' - ' + loadedData.EquipmentDescription;
            self.detail(loadedData);

            self.dispatchId(loadedData.DispatchId);
            self.empId(loadedData.EmployeeId);
            self.tradeCode(loadedData.DispatchTradeCode);
            self.tradeDescription(loadedData.DispatchTradeDescription);
            self.equipmentCode(loadedData.EquipmentCode);
            self.equipmentDescription(loadedData.EquipmentDescription);
            self.inactive(loadedData.IsInactive);
            if (self.empId() && !loadedData.DispatchTradeCode) {
                getEmployeeDefaultTrade(self.empId());
            }
        }

        function getEmployeeDefaultTrade(employeeId) {
            if (employeeId) {
                return tradesService.getEmployeeDefaultTrade(employeeId).done(setEmployeeDefaultTrade);
            } else {
                return jQuery.Deferred().resolve();
            }
        }

        function setEmployeeDefaultTrade(data) {
            if (data) {
                self.trade(data ? { id: data.Code, text: data.Code + " - " + data.Description } : null);
                self.tradeCode(data.Code);
                self.tradeDescription(data.Description);
            }
        }

        function getDefaultQueryStringParameters() {
            return { readonly: self.readonly };
        }

        function mapResourceToJson(id, text, isEmployee) {
            return {
                "id": id,
                "name": id + " - " + text,
                "isEmployee": isEmployee
            };
        }

        function getSelectedEmployee() {
            return self.isEmployee() ? JSON.stringify(
            [
                    mapResourceToJson(self.detail().EmployeeId, self.detail().EmployeeFirstName + ' ' + self.detail().EmployeeLastName, self.isEmployee())
            ]) : null;
        }

        function getSelectedEquipment() {
            return !self.isEmployee() ?
                JSON.stringify([mapResourceToJson(self.detail().EquipmentCode, self.detail().EquipmentDescription, self.isEmployee())])
                : null;
        }

        function buildParamsObject() {
            var params = getDefaultQueryStringParameters();
            params.selectedResources = self.isEmployee() ? getSelectedEmployee() : getSelectedEquipment();
            params.multi = false;
            params.isEmployee = self.isEmployee();

            return params;
        }

        function setInactive() {
            routerHelper.showLoading();

            dispatchService.setWorkorderInactive(self.workOrderId(), self.dispatchId(), !self.inactive())
                .done(function (data) {
                    loadData();
                })
                .always(function () {
                    routerHelper.hideLoading();
                });
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.isEmployee, params.id, params.q);
            return loadData();
        };

        ctor.prototype.hasEquipment = function () {
            return self.detail().LinkedResources && self.detail().LinkedResources.length > 0;
        };

        ctor.prototype.getAddActivityUrl = function (id) {
                var queryString = routerHelper.addQuerystring(buildParamsObject());

            var url = id
                ? routerHelper.navigateTo("FieldService_Hour_Edit", self.workOrderId(), id)
                : routerHelper.navigateTo("FieldService_Hour_Edit", self.workOrderId());
            return url + queryString;
        };

        ctor.prototype.deleteTimeEntry = function (lineNo, timestamp) {
            notificationHelper.showDeleteConfirmation()
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        workorderTimesheetService.deleteWorkorderTimeEntry(self.workOrderId(), lineNo, timestamp)
                            .done(function () {
                                loadData();
                            })
                            .concurrence(loadData)
                            .always(function () {
                                routerHelper.hideLoading();
                            });
                    }
                });
        };

        ctor.prototype.navigateToEmployee = function () {
            var url = routerHelper.navigateTo("FieldService_Employee", self.workOrderId(), self.empId(), self.dispatchId());
            url += routerHelper.addQuerystring(getDefaultQueryStringParameters());

            routerHelper.navigate(url);
        };

        ctor.prototype.getTrades = {
            transport: function (params, success, failure) {
                tradesService.getTradesForEmployee(self.empId(), params.data.filter, params.data.page || 1)
                    .done(function (data) {
                        return success(_.map(data, function (x) {
                            x.Id = x.Code;
                            return x;
                        }));
                    })
                    .fail(failure);
            }
        };

        ctor.prototype.onTradeSelect = function () {
            routerHelper.showLoading();

            tradesService.setTradeForDispatch(self.dispatchId(), self.trade() ? self.trade().id : 0)
                .done(function (data) {
                    self.tradeCode(data.Code);
                    self.tradeDescription(data.Description);
                })
                .always(function () {
                    routerHelper.hideLoading();
                });
        };

        ctor.prototype.inactiveClicked = function () {
            if (self.detail().Timesheets.length > 0) {
                notificationHelper.showConfirmation(resx.localize('msg_AbsentConfirmationEquipment'))
                    .done(function (success) {
                        if (success) {
                            setInactive();
                        }
                    });
            } else {
                setInactive();
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
