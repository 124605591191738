import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as routerHelper } from "helpers/routerHelper";

export class In {
    type;

    canActivate(params) {
        if (params.type === "canactivatefalse") {
            return false;
        }
        if (params.type === "canactivatethrow") {
            this.throwError();
        }

        if (params.type === "canactivatepromisethrow") {
            return new Promise((success, fail) => {
                        this.throwError();
            });
        }
    }

    activate(params) {
        this.type = params.type;

        if (this.type === "activatethrow") {
            this.throwError();
        }

        if (this.type === "activatepromisethrow") {
            return new Promise((success, fail) => {
                this.throwError();
            });
        }
    }

    attached() {
        if (this.type === "attachedthrow") {
            this.throwError();
        }

        if (this.type === "attachedpromisethrow") {
            return new Promise((success, fail) => {
                this.throwError();
            });
        }
    }

    throwError() {
        throw new Error("Test");
    }
}