import { inject, transient } from "aurelia-framework";
import GuidHelper from "helpers/guidHelper";


import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

import * as enumHelper from "helpers/enumHelper";
import "widgets/maSelect";

function bindViewModel(self, widgetSettings) {
    self.ajax = widgetSettings.ajax;
    self.disable = (widgetSettings.disable === null || widgetSettings.disable === undefined) ? false : widgetSettings.disable;
    self.id = widgetSettings.id || self.GuidHelper.createGuid();
    self.onSelect = widgetSettings.onSelect;
    self.minimumInputLength = widgetSettings.minimumInputLength;
    self.clearOnClose = widgetSettings.clearOnClose || false;

    self.showNotAvailable = widgetSettings.showNotAvailable || false;
}

@transient()
@inject(GuidHelper)
export class AddAttendance {
    constructor(guidHelper) {
        this.GuidHelper = guidHelper;
        this.resx = resx;

        this.ajax = null;
        this.disable = false;

        const attendanceTypes = enumHelper.attendanceTypes();

        this.selectedResourceType = ko.observable(attendanceTypes.EMPLOYEE.id);
        this.resourcesTypes = _.map(attendanceTypes, att => att.id);
        this.resourcesTypesLabel = _.map(attendanceTypes, att  => att.label);
        this.selectedItem = ko.observable();
        this.clearOnClose = false;
        this.showNotAvailable = false;
        this.id = this.GuidHelper.createGuid();
    }

    activate(widgetSettings) {
        bindViewModel(this, widgetSettings);
    }
}