import { transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as labelHelper } from "helpers/labelHelper";

function loadWidgetSettings(self, widgetSettings) {
    self.selectedWage = widgetSettings.selectedWage;
    self.disabled = widgetSettings.disabled;
    self.selectedWage(widgetSettings.savedWage);
    self.availableWageType = widgetSettings.wageType;
    self.readonly = widgetSettings.readonly;
    loadWageTypes(self);
}

function getWageTypeCssClass(self, id) {
    var cls = self.selectedWage() === id ? 'fill' : '';
    cls += self.readonly ? " disabled" : "";
    return cls;
}

function loadWageTypes(self) {
    var Wage = function (id) {
        var wSelf = this;
        wSelf.id = id;
        wSelf.cssClass = ko.computed(function () {
            return getWageTypeCssClass(self, id);
        }, wSelf);

        wSelf.click = function () {
            wageTypeClicked(self, id);
        };
    };

    self.wageTypes(_.map(self.availableWageType,
        function (i) {
            return new Wage(i);
        }));
}

function wageTypeClicked(self, id) {
    self.selectedWage(id);
}

@transient()
export class WageTypeSelect {
    constructor() {
        this.resx = resx;
        this.labelHelper = labelHelper;
        this.wageTypes = ko.observableArray();
        this.selectedWage = ko.observable();
        this.disabled = false;
        this.availableWageType = ko.observableArray();
        this.readonly = false;

        this.wageTypeClicked = this.wageTypeClicked.bind();
    }

    activate(widgetSettings) {
        loadWidgetSettings(this, widgetSettings);
        return true;
    }


    wageTypeClicked(id) {
        wageTypeClicked(this, id);
    }
}