import { default as _ } from "underscore";
import { default as routerHelper } from "helpers/routerHelper";
import { default as currencyHelper } from "helpers/currencyHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as workflowService } from "services/workflowService";
import { default as documentHelper } from "helpers/documentHelper";

export class Approval {
    currencyHelper = currencyHelper;
    routerHelper = routerHelper;
    approvals = [];

    constructor() {}

    async activate() {
        return await this.loadData();
    }

    async loadData() {
        this.approvals = await workflowService.getApprovals();
    }

    goTo(action, actionId) {
        routerHelper.navigate(routerHelper.getRelativeUrl(actionId + "/" + action));
    }

    checkAndOpenDocument(approval) {
        const item = { Id: approval.DocumentId };
        const document = documentHelper.reformDocument(item);
        documentHelper.openDocument(document);
    }
}
