import urlHelper from "helpers/urlHelper";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { I18N } from "aurelia-i18n";
import { observable } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { NotificationHelper } from "helpers/notification-helper";

import { InspectionModel } from "api/models/company/inspection/inspection-model";
import { InspectionTypeLookupModel } from "api/models/company/inspection/inspection-type-lookup-model";

import { InspectionContext } from "api/enums/inspection-context";

import { InspectionService } from "services/inspection-service";
import { ServiceCallInspectionService } from "services/service-call-inspection-service";
import { ServiceDispatchType } from "enums/service-dispatch-type";

@autoinject
export class ServiceCallInspectionAdd {

    public contexts: any[] = [];

    @observable public inspectionContext: any = null;

    public inspection!: InspectionModel;

    public inspectionEnum: any = InspectionContext;
    public isContextSelected: boolean = false;
    public isInspectionTypeSelectable: boolean = false;
    public selectedInspectionTypeId: any;
    public okToSave: boolean = false;
    public dispatchId: any;

    constructor(private readonly i18n: I18N,
                private readonly serviceCallInspectionService: ServiceCallInspectionService,
                private readonly inspectionService: InspectionService,
                private readonly routeRepository: RouteRepository,
                private readonly notificationHelper: NotificationHelper) {
    }

    public async activate(params: any): Promise<void> {
        this.dispatchId = params.dispatchId;

        this.inspection = (await this.inspectionService.GetNewInspection(InspectionContext.ServiceCall, this.dispatchId))!;
    }

    public get GetInspectionTypes(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.serviceCallInspectionService.GetInspectionTypesLookup(this.dispatchId, params.data.filter, { page: params.data.page, pageSize: 20}).then(
                    (result: InspectionTypeLookupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: InspectionTypeLookupModel): any => {
                return { id: item.Id, text: `${item.Id} - ${item.Description}`, data: item };
            }
        };
    }

    public onInspectionTypeSelected(inspectionType: InspectionTypeLookupModel): void {
        this.inspection.InspectionTypeId = inspectionType.Id;
        this.isOkToSave();
    }

    public async save(): Promise<void> {

        const result: InspectionModel | null = await this.inspectionService.SaveInspection(this.inspection);

        if (result) {
            this.replaceBrowsingHistory();
            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Call_Inspections_Info.name, { inspectionId: result.Id, dispatchId: this.dispatchId, serviceType: ServiceDispatchType.Service});
        }

    }

    private replaceBrowsingHistory(): void {
        const baseUrl = urlHelper.getBaseUrlWithDash();
        const route =  this.routeRepository.routes.Service_Call_Inspections.route.toString().replace(":dispatchId", this.dispatchId).replace(":serviceType", ServiceDispatchType.Service);
        window.history.replaceState("", "", baseUrl + route);
    }

    private isOkToSave(): void {
        this.okToSave = this.inspection !== null && this.inspection.InspectionTypeId !== null && this.inspection.VisitNumber !== 0;
    }
}
