import { autoinject, Container } from "aurelia-dependency-injection";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { NavigationInstruction, PipelineStep, RouteConfig, Router, RouterConfiguration } from "aurelia-router";

import { default as ko } from "knockout";
import "knockout.validation";

import { ErrorHandlingKnockoutBindingProvider } from "error-management/error-handling-knockout-binding-provider";
import HostContext from "core/host-context";
import * as authorizeSteps from "core/pipeline/authorizeSteps";
import * as postRenderSteps from "core/pipeline/postRenderSteps";
import * as preActivateSteps from "core/pipeline/preActivateSteps";
import * as preRenderSteps from "core/pipeline/preRenderSteps";
import { default as resx } from "core/resx";
import { default as storage } from "core/storage";
import { default as enumHelper } from "helpers/enumHelper";
import { default as notifier } from "helpers/notificationHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { ViewModeHelper } from "helpers/view-mode-helper";
import RouteRepository, { SpecificRouteConfig } from "repositories/routeRepository";
import { default as settings } from "repositories/settingRepository";
import NotificationService from "services/notificationService";
import UserAccessService from "services/user-access-service";
import UserOptionsManager from "services/user-options-manager";
import { KeyboardShortcutsHelper } from "helpers/keyboard-shortcuts-helper";
import { DevShortcutKeys, HostedShortcutKeys } from "enums/shortcut-keys";

// TODO ML: Fonctionne, mais quand le provider rethrow l'exception, knockout log le contenu de notre fonction et non celle dans le binding...
ko.bindingProvider.instance = new ErrorHandlingKnockoutBindingProvider();

@autoinject()
export class App {
    public isShowingOverlay: boolean = false;
    public showBack: boolean = true;
    public showHeader: boolean = false;
    public title: string | null = "";
    public titleCssClass: string = "";
    private isIndexPage: boolean = false;
    private nbFieldServices: number = -1;
    private nbProjects: number = -1;
    private nbServices: number = -1;
    // We currently set the title on the router event navigation:success depending on the target route.
    // However in a few cases we want to set the title in a page's activate/bind. This proprty is used to avoid the title being replaced.
    private titleOverride: string = "";
    private subscription: Subscription[] = [];

    constructor(
        public readonly router: Router,
        private readonly routeRepository: RouteRepository,
        private readonly eventAggregator: EventAggregator,
        private readonly container: Container,
        private readonly hostContext: HostContext,
        private readonly userOptionsManager: UserOptionsManager,
        private viewModeHelper: ViewModeHelper,
        private readonly userAccessService: UserAccessService,
        private readonly notificationService: NotificationService,
        private readonly keyboardShortcutsHelper: KeyboardShortcutsHelper
    ) {
    }

    public async activate(): Promise<void> {
        this.removeUnusedStorageKey();

        // TODO JL: Remove when we don't need ko validation anymore.
        ko.validation.init({ insertMessages: false });

        this.registerEventsHandlers();
        await this.userOptionsManager.initialize();
    }

    public attached(): void {
        if (this.viewModeHelper.getIsDesktopMode()) {
            this.configureApplicationShortcuts();
        }
    }

    public detached(): void {
        this.subscription.forEach((subscription: Subscription) => subscription.dispose());
    }

    public back(): void {
        if (!window.navigator.onLine) {
            notifier.showError(resx.localize("err_NetworkError"));
        } else {
            routerHelper.navigateBack();
        }
    }

    public configureRouter(config: RouterConfiguration, router: Router): void {
        if (!this.hostContext.isHosted()) {
            config.title = "Maestro*Mobile";
        }

        routerHelper.init(router);

        // Add custom pipeline steps for the AppRouter
        // TODO ML: Convert pipeline steps to TS and remove "| any"
        Object.values(authorizeSteps).forEach((step: PipelineStep | any) => {
            const instance: PipelineStep = this.container.get(step);
            config.addAuthorizeStep(instance);
        });
        Object.values(preActivateSteps).forEach((step: PipelineStep | any) => {
            const instance: PipelineStep = this.container.get(step);
            config.addPreActivateStep(instance);
        });
        Object.values(preRenderSteps).forEach((step: PipelineStep | any) => {
            const instance: PipelineStep = this.container.get(step);
            config.addPreRenderStep(instance);
        });
        Object.values(postRenderSteps).forEach((step: PipelineStep | any) => {
            const instance: PipelineStep = this.container.get(step);
            config.addPostRenderStep(instance);
        });

        config.map(this.routeRepository.allRoutes());

        // Unknown routes:
        // When hosted, show a specific error page
        // Otherwise, redirect to the mobile home page
        config.mapUnknownRoutes((instruction: NavigationInstruction): RouteConfig => {
            if (this.hostContext.isHosted()) {
                return { route: "unknown", redirect: "error" };
            } else {
                return { route: "unknown", redirect: "home" };
            }
        });

        // Fallback route:
        if (this.hostContext.isHosted()) {
            config.fallbackRoute("error_hosted");
        } else {
            config.fallbackRoute("home");
        }
    }

    public scrollToTop(): void {
        window.scrollTo(0, 1);
    }

    private configureApplicationShortcuts(): void {
        this.keyboardShortcutsHelper.Shortcuts = this.hostContext.isHosted() ? new HostedShortcutKeys() : new DevShortcutKeys();
    }

    private updateApprovalNotification(): void {
        // TODO JL: Faire un autre pooler standalone pour les approbations
        if (this.userAccessService.isOptionEnabledSync(enumHelper.userOptions.APPROVAL)) {
            this.notificationService.updateApprovalNotification();
        }
    }

    private handleHeaderVisibility({ instruction }: { instruction: NavigationInstruction }): void {
        // On ne peut pas utiliser le paramètre de l'événement de navigation (navigation success) car dans le cas d'un cancel de la navigation on reçoit quand même l'instruction originale!
        // On utilise donc le currentInstruction du router.
        // Voir bug Aurelia ouvert: https://github.com/aurelia/router/issues/177
        const currentInstruction: NavigationInstruction = this.router.currentInstruction;

        const route: SpecificRouteConfig | null = this.routeRepository.getRoute(currentInstruction.config.route as string);
        if (route && route.hideHeader !== true && !this.viewModeHelper.getIsDesktopMode()) {
            this.showHeader = true;
        } else {
            this.showHeader = false;
        }
    }

    private registerEventsHandlers(): void {
        this.subscription = [];

        this.subscription.push(this.eventAggregator.subscribe("user-options:changed", () => this.updateApprovalNotification()));

        this.subscription.push(this.eventAggregator.subscribe("router:navigation:processing", () => notifier.closeDialogs()));
        this.subscription.push(this.eventAggregator.subscribe("router:navigation:processing", () => { this.titleOverride = ""; }));

        this.subscription.push(this.eventAggregator.subscribe("router:navigation:success", ({ instruction }: { instruction: NavigationInstruction }) => this.handleHeaderVisibility({ instruction })));
        this.subscription.push(this.eventAggregator.subscribe("router:navigation:success", ({ instruction }: { instruction: NavigationInstruction }) => this.updateDocumentTitle({ instruction })));

        this.subscription.push(this.eventAggregator.subscribe("dialogs:openeddialogs:add", (dialog: any) => notifier.registerDialog(dialog)));
        this.subscription.push(this.eventAggregator.subscribe("dialogs:openeddialogs:remove", (dialog: any) => notifier.unregisterDialog(dialog)));

        this.subscription.push(this.eventAggregator.subscribe("overlay:loading:visible", (visible: boolean) => { this.isShowingOverlay = visible; }));

        this.subscription.push(this.eventAggregator.subscribe("updateTitle", (title: string) => this.titleOverride = title));
    }

    private removeUnusedStorageKey(): void {
        storage.remove("INCREMENT");
    }

    private updateDocumentTitle({ instruction }: { instruction: NavigationInstruction }): void {
        // On ne peut pas utiliser le paramètre de l'événement de navigation (navigation success) car dans le cas d'un cancel de la navigation on reçoit quand même l'instruction originale!
        // On utilise donc le currentInstruction du router.
        // Voir bug Aurelia ouvert: https://github.com/aurelia/router/issues/177
        const currentInstruction: NavigationInstruction = this.router.currentInstruction;

        this.isIndexPage = currentInstruction && currentInstruction.config.name === "Home";

        if (this.isIndexPage) {
            this.title = settings.getCompanyName();
            this.titleCssClass = "align-center";
        } else if (currentInstruction.config.name === "Logout") {
            this.title = null;
            this.titleCssClass = "";
        } else if (this.titleOverride) {
            this.title = this.titleOverride;
            this.titleOverride = "";
            this.titleCssClass = "";
        } else {
            const key: string = "pageTitle_" + currentInstruction.config.name;
            const title: string = resx.localize(key);

            if (!title || title === key) {
                this.title = null;
            } else {
                this.title = title;
            }

            this.titleCssClass = "";
        }

        this.showBack = currentInstruction.config.nav !== 1 && currentInstruction.config.showBack !== false && currentInstruction.fragment !== "";

        // Changer le titre de la fenêtre en mode hosted.
        if (this.hostContext.isHosted() && currentInstruction && currentInstruction.config && currentInstruction.config.navModel && this.title) {
            currentInstruction.config.navModel.setTitle(this.title);
        }
    }
}
