import { EventAggregator } from 'aurelia-event-aggregator';
import { inject } from 'aurelia-dependency-injection';
import serviceService from "services/serviceService";
import { default as resx } from "core/resx";

@inject(EventAggregator)
export class MaterialList {

    resx = resx;

    dispatchId = 0;

    reservedMaterialsOnly = false;

    constructor(eventAggregator) {
        this.eventAggregator = eventAggregator;
    }

    activate(params) {
        this.dispatchId = params.dispatchId;
        this.serviceType = params.serviceType;
        this.initMaterials(params.serviceType, params.dispatchId);
        return this.initPageTitle(params.serviceType, params.dispatchId);
    }

    initPageTitle(serviceType, dispatchId) {
        return serviceService.getIsReservedMaterials(serviceType, dispatchId)
            .then((result) => {
                this.reservedMaterialsOnly = result.ReserveRequiredMaterialForCallReturns;
                let pageTitle = this.reservedMaterialsOnly
                    ? resx.localize('MaterialAndBillingItem_Reserved')
                    : resx.localize('MaterialAndBillingItem_OrderedOrReserved');

                this.eventAggregator.publish("updateTitle", pageTitle);

                return Promise.resolve();
            });
    }

    initMaterials(serviceType, dispatchId) {
        return serviceService.getReservedMaterials(serviceType, dispatchId)
            .then((materials) => {
                this.materials = materials;
            });
    }
}
