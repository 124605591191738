define([
    "underscore",
    "knockout",
    "helpers/routerHelper"
], function (_, ko, routerHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.readonly = false;
            self.workOrderId = ko.observable('');
            self.employeeId = ko.observable(0);

            //#endregion
        }

        //#region Private Functions
        
        function bindViewModel(workOrderId, employeeId, dispatchId, querystring) {
            var params = routerHelper.getQuerystring(querystring);
            self.readonly = !(params && params.readonly === "false");
            self.workOrderId(workOrderId);
            self.employeeId(employeeId);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.employeeId, params.dispatchId, params.q);

            return true;
        };

        
        //#endregion
        return ctor;
    })();

    return viewModel;
});
