import { inject, transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

import { default as dateHelper } from "helpers/dateHelper";
import { default as labelHelper } from "helpers/labelHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as notificationHelper } from "helpers/notificationHelper";

import { default as timesheetService } from "services/timesheetService";

function loadData(self, item) {
    //ProjectId et Description
    self.title = item.Key.Id + " - " + item.Key.Description;
    self.isLast = item.isLast;

    self.lines = _.chain(item.Items)
        .filter(function (x) { return x.Bonus === '' && x.EquipmentId === ''; })
        .map(function (x) {
            return {
                timesheetId: x.TimesheetId,
                timesheetEntryId: x.TimesheetEntryId,
                activity: x.EmployeeActivity + " - " + x.EmployeeActivityDescription,
                rateType: labelHelper.getWageTypeLabel(x.RateType),
                trade: x.TradeId + " - " + x.TradeDescription,
                hasTrade: !!x.TradeId,
                hours: dateHelper.hoursTohmm(x.Hours),
                comment: x.Comment,
                readonly: x.IsReadOnly || item.readonly
            };
        })
        .value();

    self.primes = _.chain(item.Items)
        .filter(function (x) { return x.Bonus !== ''; })
        .map(function (x) {
            return {
                timesheetId: x.TimesheetId,
                timesheetEntryId: x.TimesheetEntryId,
                title: x.Bonus + " - " + x.BonusDescription,
                quantity: x.BonusUnit ? x.BonusQuantity + " " + x.BonusUnit : x.BonusQuantity,
                readonly: x.IsReadOnly || item.readonly
            };
        })
        .value();

    self.equipments = _.chain(item.Items)
        .filter(function (x) { return x.EquipmentId !== ''; })
        .map(function (x) {
            return {
                timesheetId: x.TimesheetId,
                timesheetEntryId: x.TimesheetEntryId,
                title: x.EquipmentId + " - " + x.EquipmentDescription,
                activity: x.EquipmentActivity + " - " + x.EquipmentActivityDescription,
                hours: dateHelper.hoursTohmm(x.Hours),
                readonly: x.IsReadOnly || item.readonly
            };
        })
        .value();
}

function loadWidgetSettings(self, { root, item }) {
    self.root = root;
    loadData(self, item);
}

@transient()
export class TimesheetItem {
    constructor() {
        this.resx = resx;
        this.title = "";
        this.lines = [];
        this.primes = [];
        this.equipments = [];
        this.isLast = false;
    }

    activate(widgetSettings) {      
        loadWidgetSettings(this, widgetSettings);
    }

    deleteEntry(data) {

        var message = resx.localize('msg_DeleteEntryConfirmationText');
        notificationHelper.showDialogYesNo(message)
            .done((success) => {
                if (success) {
                    routerHelper.showLoading();
                    timesheetService.deleteTimesheetEntry(data.timesheetId, data.timesheetEntryId)
                        .done(routerHelper.navigateSelf)
                        .always(routerHelper.hideLoading);
                }
            });
    }
}
