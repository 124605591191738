import { autoinject } from "aurelia-framework";
import documentService from "services/documentService";
import documentHelper from "helpers/documentHelper";
import routerHelper from "helpers/routerHelper";

@autoinject()
export class ProjectDocuments {

    public actions: any;
    public dispatchProjectCode: string = "";
    public readonly: boolean = false;

    public async activate(params: any): Promise<void> {

        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchProjectCode = params.dispatchProjectCode;

        this.actions = {
            getSections: documentService.getSectionsByDispatchProjectCode.bind(self, params.dispatchProjectCode),
            getSectionDocuments: documentService.getDocumentsByDispatchProjectCode.bind(self, params.dispatchProjectCode),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
            deleteDocument: documentService.deleteDocument.bind(documentService)
        };
    }
}
