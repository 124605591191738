import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import val from "core/val";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { EquipmentEditParameters } from "components/pages/services/equipment-details/equipment-details-informations";
import Parse from "helpers/parse";

@autoinject
export class EquipmentEdit {
    public isNewEquipment: boolean = false;
    public isReplacement: boolean = false;
    public equipmentId: number | null = 0;

    public dispatchId: any;
    public isEquipmentSelected: boolean = false;
    public val: any = val;
    public readonly: boolean = true;

    @bindable
    public equipment: ServiceCallContractEquipmentModel | null = null;

    public replacedEquipment: ServiceCallContractEquipmentModel | null = null;

    @bindable
    public selectedEquipment: any | null = null;

    constructor(private readonly i18n: I18N, private readonly equipmentService: ServiceCallContractEquipmentService) {
        this.i18n = i18n;
        this.equipmentService = equipmentService;
    }

    public async activate(params: EquipmentEditParameters): Promise<void> {
        this.dispatchId = params.dispatchId;
        this.isNewEquipment =  Parse.Boolean(params.isNewEquipment);
        this.isReplacement =  Parse.Boolean(params.isReplacement);
        this.equipmentId = params.equipmentId;
        this.readonly = !Parse.Boolean(params.allowedServiceCallContractEquipmentManagement) || Parse.Boolean(params.readonly);

        if (!this.isNewEquipment && !this.isReplacement) {
            this.equipmentId = params.equipmentId;
            this.equipment = await this.equipmentService.getForEquipment(this.dispatchId, this.equipmentId!);

            this.initialiseExistingEquipment(this.equipment!);
        }
        if (this.isReplacement) {
            this.replacedEquipment = await this.equipmentService.getForEquipment(this.dispatchId, this.equipmentId!);
        }
    }

    public getSelectedItemDisplay(equipment: ServiceCallContractEquipmentModel): string {
        return `${equipment!.Code} - ${equipment.Description} ${equipment.Brand ? `(${equipment.Brand})` : ""}`;
    }

    public initialiseExistingEquipment(equipment: ServiceCallContractEquipmentModel): void {
        this.selectedEquipment = { id: equipment.Code, text: this.getSelectedItemDisplay(equipment), data: equipment };
        this.isEquipmentSelected = true;
    }

    public get GetEquipments(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.equipmentService.getNewServiceCallContractEquipments(params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ServiceCallContractEquipmentModel): any => {
                return { id: item.Code, text: this.getSelectedItemDisplay(item), data: item };
            },
        };
    }

    public onEquipmentSelected(item: ServiceCallContractEquipmentModel): void {
        const dialogMessage = this.i18n.tr("ms_ChangeEquipmentConfirmation");

        if (this.isEquipmentSelected) {
            notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<void> => {
                if (success) {
                    this.equipment = item;
                }
            });
        } else {
            this.equipment = item;
            this.isEquipmentSelected = true;
        }
    }

    public async save(): Promise<void> {
        const isInvalid = !this.isEquipmentSelected && this.equipment!.DateEffective === null;

        if (isInvalid) {
            return;
        }

        if (this.isReplacement) {
            await this.equipmentService.replaceServiceCallContractEquipment(this.dispatchId, { NewActiveEquipment: this.equipment,
                InactiveEquipment: this.replacedEquipment });
        } else {
            await this.equipmentService.updateContractEquipment(this.dispatchId, this.equipment!);
        }

        routerHelper.navigateBack();
    }
}
