import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";

export class InspectionDocumentAdd {

    public readonly: boolean = false;
    public inspectionId: any = null;

    public actions: any;

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.inspectionId = params.inspectionId;
        this.actions = { save: documentService.addDocumentByInspectionId.bind(self, this.inspectionId)};
    }
}
