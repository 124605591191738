import { GeolocationService } from "services/geolocationService";

define([
    "jquery",
    "knockout",
    "core/resx",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "helpers/viewbag",
    "services/weatherService",
    "services/dailyEntryService",
    "services/applicationInsightsService",
    "helpers/stringHelper",
    "widgets/weatherPicker"
], function (jQuery, ko, resx, routerHelper, notificationHelper, viewbag, weatherService, dailyEntryService, applicationInsightsService, stringHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor(geolocationService) {
            self = this;

            //#region Properties
            var enumType = {
                WEATHER: resx.localize('msg_Weather_TypeRequired'),
                WIND: resx.localize('msg_Weather_WindForceRequired')
            };

            var enumPeriod = {
                DAY: resx.localize('msg_Weather_fortheday'),
                EVENING: resx.localize('msg_Weather_fortheevening'),
                NIGHT: resx.localize('msg_Weather_forthenight')
            };

            self.geolocationService = geolocationService;
            self.resx = resx;
            self.id = "";
            self.weatherButtonDisable = ko.observable(false);
            self.entityId = ko.observable(null);
            self.dispatchProjectCode = ko.observable("");
            self.dispatchDate = ko.observable("");
            self.periodSelected = ko.observable("day");
            self.daySelected = ko.computed(function() {
                return self.periodSelected() === "day";
            });
            self.eveningSelected = ko.computed(function() {
                return self.periodSelected() === "evening";
            });
            self.nightSelected = ko.computed(function() {
                return self.periodSelected() === "night";
            });

            self.dayWeatherReload = ko.observable();
            self.eveningWeatherReload = ko.observable();
            self.nightWeatherReload = ko.observable();

            self.validatedForm = ko.validatedObservable({
                dayWeather: ko.observable({ weatherType: 0, windForce: 0, degree: 0 }),
                eveningWeather: ko.observable({ weatherType: 0, windForce: 0, degree: 0 }),
                nightWeather: ko.observable({ weatherType: 0, windForce: 0, degree: 0 }),

                clear: function() {
                    var selfForm = this;
                    selfForm.dayWeather({ weatherType: 0, windForce: 0, degree: 0 });
                    selfForm.eveningWeather({ weatherType: 0, windForce: 0, degree: 0 });
                    selfForm.nightWeather({ weatherType: 0, windForce: 0, degree: 0 });
                },

                init: function() {
                    var selfForm = this;
                    selfForm.dayWeather.extend({
                        validation: {
                            validator: function() {
                                if (selfForm.dayWeather().windForce > 0 && selfForm.dayWeather().weatherType === 0) {
                                    this.message = enumType.WEATHER + ' ' + enumPeriod.DAY;
                                    return false;
                                }
                                if (selfForm.dayWeather().weatherType > 0 && selfForm.dayWeather().windForce === 0) {
                                    this.message = enumType.WIND + ' ' + enumPeriod.DAY;
                                    return false;
                                }
                                return true;
                            }
                        }
                    });

                    selfForm.eveningWeather.extend({
                        validation: {
                            validator: function() {
                                if (selfForm.eveningWeather().windForce > 0 &&
                                    selfForm.eveningWeather().weatherType === 0) {
                                    this.message = enumType.WEATHER + ' ' + enumPeriod.EVENING;
                                    return false;
                                }
                                if (selfForm.eveningWeather().weatherType > 0 &&
                                    selfForm.eveningWeather().windForce === 0) {
                                    this.message = enumType.WIND + ' ' + enumPeriod.EVENING;
                                    return false;
                                }
                                return true;
                            }
                        }
                    });

                    selfForm.nightWeather.extend({
                        validation: {
                            validator: function() {
                                if (selfForm.nightWeather().windForce > 0 &&
                                    selfForm.nightWeather().weatherType === 0) {
                                    this.message = enumType.WEATHER + ' ' + enumPeriod.NIGHT;
                                    return false;
                                }
                                if (selfForm.nightWeather().weatherType > 0 &&
                                    selfForm.nightWeather().windForce === 0) {
                                    this.message = enumType.WIND + ' ' + enumPeriod.NIGHT;
                                    return false;
                                }
                                return true;
                            }
                        }
                    });
                }
            });

            //#endregion
        }

        ctor.inject = [GeolocationService];

        //#region Private Functions
        function mapDataToPost() {
            var isTempDay = self.validatedForm().dayWeather().weatherType > 0 || self.validatedForm().dayWeather().windForce > 0;
            var isTempEvening = self.validatedForm().eveningWeather().weatherType > 0 || self.validatedForm().eveningWeather().windForce > 0;
            var isTempNight = self.validatedForm().nightWeather().weatherType > 0 || self.validatedForm().nightWeather().windForce > 0;

            if (!isTempDay && !isTempEvening && !isTempNight) {
                return undefined;
            }

            return {
                TemperatureTypeAm: isTempDay ? self.validatedForm().dayWeather().weatherType : 0,
                TemperatureWindAm: isTempDay ? -self.validatedForm().dayWeather().windForce : 0,
                TemperatureDegreeAm: isTempDay ? self.validatedForm().dayWeather().degree : null,

                TemperatureTypeEvening: isTempEvening ? self.validatedForm().eveningWeather().weatherType : 0,
                TemperatureWindEvening: isTempEvening ? -self.validatedForm().eveningWeather().windForce : 0,
                TemperatureDegreeEvening: isTempEvening ? self.validatedForm().eveningWeather().degree : null,

                TemperatureTypeNight: isTempNight ? self.validatedForm().nightWeather().weatherType : 0,
                TemperatureWindNight: isTempNight ? -self.validatedForm().nightWeather().windForce : 0,
                TemperatureDegreeNight: isTempNight ? self.validatedForm().nightWeather().degree : null
            };
        }

        function mapDataFromViewBag(data) {
            self.periodSelected(data.periodSelected);

            self.validatedForm().dayWeather(data.value.dayWeather);
            self.validatedForm().eveningWeather(data.value.eveningWeather);
            self.validatedForm().nightWeather(data.value.nightWeather);
        }

        function mapDataFromWeatherApi(data) {
            if (self.periodSelected() === 'day') {
                self.validatedForm().dayWeather(data);
                self.dayWeatherReload(data);
            }
            if (self.periodSelected() === 'evening') {
                self.validatedForm().eveningWeather(data);
                self.eveningWeatherReload(data);
            }
            if (self.periodSelected() === 'night') {
                self.validatedForm().nightWeather(data);
                self.nightWeatherReload(data);
            }
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            var paramsQueryString = routerHelper.getQuerystring(params.q);
            var vBag = viewbag();

            self.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
            self.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
            self.dispatchDate(params.dailyEntryDate);
            self.dispatchProjectCode(params.dispatchProjectCode);
            self.weatherButtonDisable(false);

            if (!vBag) {
                routerHelper.navigateBack();
                return false;
            }

            mapDataFromViewBag(vBag);

            self.validatedForm().init();

            return new jQuery.Deferred().resolve();
        };

        ctor.prototype.updateWeather = function () {
            if (self.daySelected() || self.eveningSelected() || self.nightSelected()) {

                //TODO JL: Refactor ce code ça fait pas ce que c'est supposé
                // La gestion des erreurs est vraiment boboche, autant pour la géolocalisation que pour l'api météo.
                // Les messages d'erreur sont pas clair non plus, on a l'impression que c'est la météo qui a planté alors que c'est la géolocalisation
                // On cache les 3 points après la géolocalisation, on attends pas après l'api météo qui prends plus de temps.
                // Vérifier que les erreurs sont loggers sur appinsight

                routerHelper.showLoading();

                applicationInsightsService.trackEvent("Get Weather From External Api");

                self.geolocationService.getCurrentLocation().done(function (position) {
                    weatherService.getCurrentTemperature(position).done(function (data) {
                        mapDataFromWeatherApi(data);
                    });
                }).fail(function (ex) {

                    self.weatherButtonDisable(true);
                    if (stringHelper.startsWith(ex.message, "Only secure origins are allowed") || ex.message.indexOf("Access to geolocation was blocked over insecure connection") > -1) {
                        notificationHelper.showWarning(resx.localize("LocationRequireSSL"));
                    } else {
                        notificationHelper.showWarning(resx.localize("LocationDisabledUnableUpdateWeather"));
                    }
                }).always(function () {
                    routerHelper.hideLoading();
                });
            }

        };

        ctor.prototype.saveWeather = function () {
            if (!self.validatedForm.isValid()) {
                notificationHelper.showValidationError(self.validatedForm.errors);
                return false;

            } else {
                routerHelper.showLoading();

                var data = mapDataToPost();

                if (data) {
                    dailyEntryService.setWeather(self.dispatchProjectCode(), self.dispatchDate(), data)
                        .done(routerHelper.navigateBack)
                        .always(routerHelper.hideLoading);
                } else {
                    notificationHelper.showWarning(resx.localize('msg_WeatherRequired'));
                    routerHelper.hideLoading();
                }
            }
        };

        //#endregion
        return ctor;
    })();

    return viewModel;
});
