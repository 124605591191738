import { inject, transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

@transient()
export class AddNote {
    constructor() {
            //#region Properties
            this.resx = resx;
            this.showInput = ko.observable(false);
            this.value = ko.observable();
            this.option = '';
            this.context = '';
            this.id = '';
            this.maxLength = ko.observable();
            this.disable = ko.observable(false);
            //#endregion
        }

        //#region Public Functions
        activate (widgetSettings) {
            this.value = widgetSettings.value || ko.observable('');
            this.option = widgetSettings.option || "";
            this.context = widgetSettings.context || "";
            this.id = widgetSettings.id || "";

            if (widgetSettings.showInput && _.isFunction(widgetSettings.showInput)) {
                this.showInput = widgetSettings.showInput;
            }
          
            this.disable = (widgetSettings.disable === null || widgetSettings.disable === undefined) ? ko.observable(false) : _.isFunction(widgetSettings.disable) ? widgetSettings.disable : ko.observable(widgetSettings.disable);
 
            this.showInput(this.value() !== '');
            this.maxLength(widgetSettings.maxLength);
        }
}