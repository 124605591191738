import { inject } from "aurelia-dependency-injection";
import "widgets/richText";
import ko from "knockout";
import resx from "core/resx";
import _ from "underscore";
import jQuery from "jquery";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import maskHelper from "helpers/maskHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { AdditionalFieldsHelper } from "helpers/additional-fields-helper";
import moment from "moment";
import "jquery-mask-plugin";

@inject(AdditionalFieldsHelper)
export class AdditionalFields {
    additionalFields = ko.observableArray([]);
    additionalFieldsSource;

    constructor(additionalFieldsHelper) {
        this.readonly = false;
        this.saveAdditionalFieldsButton = true;
        this.resx = resx;
        this.fieldsDefinition = {
            TEXT: 0,
            DATE: 1,
            HOUR: 2,
            AMOUNT: 3,
            NUMBER: 4,
            INTSHORT: 5,
            INTLONG: 6,
            TELEPHONE: 8,
            MEMO: 13,
            CHECKBOX: 11,
            DROPDOWN: 16,
            TIMESTAMP: 17
        };
        this.supportedFieldIds = _.values(this.fieldsDefinition);
        this.additionalFieldsHelper = additionalFieldsHelper;
    }
    

    //#region Private Functions
    loadData() {
        return this.actions.getAll().done((data) => {
            var mappedFields = _.map(data, (field) => {
                var temp = {
                    description: field.Definition.Description,
                    name: field.Definition.Name,
                    numberOfDecimals: field.Definition.NumberOfDecimals,
                    mobileRequired: field.Definition.MobileRequired,
                    size: field.Definition.Size,
                    type: field.Definition.Type,
                    value: ko.observable(this.getValue(field)),
                    comboBoxValues: field.Definition.ComboBoxValues
                };

                if (temp.type === this.fieldsDefinition.AMOUNT) {
                    temp.maxLength = temp.size - temp.numberOfDecimals;
                }

                return temp;
            });

            this.additionalFields(mappedFields);
        });
    }

    clear() {
        this.additionalFields([]);
    }

    getValue(item) {
        if (item.Definition.Type === this.fieldsDefinition.HOUR) {
            return item.Value === '' ? '' : item.Value.substr(0, 2) + ':' + item.Value.substr(2, 2);
        } else if (item.Definition.Type === this.fieldsDefinition.DATE) {
            if (item.Value === "") {
                return "";
            }
            return item.Value;
            //var year = item.Value.substr(0, 2);
            //if (+year < 50) {
            //    return "20" + item.Value;
            //} else {
            //    return "19" + item.Value;
            //}
        } else if (item.Definition.Type === this.fieldsDefinition.CHECKBOX) {
            return item.Value === "true";
        } else if (item.Definition.Type === this.fieldsDefinition.TIMESTAMP) {
            if (item.Value === null || item.Value.length !== 14) {
                return "";
            }

            var dateValue = item.Value.substr(0, 8) + 'T' + item.Value.substr(8);
            dateValue = dateHelper.formatDate("YYYY-MM-DD HH:mm:ss", moment(dateValue));
            return dateValue === "Invalid date" ? '' : dateValue;
        } else if (item.Definition.Type === this.fieldsDefinition.DROPDOWN) {
            return { id: item.Value, text: item.Value, data: undefined };
        } else {
            return item.Value;
        }
    }

    getValueToPost(item) {
        if (item.type === this.fieldsDefinition.DATE) {
            return item.value() === null ? '' : dateHelper.formatDate("YYYYMMDD", item.value());
        } else if (item.type === this.fieldsDefinition.HOUR) {
            return item.value().replace(':', '');
        } else if (item.type === this.fieldsDefinition.CHECKBOX) {
            return item.value() ? "true" : "false";
        } else if (item.type === this.fieldsDefinition.TELEPHONE) {
            return maskHelper.phone.get(item.value());
        } else if (item.type === this.fieldsDefinition.DROPDOWN) {
            return item.value().text;
        } else if (item.type === this.fieldsDefinition.AMOUNT || item.type === this.fieldsDefinition.decimal || item.type === this.fieldsDefinition.INTLONG || item.type === this.fieldsDefinition.INTSHORT) {
            return jQuery('#' + item.name).cleanVal();
        } else {
            return item.value();
        }
    }
        
    mapDataToSave() {
        var data = [];
        _.each(this.additionalFields(), (val) => {
            var definition = {
                Name: val.name,
                Size: val.size,
                NumberOfDecimals: val.numberOfDecimals,
                Type: val.type,
                Description: val.description
            };

            if (definition.Type === this.fieldsDefinition.MEMO) {
                data.push({
                    Definition: definition,
                    Value: this.getMemoValue(definition.Name)
                });
            } else {
                data.push({
                    Definition: definition,
                    Value: this.getValueToPost(val)
                });
            }
                
        });
        return data;
    }

    getMemoValue(name) {
        var rtfvalue = document.getElementById(name);
                   
        if (rtfvalue != null) {
            return rtfvalue.value;
        }

        return "";
    }

    validateAdditionalFields() {
        this.additionalFields()
        .filter((val) => val.mobileRequired && val.Type !== this.fieldsDefinition.TIMESTAMP)
        .forEach((val) => {
            if (val.type === this.fieldsDefinition.MEMO) {
                val.value(this.getMemoValue(val.name));
            }

            if(!val.value() || val.value() == 0) {
                let warning = this.additionalFieldsHelper.getRequiredRessourceBySourceType(this.additionalFieldsSource);
                notificationHelper.showWarning(warning, "", { timeOut: 0 });
                return;
            }
        });
    }
    //#endregion

    //#region Public Functions
    activate(widgetSettings) {
        this.clear();

        this.supportedFieldIds = _.values(this.fieldsDefinition);
        this.actions = widgetSettings.actions;
        this.readonly = widgetSettings.readonly;
        this.saveAdditionalFieldsButton = widgetSettings.saveAdditionalFieldsButton; 
        this.additionalFieldsSource = widgetSettings.additionalFieldsSource;

        return this.loadData();
    }

    compositionComplete() {
        maskHelper.phone.set(".phoneNumber");


        _.each(jQuery(".amount"), (item) => {
            //minus 2 for mendatory decimals
            maskHelper.amount.set("#" + item.id, +jQuery(item).attr('size') - 2);
        });

        _.each(jQuery(".number"), (item) => {
            maskHelper.decimal.set("#" + item.id, +jQuery(item).attr('size') - +jQuery(item).attr('decimals'), jQuery(item).attr('decimals'));
        });

        _.each(jQuery(".integer"), (item) => {
            maskHelper.integer.set("#" + item.id, +jQuery(item).attr('size'));
        });          
    }

    save() {
        // Notice user but let it go
        this.validateAdditionalFields();

        routerHelper.showLoading();
        var mappedData = this.mapDataToSave();
        this.actions.setAll(mappedData).done(() => {
            routerHelper.navigateBack();
        }).always(routerHelper.hideLoading);
    }
    //#endregion
}