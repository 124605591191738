import { transient } from "aurelia-framework";

@transient()
export class PageBottom {
    compositionCompleteCallbacks = [];

    isCompositionComplete = false;
    isDomLoaded = false;

    get loadingCompleted() {
        return this.isCompositionComplete && this.isDomLoaded;
    }


    activate(widgetSettings) {
        if (widgetSettings && widgetSettings.compositionCompleteCallback) {
            this.compositionCompleteCallbacks.push(widgetSettings.compositionCompleteCallback);
        }
        if (widgetSettings && widgetSettings.compositionCompleteCallbacks) {
            this.compositionCompleteCallbacks.concat(widgetSettings.compositionCompleteCallbacks);
        }
    }

    compositionComplete() {
        this.isCompositionComplete = true;
        this.callParentCallback();
    }

    domLoaded() {
        this.isDomLoaded = true;
        this.callParentCallback();
    }

    callParentCallback() {
        if (this.loadingCompleted && this.compositionCompleteCallbacks) {
            for (var i = 0; i < this.compositionCompleteCallbacks.length; i++) {
                this.compositionCompleteCallbacks[i]();
            }
        }
    }
}