import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { RouterHelper } from "helpers/router-helper";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { ValidationHelper } from "helpers/validation-helper";
import RouteRepository from "repositories/routeRepository";
import { QuotationItemsParameters } from "pages/services/quotations/items";
import { nameof as nameof_ServiceCallQuotationPricingType, ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";

export interface QuotationPricingTypeParameters {
    quotationId: number;
}

interface ServiceCallQuotationPricingTypeOption {
    id: string;
    text: string;
}

@autoinject()
export class QuotationPricingType {
    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;
    public serviceCallQuotationPricingType: typeof ServiceCallQuotationPricingType = ServiceCallQuotationPricingType;
    public pricingTypes: ServiceCallQuotationPricingTypeOption[];
    public originalPricingType: string | null = null;

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.quotation, this.securitySettings);
    }

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        private readonly routerHelper: RouterHelper,
        private readonly i18n: I18N,
        private readonly validationHelper: ValidationHelper,
        private readonly validationController: ValidationController,
        private readonly routeRepository: RouteRepository
    ) {
        this.pricingTypes = [{ id: ServiceCallQuotationPricingType.TimeMaterial, text: i18n.tr("PricingTypeTime&Material") }, { id: ServiceCallQuotationPricingType.LumpSum, text: i18n.tr("PricingTypeLumpSum") }];
    }

    public async activate(params: QuotationPricingTypeParameters): Promise<void> {
        await Promise.all([
            this.initQuotation(Number(params.quotationId)),
            this.initSecuritySettings()
        ]);
        this.initValidation();
    }

    public async save(): Promise<void> {
        if (!this.quotation) { return; }

        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);

        if (isValid !== true) {
            return;
        }

        if (this.originalPricingType !== this.quotation.IsLumpSum) {
            this.quotation.Items = await this.serviceCallQuotationService.GetQuotationItemsFromContract(this.quotation, true);
        }
        
        if (this.quotation.IsLumpSum !== ServiceCallQuotationPricingType.LumpSum) {
            this.quotation.LumpSum = null;
        }

        if (this.quotation.IsLumpSum !== ServiceCallQuotationPricingType.LumpSumPlus) {
            this.quotation.ProfitMargin = 0;
        }

        await this.serviceCallQuotationService.saveQuotation(this.quotation, true, true);

        this.routerHelper.navigateBack();
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, false, false, false, false, false);
        if (this.quotation) {
            this.originalPricingType = this.quotation.IsLumpSum;
        }
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }

    private initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure((x: ServiceCallQuotationDetailsModel) => x.LumpSum).satisfies((value: number) => value > 0).withMessageKey("err_LumpSumRequired").when((x: ServiceCallQuotationDetailsModel) => x.IsLumpSum === ServiceCallQuotationPricingType.LumpSum)
            .on(this.quotation);
    }
}
