import documentService from "services/documentService";
import routerHelper from "helpers/routerHelper";
import settingRepository from "repositories/settingRepository";

export class ReceiptOfGoodsDocumentAdd {

    public readonly: boolean = false;
    public receiptId: number | null = null;
    public dispatchTemplateId: string | null = null;
    
    public actions: any;

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.receiptId = params.receiptId;
        this.dispatchTemplateId = settingRepository.getDispatchTemplate();
        this.actions = { save: documentService.saveReceiptPicture.bind(self, this.receiptId, this.dispatchTemplateId)};
    }
}
