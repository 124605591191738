import { transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

function loadWidgetSettings(self, widgetSettings) {
    if (widgetSettings.employee) {
        self.employee = widgetSettings.employee;
    }
    if (widgetSettings.showEmployeeName === false) {
        self.showEmployeeName = false;
    }

    self.linkedEquipments = widgetSettings.linkedEquipments;
}

@transient()
export class TimesheetEmployeeHeader {
    constructor() {
        this.resx = resx;

        this.showEmployeeName = true;
        this.employee = ko.observable({});
        this.linkedEquipments = ko.observableArray([]);
        this.employeeName = ko.pureComputed(() => {
            var emp = this.employee();
            return emp.Id + " - " + emp.FirstName + " " + emp.LastName;
        });
    }

    activate(widgetSettings) {
        loadWidgetSettings(this, widgetSettings);
    }
}