import { inject } from "aurelia-framework";

import { DialogController } from "aurelia-dialog";
import { EventAggregator } from 'aurelia-event-aggregator';

import ko from "knockout";
import resx from "core/resx";

@inject(ko, resx, DialogController, EventAggregator)
export default class MessageBox {
    constructor(ko, resx, dialogController, eventAggregator) {
        this.ko = ko;
        this.resx = resx;
        this.dialogController = dialogController;
        this.eventAggregator = eventAggregator;

        this.dialogController.settings.lock = false;

        this.popupTitle = ko.observable(null);
        this.popupMessage = ko.observable("");
        this.yesLabel = ko.observable("");
        this.noLabel = ko.observable("");
        this.cancelLabel = ko.observable("");
        this.otherLabel = ko.observable("");
        this.singleButton = ko.observable(false);
        this.isErrorBox = ko.observable(false);
        this.thirdButton = ko.observable(false);
        this.labelClass = ko.observable("");
        this.larger = ko.observable(false);
    }

    activate(options) {
        this.popupMessage(options.message || "");
        this.popupTitle(options.title || null);
        this.yesLabel(options.yesLabel || this.resx.localize('OK'));
        this.noLabel(options.noLabel || this.resx.localize('Cancel'));
        this.singleButton(options.singleButton || false);
        this.isErrorBox(options.isErrorBox || false);
        this.otherLabel(options.otherLabel);
        this.thirdButton(options.thirdButton || false);
        this.labelClass(options.labelClass);
        this.larger(false);

        this.eventAggregator.publish("dialogs:openeddialogs:add", this);
    }

    noAction() {
        this.dialogController.cancel(false);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    yesAction() {
        this.dialogController.ok(true);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    otherAction() {
        this.dialogController.ok("other");
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }
}
