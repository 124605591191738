import { default as recommendationService } from "services/recommendationService";

import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as routerHelper } from "helpers/routerHelper";

export class Recommendation {
    dispatchId = "";
    entityId;
    serviceType = "";
    fetchRecommendations;
    readonly = false;
    fromHistory = false;

    constructor() {
    }

    activate(params) {
        this.bindViewModel(params.serviceType, params.dispatchId, params.q);
    }

    bindViewModel(serviceType, dispatchId, querystring) {
        const params = routerHelper.getQuerystring(querystring);
        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }

        this.fromHistory = queryStringHelper.parse(querystring, "fromHistory");
        if (!this.fromHistory) {
            this.fromHistory = false;
        } else if (this.fromHistory === "true") {
            this.fromHistory = true;
        }

        this.entityId = params.entityId;
        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.fetchRecommendations = async (status, filter, page) => await recommendationService.getRecommendationsForDispatch(dispatchId, status, filter, page, this.fromHistory).promise();
    }
}
