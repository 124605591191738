import { inject } from "aurelia-dependency-injection";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { I18N } from "aurelia-i18n";

@inject(I18N)
export class AdditionalFieldsHelper {

    constructor(private readonly i18n: I18N) {
    }

    public getRequiredRessourceBySourceType(additionalFieldsSource: AdditionalFieldsSourceType | null): string {
        const warning = this.i18n.tr("err_AdditionalFields_Required");
        let typeRessource = "";

        switch (additionalFieldsSource) {
            case AdditionalFieldsSourceType.DailyEntry:
                typeRessource = this.i18n.tr("err_AdditionalFields_DailyEntry_Required");
                break;
            case AdditionalFieldsSourceType.ServiceCall:
                typeRessource = this.i18n.tr("err_AdditionalFields_ServiceCall_Required");
                break;
            case AdditionalFieldsSourceType.FieldService:
                typeRessource = this.i18n.tr("err_AdditionalFields_FieldService_Required");
                break;
            case AdditionalFieldsSourceType.PurchaseOrder:
                 typeRessource = this.i18n.tr("err_AdditionalFields_PurchaseOrder_Required");
                 break;
            default: typeRessource = warning;
        }

        return warning.replace("{0}", typeRessource);
    }
}
