define([
], function () {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
        }

        return ctor;
    })();

    return viewModel;
});
