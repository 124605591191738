import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import contactService from "services/contactService";

@autoinject
export class ProjectContact {
    public actions: any = {};
    public contacts: any;
    public dispatchProjectCode: string = "";
    public isProjectResponsible: boolean = false;

    public activate(params: any): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
        this.dispatchProjectCode = params.dispatchProjectCode;
    }

    public bind(): any {
        this.actions = {
            getContacts: contactService.getLookupByDispatchProjectCode.bind(contactService, this.dispatchProjectCode)
        };
    }
}
