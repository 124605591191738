define([
    "knockout",
    "services/materialRequisitionService",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "widgets/pages/material_requisition_edit",
    "widgets/dateTimePicker"
], function (ko, materialRequisitionService, queryStringHelper, routerHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.requisitionId = ko.observable();
            self.dispatchId = ko.observable();
            self.readonly = false;
            //#endregion
        }

        //#region Public Functions
        function setSpecificFields(item) {
            item.DispatchId = self.dispatchId();
            return item;
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {


            self.dispatchId(params.dispatchId);
            self.requisitionId(params.requisitionId === null ? "-1" : params.requisitionId);

            if (params.q) {
                const qsParams = routerHelper.getQuerystring(params.q);
                if (qsParams) {
                    self.readonly = queryStringHelper.parseReadonly(params.q);
                }
            }

            self.actions = {
                getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(self, self.requisitionId()),
                saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForService.bind(self, self.dispatchId()),
                setSpecificFields: setSpecificFields
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
