import { WorkOrderWorkTaskModel } from "api/models/company/workorder/work-order-work-task-model";

import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";

import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";

import { WorkOrderWorkTaskService } from "services/work-order-work-task-service";
import Parse from "helpers/parse";

export interface RouteInfo {
    routeName: string;
    routeParams: any;
}

@autoinject
export class MaWorkTaskIndex {

    public workTasksCards: MaCardOptionsModel[] = [];

    @bindable public id: any;
    @bindable public getWorkTask!: () => Promise<WorkOrderWorkTaskModel[]>;
    @bindable public addRouteInfo!: RouteInfo;
    @bindable public editRouteInfo!: RouteInfo;
    public readonly: boolean = false;

    constructor(private readonly i18n: I18N, private readonly workOrderWorkTaskService: WorkOrderWorkTaskService) {}

    public async bind(): Promise<void> {
        this.readonly = Parse.Boolean(this.editRouteInfo.routeParams.readonly);

        const tasks: WorkOrderWorkTaskModel[] = (await this.getWorkTask())!;

        tasks.forEach((item: WorkOrderWorkTaskModel) => {
            this.workTasksCards.push(this.createCard(item));
        });

    }

    public add(): void {
        if (this.readonly) {
            return;
        }

        routerHelper.navigateToRoute(this.addRouteInfo.routeName, this.addRouteInfo.routeParams);
    }

    public edit(item: WorkOrderWorkTaskModel): void {
        this.editRouteInfo.routeParams.transactionNumber = item.TransactionNumber;

        routerHelper.navigateToRoute(this.editRouteInfo.routeName, this.editRouteInfo.routeParams);
    }

    public delete(item: WorkOrderWorkTaskModel): void {
        if (this.readonly) {
            return;
        }

        const dialogMessage = `${this.i18n.tr("msg_DeleteWorkTaskConfirmationText")} (${this.getDescriptionDisplay(item)})`;

        notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<any> => {
            if (success) {
                const result = await this.workOrderWorkTaskService.DeleteWorkTask(item.TransactionNumber);
                if (result) {
                    this.removeCard(item.TransactionNumber);
                }
            }
        });
    }

    public createCard(item: WorkOrderWorkTaskModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            id: item.TransactionNumber,
            model: item,
            class: "ma-card-link",
            action: this.edit.bind(this, item),
            displayLines: [
                new MaCardLineModel({ display: this.getDescriptionDisplay(item), class: "text-primary font-weight-bold"}),
                new MaCardLineModel({ display: this.getStatusDisplay(item), class: "font-weight-bold" }),
                new MaCardLineModel({ display: this.getRequiredDate(item), icon: "fa fa-plus text-success pr-1" }),
            ],
            actionItems: [new MaCardActionModel({id: "edit", icon: "fa fa-chevron-right", action: this.edit.bind(this, item) })],
        });

        if (card.actionItems && !this.readonly) {
            card.actionItems.push(new MaCardActionModel({id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item) }));
        }

        return card;
    }

    private getDescriptionDisplay(item: WorkOrderWorkTaskModel): string {
        return `${item.TaskDescription}`;
    }

    private getStatusDisplay(item: WorkOrderWorkTaskModel): string {
        if (item.IsCompleted) {
            return this.i18n.tr("Completed_F");
        }
        if (item.IsDiffered) {
            return this.i18n.tr("Deferred_F");
        }

        return "";
    }

    private getRequiredDate(item: WorkOrderWorkTaskModel): string {
        return `${this.i18n.tr("RequiredDate")}: ${item.DateRequired ? dateHelper.getShortTextDate(item.DateRequired) : ""}`;
    }

    private removeCard(transactionNumber: number): void {
        this.workTasksCards = this.workTasksCards.filter((item: MaCardOptionsModel) => {
            return item.id !== transactionNumber;
        });
    }

}
