
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import RouteRepository from "repositories/routeRepository";

import { WorkOrderWorkTaskService } from "services/work-order-work-task-service";
import { RouteInfo } from "components/others/ma-work-task-index";
import workOrderService from "services/workOrderService";

@autoinject
export class WorkTaskIndex {
    public workOrderId: any;

    public getWorkTask!: () => Promise<any>;
    public addRouteInfo: RouteInfo = { routeName: "", routeParams: {}};
    public editRouteInfo: RouteInfo = { routeName: "", routeParams: {}};
    public id: any;
    public readonly: boolean = false;

    constructor(private routeRepository: RouteRepository, private readonly workOrderWorkTaskService: WorkOrderWorkTaskService) {}

    public async activate(params: any): Promise<void> {
        this.workOrderId = params.id;

        const workOrder = await workOrderService.get(this.workOrderId);
        const readonly = workOrder.IsReadOnly || workOrder.IsCompleted;

        this.getWorkTask = (await this.workOrderWorkTaskService.GetWorkTasksByWorkOrderId.bind(this.workOrderWorkTaskService, this.workOrderId));
        this.addRouteInfo.routeName = this.routeRepository.routes.FieldService_WorkTask_Add.name;
        this.addRouteInfo.routeParams = { id: this.workOrderId };
        this.editRouteInfo.routeName = this.routeRepository.routes.FieldService_WorkTask_Edit.name;
        this.editRouteInfo.routeParams = { id: this.workOrderId, readonly: readonly };

        this.id = this.workOrderId;
    }
}
