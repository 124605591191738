define([
    "knockout",
    "core/resx",
    "services/activityService",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "helpers/notificationHelper"
], function (ko, resx, activityService, routerHelper, queryStringHelper, notificationHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.dispatchDate = ko.observable();
            self.dispatchProjectCode = ko.observable();
            self.activity = {};
            self.readonly = false;
            self.resx = resx;

            self.validatedForm = ko.validatedObservable({
                rate: ko.observable(0),

                init: function() {
                    var selfForm = this;
                    selfForm.rate.extend({
                        validation: {
                            validator: function(val) {
                                if (val < 0 || val > 100) {
                                    this.message = resx.localize('err_RateBetween0And100');
                                    return false;
                                }
                                return true;
                            }
                        }
                    });
                },

                clear: function() {
                    var selfForm = this;
                    selfForm.rate(0);
                },

                populate: function() {
                    var selfForm = this;
                    selfForm.attendanceStatus(self.activity.Rate);
                }
            });
            //#endregion
        }

        //#region Private Functions
        function clear() {
            self.activity = {};
            self.validatedForm().clear();
        }

        function loadData(activityId) {

            return activityService.getActivityProgressForDailyEntry(self.dispatchProjectCode(), self.dispatchDate(), activityId)
            .done(function (data) {
                self.activity = data;
                self.validatedForm().rate(self.activity.Rate);
            });
        }

        function bindViewModel(dispatchProjectCode, dispatchDate, activityId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);

            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dispatchDate);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            clear();

            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
            self.validatedForm().init();

            return loadData(params.id);
        };

        ctor.prototype.save = function () {

            if (!self.validatedForm.isValid()) {
                notificationHelper.showValidationError(self.validatedForm.errors);
            } else {
                routerHelper.showLoading();

                activityService.setActivityProgressForDailyEntry(self.dispatchProjectCode(), self.dispatchDate(), self.activity.Id, self.validatedForm().rate())
                        .done(function () {
                            routerHelper.navigateBack();
                        }).always(function () {
                            routerHelper.hideLoading();
                        });
            }
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
