import { inject } from "aurelia-framework";

import { DialogController } from "aurelia-dialog";
import { EventAggregator } from 'aurelia-event-aggregator';

import ko from "knockout";
import resx from "core/resx";

@inject(ko, resx, DialogController, EventAggregator)
export default class TemplateDialog {
    constructor(ko, resx, dialogController, eventAggregator) {
        this.ko = ko;
        this.resx = resx;
        this.dialogController = dialogController;
        this.eventAggregator = eventAggregator;
        this.dialogController.settings.lock = false;

        this.popupTitle = ko.observable(null);
        this.popupMessage = ko.observable("");
        this.isErrorBox = ko.observable(false);
        this.singleButton = ko.observable(false);
        this.okLabel = ko.observable("");
        this.cancelLabel = ko.observable("");
        this.template = '';
        this.isOverflowAllowed = ko.observable(false);

    }

    activate(options) {
        this.popupMessage(options.message || "");
        this.popupTitle(options.title || null);
        this.isErrorBox(options.isErrorBox || false);
        this.singleButton(options.singleButton || false);
        this.okLabel(options.okLabel || this.resx.localize('OK'));
        this.cancelLabel(options.cancelLabel || this.resx.localize('Cancel'));
        this.template = options.template;
        this.templateContext = options.templateContext;
        this.eventAggregator.publish("dialogs:openeddialogs:add", this);

        this.isOverflowAllowed(options.isOverflowAllowed || false);
    }

    cancelAction() {
        this.dialogController.cancel(false);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    okAction() {
        this.dialogController.cancel(true);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }
}
