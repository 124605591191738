import { ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { ValidationError } from "api/enums/validation-error";
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject, computedFrom } from "aurelia-framework";
import Assert from "core/assert";
import { ErrorManager } from "error-management/error-manager";
import { NotificationHelper } from "helpers/notification-helper";
import { RouterHelper } from "helpers/router-helper";
import { NavigationNew } from "repositories/routeRepository";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import RouteRepository from "repositories/routeRepository";
import { GenerateServiceCallParameters } from "pages/services/quotations/generate-service-call";
import { default as settingHelper } from "helpers/settingHelper";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { QuotationRefusalParameters } from "pages/services/quotations/refusal";
import { ServiceCallContractService } from "services/service-call-contract-service";
import moment from "moment";

export interface QuotationLightParameters {
    quotationId: number;
}

@autoinject()
export class QuotationLight {
    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;
    public readonly serviceCallQuotationStatus: typeof ServiceCallQuotationStatus = ServiceCallQuotationStatus;

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.quotation, this.securitySettings) || this.quotation!.IsServiceContractNotFound;
    }

    @computedFrom("quotation", "quotation.Status", "quotation.Id", "quotation.CreationUserId")
    public get canDelete(): boolean {
        return this.serviceCallQuotationSecurityService.canDeleteQuotation(this.quotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get canPutOnHold(): boolean {
        return this.serviceCallQuotationSecurityService.canPutQuotationOnHold(this.quotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get canResume(): boolean {
        return this.serviceCallQuotationSecurityService.canResumeQuotation(this.quotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get canSendToOffice(): boolean {
        return this.serviceCallQuotationSecurityService.canSendQuotationToOffice(this.quotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get canSendToCustomer(): boolean {
        return this.serviceCallQuotationSecurityService.canSendQuotationToCustomer(this.quotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get canCaptureSignature(): boolean {
        return this.serviceCallQuotationSecurityService.canCaptureSignatureForQuotation(this.quotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get canRefuse(): boolean {
        return this.serviceCallQuotationSecurityService.canRefuseQuotation(this.quotation, this.securitySettings);
    }

    // TODO: Se baser sur la soumission non-modifiée sinon les items dans le UI vont changer dès qu'on modifie qqchose dans l'écran
    @computedFrom("quotation", "quotation.Status", "quotation.GeneratedServiceCallId", "quotation.MustCreateCustomerContract", "quotation.IsContractMiscCustomer", "securitySettings")
    public get canCreateServiceCall(): boolean {
        return this.serviceCallQuotationSecurityService.canCreateServiceCallFromQuotation(this.quotation, this.securitySettings) && !this.isResidential;
    }

    @computedFrom("quotation", "quotation.Status", "quotation.GeneratedWorkOrderId", "securitySettings")
    public get canCreateWorkOrder(): boolean {
        return this.serviceCallQuotationSecurityService.canCreateWorkOrderFromQuotation(this.quotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Items")
    public get containNotInCatalogItem(): boolean {
        if (!this.quotation) {
            return false;
        }
        return this.serviceCallQuotationSecurityService.containNotInCatalogItem(this.quotation);
    }

    @computedFrom("quotation", "quotation.Items")
    public get btnThemeForSendToCustomer(): any {
        if (!this.quotation) {
            return;
        }
        return this.serviceCallQuotationSecurityService.containNotInCatalogItem(this.quotation) ? "warning" : "success";
    }

    @computedFrom("quotation", "quotation.Items")
    public get btnThemeForSignatureAndRefusal(): any {
        if (!this.quotation) {
            return;
        }
        return this.serviceCallQuotationSecurityService.containNotInCatalogItem(this.quotation) ? "warning" : "primary";
    }

    @computedFrom("quotation", "quotation.MustCreateCustomerContract", "quotation.IsContractMiscCustomer")
    public get isResidential(): boolean {
        if (!this.quotation) {
            return false;
        }
        return  !this.quotation.IsWorkOrder && this.quotation.MustCreateCustomerContract && this.quotation.IsContractMiscCustomer;
    }

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        private readonly routerHelper: RouterHelper,
        private readonly notificationHelper: NotificationHelper,
        private readonly errorManager: ErrorManager,
        private readonly routeRepository: RouteRepository,
        private readonly i18N: I18N,
        private readonly serviceCallContractService: ServiceCallContractService
    ) {
    }

    public async activate(params: QuotationLightParameters): Promise<void> {
        Assert.strictNotEqual(params.quotationId, NavigationNew, "Must have a quotation ID in mobile view");

        await Promise.all([
            this.initQuotation(params.quotationId),
            this.initSecuritySettings()
        ]);
    }

    public async canActivate(params: QuotationLightParameters): Promise<any> {
        let sourceDispatchId = 0;
        if (params.quotationId !== 0) {
            sourceDispatchId = await this.getSourceDispatchId(params.quotationId);
        }

        if (sourceDispatchId === 0 && !settingHelper.hasDispatchModel()) {
            this.notificationHelper.showWarningKey("DispatchModelRequired", "");
            return new Redirect("Settings");
        }

        return true;
    }

    public async signByCustomer(): Promise<void> {
        if (!this.quotation) { return; }

        if (this.quotation.Date && this.quotation.RequiredDate && moment(this.quotation.RequiredDate).isBefore(moment(this.quotation.Date), "day") ) {
            this.notificationHelper.showWarning(this.i18N.tr("WarningQuotationRequiredDateIsPassed"), "", { timeOut: 0 });
            return;
        }

        return this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Summary.name, {
            quotationId: this.quotation.Id
        } as GenerateServiceCallParameters);

    }

    public async putOnHold(): Promise<void> {
        if (!this.quotation) { return; }

        await this.serviceCallQuotationService.putQuotationOnHold(this.quotation.Id);
        await this.initQuotation(this.quotation.Id);
    }

    public async resume(): Promise<void> {
        if (!this.quotation) { return; }

        await this.serviceCallQuotationService.resumeQuotation(this.quotation.Id);
        await this.initQuotation(this.quotation.Id);
    }

    public async sendToOffice(): Promise<void> {
        if (!this.quotation) { return; }

        if (await this.confirmSendToOffice()) {
            await this.serviceCallQuotationService.sendQuotationToOffice(this.quotation.Id);
        }
        await this.initQuotation(this.quotation.Id);
    }

    public async sendToCustomer(): Promise<void> {
        if (!this.quotation) { return; }

        try {
            if (this.containNotInCatalogItem) {
                this.notificationHelper.showWarningKey("msg_QuotationActionNotAllowedContainsNonCatalogItem", "", { timeOut: 0 });
                return;
            }

            if (await this.confirmSendToCustomer()) {
                await this.serviceCallQuotationService.sendQuotationToCustomer(this.quotation.Id);
            }
        } catch (error) {
            // Si une erreur se produit avec la génération du formulaire, la soumission est quand même changée d'état. On affiche un message pour avertir de contacter le bureau pour compléter la génération.
            const validationException = this.errorManager.getApiValidationExceptionFromError(error);
            if (validationException && validationException.ErrorId === ValidationError.ServiceCallQuotationFormGenerationError) {
                this.notificationHelper.showErrorKey(`ApiError.${ValidationError.ServiceCallQuotationFormGenerationError}`, undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                throw error;
            }
        }

        await this.initQuotation(this.quotation.Id);
    }

    public async delete(): Promise<void> {
        if (this.quotation && (await this.confirmDelete())) {
            await this.serviceCallQuotationService.deleteQuotation(this.quotation.Id);
            this.routerHelper.navigateBack();
        }
    }

    public async createServiceCall(): Promise<void> {
        if (!this.quotation) { return; }

        const canCreateServiceCall = await this.serviceCallContractService.pendingContractCanCreateServiceCall(this.quotation.ContractId);
        if (!canCreateServiceCall) {
            return;
        }

        return this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Generate_Service_Call.name, {
            quotationId: this.quotation.Id
        } as GenerateServiceCallParameters);
    }

    public async createWorkOrder(): Promise<void> {
        if (!this.quotation) { return; }

        return this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Generate_Work_Order.name, {
            quotationId: this.quotation.Id
        } as GenerateServiceCallParameters);
    }

    public async customerRefusal(): Promise<void> {
        if (!this.quotation) { return; }

        if (this.containNotInCatalogItem) {
            this.notificationHelper.showWarningKey("msg_QuotationActionNotAllowedContainsNonCatalogItem", "", { timeOut: 0 });
            return;
        }

        return this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Refusal.name, {
            quotationId: this.quotation.Id
        } as QuotationRefusalParameters);
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, true, true, false, false, false);

        if (this.quotation && this.quotation.ContractId && this.quotation.IsServiceContractNotFound) {
            const warning = this.i18N.tr("WarningQuotationServiceCallContractNotFound");
            this.notificationHelper.showWarning(warning.replace("{0}", this.quotation.ContractId.toString()), "", { timeOut: 0 });
        }
    }

    private async getSourceDispatchId(quotationId: number): Promise<number> {
        return await this.serviceCallQuotationService.getSourceDispatchId(quotationId);
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }

    private async confirmDelete(): Promise<boolean> {
        return await this.notificationHelper.showConfirmationKey("msg_DeleteEntryConfirmationText");
    }

    private async confirmSendToCustomer(): Promise<boolean> {
        return await this.notificationHelper.showConfirmationKey("msg_SendToCustomerConfirmationText");
    }

    private async confirmSendToOffice(): Promise<boolean> {
        return await this.notificationHelper.showConfirmationKey("msg_SendToOfficeConfirmationText");
    }
}
