import { inject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import logger from "core/logger";

import { AssignmentTypes } from "enums/assignment-types";
import { NotificationHelper } from "helpers/notification-helper";
import { default as AssignmentService } from "services/assignment-service";
import { default as labelHelper } from "helpers/labelHelper";
import { default as phoneHelper } from "helpers/phoneHelper";

@inject(AssignmentService, I18N, NotificationHelper)
export class AssignmentCalendar {
    assignmentService;
    i18n;

    constructor(assignmentService, i18n, notificationHelper) {
        this.assignmentService = assignmentService;
        this.i18n = i18n;
        this.notificationHelper = notificationHelper;
    }

    formatResult(entry) {
        const item = {
            title: entry.DispatchId,
            popupTitle: "",
            start: entry.StartDateTime,
            end: entry.EndDateTime,
            customerName: entry.CustomerName,
            contactName: entry.ContactName,
            contactPhoneNo: entry.ContactPrimaryPhone,
            contactPhoneExt: entry.ContactPrimaryPhoneExt,
            contactEmail: entry.ContactEmail,
            workLocationAddress: entry.WorkLocationAddress,
            workLocationCity: entry.WorkLocationCity,
            workLocationProvince: entry.WorkLocationProvince,
            workLocationCountry: entry.WorkLocationCountry,
            workLocationPostalCode: entry.WorkLocationPostalCode,
            workLocationZipCode: entry.WorkLocationZipCode,
            dispatchId: entry.DispatchId
        };

        switch (entry.Type) {
            case (AssignmentTypes.Unavailability):
                item.popupTitle = this.i18n.tr("Unavailability") + " - " + entry.UnavailabilityTypeDescription;
                item.title = this.i18n.tr("Unavailability") + " - " + entry.UnavailabilityTypeDescription;
                item.borderColor = "#a30000";
                item.color = "white";
                item.textColor = "#a30000";
                break;

            case (AssignmentTypes.ProjectDispatch):
                item.popupTitle = entry.DispatchProjectId + ' - ' + entry.DispatchProjectDescription;
                item.title = entry.CustomerName ? entry.CustomerName : entry.DispatchProjectId + ' - ' + entry.DispatchProjectDescription;
                item.color = "blue";
                break;

            case (AssignmentTypes.ServiceServiceCallDispatch):
                item.popupTitle = labelHelper.getCallTypeLabel(entry.ServiceCallType) + " " + entry.ServiceCallId;
                item.title = entry.CustomerName ? entry.CustomerName : entry.ServiceCallId;
                item.color = "green";
                break;

            case (AssignmentTypes.ServiceWorkOrderDispatch):
                item.popupTitle = this.i18n.tr("WorkOrder") + " " + entry.WorkOrderId;
                item.title = entry.CustomerName ? entry.CustomerName : entry.WorkOrderId;
                item.color = "orange";
                break;

            case (AssignmentTypes.FieldServiceDispatch):
                item.popupTitle = this.i18n.tr("WorkOrder") + " " + entry.WorkOrderId;
                item.title = entry.CustomerName ? entry.CustomerName : entry.WorkOrderId
                item.color = "red";
                break;

            default:
                logger.warn("Unknown assignment type", entry);
                break;
        }

        return item;
    }

    async getEvents(start, end, timezone, callback) {
        const result = await this.assignmentService.getAssignments(start.format(), end.format());
        callback(result.map((entry) => { return this.formatResult(entry); }));
    }

    onEventClicked(calEvent, view) {

        if (view === "month") {
            return;
        }

        const title = calEvent.popupTitle;

        const startDate = calEvent.start.format("LLLL");

        let message = ""
        if (calEvent.customerName) {
            message = "<b>" + this.i18n.tr("Customer:") + "</b><br />" + calEvent.customerName + "<br /><br />";
        }

        if (calEvent.contactName || calEvent.contactPhoneNo || calEvent.contactEmail) {
            message += "<b>" + this.i18n.tr("Contact:") + "</b><br />"
        }

        if (calEvent.contactName) {
            message += calEvent.contactName + "<br />";
        }

        if (calEvent.contactPhoneNo) {
            if (calEvent.contactPhoneExt) {
                message += phoneHelper.getDefaultFormat(calEvent.contactPhoneNo) + " " + this.i18n.tr("Extension") + calEvent.contactPhoneExt + "<br />";
            } else {
                message += phoneHelper.getDefaultFormat(calEvent.contactPhoneNo) + "<br />";
            }   
        }

        if (calEvent.contactEmail) {
            message += calEvent.contactEmail + "<br />";
        }

        if (calEvent.contactName || calEvent.contactPhoneNo || calEvent.contactEmail) {
            message += "<br />"
        }

        if (calEvent.workLocationAddress || calEvent.workLocationCity || calEvent.workLocationPostalCode || calEvent.workLocationZipCode) {
            message += "<b>" + this.i18n.tr("WorkLocation:") + "</b><br />"
        }

        if (calEvent.workLocationAddress) {
            message += calEvent.workLocationAddress + "<br />";
        }
       
        if (calEvent.workLocationCity) {
            if (calEvent.workLocationProvince) {
                message += calEvent.workLocationCity + ", " + calEvent.workLocationProvince + "<br />";
            } else {
                message += calEvent.workLocationCity + "<br />";
            }
        }

        if (!calEvent.workLocationCity && calEvent.workLocationProvince) {
            message += calEvent.workLocationProvince + "<br />";
        }

        if (calEvent.workLocationPostalCode) {
            if (calEvent.workLocationCountry) {
                message += calEvent.workLocationPostalCode + ", " + calEvent.workLocationCountry + "<br />";
            } else {
                message += calEvent.workLocationPostalCode + "<br />";
            }
        }

        if (calEvent.workLocationZipCode) {
            if (calEvent.workLocationCountry) {
                message += calEvent.workLocationZipCode + ", " + calEvent.workLocationCountry + "<br />";
            } else {
                message += calEvent.workLocationZipCode + "<br />";
            }
        }

        if (calEvent.workLocationAddress || calEvent.workLocationCity || calEvent.workLocationPostalCode || calEvent.workLocationZipCode) {
            message += "<br />"
        }

        message += "<b>" + this.i18n.tr("Dispatch:") + "</b><br />"
        
        if (calEvent.end) {
            if (calEvent.start.format("L") !== calEvent.end.format("L")) {
                message += this.i18n.tr("From_Date:") + " " + startDate;
                message += "<br />";
                message += this.i18n.tr("To_Date:") + " " + calEvent.end.format("LLLL");
            } else {
                message += calEvent.start.format("dddd D MMMM YYYY");
                message += "<br />";
                message += calEvent.start.format("HH:mm") + " - " + calEvent.end.format("HH:mm");
            }
        }

        this.notificationHelper.showDialogOk(message, title);
    }
}
