import { nameof as nameof_ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { DialogController } from "aurelia-dialog";
import { autoinject, bindable } from "aurelia-framework";
import { ValidationController } from "aurelia-validation";
import { ApiHelper } from "helpers/api-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemMaterialBase } from "pages/services/quotations/item-material-base";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { SettingRepository } from "repositories/setting-repository";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { I18N } from "aurelia-i18n";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { Dialog } from "interfaces/dialog";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { CloneHelper } from "helpers/cloneHelper";

export interface QuotationItemMaterialDialogParameters {
    quotationItem: ServiceCallQuotationItemModel;
    parentQuotationItem: ServiceCallQuotationItemModel | null;
    quotation: ServiceCallQuotationDetailsModel;
}

@autoinject()
export class QuotationItemMaterialDialog extends QuotationItemMaterialBase implements Dialog {

    public dialogHeaderTitle: string = "";

    constructor(
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        apiHelper: ApiHelper,
        settingRepository: SettingRepository,
        dispatchTemplateService: DispatchTemplateService,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        private readonly dialogController: DialogController,
        private readonly enumFormatValueConverter: EnumFormatValueConverter
    ) {
        super(i18N, serviceCallQuotationService, serviceCallNonBillingReasonService, validationHelper, validationController, apiHelper, settingRepository, dispatchTemplateService, serviceCallQuotationPriceService);
    }

    public async activate(params: QuotationItemMaterialDialogParameters): Promise<void> {
        this.initQuotationItem(params.quotationItem);
        this.quotation = params.quotation;
        await this.initCatalogBaseUrl();
 
        this.dialogHeaderTitle =  this.enumFormatValueConverter.toView(this.quotationItem.Type, nameof_ServiceCallQuotationItemType);
    }

    public async ok(): Promise<void> {
        if (!await this.validate()) {
            return;
        }

        // Update linked items (only if the catalog item or the quantity was changed).
        if (this.quotationItem.Quantity !== this.originalQuantity || this.quotationItem.CatalogId !== this.selectedItem.id || this.quotationItem.Id === 0) {
            await this.updateLinkedItems();
        }

        this.quotationItem.CatalogId = this.selectedItem.id;

        this.dialogController.ok(this.quotationItem);
    }

    public cancel(): void {
        Object.assign(this.quotationItem, this.originalQuotationItem);
        this.dialogController.cancel();
    }

    private  initQuotationItem(quotationItem: ServiceCallQuotationItemModel): void {
        this.quotationItem = quotationItem;
        this.originalQuotationItem = CloneHelper.deepClone(quotationItem);
        this.calculationInProgress = true;
        this.profitMargin = this.quotationItem.ProfitMargin;
        this.unitCost = this.quotationItem.UnitCost;
        this.sellingPrice = this.quotationItem.UnitPrice;
        this.originalQuantity = this.quotationItem.Quantity;
        this.initValidation();
        this.initSelectedItem(this.quotationItem);
        this.calculationInProgress = false;
    }
}
