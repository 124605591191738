// GENERATED FILE - DO NOT MODIFY
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { EquipmentCounterModel } from "api/models/company/equipment-counter-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class EquipmentProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetEquipments(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        const uri = this.buildUri(
            "/equipments",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EquipmentModel[] | null = await this.get<EquipmentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Equipment.EquipmentModel");
        return data;
    }

    public async GetEquipmentWithCounters(equipmentId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentCounterModel[] | null> {
        const uri = this.buildUri(
            "/equipments/{equipmentId}/equipmentWithCounters",
            { equipmentId: (equipmentId === null || equipmentId === undefined) ? undefined : equipmentId },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EquipmentCounterModel[] | null = await this.get<EquipmentCounterModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.EquipmentCounterModel");
        return data;
    }
}
