import { WorkOrderTimesheetProxy } from "api/proxies/work-order-timesheet-proxy";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallTimesheetProxy } from "api/proxies/service-call-timesheet-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallTimesheetService {
    constructor(private readonly serviceCallTimesheetProxy: ServiceCallTimesheetProxy, private readonly workOrderTimesheetProxy: WorkOrderTimesheetProxy) {
    }

    public async GetSeperateTimeLinkedEquipmentDispatches(isServiceCall: boolean, dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EquipmentModel[] | null> {
        if (isServiceCall) {
            return this.serviceCallTimesheetProxy.GetSeperateTimeLinkedEquipmentDispatches(dispatchId, filter, pagingInfo, requestConfig);
        } else {
            return this.workOrderTimesheetProxy.GetSeperateTimeLinkedEquipmentDispatches(dispatchId, filter, pagingInfo, requestConfig);
        }
    }
}
