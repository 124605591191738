import { autoinject } from "aurelia-framework";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";

import additionalFieldsService from "services/additionalFieldsService";
import routerHelper from "helpers/routerHelper";
@autoinject()
export class ServiceMaterialOrderAdditonnalFields {
    public actions: any = {};
    public dispatchId: number = 0;
    public orderId: string = "";
    public readonly: boolean = true;
    public extension: string = "";
    public additionalFieldsSource: number = AdditionalFieldsSourceType.PurchaseOrder;
    public saveAdditionalFieldsButton: boolean = false;

    constructor(private readonly eventAggregator: EventAggregator, private readonly i18n: I18N) {
    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchId, params.orderId, params.q);
        this.bindWidget();
    }

    private bindViewModel(dispatchId: number, orderId: string, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");
        this.extension = params.extension;

        this.saveAdditionalFieldsButton = !this.readonly;
        this.dispatchId = dispatchId;
        this.orderId = orderId;
    }

    private bindWidget(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForPurchaseOrder.bind(this, this.orderId, this.extension),
            setAll: additionalFieldsService.setAllForPurchaseOrder.bind(this, this.orderId, this.extension)
        };
    }
}
