import { AdditionalFieldType } from "api/enums/additional-field-type";
import { AdditionalFieldValueGetter } from "./additional-field-value-getter";
import { autoinject } from "aurelia-framework";

@autoinject
export class AdditionalFieldMapper {
  private additionalFieldValueGetter: AdditionalFieldValueGetter;

  constructor(additionalFieldValueGetter: AdditionalFieldValueGetter) {
    this.additionalFieldValueGetter = additionalFieldValueGetter;

  }

  public mapFields(data: any): any[] {
    return data.map((field: any) => {
      const temp = {
          description: field.Definition.Description,
          name: field.Definition.Name,
          numberOfDecimals: field.Definition.NumberOfDecimals,
          mobileRequired: field.Definition.MobileRequired,
          size: field.Definition.Size,
          type: field.Definition.Type,
          lineNo: field.Definition.LineNo,
          lineName: field.Definition.LineName,
          columnNo: field.Definition.ColumnNo,
          columnId: field.Definition.ColumnId,
          columnName: field.Definition.ColumnName,
          value: this.additionalFieldValueGetter.getValue(field),
          comboBoxValues: this.setupComboxValues(field.Definition.ComboBoxValues),
          maxLength: 0
      };

      this.setMaxLength(temp);
      this.setComboBoxValue(temp);

      return temp;
    });
  }

  public async mapDataToSave(fields: any): Promise<any> {
    return fields.map((val: any) => {
        const field = {
            Name: val.name,
            Size: val.size,
            NumberOfDecimals: val.numberOfDecimals,
            Type: val.type,
            Description: val.description,
            LineNo: val.lineNo,
            ColumnNo: val.columnNo,
            ColumnId: val.columnId,
        };

        return {
          Definition: field,
          Value: this.additionalFieldValueGetter.getSaveValue({ Definition: field, Value: val.value })
        };
    });
  }

  private setupComboxValues(items: any[]): any {
    if (!items) {
      return null;
    }

    return items.map((item: any) => {
      return {
        id: item.Id,
        text: item.Text
      };
    });
  }

  private setComboBoxValue(temp: any): void {
    if (temp.comboBoxValues) {
      temp.value = temp.comboBoxValues.find((item: any) => {
        return temp.value.id === item.id;
      });
    }
  }

  private setMaxLength(temp: any): void {
    switch (temp.type) {
      case AdditionalFieldType.NumberWithDecimals:
      case AdditionalFieldType.LongInteger:
      case AdditionalFieldType.ShortInteger:
        temp.maxLength = Math.pow(10, temp.size) - 1;
        break;

      case AdditionalFieldType.Amount:
        temp.maxLength = temp.size - temp.numberOfDecimals;
        break;
    }
  }
}
