import { default as documentHelper } from "helpers/documentHelper";
import { autoinject, bindable, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import { fabric } from "fabric-with-gestures";
import { IText } from "fabric/fabric-impl";
import { DocumentService } from "services/document-service";
import { DocumentModel } from "api/models/company/document/document-model";
import { RouterHelper } from "helpers/router-helper";
import { NotificationHelper } from "helpers/notification-helper";
@autoinject()
export class MaImageEditor {
    public canvas!: fabric.Canvas;
    public bgImage!: fabric.Image;

    @bindable public editorId!: string;
    @bindable public imageId?: any;
    @bindable public file?: any;
    @bindable public routeName?: string;
    @bindable public toggleEditing?: () => any;

    @observable public color: string = "#FF0000";
    @observable public sizeSlider: number = 5;

    public zoomIndex: number = 1;
    public zoomDisplay: string = "100%";

    public imageFormat: string = "jpeg";

    public toolboxSpace: number = 120; //size allocated for editing tools
    public zoomControl: number = 30; //size allocated for editing tools

    public canvasContainerWidth: number = 0;
    public canvasContainerHeight: number = 0;

    public modes: any = {
        move: "move",
        draw: "draw",
        text: "text",
        pan: "pan"
    };

    public currentMode: string = this.modes.draw;

    private preventAddingText: boolean = false;
    private zoomStartScale: any;
    private defaultOnTouchStartHandler: any;

    constructor(private readonly i18n: I18N, private readonly documentService: DocumentService, private readonly routerHelper: RouterHelper, private readonly notificationHelper: NotificationHelper) {}

    public attached(): any {
        this.loadCanvas();

        window.addEventListener("resize", () => {
            if (this.bgImage) {
                this.resizeCanvas(this.bgImage);
                this.scaleAndPositionImage(this.bgImage, this.canvas.width!, this.canvas.height!);
            }
        }, true);
    }

    public detached(): any {
        this.resetZoom();
        // @ts-ignore
        fabric.Canvas.prototype._onTouchStart = this.defaultOnTouchStartHandler;
    }

    public async loadCanvas(): Promise<void> {

        this.extendTouchEventForCanvas();
        this.canvas = new fabric.Canvas(this.editorId, {
            allowTouchScrolling: true
        });
        this.toggleModes(this.modes.pan);

        this.canvas.freeDrawingBrush.color = this.color;
        this.canvas.freeDrawingBrush.width = this.sizeSlider;

        if (this.imageId) {
            await this.loadImageInCanvasFromDocId(this.imageId);
        } else {
            await this.loadImageInCanvas(this.file.fileContent);
        }

        this.initCanvasEvents();

        this.canvas.renderAll();
    }

    public zoom(isZoomIn: boolean): void {
        if (!this.canvas) { return; }

        if (isZoomIn) {
            this.canvas.setZoom(this.zoomIndex += 0.1);
        } else {
            if (this.zoomIndex <= 0.1) {
                this.canvas.setZoom(this.zoomIndex = 0.1);
            } else {
                this.canvas.setZoom(this.zoomIndex += -0.1);
            }
        }
        this.resizeCanvas();
        this.updateZoomDisplay();
    }

    public editSelectedText(): void {
        if (!this.canvas || this.canvas.getActiveObjects().length === 0) { return; }

        const selectedElement = this.canvas.getActiveObjects()[0];

        if (selectedElement.type! !== "i-text") { return; }

        const textEl = (selectedElement as IText);
        textEl.enterEditing();
        this.preventAddingText = true;
    }

    public resizeCanvas(image?: fabric.Image): void {
        if (!this.canvas) { return; }

        if (!image) { image = this.bgImage; }

        const usedSpace = this.setNaNtoZero($("header").height()!) + this.setNaNtoZero($(".subheader-day").height()!) + this.setNaNtoZero($(".subheader").height()!) + this.toolboxSpace + this.zoomControl;

        this.canvasContainerWidth = document.documentElement.clientWidth;
        this.canvasContainerHeight = document.documentElement.clientHeight - usedSpace;

        const currentBgImageWidth = image.width! * this.zoomIndex;
        const currentBgImageHeight = image.height! * this.zoomIndex;

        this.canvas.setWidth(currentBgImageWidth);
        this.canvas.setHeight(currentBgImageHeight);

        this.canvas.renderAll();
    }

    public setNaNtoZero(height: number): number {
        if (!height) {
            return 0;
        }
        return Number.isNaN(height) || !Number.isSafeInteger(height) ? 0 : height;
    }

    public toggleModes(value: string): any {
        if (!this.canvas) { return; }

        this.currentMode = value;

        this.canvas.allowTouchScrolling = false;
        this.canvas.selection = true;
        this.canvas.isDrawingMode = false;

        switch (value) {
            case this.modes.move:
                this.changeSelectableStatusOfCanvasObj(true);
                break;
            case this.modes.draw:
                this.canvas.isDrawingMode = true;
                this.changeSelectableStatusOfCanvasObj(false);
                break;
            case this.modes.text:
                this.changeSelectableStatusOfCanvasObj(true);
                this.editSelectedText();
                break;
            case this.modes.pan:
                this.canvas.allowTouchScrolling = true;
                this.canvas.selection = false;
                this.changeSelectableStatusOfCanvasObj(false);
                break;
            default:
                break;
        }
    }

    public addText(positionX: number, positionY: number): any {
        if (!this.canvas) { return; }

        this.canvas.add(new fabric.IText(this.i18n.tr("TextPlaceHolder"),  {
            left: positionX,
            top: positionY,
            fill: this.color
        }));

        this.canvas.renderAll();
    }

    public delete(): void {
        if (!this.canvas) { return; }

        this.canvas.getActiveObjects().forEach((obj: fabric.Object): any => {
            this.canvas!.remove(obj);
        });

        this.canvas.renderAll();
    }

    public async save(): Promise<void> {
        this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_ConfirmationOverwriteImage")).then(async (value: boolean) => {
            if (value) {
                this.resetZoom();

                //Gestion du format???
                const fileModel: DocumentModel = {
                    Comment: null,
                    Email: "",
                    FileData: this.canvas.toDataURL({format: this.imageFormat}),
                    FileName: null,
                    OriginalFileData: null,
                    OriginalFileName: null,
                    Category: null,
                    TemplateCode: null
                };

                if (this.imageId) {
                    await this.documentService.Update(this.imageId, fileModel);
                    this.routerHelper.navigateBack();
                } else {
                    this.file.fileContent = this.canvas.toDataURL({format: this.imageFormat});
                    this.toggleEditing!();
                }
            }
        });
    }

    public sizeSliderChanged(newValue: number): void {
        if (!this.canvas) { return; }

        this.canvas.freeDrawingBrush.width = newValue;

        const selectedElements = this.canvas.getActiveObjects();

        selectedElements.forEach((item: any): void => {
            if (item.type === "path") {
                item.set("strokeWidth", newValue);
            }
        });

        this.canvas.renderAll();
    }

    public colorChanged(newColor: string): void {
        if (!this.canvas) { return; }

        this.canvas.freeDrawingBrush.color = newColor;

        this.canvas.getActiveObjects().forEach((item: fabric.Object): void => {
            if (item.type === "path") {
                item.set("stroke", newColor);
            } else {
                item.set("fill", newColor);
            }
        });

        this.canvas.renderAll();
    }

    private resetZoom(): void {
        this.canvas.setZoom(this.zoomIndex = 1);
        this.resizeCanvas();
        this.updateZoomDisplay();
    }

    private async loadImageInCanvasFromDocId(documentId: number): Promise<void> {
        const imageData = await documentHelper.getDownloadUrlById(documentId);
        this.loadImageInCanvas(imageData);
    }

    private async loadImageInCanvas(fileData: string): Promise<void> {
        fabric.Image.fromURL(fileData, (img: fabric.Image): any => {
                this.bgImage = img;
                this.resizeCanvas(img);

                this.scaleAndPositionImage(img, this.canvas.width!, this.canvas.height!);
        });
    }

    private scaleAndPositionImage(img: fabric.Image, maxWidth: number, maxHeight: number): any {
        if (!this.canvas) { return; }

        this.zoomStartScale = this.canvas.getZoom();

        const imgWidth = img.width!; // Current image width
        const imgHeight = img.height!; // Current image height

        let imgTop = 0;
        let imgLeft = 0;

        if (maxWidth > imgWidth) {
            imgLeft = (Math.round(maxWidth / 2) - Math.round(imgWidth / 2));
        }

        if (maxHeight > imgHeight) {
            imgTop = (Math.round(maxHeight / 2) - Math.round(imgHeight / 2));
        }

        this.canvas.setBackgroundImage(img, () => this.canvas!.renderAll(), {
            top: imgTop,
            left: imgLeft
        });
    }

    private pinchZoom(e: any): void {
        this.canvas.selection = false;

        if (e.self.state === "start") {
            this.zoomStartScale = this.canvas.getZoom();
        }
        const delta = this.zoomStartScale * e.self.scale;

        this.canvas.setZoom(delta);

        if (this.canvas.getZoom() <= 0.1) {
            this.canvas.setZoom(0.1);
        }

        this.zoomIndex = this.canvas.getZoom();

        this.updateZoomDisplay();
        this.resizeCanvas();

        this.canvas.selection = this.currentMode !== this.modes.pan;
    }

    private initCanvasEvents(): void {
        this.canvas.on("touch:gesture", (e: any): void => {
            if (e.e.touches && e.e.touches.length === 2) {
                this.pinchZoom(e);
            }
        });

        this.canvas.on("mouse:down", (e: any): any => {
            if (this.canvas.getActiveObjects().length > 0 && e.target !== null && e.target!.isEditing) {
                this.preventAddingText = true;
            } else if (this.currentMode === this.modes.text && e.target == null && !this.preventAddingText) {
                this.addText(e.absolutePointer!.x, e.absolutePointer!.y);
            } else {
                this.preventAddingText = false;
            }
        });
    }

    private extendTouchEventForCanvas(): void {
        // @ts-ignore
        this.defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
        const currentScope = this;

        // @ts-ignore
        fabric.Canvas.prototype._onTouchStart = (e: Event): any => {
            const target = currentScope.canvas.findTarget(e, true);
            // if allowTouchScrolling is enabled, no object was at the
            // the touch position and we're not in drawing mode, then
            // let the event skip the fabricjs canvas and do default
            // behavior
            if (currentScope.canvas.allowTouchScrolling && !target && !currentScope.canvas.isDrawingMode && !currentScope.canvas.selection) {
              // returning here should allow the event to propagate and be handled
              // normally by the browser
              return;
            }

            // otherwise call the default behavior
            this.defaultOnTouchStartHandler.call(currentScope.canvas, e);
          };
    }

    private updateZoomDisplay(): void {
        this.zoomDisplay = `${Math.round(this.zoomIndex * 100)}%`;
    }

    private changeSelectableStatusOfCanvasObj(val: boolean): void {
        if (!this.canvas) { return; }

        this.canvas.forEachObject((obj: fabric.Object): any => {
            obj.selectable = val;
        });

        this.canvas.renderAll();
    }
}
