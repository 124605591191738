import { transient, computedFrom } from "aurelia-framework";

import { default as resx } from "core/resx";
import { default as ko } from "knockout";

function currentCounterValueToString(currentCounterValue, unit) {
    return `${resx.localize('Current:')} ${currentCounterValue ? currentCounterValue : ' --- '} ${unit}`;
}

@transient()
export class CounterEditor {

    @computedFrom("currentCounterValue", "unit")
    get currentCounterValueStr() {
        return currentCounterValueToString(this.currentCounterValue(), this.unit());
    }

    constructor() {
        this.resx = resx;

        this.disabled = ko.observable(false);
        this.description = ko.observable();
        this.currentCounterValue = ko.observable("");
        this.unit = ko.observable();
        this.value = ko.observable();
    }

    activate(widgetSettings) {
        this.disabled = widgetSettings.disabled;
        this.description = widgetSettings.description;
        this.currentCounterValue = widgetSettings.currentCounterValue;
        this.unit = widgetSettings.unit;

        
        this.value = widgetSettings.value;

        // Pervasive return 0 for null for null double value.
        if(this.value() === 0) {
            this.value(null);
        }
    }
}