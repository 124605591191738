define([
    "knockout",
    "underscore",
    "jquery",
    "services/documentService",
    "helpers/documentHelper",
    "select2"
], function (ko, _, jQuery, documentService, documentHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = true;
            self.dispatchProjectCode = ko.observable();
            self.dispatchDate = ko.observable();

            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.readonly = true;
            self.dispatchProjectCode(params.dispatchProjectCode);
            self.dispatchDate(params.dailyEntryDate);
            self.actions = {
                getSections: documentService.getCertificatesSectionsByDispatchProjectDispatchId.bind(self, params.dispatchId, false),
                getSectionDocuments: documentService.getCertificatesDispatchDocumentsByDispatchProjectDispatchIdSectionId.bind(self, params.dispatchId, false),
                getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
                sectionToOpen: params.certificationAssignmentId,
                deleteDocument: documentService.deleteDocument.bind(documentService)
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
