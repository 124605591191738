import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ValidationController } from "aurelia-validation";
import { RouterHelper } from "helpers/router-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemLaborBase } from "pages/services/quotations/item-labor-base";
import { CatalogService } from "services/catalog-service";
import { ServiceCallActivityService } from "services/service-call-activity-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { ServiceCallOccupationCategoryService } from "services/service-call-occupation-category-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { TradeService } from "services/trade-service";
import { WorkCategoryService } from "services/work-category-service";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { SettingRepository } from "repositories/setting-repository";

export interface QuotationItemLaborParameters {
    quotationId: number;
    itemId?: number;
}

@autoinject()
export class QuotationItemLabor extends QuotationItemLaborBase {

    @computedFrom("parentQuotationItem")
    public get otherCatalogDescription(): string {
        if (this.parentQuotationItem  === null) {
            return "";
        }

        return this.parentQuotationItem.CatalogDescription1 + "\r\n" + this.parentQuotationItem.CatalogDescription2 + "\r\n" + this.parentQuotationItem.CatalogDescription3;
    }

    constructor(
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        serviceCallActivityService: ServiceCallActivityService,
        serviceCallOccupationCategoryService: ServiceCallOccupationCategoryService,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        viewModeHelper: ViewModeHelper,
        dispatchTemplateService: DispatchTemplateService,
        tradeService: TradeService,
        workCategoryService: WorkCategoryService,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        settingRepository: SettingRepository,
        private readonly catalogService: CatalogService,
        private readonly routerHelper: RouterHelper
    ) {
        super(i18N, serviceCallQuotationService, serviceCallNonBillingReasonService, serviceCallActivityService, serviceCallOccupationCategoryService, validationHelper, validationController, viewModeHelper, dispatchTemplateService, tradeService, workCategoryService, serviceCallQuotationPriceService, settingRepository);
    }

    public async activate(params: QuotationItemLaborParameters): Promise<void> {
        await this.initQuotationItem(params.quotationId, params.itemId);
        await this.initTimeIncrement();

        this.initValidation();
    }

    public async save(): Promise<void> {
        if (!await this.validate()) {
            return;
        }

        await this.serviceCallQuotationService.saveQuotationItem(this.quotationItem);
        this.routerHelper.navigateBack();
    }

    private async initQuotationItem(quotationId: number, itemId: number | undefined): Promise<void> {
        await this.initQuotation(quotationId);

        if (itemId) {
            this.quotationItem = await this.serviceCallQuotationService.getQuotationItem(itemId);

            if (this.categoryIsRequired) {
                this.quotationItem.OccupationCategoryDescription = `${this.quotationItem.OccupationCategoryId} - ${this.quotationItem.OccupationCategoryDescription}`;
            }

            if (this.quotationItem.TradeId !== 0) {
                this.quotationItem.TradeDescription = `${this.quotationItem.TradeId} - ${this.quotationItem.TradeDescription}`;
            }
        } else {
            this.quotationItem = this.serviceCallQuotationService.createNewQuotationItem(quotationId, ServiceCallQuotationItemType.Labor, this.quotation.ProfitMargin);
        }

        if (this.quotationItem.ParentId > 0) {
            this.parentQuotationItem = await this.serviceCallQuotationService.getQuotationItem(this.quotationItem.ParentId);
        }
    }
}
