import documentService from "services/documentService";
import settingRepository from "repositories/settingRepository";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { autoinject } from "aurelia-dependency-injection";
@autoinject()
export class MaterialOrderDocumentAdd {
    public workOrderId?: string;

    public readonly: boolean = false;
    public actions: any = {};
    public isCategorySelectionAllowed: boolean = false;
    private dispatchTemplateId: string | null = "";

    constructor(
        private readonly dispatchTemplateService: DispatchTemplateService
    ) {}

    public async activate(params: any): Promise<void> {
        this.dispatchTemplateId = settingRepository.getDispatchTemplate();
        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(this.dispatchTemplateId!);

        this.isCategorySelectionAllowed = dispatchTemplate!.AllowCategorySelection;

        this.setupHeaderInfos(params.workOrderId);

        await this.setAction(params.orderId);
    }

    private setupHeaderInfos(dispatchId: string): void {
      this.readonly = false;
      this.workOrderId = dispatchId;
    }

    private async setAction(orderId: string): Promise<void> {
      this.actions = {
          save: documentService.addDocumentByPurchaseOrderId.bind(self, orderId, this.dispatchTemplateId)
        };
    }
}
