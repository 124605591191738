import { WageTypeString, nameof as nameof_WageTypeString } from "api/enums/wage-type-string";
import { autoinject, bindingMode, customElement } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { RadioButtonItem } from "components/buttons/radio-button-new";
import { FormControl } from "components/form-controls/form-control";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { EnumHelper } from "helpers/enum-helper";

@autoinject()
@customElement("rate-type-picker")
export class RateTypePicker extends FormControl {
    public items: RadioButtonItem[] = [];

    @bindable.boolean
    public includeBonus: boolean = false;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public value: any = null;

    constructor(element: Element, private readonly enumFormatValueConverter: EnumFormatValueConverter) {
        super(element);
    }

    private bind(): void {
        this.initItems();
    }

    private initItems(): void {
        this.items = EnumHelper.getStringValues(WageTypeString)
            .filter((x: WageTypeString) => x !== WageTypeString.Equipment && (this.includeBonus || (x !== WageTypeString.Bonus)))
            .map((x: WageTypeString) => this.createRadioButtonItem(x));
    }

    private createRadioButtonItem(rateType: WageTypeString): RadioButtonItem {
        return { id: rateType, text: this.enumFormatValueConverter.toView(rateType, nameof_WageTypeString) } as RadioButtonItem;
    }
}
