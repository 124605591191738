define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "services/interruptionService"
], function (ko, _, resx, dateHelper, notificationHelper, queryStringHelper, routerHelper, interruptionService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;

            self.dispatchProjectCode = ko.observable();
            self.dispatchDate = ko.observable();
            self.readonly = false;

            self.isProjectResponsible = false;
            self.interruptions = ko.observableArray();
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.readonly = queryStringHelper.parseReadonly(queryString);

            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dispatchDate);
        }

        function loadData() {
            return interruptionService.getInterruptionsForDispatchProjectCodeAndDate(self.dispatchProjectCode(), self.dispatchDate()).done(self.interruptions);
        }

        
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
            return loadData();
        };

        ctor.prototype.delete = function (data) {
            notificationHelper.showDeleteConfirmation()
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        interruptionService.delInterruptionsForDispatchProjectCodeAndDate(self.dispatchProjectCode(), self.dispatchDate(), data.LineNumber)
                            .done(loadData)
                            .always(routerHelper.hideLoading);
                    }
                });
        };

        ctor.prototype.gotoPage = function (rel) {
            routerHelper.navigate(routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly:self.readonly }));
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
