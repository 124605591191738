define([
    "moment",
    "helpers/stringHelper",
    "repositories/settingRepository"
], function (moment, stringHelper, settings) {
    "use strict";

    const NavigationParameterFormat = "YYYY-MM-DD";
    const FrenchCode = "fr-CA";
    const EnglishCode = "en-CA";

    var exports = {
        parseDate: function (inputString) {
            return moment(inputString).toDate();
        },

        dateToNavigationParameter: function (date) {
            return moment(date).format(NavigationParameterFormat);
        },

        dateFromNavigationParameter: function (param) {
            return moment(param, NavigationParameterFormat).toDate();
        },

        formatDateLongFormat: function (date) {
            const options = { day: 'numeric', month: 'long', year: 'numeric' };

            var _moment = this.setMomentLocal(date);

            return date.toLocaleDateString(this.getMomentLocal(_moment), options);
        },

        formatDate: function (format, inputDate, checkForNull = false) {
            if (inputDate === null || inputDate === undefined) {

                if (checkForNull) {
                    return;
                }

                if (!format) {
                    return moment().toDate();
                } else {
                    return moment().format(format);
                }
            }
            if (inputDate instanceof Date) {
                inputDate = inputDate.toISOString();
            }

            var _moment = this.setMomentLocal(inputDate);

            if (!format) {
                return _moment.toDate();
            }
            return _moment.format(format);
        },

        formatDateToSend: function (inputDate) {
            return this.formatDate('YYYY-MM-DDTHH:mm:ss', inputDate);
        },

        getNow: function () {
            return moment();
        },

        getDate: function () {
            var d = new Date();
            d.setHours(0, 0, 0, 0);
            return d;
        },

        isToday: function (value) {
            var today = new moment().startOf("day");
            var currentValue = new moment(value).startOf("day");
            return today.isSame(currentValue);
        },
        isTodayOrBefore: function (value) {
            var today = new moment().startOf("day");
            var currentValue = new moment(value).startOf("day");
            return currentValue.isSameOrBefore(today);
        },

        isBeforeToday: function (value) {
            var today = new moment().startOf("day");
            var currentValue = new moment(value).startOf("day");
            return currentValue.isBefore(today);
        },

        addHour: function (dateValue, value) {
            var coeff = 1000 * 60 * 60;
            var isDate = true;
            if (!(dateValue instanceof Date)) {
                isDate = false;
                dateValue = moment(dateValue).toDate();
            }
            dateValue.setTime(dateValue.getTime() + (coeff * value));
            if (isDate) {
                return dateValue;
            } else {
                return dateValue.toISOString();
            }
        },

        addDay: function (dateValue, value) {
            var isDate = true;
            if (!(dateValue instanceof Date)) {
                isDate = false;
                dateValue = moment(dateValue).toDate();
            }

            dateValue.setDate(dateValue.getDate() + value);

            if (isDate) {
                return dateValue;
            } else {
                return dateValue.toISOString();
            }
        },

        roundTime: function (dateValue, roundValue) {
            var coeff = 1000 * 60 * roundValue;
            if (!(dateValue instanceof Date)) {
                dateValue = moment(dateValue).toDate();
            }
            if (coeff <= 0) {
                coeff = 1;
            }
            return new Date(Math.round(dateValue.getTime() / coeff) * coeff);
        },

        dateFromUTC: function (inputDate, format, language) {
            if (inputDate === null || inputDate === undefined) {
                return null;
            }
            if (inputDate instanceof Date) {
                inputDate = inputDate.toISOString();
            }

            var _moment = this.setMomentLocal(inputDate, language);

            if (!format) {
                return _moment.toDate();
            }
            return _moment.format(format);
        },

        dateToUTC: function (inputDate) {

            return this.getUTCDate(inputDate).toISOString();
        },

        getTime: function (value) {
            if (!value) {
                return "...";
            }
            if (value instanceof Date) {
                value = value.toISOString();
            }
            var _moment = this.setMomentLocal(value);
            return _moment.format("HH:mm");
        },

        getVeryShortDateLocal: function (value) {
            if (value instanceof Date) {
                value = value.toISOString();
            }
            var _moment = this.setMomentLocal(value);

            return _moment.format("YYYYMMDD");
        },

        getFullLogSystemDateTimeLocal: function (value) {
            if (value instanceof Date) {
                value = value.toISOString();
            }

            var _moment = this.setMomentLocal(value);

            return _moment.format("YYYYMMDDHHmmss");
        },

        setMomentLocal: function (value, language) {
            var _moment = moment(value);

            if (!language) {

                language = settings.getLanguage().toLowerCase();
                switch(language) {
                    case "en" :
                        language = EnglishCode;
                        break;
                    case "fr" :
                        language = FrenchCode;
                        break;
                }
            }

            _moment.locale(language);
            return _moment;
        },

        getMomentLocal: function(moment) {
            return moment._locale._abbr;
        },

        //Returned format : Saturday, April 2, 2016
        //Format retourné : Samedi, 2 avril, 2016
        getFullTextDate: function (value, language) {
            if (value === null || value === undefined) {
                return null;
            }
            if (value instanceof Date) {
                value = value.toISOString();
            }

            var _moment = this.setMomentLocal(value, language);

            if (this.getMomentLocal(_moment) === FrenchCode) {
                var tmp = _moment.format("dddd, D MMMM YYYY");
                return tmp.charAt(0).toUpperCase() + tmp.substring(1);
            } else {
                return _moment.format("dddd, MMMM D, YYYY");
            }
        },

        getShortTextDate: function (value) {
            if (value === null || value === undefined) {
                return null;
            }
            if (value instanceof Date) {
                value = value.toISOString();
            }

            var _moment = this.setMomentLocal(value);

            if (this.getMomentLocal(_moment) === FrenchCode) {
                return _moment.format("DD MMM YYYY");
            } else {
                var tmp = _moment.format("MMM DD, YYYY");
                return tmp.charAt(0).toUpperCase() + tmp.substring(1);
            }
        },

        //Returned format : Mon., Sept. 10
        //Format retourné : Lun. 10 sept.
        getDayOfWeekTextDate: function (value) {
            if (value instanceof Date) {
                value = value.toISOString();
            }

            var _moment = this.setMomentLocal(value);

            if (this.getMomentLocal(_moment) === FrenchCode) {
                var tmp = _moment.format("ddd D MMM");
                return tmp.charAt(0).toUpperCase() + tmp.substring(1);
            } else {
                return _moment.format("ddd, MMM D");
            }
        },

        getDayOfWeek: function (value) {
            var _moment = this.setMomentLocal(value);

            if (this.getMomentLocal(_moment) === FrenchCode) {
                var tmp = _moment.format("dddd");
                return tmp.charAt(0).toUpperCase() + tmp.substring(1);
            } else {
                return _moment.format("dddd");
            }
        },

        //Returned format April 2, 2016
        //Format retourné : 2 avril, 2016
        getTextDate: function (value, language) {
            if (value instanceof Date) {
                value = value.toISOString();
            }

            var _moment = this.setMomentLocal(value, language);

            if (this.getMomentLocal(_moment) === FrenchCode) {
                return _moment.format("D MMMM, YYYY");
            } else {
                return _moment.format("MMMM D, YYYY");

            }
        },

        getFullTextDateTime: function (value) {
            if (value instanceof Date) {
                value = value.toISOString();
            }

            var _moment = this.setMomentLocal(value);

            if (this.getMomentLocal(_moment) === FrenchCode) {
                var tmp = _moment.format("dddd, D MMMM, YYYY HH:mm");
                return tmp.charAt(0).toUpperCase() + tmp.substring(1);
            } else {
                return _moment.format("dddd, MMMM D, YYYY HH:mm");
            }
        },

        getUTCDate: function (inputDate) {
            if (inputDate === null || inputDate === undefined) {
                return null;
            }

            var _t = new Date(inputDate.toString());
            var _seconds = _t.getSeconds();
            _t.setSeconds(0);
            if (_seconds > 30) {
                _t.setMinutes(_t.getMinutes() + 1);
            }
            return moment.utc(_t);
        },

        hoursTohmm: function (hours) {
            // Après révision de la rédaction, on ne devrait pas afficher le 0 devant les heures/durées.

            if (isNaN(hours)) {
                return "0:00";
            }

            var sign = hours < 0 ? "-" : "";
            var hour = Math.floor(Math.abs(hours));
            var min = Math.round((Math.abs(hours) * 60) % 60);

            if (min === 60) {
                hour += 1;
                min = 0;
            }
            return sign + hour + ":" + (min < 10 ? "0" : "") + min;
        },

        //Input: compatible moment datetime (ie: 2016-05-16T07:45:00.000Z)
        //Output: 3.75
        //Extracts time base on UTC time
        toHourDecimal: function (datetime, returnUtc) {
            if (!moment.isMoment(datetime)) {
                datetime = moment(datetime);
            }

            var hour = returnUtc === true ? moment(datetime).utc().hour() : moment(datetime).hour();
            var minute = moment(datetime).minute();
            return hour + (minute / 60);
        },

        getTimeSpan: function (startTime, endTime) {
            var diff = moment(endTime).diff(moment(startTime));
            var duration = moment.duration(diff);
            return Math.floor(duration.asHours()) + moment.utc(diff).format(":mm");
        },

        getTimeSpanInHour: function (startTime, endTime) {
            var diff = moment(endTime).diff(moment(startTime));
            var duration = moment.duration(diff);
            return duration.asHours();
        },

        addTimeSpan: function (value, timespan) {
            if (timespan.indexOf(":") === -1) {
                timespan = this.hoursTohmm(timespan);
            }
            var timespanValues = timespan.split(":");
            if (value instanceof Date) {
                if (timespanValues.length) {
                    value.setHours(timespanValues[0]);
                    if (timespanValues.length > 1) {
                        value.setMinutes(timespanValues[1]);
                        if (timespanValues.length > 2) {
                            value.setSeconds(timespanValues[2]);
                            if (timespanValues.length > 3) {
                                value.setMilliseconds(timespanValues[3]);
                            }
                        }
                    }
                }
            }
            return value;
        },

        formatTimeSpan: function (timespan) {
            // Après révision de la rédaction, on ne devrait pas afficher le 0 devant les heures/durées.
            var temp = moment.duration(timespan);

            var hours = Math.trunc(temp.asHours());
            var minutes = temp.minutes();
            minutes = minutes < 10 ? "0" + temp.minutes() : temp.minutes();

            return hours + ":" + minutes;
        },

        parseTimeToDecimal: function (input) {
            return parseFloat(input.replace(':', '.'));
        },

        calculateTimeSpan: function (time) {
            var diff = moment().diff(moment(time));
            return moment.duration(diff)._milliseconds;
        },

        timeSpanToSeconds: function (value) {
            var result = 0;
            var timespanDayParts = value.split(".");

            if (timespanDayParts.length > 1) {
                result += timespanDayParts[0] * 60 * 60 * 24;
                value = timespanDayParts[1];
            }

            var timespanValues = value.split(":");
            if (timespanValues.length) {
                result += timespanValues[0] * 60 * 60;
                if (timespanValues.length > 1) {
                    result += timespanValues[1] * 60;
                    if (timespanValues.length > 2) {
                        result += timespanValues[2] * 1;
                    }
                }
            }
            return result;
        },

        isAfter: function (endDate, startDate) {
            return moment(this.formatDate("YYYY-MM-DD", endDate)).isAfter(this.formatDate("YYYY-MM-DD", startDate));
        },

        setHoursMinOnDate: function (date, hours, minuts) {
            var newDate = this.dateFromUTC(date);
            newDate.setHours(hours);
            newDate.setMinutes(minuts);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);

            return newDate;
        },

        getDayOfWeekString: function (day, format) {
            if (day === 8) {
                const monday = moment(1, 'd').format(format);
                const friday = moment(5, 'd').format(format);

                return monday + "-" + friday;
            }

            // Sunday is 7 in our code
            const zeroBasedDay = (day % 7);
            return moment(zeroBasedDay, 'd').format(format);
        },

        getDifference: function (time1, time2, unit) {
            unit = unit || "milliseconds";
            return moment(time1, "HH:mm").diff(moment(time2, "HH:mm"), unit);
        },

        getTimeFromDate: function (date, prefix) {
            const type = Object.prototype.toString.call(new Date());
            if (!date || type !== "[object Date]") {
              return "--:--";
            }

            const date2 = new Date(this.dateFromUTC(date));

            const hours = prefix && date2.getHours() < 10
            ? `0${date2.getHours()}`
            : date2.getHours();

            const minutes = date2.getMinutes() < 10
              ? `0${date2.getMinutes()}`
              : date2.getMinutes();

            return `${hours}:${minutes}`;
          }
    };

    return exports;
});
