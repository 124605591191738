import { autoinject, bindable } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";

@autoinject()
export class MaLinkButton {
    @bindable public btnUrl: string = "";
    @bindable public btnIcon: string = "";
    @bindable public display: string = "";

    @bindable public urlParams: any;
    @bindable public isUrlRelative: boolean = false;

    public genUrl(): string {
        return routerHelper.navigateTo(this.btnUrl);
    }

    public goToUrl(): void {
        let url = this.isUrlRelative ? routerHelper.getRelativeUrl(this.btnUrl) : this.btnUrl;

        if (this.urlParams) {
            url += routerHelper.addQuerystring(this.urlParams);
        }

        routerHelper.navigate(url);
    }

}
