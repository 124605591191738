export class Compare {
    public static Strings(a: string, b: string): number {
        return a.localeCompare(b);
    }

    public static NullableStrings(a: string | null | undefined, b: string | null | undefined): number {
        if (a && b) {
            return Compare.Strings(a, b);
        }

        if (!a && b) { return -1; }
        if (a && !b) { return 1; }

        return 0;
    }

    public static Dates(a: Date, b: Date): number {
        return a.getTime() - b.getTime();
    }

    public static NullableDates(a: Date | null | undefined, b: Date | null | undefined): number {
        if (a && b) {
            return Compare.Dates(a, b);
        }

        if (!a && b) { return -1; }
        if (a && !b) { return 1; }

        return 0;
    }
}
