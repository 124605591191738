define([
    "helpers/dateHelper",
    "services/documentService",
    "repositories/settingRepository",
    "helpers/notificationHelper",
    "core/resx",
    "helpers/urlHelper",
    "helpers/apiHelper"
], function (dateHelper, documentService, settings, notificationHelper, resx, urlHelper, apiHelper) {
    "use strict";

    var exports = {
        getIconClass: function (type) {
            switch (type) {
                case "IMAGE":
                    return "fa fa-file-image-o";
                case "POWERPOINT":
                    return "fa fa-file-powerpoint-o";
                case "WORD":
                case "application/msword":
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                    return "fa fa-file-word-o";
                case "EXCEL":
                case "application/vnd.ms-excel":
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                case "application/vnd.ms-excel.sheet.macroEnabled.12":
                    return "fa fa-file-excel-o";
                case "PDF":
                case "application/pdf":
                    return "fa fa-file-pdf-o";
                case "TEXTE":
                case "text/plain":
                    return "fa fa-file-text-o";
                case "NOTE":
                    return "fa fa-sticky-note-o";
                case "NOFILE":
                    return "fa fa-ban";
                case "FOLDER":
                    return "ma ma-saveToDisk";
                default:
                    return "fa fa-file-o";
            }
        },

        getLocalizationKey: function (type) {
            var key = "documentType_";
            switch (type) {
                case "IMAGE":
                    return key + "Image";
                case "POWERPOINT":
                    return key + "PowerPoint";
                case "WORD":
                    return key + "Word";
                case "EXCEL":
                    return key + "Excel";
                case "PDF":
                    return key + "Pdf";
                case "TEXTE":
                    return key + "Text";
                case "NOTE":
                    return key + "Note";
                case "NOFILE":
                    return key + "NoFile";
                default:
                    return key + "Other";
            }
        },

        formatDocumentSize: function (size) {
            if (size > 1024 * 1024 * 1024) {
                return (Math.round(size / 1024 / 1024 / 1024 * 10) / 10) + ' gb';
            }
            if (size > 1024 * 1024) {
                return (Math.round(size / 1024 / 1024 * 10) / 10) + ' mb';
            }

            if (size > 1024) {
                return Math.round(size / 1024) + ' kb';
            }
            return size + ' b';
        },

        formatDate: function (date) {
            return dateHelper.dateFromUTC(date, "dddd, LL, HH:mm");
        },

        getDownloadUrl: function (item) {
            if (!item.Id) {
                return "javascript:void(0);";
            }
            var documentUrl = this.getDocumentUrlById(item.Id);
            documentUrl += "?access_token=" + settings.getToken();
            return documentUrl;
        },

        getDownloadUrlById: function (id) {
            var documentUrl = this.getDocumentUrlById(id);
            documentUrl += "?access_token=" + settings.getToken();
            return documentUrl;
        },

        openDocument: function (document) {
            if (document.id) {
                var popupWindow = window.open(document.url);
                documentService.checkDocumentExist(document.id)
                    .fail(function() {
                        popupWindow.close();
                        notificationHelper.showErrorDialogYesNo(resx.localize("ApiError.577"),
                                resx.localize("DoYouWantToReloadPage"))
                            .done(function(success) {
                                if (success) {
                                    window.location.reload(true);
                                }
                            });
                    });
            }
        },

        reformDocument: function (item) {
            var document = item;
            if (item.Id) {
                document.url = exports.getDownloadUrl(item);
                document.id = item.Id;
            }
            return document;
        },

        getDocumentUrlById: function (id) {
            return urlHelper.getWebServiceBaseUrl() + "/" + apiHelper.getBaseUrl() + "/documents/" + id;
        },
    };

    return exports;
});
