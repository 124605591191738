import Logger from "core/logger";
/*global HTMLCanvasElement*/
define([
    "jquery",
    "knockout",
    "core/resx",
    "core/val",
    "underscore",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "repositories/settingRepository",
    "blueimp-load-image"
    //"load-image-orientation",
    //"load-image-meta",
    //"load-image-exif",
    //"load-image-exif-map"
], function (jQuery, ko, resx, val, _, routerHelper, notifier, settingRepository, loadImage) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.val = val;
            self.option = '';
            self.entityId = ko.observable();
            self.readonly = false;

            self.currentFile = ko.observable(null);
            self.currentPicture = ko.observable(null);
            self.comment = ko.observable("");

            self.actions = {
                save: function(pictureModel) {}
            };
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (widgetSettings) {
            self.option = widgetSettings.option;
            self.contextPrefix = widgetSettings.contextPrefix || "";
            self.entityId(widgetSettings.entityId);
            self.readonly = widgetSettings.readonly;
            self.actions = widgetSettings.actions;
        };

        ctor.prototype.savePicture = function () {
            if (!self.currentPicture()) {
                notifier.showInfo(resx.localize('NoPicture/DocumentSelected'));
            } else {
                routerHelper.showLoading();
                var pictureModel = {
                    Comment: self.comment() || null,
                    FileData: self.currentPicture(),
                    Type: self.currentFile().type,
                    OriginalFileName: self.currentFile().name
                };
                if (self.option === 'fieldservices') {
                    pictureModel.TemplateCode = settingRepository.getDispatchTemplate();
                }
                Logger.debug(pictureModel);

                self.actions.save(pictureModel)
                    .done(function () {
                        routerHelper.navigateBack();
                    })
                    .always(function () {
                        routerHelper.hideLoading();
                    });
            }
        };

        ctor.prototype.pictureAcquired = function (ignore, ev) {
            var file = ev.target.files[0];

            var options = {
                maxWidth: 1900,
                maxHeight: 1425,
                canvas: true,
                orientation: undefined
            };

            var replaceImage = function (img) {
                if (img.type === "error") {
                    self.currentPicture(null);
                    self.currentFile(null);
                    jQuery(".picture-container").html(resx.localize('err_LoadImageFailed'));
                } else if (img.src || img instanceof HTMLCanvasElement) {
                    self.currentPicture(img.src || img.toDataURL());
                    var imageElement = jQuery("<img>").attr("src", self.currentPicture());
                    jQuery(".picture-container").html(imageElement);
                }
            };

            if (file) {
                loadImage.parseMetaData(file, function (data) {
                    if (data.exif) {
                        options.orientation = data.exif.get('Orientation');
                    }
                    if (loadImage(file, replaceImage, options)) {
                        self.currentFile(file);
                    } else {
                        jQuery(".picture-container").html(resx.localize('err_LoadImageNotSupported'));
                    }
                });
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
