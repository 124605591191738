import { I18N } from "aurelia-i18n";
import { inject, observable, computedFrom, bindable } from "aurelia-framework";
import { ValidationRules, ValidationController, validateTrigger } from "aurelia-validation";

import _ from "underscore";
import subcontractorService from "services/subcontractorService";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import settingsHelper from "helpers/settingHelper";
import moment from "moment";
import queryStringHelper from "helpers/queryStringHelper";
import templateService from "services/templateService";
import { PhoneNumberFormatValueConverter } from "converters/format/phone-number-format";
import { default as val } from "core/val";

@inject(I18N, ValidationController, PhoneNumberFormatValueConverter)
export class SubcontractorEdit {
    i18n;
    validationController;
    dateHelper = dateHelper;

    dispatchDate;
    dispatchProjectCode;
    increment = "";
    readonly = false;
    loadSubcontractors;

    intervener = {};

    @observable isPresent = true;
    @observable isAbsent = false;
    comment = "";
    dispatchId = 0;
    startTime = dateHelper.getNow();
    endTime = dateHelper.getNow();
    @bindable quantity = 0;
    selectedSubcontractor = "";
    isSubcontractor = true;
    typeInt = 0;
    project = "";
    commentMaxLength;
    isIntervenerPopulated = false;

    isFirstSplitterDisplayed = false;
    isSecondSplitterDisplayed = false;

    constructor(i18n, validationController, phoneNumberFormatValueConverter) {
        this.i18n = i18n;

        this.validationController = validationController;
        this.validationController.validateTrigger = validateTrigger.manual;

        this.phoneNumberFormatValueConverter = phoneNumberFormatValueConverter;

        this.commentMaxLength = val.get("projects.dailyEntry.comments", "maxLength");

        this.loadSubcontractors = {
            transport: (params, success, failure) => {
                subcontractorService
                    .getLookup(this.dispatchProjectCode, this.dispatchDate, params.data.filter, params.data.page || 1)
                    .done(success)
                    .fail(failure);
            },
            mapResults: item => {
                this.isIntervenerPopulated = false;
                return { id: item.Id, text: item.Id + " - " + item.Name };
            },
        };
    }

    isPresentChanged(newValue) {
        if (!this.isSubcontractor && newValue) {
            this.isAbsent = false;
        }
    }

    isAbsentChanged(newValue) {
        if (!this.isSubcontractor && newValue) {
            this.isPresent = false;
        }
    }

    quantityChanged(newValue, oldValue) {
        if(!newValue || newValue < 1) {
        // Le zero en string pour ne pas re-trigger le "Changed"
            this.quantity = "0";
            return;
        }
       if(newValue.toString() !== oldValue.toString()) {
           if(newValue > 999) {
                this.quantity = "999";
                return;
           }
           this.quantity = parseInt(newValue).toString();
       }
    }

    @computedFrom("typeInt")
    get intervenerLabel() {
        if (this.isSubcontractor) {
            return this.i18n.tr("Subcontractor");
        }
        return this.i18n.tr("Contact");
    }

    @computedFrom("item")
    get phone() {
        return this.phoneNumberFormatValueConverter.toView(this.intervener.PhoneNumber);
    }

    @computedFrom("item")
    get mobile() {
        return this.phoneNumberFormatValueConverter.toView(this.intervener.Mobile);
    }

    initValidation() {
        ValidationRules.ensure(m => m.quantity)
            .required()
            .when(() => this.isPresent)
            .withMessageKey("err_QuantityRequired")
            .ensure(m => m.quantity)
            .satisfies(qty => +qty > 0)
            .when(() => this.isPresent)
            .withMessageKey("err_MinQuantity")
            .ensure(m => m.selectedSubcontractor)
            .required()
            .when(() => this.isSubcontractor)
            .withMessageKey("err_SubcontractorRequired")
            .on(this);
    }

    clear() {
        this.isSubcontractor = true;
        this.intervener = {};
        this.isPresent = true;
        this.isAbsent = false;
        this.comment = "";
        this.dispatchId = 0;
        this.startTime = dateHelper.getNow();
        this.endTime = dateHelper.getNow();
        this.quantity = 0;
        this.selectedSubcontractor = "";
        this.typeInt = 0; //0 = Subcontractor, 9 = Contacts
    }

    populate(intervener) {
        this.isSubcontractor = intervener.TypeInt === 0;
        this.intervener = intervener;
        this.isPresent = this.intervener.Present === 2;
        this.isAbsent = this.intervener.Present === 1;
        this.comment = intervener.Comments;
        this.dispatchId = intervener.DispatchId;
        this.startTime = dateHelper.getTime(intervener.StartTime);
        this.endTime = dateHelper.getTime(intervener.EndTime);
        this.quantity = intervener.Quantity;
        this.selectedSubcontractor = this.isSubcontractor ? { id: intervener.SubcontractorId, text: intervener.SubcontractorDesc } : intervener.SubcontractorId;
        this.typeInt = intervener.TypeInt;
        this.project = intervener.ProjectNo;
        this.isIntervenerPopulated = this.intervener.Email || this.intervener.PhoneNumber || this.intervener.Mobile;

        this.isFirstSplitterDisplayed = this.intervener && this.intervener.Email !== '' && (this.intervener.PhoneNumber !== '' || this.intervener.Mobile !== '');
        this.isSecondSplitterDisplayed = this.intervener && (this.intervener.PhoneNumber !== '' && (this.intervener.Mobile !== '' || this.intervener.Email !== ''));
    }

    loadData(itemId) {
        var dfdConfigs = templateService.getCurrentDispatchIncrement();
        if (itemId) {
            var dfdSubcontractor;

            if (this.typeInt === 9) {
                dfdSubcontractor = subcontractorService.getContactDetail(this.dispatchProjectCode, this.dispatchDate, itemId);
            } else {
                dfdSubcontractor = subcontractorService.getSubcontractorDetail(this.dispatchProjectCode, this.dispatchDate, itemId);
            }

            return jQuery.when(dfdSubcontractor, dfdConfigs).done((subContractor, increment) => {
                this.increment = increment;
                this.populate(subContractor);
            });
        } else {
            return dfdConfigs.done(increment => {
                this.increment = increment;
                this.clear();
            });
        }
    }

    bindViewModel(dispatchProjectCode, dispatchDate, typeInt, itemId, querystring) {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);

        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
        this.typeInt = +typeInt;

        this.initValidation();
    }

    activate(params) {
        this.clear();
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.type, params.id, params.q);
        return this.loadData(params.id);
    }

    async save() {
        var result = await this.validationController.validate();
        if (!result.valid) {
            const error = _.pluck(_.filter(result.results, res => !res.valid), "message");
            notificationHelper.showValidationError(error);
            return;
        }

        routerHelper.showLoading();

        var data = this.getDataModel();

        if (!settingsHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            routerHelper.hideLoading();
            routerHelper.navigateToRoute("Settings");
        } else {
            var dispatchModel = settingsHelper.getSelectedDispatchModel();

            var serviceDelegate = this.isSubcontractor ? subcontractorService.saveSubcontractor : subcontractorService.saveContact;
            serviceDelegate(this.dispatchProjectCode, this.dispatchDate, dispatchModel, data)
                .done(() => {
                    routerHelper.navigateBack();
                })
                .always(() => {
                    routerHelper.hideLoading();
                });
        }
    }

    getDataModel() {
        const present = this.isSubcontractor ?
            (this.isPresent ? 2 : 1) :
            (this.isPresent ? 2 : (this.isAbsent ? 1 : 0));

        var startTime = this.isSubcontractor && !this.isPresent ? "00:00" : this.startTime;
        var startDateTime = this.getDateTime(this.intervener.StartTime, startTime);
        var endTime = this.isSubcontractor && !this.isPresent ? "00:00" : this.endTime;
        var endDateTime = this.getDateTime(this.intervener.EndTime, endTime);

        if (endDateTime.isSameOrBefore(startDateTime)) {
            endDateTime.add(1, "days");
        } else {
            while (endDateTime.diff(startDateTime, "hours") > 24) {
                endDateTime.subtract(1, "days");
            }
        }

        return {
            Comments: this.comment,
            Counter: this.intervener.Counter,
            Line: this.intervener.Line || -1,
            Present: present,
            Quantity: +this.quantity,
            StartTime: dateHelper.formatDateToSend(startDateTime),
            EndTime: dateHelper.formatDateToSend(endDateTime),
            SubcontractorId: this.isSubcontractor ? this.selectedSubcontractor.id : this.intervener.SubcontractorId,
            TypeInt: this.typeInt,
            ProjectNo: this.project,
        };
    }

    getDateTime(date, time) {
        var timeTokens = time.split(":");
        var dateTime = moment(date)
            .hours(timeTokens[0])
            .minutes(timeTokens[1]);
        return dateTime;
    }
}
