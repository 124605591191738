import { transient, inject } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

import config from "config";
import { default as dateHelper } from "helpers/dateHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as maskHelper } from "helpers/maskHelper";
import { default as moment } from "moment";
import { default as maskedInput } from "jquery-mask-plugin";
import { default as settings } from "repositories/settingRepository";

import { FroalaLazyLoader } from "helpers/froala-lazy-loader";

@transient()
@inject(FroalaLazyLoader)
export class RichText {
    constructor(froalaLazyLoader) {
        this.froalaLazyLoader = froalaLazyLoader;
        this.value = ko.observable();
        this.readonly = false;
        this.resx = resx;
    }

    async activate(widgetSettings) {
        await this.froalaLazyLoader.loadModule();

        this.value = widgetSettings.value;
        this.readonly = widgetSettings.disable;
        this.id = widgetSettings.id;
    }

    compositionComplete() {
        //Init the textarea to be an RTF editor control
        //TODO JL: Pas trop robuste comme selecteur. Changer pour un élément quand on converti à aurelia.
        const $editor = jQuery("textarea");       

        $editor.froalaEditor({
            key: config.froalaEditorKey,
            toolbarButtons: [
                'fullscreen', 'fontFamily', 'fontSize', 'bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL'
            ],
            toolbarButtonsMD: [
                'fullscreen', 'fontFamily', 'fontSize', 'bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL'
            ],
            toolbarButtonsSM: [
                'fullscreen', 'fontFamily', 'fontSize', 'bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL'
            ],
            toolbarButtonsXS: [
                'fullscreen', 'fontFamily', 'fontSize', 'bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL'
            ],
            theme: "gray",
            language: settings.getLanguage().toLowerCase()
        });

        if (this.readonly) {
            $editor.froalaEditor('edit.off');
            jQuery("div.fr-element.fr-view").css("background-color", "#e6e6e6");
        }
    }
}
