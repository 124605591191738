define([
    "knockout",
    "helpers/routerHelper",
    "services/materialRequisitionService",
    "helpers/queryStringHelper",
    "widgets/pages/material_requisition"
], function (ko, routerHelper, materialRequisitionService, queryStringHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.dispatchId = ko.observable();
            self.isProjectResponsible = false;
            self.readonly = false;
            self.fromHistory = false;
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.serviceType = params.serviceType;
            self.dispatchId(params.dispatchId);

            self.fromHistory = queryStringHelper.parse(params.q, "fromHistory");
            if (!self.fromHistory) {
                self.fromHistory = false;
            } else if (self.fromHistory === "true") {
                self.fromHistory = true;
            }
           
            self.actions = {
                getRequisitions: materialRequisitionService.getMaterialRequisitionsForService.bind(self, self.dispatchId(), self.fromHistory)
            };

            var paramsQs = routerHelper.getQuerystring(params.q);
            self.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
            if (paramsQs && paramsQs.readonly) {
                self.readonly = queryStringHelper.parseReadonly(params.q);
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
