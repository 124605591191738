import { TradeService } from "services/trade-service";
import { ServiceCallActivityBillableType } from "api/enums/service-call-activity-billable-type";
import { ServiceCallContractBillingMethod } from "api/enums/service-call-contract-billing-method";
import { ServiceCallLabourBillingCatalogCode } from "api/enums/service-call-labour-billing-catalog-code";
import { ServiceCallOccupationCategoryModel } from "api/models/company/service-call/service-call-occupation-category-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { ServiceCallActivityModel } from "api/models/company/service/service-call-activity-model";
import { PagingInfo } from "api/paging-info";
import { computedFrom, observable} from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemBase } from "pages/services/quotations/item-base";
import { ServiceCallActivityService } from "services/service-call-activity-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { ServiceCallOccupationCategoryService } from "services/service-call-occupation-category-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { default as templateService } from "services/templateService";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { inject, NewInstance } from "aurelia-dependency-injection";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { ProjectLabourBillingCatalogCodeEn } from "api/enums/project-labour-billing-catalog-code-en";
import { TradeModel } from "api/models/company/trade-model";
import { WorkCategoryService } from "services/work-category-service";
import { WorkCategoryModel } from "api/models/company/work-category-model";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";
import { SettingRepository } from "repositories/setting-repository";

@inject(NewInstance.of(ValidationController))
export class QuotationItemLaborBase extends QuotationItemBase {
    @observable()
    public profitMargin: number = 0;

    @observable()
    public sellingPrice: number = 0;

    public parentQuotationItem: ServiceCallQuotationItemModel | null = null;
    public timeIncrement: number = 15;

    public readonly serviceCallQuotationPricingType: typeof ServiceCallQuotationPricingType = ServiceCallQuotationPricingType;
    public calculationInProgress: boolean = false;

    @computedFrom("quotation", "quotation.BillingMethod")
    public get categoryIsRequired(): boolean {

        if (!!this.quotation && this.quotation.IsWorkOrder && this.quotation.ProjectLabourBillingCatalogCodeConfiguration) {
            return this.quotation.ProjectLabourBillingCatalogCodeConfiguration.search(ProjectLabourBillingCatalogCodeEn.Category) !== -1;
        }

        return !!this.quotation && (this.quotation.BillingMethod === ServiceCallContractBillingMethod.Category || (this.quotation.BillingMethod === ServiceCallContractBillingMethod.CustomerPricing && this.quotation.LabourBillingCatalogCodeConfiguration === ServiceCallLabourBillingCatalogCode.ServiceCallCategory)) ;
    }

    @computedFrom("quotation", "quotation.BillingMethod")
    public get activityIsRequired(): boolean {
        return !!this.quotation && (this.quotation.BillingMethod === ServiceCallContractBillingMethod.Activity || (this.quotation.BillingMethod === ServiceCallContractBillingMethod.CustomerPricing && this.quotation.LabourBillingCatalogCodeConfiguration === ServiceCallLabourBillingCatalogCode.ServiceCallActivity));
    }

    @computedFrom("quotation", "quotationItem", "quotationItem.ActivityId")
    public get ActivityDescription(): string | null {
        if (this.quotation === null || this.quotationItem === null) {
            return "";
        }

        return this.quotation.BillingMethod === ServiceCallContractBillingMethod.Activity ? `${this.quotationItem.ActivityId} - ${this.quotationItem.Description}` : `${this.quotationItem.ActivityId} - ${this.quotationItem.ActivityDescription}`;
    }

    @computedFrom("quotation", "quotationItem", "quotationItem.CategoryId")
    public get OccupationCategoryDescription(): string | null {
        if (this.quotation === null || this.quotationItem === null) {
            return "";
        }

        return this.quotation.BillingMethod === ServiceCallContractBillingMethod.Category ? this.quotationItem.Description : this.quotationItem.OccupationCategoryDescription;
    }

    @computedFrom("quotation.IsLumpSum", "quotationItem")
    public get minLumpSumPlus(): number {
        if (this.quotation !== null && this.quotationItem !== null) {
            return this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.LumpSumPlus ? this.minLumpSumPlusSaleAmount : this.minSaleAmount;
        }

        return this.minSaleAmount;
    }

    constructor(
        i18n: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        private readonly serviceCallActivityService: ServiceCallActivityService,
        private readonly serviceCallOccupationCategoryService: ServiceCallOccupationCategoryService,
        private readonly validationHelper: ValidationHelper,
        private readonly validationController: ValidationController,
        private readonly viewModeHelper: ViewModeHelper,
        private readonly dispatchTemplateService: DispatchTemplateService,
        private readonly tradeService: TradeService,
        private readonly workCategoryService: WorkCategoryService,
        private readonly serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        private readonly settingRepository: SettingRepository
    ) {
        super(i18n, serviceCallQuotationService, serviceCallNonBillingReasonService);
    }

    public async onSelectedActivityChanged(event: CustomEvent<ServiceCallActivityModel>): Promise<void> {

        if (this.quotation === null || this.quotationItem === null) {
            return;
        }

        const serviceCallActivity = event ? event.detail : null;

        this.quotationItem.ActivityId = serviceCallActivity ? serviceCallActivity.Id : null;

        if (!!this.quotation && (this.quotation.BillingMethod === ServiceCallContractBillingMethod.Activity || (this.quotation.BillingMethod === ServiceCallContractBillingMethod.CustomerPricing && this.quotation.LabourBillingCatalogCodeConfiguration === ServiceCallLabourBillingCatalogCode.ServiceCallActivity)) ) {
            this.quotationItem.Description = serviceCallActivity ? serviceCallActivity.CustomerDescription : "";
        }

        this.quotationItem.ActivityDescription = serviceCallActivity ? serviceCallActivity.CustomerDescription : "";

        // Billable set to "Never" for the selected activity. By default set this item as not billable.
        if (serviceCallActivity && serviceCallActivity.BillableType === ServiceCallActivityBillableType.Never) {
            this.quotationItem.IsBillable = false;
        } else {
            this.quotationItem.IsBillable = true;
        }

        await this.updatePriceItem();
    }

    public profitMarginChanged(newValue: any, oldValue: any): void {
        const isValueUnchanged = newValue === oldValue;

        if (this.calculationInProgress || !this.quotationItem || isValueUnchanged) {
            return;
        }

        if (newValue === null) {
            this.quotationItem.ProfitMargin = 0;
            newValue = 0;
        } else {
            this.quotationItem.ProfitMargin = newValue;
        }

        if (this.sellingPrice >= 0) {
            this.calculationInProgress = true;
            this.sellingPrice = this.serviceCallQuotationPriceService.UpdateItemUnitPriceWithProfitMargin(newValue, this.quotationItem);
            this.calculationInProgress = false;
        }
    }

    public sellingPriceChanged(newValue: any, oldValue: any): void {
        const isValueUnchanged = newValue === oldValue;

        if (this.calculationInProgress || !this.quotation || !this.quotation.Items || !this.quotationItem || isValueUnchanged) {
            return;
        }

        this.quotationItem.UnitPrice = newValue;

        if (this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.LumpSumPlus) {
            this.calculationInProgress = true;
            this.profitMargin = this.serviceCallQuotationPriceService.UpdateItemProfitMargin(this.quotationItem);
            this.calculationInProgress = false;
        }

    }

    public async onSelectedOccupationCategoryChanged(event: CustomEvent<ServiceCallOccupationCategoryModel>): Promise<void> {

        if (this.quotation === null || this.quotationItem === null) {
            return;
        }

        const serviceCallOccupationCategory = event ? event.detail : null;
        this.quotationItem.OccupationCategoryId = serviceCallOccupationCategory ? serviceCallOccupationCategory.Id : 0;
        if (!!this.quotation && (this.quotation.BillingMethod === ServiceCallContractBillingMethod.Category || (this.quotation.BillingMethod === ServiceCallContractBillingMethod.CustomerPricing && this.quotation.LabourBillingCatalogCodeConfiguration === ServiceCallLabourBillingCatalogCode.ServiceCallCategory))) {
            this.quotationItem.Description = serviceCallOccupationCategory ? serviceCallOccupationCategory.Description : "";
        }

        this.quotationItem.OccupationCategoryDescription = serviceCallOccupationCategory ? serviceCallOccupationCategory.Description : "";

        await this.updatePriceItem();
    }

    public onSelectedWorkCategoryChanged(event: CustomEvent<WorkCategoryModel>): void {

        if (this.quotation === null || this.quotationItem === null) {
            return;
        }

        const workCategory = event ? event.detail : null;
        this.quotationItem.OccupationCategoryDescription = workCategory ? workCategory.Description : "";
    }

    public onSelectedTradeChanged(event: CustomEvent<TradeModel>): void {
        const trade = event ? event.detail : null;
        this.quotationItem.TradeId = trade ? trade.Code : 0;
        this.quotationItem.Description = trade ? trade.Description : "";
    }

    public async onSelectedRateTypeChanged(rateType: string): Promise<void> {
        this.quotationItem.RateType = rateType;
        await this.updatePriceItem();
    }

    public async populateActivities(filter: string, pagingInfo: PagingInfo): Promise<ServiceCallActivityModel[]> {
        return await this.serviceCallActivityService.getActivities(this.viewModeHelper.getIsMobileMode(), this.quotation.Id, filter, pagingInfo);
    }

    public async populateOccupationCategories(filter: string, pagingInfo: PagingInfo): Promise<ServiceCallOccupationCategoryModel[]> {

        let dispatchTemplateCode = null;
        if (this.viewModeHelper.getIsMobileMode()) {
            if (this.quotation.SourceDispatchId !== 0) {
                const dispatchTemplate = await this.dispatchTemplateService.getDispatchTemplatesByDispatchId(this.quotation.SourceDispatchId);
                if (dispatchTemplate !== null) {
                    dispatchTemplateCode = dispatchTemplate.Code;
                }
            } else {
                dispatchTemplateCode = this.settingRepository.getDispatchTemplate();
            }

            if (dispatchTemplateCode === "null" || dispatchTemplateCode === "undefined") {
                dispatchTemplateCode = null;
            }
        }

        return await this.serviceCallOccupationCategoryService.getOccupationCategories(dispatchTemplateCode, filter, pagingInfo);
    }

    public async populateWorkCategories(filter: string, pagingInfo: PagingInfo): Promise<WorkCategoryModel[]> {
        return await this.workCategoryService.getWorkCategories(filter, pagingInfo);
    }

    public async populateTrades(filter: string, pagingInfo: PagingInfo): Promise<TradeModel[] | null> {
        return await this.tradeService.GetTrades(filter, pagingInfo);
    }

    protected async updatePriceItem(): Promise<void> {
        this.sellingPrice = 0;
        this.quotationItem.UnitPrice = 0;
        this.quotationItem = await this.serviceCallQuotationPriceService.GetItemPrices(this.quotation, this.quotationItem);

        if (this.quotation.ProfitMargin === 0) {
            this.sellingPrice = this.quotationItem.UnitPrice;
        } else {
            this.calculationInProgress = true;
            this.quotationItem.ProfitMargin = this.quotation.ProfitMargin;
            this.profitMargin = this.quotation.ProfitMargin;
            this.sellingPrice = this.serviceCallQuotationPriceService.UpdateItemUnitPriceWithProfitMargin(this.quotation.ProfitMargin, this.quotationItem);
            this.calculationInProgress = false;
        }
    }

    protected async initTimeIncrement(): Promise<void> {
        if (!this.quotation) { return; }

        let increment = await templateService.getUserTemplateIncrement();
        if (this.quotation.SourceDispatchId !== 0) {
            const dispatchTemplate = await this.dispatchTemplateService.getDispatchTemplatesByDispatchId(this.quotation.SourceDispatchId);
            increment = dispatchTemplate.TimeIncrement;
        }

        this.timeIncrement = increment > 0 ? increment : this.timeIncrement;
    }

    protected initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure((x: ServiceCallQuotationItemModel) => x.ActivityId).required().withMessageKey("err_ActivityRequired").when((x: ServiceCallQuotationItemModel) => this.activityIsRequired)
            .ensure((x: ServiceCallQuotationItemModel) => x.OccupationCategoryId).satisfies((value: number) => value >= 1).withMessageKey("err_WorkCategoryRequired").when((x: ServiceCallQuotationItemModel) => this.categoryIsRequired)
            .ensure((x: ServiceCallQuotationItemModel) => x.Quantity).required().withMessageKey("err_TimeSpentRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.Quantity).satisfies((value: number) => value > 0).withMessageKey("err_MinQuantity")
            .ensure((x: ServiceCallQuotationItemModel) => x.NonBillableReasonId).required().when((x: ServiceCallQuotationItemModel) => !x.IsBillable).withMessageKey("err_NonBillableReasonRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.TradeId).required().satisfies((value: number) => value > 0).when((x: ServiceCallQuotationItemModel) => this.quotation.IsWorkOrder).withMessageKey("err_TradeRequired")
            .on(this.quotationItem);
    }

    protected async validate(): Promise<boolean> {
        return await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);
    }
}
