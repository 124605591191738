import { MaterialRequisitionItemModel } from "api/models/company/material-requisition/material-requisition-item-model";
import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { MaterialRequisitionSummaryModel } from "api/models/company/material-requisition/material-requisition-summary-model";
import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import notificationHelper from "helpers/notificationHelper";
import routerHelper from "helpers/routerHelper";
import materialRequisitionService from "services/materialRequisitionService";

@autoinject
export class MaterialRequisition {

    public dateHelper: typeof dateHelper = dateHelper;
    public routerHelper: typeof routerHelper = routerHelper;

    @bindable public actions: any = {};
    @bindable public isProjectResponsible: boolean = false;
    @bindable public readonly: boolean = false;

    public requisitions: MaterialRequisitionSummaryModel[] = [];

    constructor(private readonly i18n: I18N) {
    }

    public async bind(): Promise<any> {
        await this.loadData();
    }

    public genUrl(rel: string, requisitionId: string): string {
        return routerHelper.getRelativeUrl(rel + encodeURIComponent(requisitionId)) + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly });
    }

    public gotoPage(): void {
        routerHelper.navigate(routerHelper.getRelativeUrl("edit") + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly }));
    }

    public deleteRequisition(requisition: MaterialRequisitionSummaryModel): void {
        notificationHelper.showDeleteConfirmation().then((success: boolean) => {
            if (success) {
                if (requisition.Status !== "0") {
                    notificationHelper.showError(this.i18n.tr("RequisitionCompletedText"));
                } else {
                    routerHelper.showLoading();
                    materialRequisitionService.delMaterialRequisition(requisition.Id!).then(() => {
                        this.loadData();
                    });
                    routerHelper.hideLoading();
                }
            }
        });
    }

    private async loadData(): Promise<void> {
        this.actions.getRequisitions().then((data: MaterialRequisitionSummaryModel[]) => {
            this.requisitions = data;
        });
    }
}
