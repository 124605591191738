define([
        "knockout",
        "underscore",
        "core/resx",
        "helpers/notificationHelper",
        "helpers/routerHelper",
        "helpers/queryStringHelper",
        "services/projectService",
        "services/productionService"
],
    function (ko, _, resx, notificationHelper, routerHelper, queryStringHelper, projectService, productionService) {
        "use strict";

        var viewModel = (function () {
            var self = null;

            function ctor() {
                self = this;
                //#region Properties
                self.resx = resx;

                self.dispatchProjectCode = ko.observable();
                self.dispatchDate = ko.observable();
                self.line = ko.observable();
                self.productionItemId = ko.observable();
                self.isProjectResponsible = false;
                self.readonly = true;
                self.subscriptions = [];

                self.unitDescription = ko.observable();

                self.validatedForm = ko.validatedObservable({
                    activity: ko.observable(),
                    quantity: ko.observable(),

                    clear: function() {
                        self.validatedForm().activity(null);
                        self.validatedForm().quantity(1);
                    }
                });

                self.validatedForm()
                    .activity.extend({
                        required: {
                            message: resx.localize('err_ActivityRequired')
                        }
                    });

                self.validatedForm()
                    .quantity.extend({
                        required: {
                            message: resx.localize('err_QuantityRequired')
                        },
                        min: {
                            params: 1,
                            message: resx.localize('err_MinQuantity')
                        }
                    });
                //#endregion
            }

            //#region Private Functions
            function bindViewModel(dispatchProjectCode, dispatchDate, id, line, queryString) {
                self.unitDescription(null);
                self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);
                self.readonly = queryStringHelper.parseReadonly(queryString);

                self.dispatchDate(dispatchDate);
                self.dispatchProjectCode(dispatchProjectCode);
                self.line(line);
                self.productionItemId(id);
            }

            function buildProductionDto() {
                var data = {};
                
                if (self.productionItemId()) {
                    data.Id = self.productionItemId();
                    data.Line = self.line();
                }

                data.ActivityCode = self.validatedForm().activity().id;
                data.ActivityDescription = self.validatedForm().activity().text;
                data.Quantity = self.validatedForm().quantity();

                return data;
            }

            function loadData() {
                if (self.productionItemId() && self.line()) {
                    return productionService
                        .getProductionItem(self.dispatchProjectCode(),
                            self.dispatchDate(),
                            self.productionItemId(),
                            self.line())
                        .done(initialiseProductionsItem);
                } else {
                    self.validatedForm().clear();
                }
            }

            function initSubscribes() {
                //set quantity to balance in inventory as requested
                self.subscriptions.push(self.validatedForm().activity.subscribe(function (newValue) {
                    if (newValue) {
                        self.unitDescription(newValue.data.ProductionUnit);
                    } else {
                        self.unitDescription(null);
                    }
                }));
            }

            function dispose() {
                _.each(self.subscriptions,
                    function (x) {
                        x.dispose();
                    });
            }

            function loadActivities(params) {
                return projectService
                    .getActivitiesForDispatchProject(self.dispatchProjectCode(), params.data.filter, params.data.page || 1);
            }

            function initialiseProductionsItem(data) {
                self.readonly = data.IsReadOnly;

                self.validatedForm().activity({ "id": data.ActivityCode, "text": data.ActivityCode + " - " + data.ActivityDescription, "data": { "ProductionUnit": data.Unit } });
                self.validatedForm().quantity(data.Quantity);
            }

            //#endregion

            //#region Public Functions
            ctor.prototype.activate = function (params) {
                initSubscribes();
                bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.line, params.q);
                return loadData(self);
            };

            ctor.prototype.detached = function () {
                dispose();
            };

            ctor.prototype.getActivities = {
                transport: function (params, success, failure) {
                    loadActivities(params)
                        .done(function (data) {
                            return success(data);
                        })
                        .fail(failure);
                }
            };

            ctor.prototype.save = function() {
                if (!self.validatedForm.isValid()) {
                    notificationHelper.showValidationError(self.validatedForm.errors);
                    return;
                }

                routerHelper.showLoading();

                productionService.setProductionItem(self.dispatchProjectCode(),
                        self.dispatchDate(),
                        buildProductionDto())
                    .done(function() {
                        routerHelper.navigateBack();
                    })
                    .always(function() {
                        routerHelper.hideLoading();
                    });
            };
            //#endregion
            return ctor;
        })();

        return viewModel;
    });