import { bindable, transient, computedFrom } from "aurelia-framework";

import { default as resx } from "core/resx";
import { default as ko } from "knockout";

import "widgets/counterEditor"

@transient()
export class EquipmentCounterEditor {
    constructor() {
        this.resx = resx;

        this.disabled = ko.observable(false);
        this.equipment = ko.observable();

        // Counters informations
        this.equipmentId = ko.observable();
        this.equipmentDescription = ko.observable("");
        this.counter1TypeDescription = ko.observable("");
        this.counter1PreviousValue = ko.observable("");
        this.counter1Type = ko.observable("");
        this.hasCounter1 = ko.observable(false);
        this.counter2TypeDescription = ko.observable("");
        this.counter2PreviousValue = ko.observable("");
        this.counter2Type = ko.observable("");
        this.hasCounter2 = ko.observable(false);
        this.counter1Value = ko.observable("");
        this.counter2Value = ko.observable("");
    }

    activate(widgetSettings) {
        this.disabled(widgetSettings.disabled);
        this.equipment(widgetSettings.equipment);
        
        if(this.equipment()) {
            this.equipmentId = this.equipment().EquipmentId;
            this.equipmentDescription = this.equipment().EquipmentDescription;

            this.counter1Value = this.equipment().Counter1Value;
            this.counter1TypeDescription = this.equipment().Counter1TypeDescription;
            this.counter1PreviousValue = this.equipment().Counter1PreviousValue;
            this.counter1Type = this.equipment().Counter1Type;
            this.hasCounter1 = this.equipment().HasCounter1;

            this.counter2Value = this.equipment().Counter2Value;
            this.counter2TypeDescription = this.equipment().Counter2TypeDescription;
            this.counter2PreviousValue = this.equipment().Counter2PreviousValue;
            this.counter2Type = this.equipment().Counter2Type;
            this.hasCounter2 = this.equipment().HasCounter2;
        }
    }
}