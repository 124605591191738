import { inject, transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import GuidHelper from "helpers/guidHelper";
import { default as labelHelper } from "helpers/labelHelper";
import { default as defaultValues } from "services/defaultService";
import { default as ajaxHelper } from "helpers/ajaxHelper";

//TODO JL: Move binding handlers outside the widget. We should have a file only for ko binding handlers.
ko.bindingHandlers.element = {
    init: function (element, valueAccessor) {
        var target = valueAccessor();
        target(element);
    }
};

function updateLabel(self, newvalue) {
    if (!self.renderAsButton) {
        if (newvalue) {
            self.displayBtnText(newvalue.text);
            self.cssPlaceHolder(false);
        } else {
            self.displayBtnText(self.btnText);
            self.cssPlaceHolder(true);
        }
    }
}

function infiniteScroll(self) {
    var container = self.resultContainerElement();

    var scrollHeight = container.scrollHeight;
    var scrollTop = container.scrollTop;
    var offsetHeight = container.offsetHeight;

    var buffer = 225; // 45px * 5 items

    // Fix un bug ou on load des items à l'infinie quand le contrôle est en display none
    if (offsetHeight === 0)return;

    if (scrollHeight - (scrollTop + offsetHeight) < buffer) {
        if (self.state.hasMoreResults() && !self.state.loadingMore()) {
            ajaxHelper.loadMoreItems.call(self, self.state.loadingMore, false).done(() => infiniteScroll(self));
        }
    }
}

function toggleModal(self, show) {
    if (show) {
        self.openSelect();
    }
    else {
        self.closeSelect();
    }
}

function reloadItems(self) {
    if (!self.state.inputTooShort()) {
        ajaxHelper.loadMoreItems.call(self, self.state.searching, true).done(() => infiniteScroll(self));
    }
}

// The delay between the time the user finishes typing before we fire the ajax call to fetch the items
const SEARCH_DELAY = 250;

@transient()
@inject(GuidHelper)
export class MaSelect {
    constructor(guidHelper) {
        this.resx = resx;
        this.GuidHelper = guidHelper;

        this.searchEnabled = ko.observable(true);
        this.subscriptionReferences = [];
        this.items = ko.observableArray([]);
        this.resultContainerElement = ko.observable(null);
        this.searchInput = ko.observable('');
        this.state = {
            page: 1,
            searching: ko.observable(true),
            loadingMore: ko.observable(false),
            hasMoreResults: ko.observable(false),
            inputTooShort: ko.pureComputed(() => {
                if (this.searchInput().length < this.minimumInputLength && this.minimumInputLength !== undefined) {
                    return true;
                } else {
                    return false;
                }
            }),
            noResults: ko.pureComputed(() => {
                if (this.state.searching()) {
                    return false;
                }

                if (this.state.inputTooShort()) {
                    return false;
                }

                if (_.isEmpty(this.items())) {
                    return true;
                }
                return this.multiSelect && !_.any(this.items(), (item) => { return !item.selected(); });
            })
        };

        this.addItem = this.addItem.bind(this);
        this.addItems = this.addItems.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.itemSelected = this.itemSelected.bind(this);
        this.openSelect = this.openSelect.bind(this);
        this.closeSelect = this.closeSelect.bind(this);
        this.toggleCheckBoxFilter = this.toggleCheckBoxFilter.bind(this);
        this.resultsScrolled = this.resultsScrolled.bind(this);
        this.searchChanged = this.searchChanged.bind(this);
        this.equipmentFilterSelected = this.equipmentFilterSelected.bind(this);
        this.initList = [];
    }

    //#region Public Functions
    activate(widgetSettings) {
        this.id = 'maSelect_' + (widgetSettings.id || this.GuidHelper.createGuid());
        this.modalShown = ko.observable(false);
        this.placeholder = widgetSettings.placeholder || resx.localize("Search");
        this.searchShown = ko.observable(widgetSettings.searchShown === undefined ? true : widgetSettings.searchShown);
        this.title = resx.localize('SelectOoo');
        this.btnText = widgetSettings.btnText || resx.localize('SelectOoo');
        this.allowClear = widgetSettings.allowClear || false;
        this.displayIcon = widgetSettings.displayIcon || false;
        this.icon = 'fa fa-' + widgetSettings.icon + ' icon-title';

        if (_.isFunction(widgetSettings.btnText)) {
            this.displayBtnText = widgetSettings.btnText;
        } else {
            this.displayBtnText = ko.observable(widgetSettings.btnText || resx.localize('SelectOoo'));
        }

        this.renderAsButton = widgetSettings.renderAsButton || false;
        this.fill = widgetSettings.fill || false;
        this.cssPlaceHolder = ko.observable(!this.renderAsButton /*&& this.btnText === this.displayBtnText()*/);
        this.disable = (widgetSettings.disable === null || widgetSettings.disable === undefined) ? ko.observable(false) : _.isFunction(widgetSettings.disable) ? widgetSettings.disable : ko.observable(widgetSettings.disable);
        this.pageSize = widgetSettings.pageSize || defaultValues.getPageSize();
        this.prependIds = (widgetSettings.prependIds !== false);
        this.templateResult = widgetSettings.templateResult;
        this.templateSelection = widgetSettings.templateSelection;
        this.minimumInputLength = widgetSettings.minimumInputLength || 0;
        this.minimumResultsForSearch = widgetSettings.minimumResultsForSearch === undefined ? defaultValues.getMinimumResultsForSearch() : widgetSettings.minimumResultsForSearch;
        this.onSelect = widgetSettings.onSelect || undefined;
        this.onClear = widgetSettings.onClear || undefined;
        this.onCancel = widgetSettings.onCancel || undefined;
        this.filters = widgetSettings.filters || [];
        this.filtersName = widgetSettings.filtersName || [];
        this.filtersFormat = widgetSettings.filtersFormat || "";
        this.filterSelected = widgetSettings.filterSelected || "";
        this.updateLabelOnSelection = widgetSettings.updateLabelOnSelection || true;
        this.multiSelect = widgetSettings.multiSelect || false;
        this.clearOnClose = widgetSettings.clearOnClose || false;
        this.checkBoxFilter = ko.observable(!!widgetSettings.checkBoxFilter);
        this.checkBoxFilterOriginal = !!widgetSettings.checkBoxFilter;
        this.showCheckBoxFilter = widgetSettings.showCheckBoxFilter;
        this.checkBoxFilterText = widgetSettings.checkBoxFilterText;
        this.data = widgetSettings.data || undefined;
        this.toggleModal = widgetSettings.toggleModal;
        this.context = widgetSettings.context;
        this.initialisedList = widgetSettings.initialisedList;
        if (widgetSettings.ajax) {
            ajaxHelper.initAjax.call(this, widgetSettings.ajax);
        }
        this.selectedItem = widgetSettings.selectedItem;

        this.initialiseSubscription();
    }

    initialiseSubscription() {
        if (this.selectedItem) {
            this.subscriptionReferences.push(this.selectedItem.subscribe((newvalue) => {
                if (this.updateLabelOnSelection) {
                    updateLabel(this, newvalue);
                }
            }));
        }

        if (this.selectedItem && _.isFunction(this.selectedItem)) {
            if (this.updateLabelOnSelection) {
                updateLabel(this, this.selectedItem());
            }
        }

        if (this.filters.length && this.filterSelected) {
            if (_.isFunction(this.filterSelected)) {
                this.subscriptionReferences.push(this.filterSelected.subscribe(() => {
                    this.searchInput('');
                    reloadItems(this);
                }));
            }
        }

        if (this.toggleModal) {
            this.subscriptionReferences.push(this.toggleModal.subscribe((newValue) => { return toggleModal(this, newValue); }));
        }

        this.subscriptionReferences.push(this.searchInput.subscribe(this.searchChanged));
    }

    compositionComplete() {
        if (this.data) {
            this.searchShown(this.searchShown() && this.data.length >= this.minimumResultsForSearch);
        }

        toggleModal(this, this.toggleModal ? this.toggleModal() : false);
    }

    clear() {
        this.selectedItem(null);

        if (this.onClear) {
            this.onClear();
        }
    }

    addItem(data) {
        // TODO this code doesn't belong here. Try to refactor the widget to extract the filters.
        if (_.isFunction(this.filterSelected)) {
            data.type = this.filterSelected();
        }

        if (this.multiSelect) {
            var selectedItems = this.selectedItem();

            if (!selectedItems) {
                selectedItems = [];
            }

            selectedItems.push(data);
            this.selectedItem(selectedItems);

            // Hide selected items in multiselect
            data.selected(true);

            // Load more items if we're at the end of the list
            infiniteScroll(this);
        } else {
            this.selectedItem(data);
            this.itemSelected();
        }
    }

    addItems(newItems) {
        ko.utils.arrayPushAll(this.items, newItems);
    }

    removeItem(data) {
        var selectedItems = this.selectedItem();
        var index = selectedItems.indexOf(data);

        if (!selectedItems) {
            selectedItems = [];
        }

        if (index !== -1) {
            selectedItems.splice(index, 1);
        }

        if (this.multiSelect) {
            data.selected(false);
        }
        this.selectedItem(selectedItems);
        reloadItems(this);
    }

    itemSelected() {
        document.activeElement.blur();

        if (_.isFunction(this.onSelect)) {
            this.onSelect(this.selectedItem());
        }

        this.closeSelect(false);
    }

    openSelect() {
        document.activeElement.blur();
        // Fix a bug where we can scroll the page behind the modals
        document.body.style.overflow = 'hidden';
        this.searchInput('');
        this.initList = [];

        if (_.isFunction(this.initialisedList)) {
            if (this.initialisedList() && this.initialisedList().length > 0) {
                this.initList = _.union(this.selectedItem(), this.initialisedList());

                this.selectedItem(this.initList);
            }
        }

        reloadItems(this);
        this.modalShown(true);
    }

    closeSelect(isCancelling) {
        document.activeElement.blur();

        // Fix a bug where we can scroll the page behind the modals
        document.body.style.overflow = '';

        this.modalShown(false);

        if (isCancelling && this.onCancel) {
            this.onCancel();
        }

        if (this.clearOnClose) {
            this.selectedItem(null);
        }
    }

    toggleCheckBoxFilter() {
        this.checkBoxFilter(!this.checkBoxFilter());

        reloadItems(this);
    }

    resultsScrolled(data, e) {
        infiniteScroll(this);
    }

    searchChanged() {
        this.items([]);
        if (this._searchTimeout) {
            window.clearTimeout(this._searchTimeout);
        }

        this._searchTimeout = window.setTimeout(reloadItems.bind(this, this), SEARCH_DELAY);
    }

    //TODO: HM: TO refactor, this code will be changed with filters/templates refactor as 'refresh' setting received.
    equipmentFilterSelected() {
        if (this.filtersName[this.filterSelected() - 1] === this.resx.localize("Equipment")) {
            return true;
        } else {
            return false;
        }
    }
    //#endregion
}