import { default as Parse } from "helpers/parse";

define([
    "knockout",
    "core/resx",
    "helpers/dateHelper",
    //"plugins/router",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "services/supplierService"
], function (ko, resx, dateHelper, /*router, */routerHelper, notifier, supplierService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.dateHelper = dateHelper;
            self.resx = resx;
            self.routerHelper = routerHelper;

            self.actions = {
                deleteMaterial: function(lineNo) {},
                getMaterials: function() {},
                getReserved: function() {},
                setReservedMaterials: function() {},
                createPurchaseOrder: function() {}
            };

            self.isReserved = ko.observable(false);
            self.reservedDisable = ko.observable(false);
            self.showAddOrderButton = ko.observable(false);
            self.showMaterialBillButton = false;
            self.ReserveRequiredMaterialForCallReturns = ko.observable(false);
            self.selectedSupplier = ko.observable();

            self.list = ko.observable();
            self.readonly = false;
            self.serviceType = "";
            self.lookupSupplier = {
                transport: function(params, success, failure) {
                    supplierService.getSupplierForCompany(params.data.filter, params.data.page || 1).done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.Code, text: item.Code + ' - ' + item.Name, data: item };
                }
            };
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(widgetOptions) {
            self.actions = widgetOptions.actions;
            self.serviceType = widgetOptions.serviceType;
            self.readonly = widgetOptions.readonly;
            self.showMaterialBillButton = widgetOptions.showMaterialBillButton;

            setReserved();
        }

        function loadData() {
            return self.actions.getMaterials()
                .done(function (data) {
                    self.list(data);
                });
        }

        function setReserved() {
            if (self.actions.getReserved) {
                self.actions.getReserved()
                    .done(function(data) {
                        //we now show button even if some reserved items has been used
                        self.isReserved(data.ReservedItemsCount > 0);
                        self.reservedDisable(data.AllBalanceZero);
                        self.showAddOrderButton(data.PurchaseOrdersEnabled);
                        self.ReserveRequiredMaterialForCallReturns(data.ReserveRequiredMaterialForCallReturns);
                    });
            } else {
                self.isReserved(false);
            }
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (widgetOptions) {
            bindViewModel(widgetOptions);

            return loadData();
        };

        ctor.prototype.addReserved = function () {
            routerHelper.showLoading();
            self.actions.setReservedMaterials().done(function (data) {
                self.list(data);
                setReserved();
            }).always(function () {
                routerHelper.hideLoading();
            });
        };

        ctor.prototype.delete = function (item) {
            notifier.showDeleteConfirmation().done(function (success) {
                if (success) {
                    routerHelper.showLoading();
                    self.actions.deleteMaterial(item.LineNo, item.IsBillingItem, item.Timestamp).done(function (data) {
                        self.list(data);
                        setReserved();
                    })
                        .concurrence(loadData)
                        .always(function () {
                            routerHelper.hideLoading();
                        });
                }
            });
        };

        ctor.prototype.gotoPage = function (material, isBillingItem) {
            if (self.actions.goToPage) {
                self.actions.goToPage(material);
            } else {
                var url = (material && material.LineNo)
                    ? routerHelper.getRelativeUrl("edit", material.IsBillingItem, material.LineNo) :
                    routerHelper.getRelativeUrl("edit", Parse.Boolean(isBillingItem), -1);

                routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: self.readonly }));
            }
        };

        ctor.prototype.gotoPageMaterialNoBill = function(material) {
            self.gotoPage(material, false);
        };

        ctor.prototype.gotoPageMaterialBill = function(material) {
            self.gotoPage(material, true);
        };

        ctor.prototype.createOrder = function (selectedSupplier) {
            routerHelper.showLoading();
            self.actions.createPurchaseOrder(selectedSupplier.id)
                .done(function (orderNumber) {
                    notifier.showSuccess(resx.localize("msg_PurchaseOrderCreationSuccess").replace("[No]", orderNumber));
                })
                .always(routerHelper.hideLoading);
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
