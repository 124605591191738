import { Container } from "aurelia-dependency-injection";
import Logger from "core/logger";
import { ErrorManager } from "error-management/error-manager";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { ClientApplication } from "api/enums/client-application";
import Config from "config";


define([
    "jquery",
    "knockout",
    "underscore",
    "helpers/urlHelper",
    "repositories/settingRepository",
    "helpers/routerHelper"
], function (jQuery, ko, _, urlHelper, settings, routerHelper) {
    "use strict";

    var requestOptions = {
        UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
        RETURN_AJAX: 'RETURN_AJAX'
    };

    function getRequestUri(uri, method, dataType) {
        var addr = uri;

        var urlRegex = /^https?:\/\/|^\/\//i;
        if (!urlRegex.test(uri)) {
            addr = urlHelper.getWebServiceBaseUrl();
            if (uri[0] !== "/") {
                uri = "/" + uri;
            }
            addr += uri;
        }

        if (addr.toLowerCase().indexOf("lang=") === -1) {
            addr += addr.indexOf("?") > -1 ? "&" : "?";
            addr += "lang=" + settings.getLanguage();
        }

        if (method === "GET" && dataType === "json") {
            if (addr.toLowerCase().indexOf("page=") > -1 && addr.toLowerCase().indexOf("pagesize=") === -1) {
                addr += "&pageSize=20";
            }
        }
        return addr;
    }

    function addServerHeaders(options) {
        if (!_.isObject(options)) {
            options = {};
        }

        return _.extendOwn(options, {
            beforeSend: function (xHdr) {
                const viewModeHelper = Container.instance.get(ViewModeHelper);
                xHdr.setRequestHeader("Authorization", "Bearer " + settings.getToken());
                xHdr.setRequestHeader("MaestroVersion", settings.getVersion());
                xHdr.setRequestHeader("ClientApplication", viewModeHelper.getIsMobileMode() ? ClientApplication.Mobile : ClientApplication.Maestro);
            }
        });
    }

    function getSettings(method, uri, data, dataType, options) {
        dataType = dataType || "json";
        uri = getRequestUri(uri, method, dataType);

        var querySettings = {
            method: method,
            url: uri,
            contentType: "application/json",
            cache: false,
            timeout: Config.defaultRequestTimeout
        };

        if (dataType === 'blob') {
            querySettings.xhrFields = {
                responseType: 'blob'
            };            
        }

        if (data !== undefined && data !== null) {
            if (method === "POST" && (!options || options.contentType === undefined || options.contentType === "application/json")) {
                data = ko.toJSON(data);
            }
            querySettings.data = data;
        }

        if (uri.indexOf('/api/') >= 0) {
            options = addServerHeaders(options);
        }
        if (_.isObject(options)) {
            querySettings = _.extendOwn(querySettings, options);
        }

        Logger.debug(querySettings);

        return querySettings;
    }

    function onRequestFail(jqXhr, deferred, context) {
        deferred.reject(jqXhr).fail(function (xhr) {
            routerHelper.hideLoading();
            if (!xhr.handled) {
                const errorManager = Container.instance.get(ErrorManager);
                errorManager.handleHttpRequestError(xhr, context ? context.url : undefined, deferred);
            }
        });
    }

    function onRequestDone(data, textStatus, jqXHR, deferred) {
        deferred.resolve(data);
    }

    function sendRequest(querySettings) {
        var ajaxQuery = jQuery.ajax(querySettings);

        return ajaxQuery.fail(function (jqXHR, textStatus, errorThrown) {
            if (!jqXHR.handled) {
                const errorManager = Container.instance.get(ErrorManager);
                errorManager.handleHttpRequestError(jqXHR, this.url, ajaxQuery);
            }
        });
    }

    /// Wrap an ajax request in a jquery deferred and return the deferred object.
    function sendRequestAndReturnDeferred(querySettings, fireAndForget) {
        var deferred = new jQuery.Deferred();

        deferred.concurrence = function (data) {
            if (arguments.length && _.isFunction(arguments[0])) {
                deferred.funcConcurrence = data;
            }
            return deferred;
        };

        var ajaxQuery = jQuery.ajax(querySettings);
        if (fireAndForget) {
            return deferred.resolve();
        }

        ajaxQuery.done(function (data, textStatus, jqXHR) {
            onRequestDone(data, textStatus, jqXHR, deferred);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            onRequestFail(jqXHR, deferred, this);
        });

        return deferred;
    }

    function request(method, uri, data, dataType, options, requestOption, showLoading) {
        var querySettings = getSettings(method, uri, data, dataType, options);

        if (showLoading === true) {
            routerHelper.showLoading();
        }

        var deferred;

        if (requestOption === requestOptions.UPLOAD_PROGRESS) {
            deferred = sendRequestAndReturnDeferred(_.extend(querySettings, {
                xhr: function () {
                    var xhr = new window.XMLHttpRequest();
                    xhr.upload.addEventListener("progress",
                        function (evt) {
                            if (evt.lengthComputable) {
                                var percentComplete = (parseInt(evt.loaded / evt.total * 100));
                                //declared here to avoid circular reference
                                require(["services/documentQueueService"], function (documentQueueService) {
                                    documentQueueService.updateMessageInProgress(percentComplete);
                                });
                            }
                        },
                        false);
                    return xhr;
                }
            }));
        }
        else if (requestOption === requestOptions.RETURN_AJAX) {
            deferred = sendRequest(querySettings);
        }
        else {
            deferred = sendRequestAndReturnDeferred(querySettings);
        }

        return deferred.always(() => {
            if (showLoading === true) {
                routerHelper.hideLoading();
            }
        });
    }

    function requestAndForget(method, uri, data, dataType, options) {
        var querySettings = getSettings(method, uri, data, dataType, options);
        sendRequestAndReturnDeferred(querySettings, true);
    }

    var exports = {
        getAndForget: function (uri, query) {
            requestAndForget("GET", uri, query, "json");
        },

        postAndForget: function (uri, data, options) {
            requestAndForget("POST", uri, data, "json", options);
        },

        getHtml: function (uri, query, showLoading) {
            return request("GET", uri, query, "html", undefined, undefined, showLoading !== false);
        },
        getHtmlNoLoading: function (uri, query) { return exports.getHtml(uri, query, false); },

        getJson: function (uri, data, returnAjax, showLoading) {
            var dataType = "json", options = null;
            var requestOption = returnAjax ? requestOptions.RETURN_AJAX : false;

            return request("GET", uri, data, dataType, options, requestOption, showLoading !== false);
        },
        getJsonNoLoading: function (uri, data, returnAjax) { return exports.getJson(uri, data, returnAjax, false); },

        getExternalJson: function (uri) {
            return jQuery.ajax({
                method: "GET",
                url: uri,
                dataType: "jsonp"
            });
        },

        post: function (uri, data, options, dataType, returnAjax, showLoading) {
            var requestOption = returnAjax ? requestOptions.RETURN_AJAX : false;
            if (!dataType) {
                dataType = "json";
            }

            return request("POST", uri, data, dataType, options, requestOption, showLoading !== false);
        },
        postNoLoading: function (uri, data, options, dataType, returnAjax) { return exports.post(uri, data, options, dataType, returnAjax, false); },

        remove: function (uri, query, showLoading) {
            return request("DELETE", uri, query, undefined, undefined, undefined, showLoading !== false);
        },
        removeNoLoading: function (uri, query) { return exports.remove(uri, query, false); },

        postDocument: function (uri, data, options) {
            return request("POST", uri, data, "json", options, null, false);
        },

        sendFile: function (uri, data) {
            return request("POST", uri, data, false, {
                cache: false,
                contentType: false,
                processData: false
            });
        }
    };
    return exports;
});
