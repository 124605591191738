import moment from "moment";
import { default as routeRepository } from "repositories/routeRepository";

define([
    "underscore",
    "knockout",
    "core/resx",
    "services/employeeService",
    "services/documentService",
    "repositories/settingRepository",
    "helpers/documentHelper",
    "helpers/phoneHelper",
    "helpers/dateHelper",
    "helpers/routerHelper"
], function (_, ko, resx, employeeService, documentService, settings, documentHelper, phoneHelper, dateHelper, routerHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        ctor.inject = [routeRepository];

        function ctor(routeRepository) {
            self = this;
            //#region Properties
            self.readonly = false;
            self.dispatchProjectCode = ko.observable('');
            self.dispatchDate = ko.observable('');
            self.workOrderId = ko.observable('');
            self.dipatchId = 0;
            self.employeeId = 0;
            self.employee = ko.observable({});
            self.dateHelper = dateHelper;
            self.phoneHelper = phoneHelper;
            self.resx = resx;
            self.oneActiveCertificateHasDocument = ko.observable(false);
            self.oneInactiveCertificateHasDocument = ko.observable(false);
            self.isResponsible = ko.observable(false);
            self.routeRepository = routeRepository;
            //#endregion
        }

        //#region Private Functions
        function clear() {
            self.employee({});
        }

        function getPicture(pictureCode) {
            if (pictureCode === null) {
                return "url('images/avatar.png')";
            } else {
                return "url('data:image/jpg;base64," + pictureCode + "')";
            }
        }

        function mapLoadedData(data) {
            data.avatar = getPicture(data.Picture);
            data.certificationActive = _.filter(data.Certifications, function (certification) { return !certification.Inactive; });
            self.oneActiveCertificateHasDocument = _.filter(data.certificationActive, function (certification) { return certification.DocumentInfo !== null; }).length > 0;

            data.certificationInActive = _.filter(data.Certifications, function (certification) { return certification.Inactive; });
            self.oneInactiveCertificateHasDocument = _.filter(data.certificationInActive, function (certification) { return certification.DocumentInfo !== null; }).length > 0;

            self.employee(data);
        }

        function loadData() {
            return employeeService.getEmployeeFile(self.employeeId).done(mapLoadedData);
        }

        function getDocumentSize(size) {
            return documentHelper.formatDocumentSize(size);
        }

        function getDocumentIcon(type) {
            if (type) {
                return documentHelper.getIconClass(type);
            } else {
                return "";
            }

        }

        function getDocumentType(type) {
            return resx.localize(documentHelper.getLocalizationKey(type));
        }

        function bindViewModel(widgetSettings) {
            self.readonly = widgetSettings.readonly;
            self.dispatchId = widgetSettings.dispatchId;
            self.employeeId = widgetSettings.employeeId;
            self.isResponsible(widgetSettings.employeeId);

            self.dispatchProjectCode(widgetSettings.dispatchProjectCode ? widgetSettings.dispatchProjectCode() : null);
            self.dispatchDate(widgetSettings.dispatchDate ? widgetSettings.dispatchDate(): null);
            self.workOrderId(widgetSettings.workOrderId ? widgetSettings.workOrderId(): null);
        }

        function getDownloadUrl(documentId) {
            if (documentId) {
                var documentUrl = documentHelper.getDocumentUrlById(documentId);
                documentUrl += "?access_token=" + settings.getToken();
                return documentUrl;
            } else {
                return 'javascript:void(0);';
            }
        }

        //#endregion

        //#region Public Functions

        ctor.prototype.activate = function (widgetSettings) {
            bindViewModel(widgetSettings);
            return loadData();
        };

        ctor.prototype.genUrl = function (route, param) {
            var relativeUrl = '';

            if (param !== undefined) {
                relativeUrl = routerHelper.getRelativeUrl(route, param);
            } else {
                relativeUrl = routerHelper.getRelativeUrl(route);
            }

            return relativeUrl + routerHelper.addQuerystring({ isProjectResponsible: self.isResponsible(), readonly: self.readonly });
        };

        ctor.prototype.navigateTo = function (route, param) {
            var url = routerHelper.navigateTo(route, self.dispatchProjectCode(), self.dispatchDate(), param);
            url += routerHelper.addQuerystring({ isProjectResponsible: self.isResponsible(), readonly: self.readonly });
            return url;
        };

        ctor.prototype.navigateToActivities = function () {
            var url = self.navigateTo('Project_Detail_Daily_Entry_TimeEntryEmployee', self.dispatchId);
            routerHelper.navigate(url, { replace: true, trigger: true });
        };

        ctor.prototype.addUnavailability = function() {
            const params = {
                employeeId: self.employeeId,
                startTime: moment().format(),
                endTime: moment().format(),
            }
            routerHelper.navigateToRoute(self.routeRepository.routes.Calendar_AddUnavailability.name, params)
        }

        ctor.prototype.getDocument = function (item, isSkill) {
            var t = item.DocumentsInfo && item.DocumentsInfo.length > 1 ?
                {
                    name: resx.localize("Documents"),
                    size: '',
                    icon: getDocumentIcon('FOLDER'),
                    type: '',
                    url: (routerHelper.getRelativeUrl(item.CertificationAssignmentId + ""))
                    + routerHelper.addQuerystring({ isProjectResponsible: self.isResponsible(), readonly: self.readonly })
                }
                :
                {
                    id: item.DocumentsInfo.length ? item.DocumentsInfo[0].DocumentId : '',
                    name: item.DocumentsInfo.length ? item.DocumentsInfo[0].FileName : '',
                    size: item.DocumentsInfo.length ? getDocumentSize(item.DocumentsInfo[0].FileSize) : '',
                    icon: item.DocumentsInfo.length ? getDocumentIcon(item.DocumentsInfo[0].Type) : isSkill ? 'fa fa-cogs' : 'fa fa-certificate',
                    type: item.DocumentsInfo.length ? getDocumentType(item.DocumentsInfo[0].Type) : '',
                    url: item.DocumentsInfo.length ? getDownloadUrl(item.DocumentsInfo[0].DocumentId) : 'javascript:void(0)'
                };
            return t;
        };

        ctor.prototype.addPicture = function () {
            var params = { employeeId: self.employeeId, dispatchProjectCode: self.dispatchProjectCode(), dispatchDate: self.dispatchDate(), workOrderId: self.workOrderId() };
            routerHelper.navigateToRoute(self.routeRepository.routes.Employee_Profile_Picture.name, params);
        };

        ctor.prototype.checkAndOpenDocument = function (item) {
            var document = self.getDocument(item);
            documentHelper.openDocument(document);
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
