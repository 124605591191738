import { autoinject, observable } from "aurelia-framework";

import { default as resx } from "core/resx";

import { default as enumHelper } from "helpers/enumHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as notificationHelper } from "helpers/notificationHelper";

import { default as purchaseOrderService } from "services/purchaseOrderService";
import userSettingService from "services/userSettingsService";

class PurchaseOrderTabs {
    active = 1;
    fullyReceived = 2;
    closed = 3;
}

@autoinject()
export class MaterialOrder {

    resx = resx;

    dateHelper = dateHelper;
    routerHelper = routerHelper;
    purchaseOrderTabs = new PurchaseOrderTabs();
   
    displayAllPurchaseOrders = false;

    @observable dispatchProjectCode = "";
    @observable dispatchId = "";
    @observable dispatchDate = "";
    @observable projectCode = "";
    @observable showClosedSection = false;
    @observable currentTabShown = new PurchaseOrderTabs().active;
    @observable filterLabels = [];

    orders = ko.observable({});
    closedOrders = ko.observable({});
    fullyReceivedOrders = ko.observable({});

    constructor() {
        this.readonly = false;
        this.isProjectResponsible = false;
        this.actions = {};
    }

    activate(params) {
        this.bindViewModel(params);
        return this.loadData();
    }

    attached() {
        this.formatToggleLabels(this.displayAllPurchaseOrders);
    }

    bindViewModel(params) {
        this.actions = params.actions;
        this.readonly = params.readonly;
        this.dispatchId = params.dispatchId;
        this.projectCode = params.projectCode;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.isProjectResponsible = params.isProjectResponsible || false;
        this.showClosedSection = params.showClosedSection || false;

        if (params.dispatchDate) {
            this.dispatchDate = params.dispatchDate;
        }
    }

    async loadData() {

        if (this.showClosedSection) {
            const usersetting = await userSettingService.getPurchaseOrdersForCurrentUserSetting();
            if (usersetting) {
                this.displayAllPurchaseOrders = usersetting.Value === "true";
            }
        }
       
        var result = await this.actions.getOrders(this.currentTabShown === this.purchaseOrderTabs.closed, this.currentTabShown === this.purchaseOrderTabs.fullyReceived, this.displayAllPurchaseOrders);
        if (this.currentTabShown === this.purchaseOrderTabs.closed) {
            this.closedOrders(result);
        } else if (this.currentTabShown === this.purchaseOrderTabs.fullyReceived) {
            this.fullyReceivedOrders(result);
        } else {
            this.orders(result);
        }
    }

    deletePurchaseOrder(purchaseOrder) {
        notificationHelper.showDeleteConfirmation().done(async (success) => {
            if (success) {
                if (purchaseOrder.Status !== enumHelper.purchaseOrderStatus.RESERVED) {
                    notificationHelper.showError(resx.localize('611'));
                } else {
                    routerHelper.showLoading();
                    await purchaseOrderService.deletePurchaseOrder(purchaseOrder.Id, purchaseOrder.Extension)
                        .done(function () {
                            routerHelper.navigateSelf();
                        })
                        .always(function () {
                            routerHelper.hideLoading();
                        });
                }
            }
        });
    }

    async show(section) {
        this.currentTabShown = section;

        await this.loadData();
    }

    async toggle(section) {
        this.displayAllPurchaseOrders = !this.displayAllPurchaseOrders

        await userSettingService.setPurchaseOrdersForCurrentUserSetting(this.displayAllPurchaseOrders);

        this.formatToggleLabels(this.displayAllPurchaseOrders);

        this.currentTabShown = section;

        await this.loadData();
    }

    genUrl(rel, extension) {
        return routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, dispatchId: this.dispatchId, extension: extension });
    }

    gotoPage() {
        routerHelper.navigate(routerHelper.getRelativeUrl('edit') + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, dispatchId: this.dispatchId }));
    }

   formatToggleLabels(checked) {
       if (!this.filterLabels  || this.filterLabels.length <= 0) {
            return;
        }

        const indexToActivate = checked ? 1 : 0;

        // Disable everything
       this.filterLabels.forEach((label) => {
            label.classList.remove("bold");
            label.classList.add("text-muted");
        });

        // Enable the index received
       this.filterLabels[indexToActivate].classList.add("bold");
       this.filterLabels[indexToActivate].classList.remove("text-muted");
    }
}