// GENERATED FILE - DO NOT MODIFY
import { ServiceCallEquipmentMaintenanceMaterialModel } from "api/models/company/service/service-call-equipment-maintenance-material-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallDispatchEquipmentMaintenanceMaterialProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetRequiredMaterialsForMaintenance(dispatchId: number, equipmentCode: string | null, maintenanceId: string | null, equipmentid: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallEquipmentMaintenanceMaterialModel[] | null> {
        const uri = this.buildUri(
            "/service-call/{dispatchId}/equipment/maintenance/required-materials",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { equipmentCode: (equipmentCode === null || equipmentCode === undefined) ? undefined : equipmentCode, maintenanceId: (maintenanceId === null || maintenanceId === undefined) ? undefined : maintenanceId, equipmentid: (equipmentid === null || equipmentid === undefined) ? undefined : equipmentid.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallEquipmentMaintenanceMaterialModel[] | null = await this.get<ServiceCallEquipmentMaintenanceMaterialModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallEquipmentMaintenanceMaterialModel");
        return data;
    }

    public async GetAllRequiredMaterialsForMaintenance(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallEquipmentMaintenanceMaterialModel[] | null> {
        const uri = this.buildUri(
            "/service-call/{dispatchId}/equipment/maintenance/required-materials/all",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallEquipmentMaintenanceMaterialModel[] | null = await this.get<ServiceCallEquipmentMaintenanceMaterialModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallEquipmentMaintenanceMaterialModel");
        return data;
    }
}
