import { autoinject, bindable } from "aurelia-framework";
import documentService from "services/documentService";
import settingRepository from "repositories/settingRepository";

@autoinject()
export class InventorySiteTransferDocumentsAdd {
    public actions: any;
    @bindable public transferId: number | null = null;
    public dispatchProjectCode: string | null = null;

    public async activate(params: any): Promise<void> {
        this.transferId = params.transferId;
        this.dispatchProjectCode = settingRepository.getDispatchTemplate();
        this.actions = { save: documentService.saveTransferInventorySitePicture.bind(self, params.transferId, this.dispatchProjectCode)};
    }
}
