import { PLATFORM } from "aurelia-pal";

define([
    "knockout",
    "underscore",
    //"plugins/router",
    "core/resx",
    "helpers/queryStringHelper",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "helpers/enumHelper",
    "helpers/attendanceHelper",
    "services/attendanceService"
], function (ko, _, /*router,*/ resx, queryStringHelper, dateHelper, routerHelper, enumHelper, attendanceHelper, attendanceService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.readonly = false;
            self.workOrderId = ko.observable();
            self.employeesAttendances = ko.observable(null);
            self.listTeams = ko.observableArray([]);
            self.equipmentAttendances = ko.observable(null);
            self.btnAddActivityDisabled = ko.observable(true);
            self.selectedEntries = ko.observableArray([]);

            PLATFORM.moduleName('pages/fieldservices/hour__Template.html');
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(workOrderId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);

            self.workOrderId(workOrderId);
            assignSelectedObservablesValue([]);
        }


        function loadData() {
            return attendanceService.listForWorkorderId(self.workOrderId())
                .done(function (data) {
                    mapLoadedData(data);
                });
        }

        function mapLoadedData(loadedData) {
            loadedData = _.map(loadedData, function (item) {
                item.lblName = item.EquipmentCode ? item.EquipmentCode + ' - ' + item.EquipmentDescription : item.EmployeeNumber + ' - ' + item.EmployeeFirstName + ' ' + item.EmployeeLastName;
                item.id = item.EquipmentCode ? "equipment-" + item.EquipmentCode : "emp-" + item.EmployeeNumber;
                item.selected = ko.observable(false);
                return item;
            });

           var groupedAndSortAttendances = attendanceHelper.groupAndSortAttendances(loadedData, true);

            self.employeesAttendances(_.first(attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(groupedAndSortAttendances)));
            self.listTeams(attendanceHelper.getTeamsFromGroupedAndSortedAttendances(groupedAndSortAttendances));
            self.equipmentAttendances(attendanceHelper.getEquipmentFromAttendances(loadedData));
        }

        function assignSelectedObservablesValue(selectedEntries) {
            self.selectedEntries(selectedEntries);
            self.btnAddActivityDisabled(self.readonly || selectedEntries.length === 0);
        }

        function updateSelectedEntries(selectedEntries, entry) {
            //add remove id from array
            var temp = _.find(selectedEntries, function (item) {
                return item.IsEmployee === entry.IsEmployee &&
                        item.IsEquipment === entry.IsEquipment &&
                        item.EmployeeNumber === entry.EmployeeNumber &&
                        item.EquipmentCode === entry.EquipmentCode;
            });

            if (temp) {
                selectedEntries = _.without(selectedEntries, temp);
            } else {
                selectedEntries.push(entry);
            }

            return selectedEntries;
        }

        function selectAll(list) {
            var selectedEntries = [];

            _.each(list, function (team) {
                _.each(team.Attendances, function (item) {
                    if (item.Presence === enumHelper.attendance().ONSITE && !item.IsInactive) {
                        item.selected(true);
                        selectedEntries.push(item);
                    }
                });
            });

            assignSelectedObservablesValue(selectedEntries);
        }


        function mapSelectedEntries() {
            var selectedEmployees = _.map(self.selectedEntries(), function (item) {
                return {
                    id: item.EmployeeNumber !== 0 ? item.EmployeeNumber : item.EquipmentCode,
                    name: item.EmployeeNumber !== 0 ? (item.EmployeeNumber + ' - ' + item.EmployeeFirstName + ' ' + item.EmployeeLastName) : item.EquipmentCode + ' - ' + item.EquipmentDescription,
                    isEmployee: item.EmployeeNumber !== 0,
                    DefaultLaborActivityCode: item.EmployeeNumber !== 0 ? item.DefaultLaborActivityCode : item.DefaultEquipmentActivityCode
                };
            });
            return JSON.stringify(selectedEmployees);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.q);
            return loadData();
        };

        ctor.prototype.goToUrlForDispatch = function (entry) {

            var url = routerHelper.getRelativeUrl(entry.EmployeeNumber ? "/true" : "/false", entry.EmployeeNumber ? entry.EmployeeNumber : entry.EquipmentCode);
            url += routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly });

            routerHelper.navigate(url);
        };

        ctor.prototype.selectEntry = function (entry) {
            var selectedEntries = self.selectedEntries();
            entry.selected(!entry.selected());
            selectedEntries = updateSelectedEntries(selectedEntries, entry);

            assignSelectedObservablesValue(selectedEntries);
        };

        ctor.prototype.selectAll = function () {
            var all = [];

            if (self.employeesAttendances()) {
                all.push(self.employeesAttendances());
            }

            if (self.listTeams()) {
                all.push(...self.listTeams());
            }

            if (self.equipmentAttendances()) {
                all.push(self.equipmentAttendances());
            }

            selectAll(all);
        };

        ctor.prototype.addActivityClick = function (type) {
            var route = routerHelper.getRelativeUrl("edit") + routerHelper.addQuerystring({
                readonly: self.readonly,
                selectedResources: mapSelectedEntries(),
                multi: true,
                isEmployee: type === "employee" || self.selectedEntries().length > 0
            });

            routerHelper.navigate(route);
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
