import GuidHelper from "helpers/guidHelper";
import { inject, transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

@transient()
@inject(GuidHelper)
export class AutoShowAddButton {

    constructor(guidHelper) {     
        this.GuidHelper = guidHelper;

        this.VIEWPORT_BUFFER = 100;
    }

    activate(widgetSettings) {
        this.resx = resx;
        this.id = widgetSettings.id || this.GuidHelper.createGuid();
        this.text = widgetSettings.text;
        this.action = widgetSettings.action || null;
        this.href = widgetSettings.href || "javascript:void(0);";
        this.anchorId = this.GuidHelper.createGuid();

        this.show = ko.observable(false);
    }

    compositionComplete() {
        this.updateDisplay();
        jQuery(window).resize(this.updateDisplay);
    }

    getAnchorId() {
        return this.anchorId;
    }

    getText() {
        return this.text;
    }

    updateDisplay() {
        var element = document.getElementById(this.anchorId);
        if (element) {
            var btnOffsetTop = jQuery(element).offset().top;
            var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

            if (!this.show) {
                this.show = ko.observable(false);
            }

            this.show(btnOffsetTop > viewportHeight + this.VIEWPORT_BUFFER);
            
        }
    }
}