import { BonusProxy } from "api/proxies/bonus-proxy";
import { DispatchProjectDefaultBonusHeaderModel } from "api/models/company/project/dispatch/dispatch-project-default-bonus-header-model";
import { DefaultBonusParametersModel } from "api/models/company/bonus/default-bonus-parameters-model";
import { DispatchProjectBonusProxy } from "api/proxies/dispatch-project-bonus-proxy";
import { autoinject } from "aurelia-framework";
import { PagingInfo } from "api/paging-info";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { BonusModel } from "api/models/company/bonus/bonus-model";
import { BonusConfigModel } from "api/models/company/bonus/bonus-config-model";

@autoinject()
export class BonusService {
    private dispatchProjectBonusProxy: DispatchProjectBonusProxy;

    constructor(dispatchProjectBonusProxy: DispatchProjectBonusProxy, private readonly bonusProxy: BonusProxy) {
        this.dispatchProjectBonusProxy = dispatchProjectBonusProxy;
    }

    public async GetDefaultBonuses(model: DefaultBonusParametersModel): Promise<DispatchProjectDefaultBonusHeaderModel | null> {
        return await this.dispatchProjectBonusProxy.GetDefaultBonuses(model, undefined);
    }

    public async GetBonuses(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<BonusModel[] | null> {
        return this.bonusProxy.GetBonuses(filter, pagingInfo, requestConfig);
    }

    public async GetBonusesForDispatchProject(projectDispatchCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<BonusModel[] | null> {
       return this.bonusProxy.GetBonusesForDispatchProject(projectDispatchCode, filter, pagingInfo, requestConfig);
    }

    public async GetConfig(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<BonusConfigModel[] | null> {
       return this.bonusProxy.GetConfig(filter, pagingInfo, requestConfig);
    }
}
