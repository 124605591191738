import { DocumentProxy } from "api/proxies/document-proxy";
import routerHelper from "helpers/routerHelper";
import documentService from "services/documentService";
import { autoinject } from "aurelia-framework";
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { DispatchTemplateService } from "services/dispatch-template-service";

@autoinject
export class DailyEntryDocumentAdd {
    public readonly: boolean = false;
    public dispatchId: string | null = null;
    public actions: any;
    public isCategorySelectionAllowed: boolean = false;
    public getCategoryProxy?: () => Promise<DocumentCategoryModel[] | null>;

    constructor(
        private readonly documentProxy: DocumentProxy,
        private readonly dispatchTemplateService: DispatchTemplateService
    ) {}

    public async activate(params: any): Promise<void> {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchId = params.dispatchId;
        this.actions = { save: documentService.saveDispatchPicture.bind(self, params.serviceType, params.dispatchId, paramsQueryString.entityId) };
        const dispatchTemplate = await this.dispatchTemplateService.getDispatchTemplatesByDispatchId(+this.dispatchId!);
        this.isCategorySelectionAllowed = dispatchTemplate.AllowCategorySelection;
        this.getCategoryProxy = this.documentProxy.GetCategories.bind(this.documentProxy, dispatchTemplate.Code);
    }
}
