import { Redirect } from "aurelia-router";

define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/queryStringHelper",
    "helpers/settingHelper",
    "helpers/notificationHelper",
    "services/documentService"
    
], function (ko, _, resx, queryStringHelper, settingHelper, notificationHelper, documentService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = false;
            self.workOrderId = ko.observable();

            self.actions = {};
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(workOrderId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.workOrderId(workOrderId);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.canActivate = function () {
            if (!settingHelper.hasDispatchModel()) {
                notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
                return new Redirect("Settings");
            }

            return true;
        }
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.q);
            
            self.actions = {
                save: documentService.saveWorkOrderPicture.bind(self, params.workOrderId, settingHelper.getSelectedDispatchModel())
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
