import { inject, transient } from "aurelia-framework";

import GuidHelper from "helpers/guidHelper";

import { default as ko } from "knockout";
import { default as resx } from "core/resx";
import { default as common } from "services/commonService";
import { default as enumHelper } from "helpers/enumHelper";

import { default as logger } from 'core/logger';
import { Key } from "ts-keycode-enum";

function loadData(self, widgetSettings) {
    self.option = widgetSettings.option;
    self.context = widgetSettings.context;
    self.selectedValue = ko.observable('');

    self.width = widgetSettings.width;
    self.maxLength = widgetSettings.maxLength;
    self.disabled = widgetSettings.disabled;

    self.selectedValue.subscribe(function (text) {
        self.insertText(text);
    });

    if (self.value() === undefined) {
        self.value('');
    }
}

@transient()
@inject(GuidHelper)
export class PredefinedSentences {
    constructor(guidHelper) {
        this.guidHelper = guidHelper;

        this.getTextList = {
            transport: (params, success, failure) => {
                var option = enumHelper.predefinedSentences.options[this.option];
                var context = enumHelper.predefinedSentences.contexts[this.context];
                if (!option || !context) {
                    logger.error("Invalid Predefined Sentence Configuration", { option: option, context: context });
                }
                common.getDefinedText(option, context, this.entityId(), params.data.filter, params.data.page)
                    .done(success)
                    .fail(failure);
            },
            mapResults: function (item) { return { id: 0, text: item.Description }; }
        };
        //#endregion
    }

    //#region Public Functions
    activate(widgetSettings) {
        this.id = this.guidHelper.createGuid();
        this.entityId = ko.observable();
        this.resx = resx;
        this.option = '';
        this.context = '';
        this.value = ko.observable("");
        this.selectedValue = ko.observable();
        this.height = ko.observable(widgetSettings.height);
        this.originalHeight = widgetSettings.height;
        this.width = '100%';
        this.maxLength = 0;
        this.disabled = widgetSettings.disable || false;
        this.valueList = ko.observableArray([]);
        this.placeholder = widgetSettings.placeholder || '';

        this.acceptCrLf = true;
        if (widgetSettings.acceptCrLf === false) {
            this.acceptCrLf = false;
        }

        this.entityId(ko.unwrap(widgetSettings.entityId));

        this.entityId.subscribe(() => {
            loadData(this, widgetSettings);
        });

        this.value = widgetSettings.value;

        if (!this.disabled) {
            loadData(this, widgetSettings);
        }

        this.insertText = this.insertText.bind(this);
    }

    insertText(item) {
        if (item.text) {
            var txtarea = jQuery('#' + this.id)[0];
            var scrollPos = txtarea.scrollTop;
            var strPos = 0;
            var br = ((txtarea.selectionStart || txtarea.selectionStart === '0')
                ? "ff"
                : (document.selection ? "ie" : false));
            if (br === "ie") {
                txtarea.focus();
                var range1 = document.selection.createRange();
                range1.moveStart('character', -txtarea.value.length);
                strPos = range1.item.text.length;
            } else if (br === "ff") {
                strPos = txtarea.selectionStart;
            }

            var front = (this.value() ? (this.value()).substring(0, strPos) : "");
            var back = (this.value() ? (this.value()).substring(strPos, this.value().length) : "");
            if (this.acceptCrLf) {
                this.value(front + item.text + '\n' + back);
            } else {
                this.value(front + item.text + ' ' + back);
            }
           
            strPos = strPos + item.text.length;
            if (br === "ie") {
                txtarea.focus();
                var range2 = document.selection.createRange();
                range2.moveStart('character', -this.value().length);
                range2.moveStart('character', strPos);
                range2.moveEnd('character', 0);
                range2.select();
            } else if (br === "ff") {
                txtarea.selectionStart = strPos + 1;
                txtarea.selectionEnd = strPos + 1;
                txtarea.focus();
            }
            txtarea.scrollTop = scrollPos;

            //window.setTimeout(function () { this.selectedValue(''); }, 10);
        }
    }

    removeNewLine() {
        if (!this.acceptCrLf) {
            this.value(this.value().replace(/\n/g, " "));
        }
    }

    handleKeyDown(event) {
        if (!this.acceptCrLf) {
            if (event.keyCode == Key.Enter) {
                return false;
            }
            this.removeNewLine();
        }
        return true;
    }
}
