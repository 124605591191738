import { Redirect } from "aurelia-router";

define([
    "knockout",
    "underscore",
    "jquery",
    "core/resx",
    //"plugins/router",
    "helpers/viewbag",
    "services/workOrderService",
    "helpers/queryStringHelper",
    "helpers/notificationHelper",
    "helpers/settingHelper",
    "helpers/routerHelper"
], function (ko, _, jQuery, resx, /*router,*/ viewbag, workOrderService, queryStringHelper, notificationHelper, settingHelper, routerHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.readonly = false;
            self.workOrderId = ko.observable();
            self.viewbagValid = ko.observable(false);
            self.txtSignature = '';
            self.email = '';
            self.doPrintForm = viewbag() && viewbag().doPrintForm;
            self.txtViewbagError = resx.localize('err_SignatureInfoForFieldServiceRequired');

            self.actions = {};
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(workOrderId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.workOrderId(workOrderId);

            self.actions = {
                close: function() {
                    var dfd = new jQuery.Deferred();
                    dfd.resolve();
                    return dfd;
                },
                sign: workOrderService.sign.bind(null, self.workOrderId(), settingHelper.getSelectedDispatchModel())
            };

            if (self.doPrintForm === true) {
                self.actions.printForm = workOrderService.printForm.bind(null, self.workOrderId(), self.email);
            } else {
                self.actions.printForm = function() {
                    var dfd = new jQuery.Deferred();
                    dfd.resolve();
                    return dfd;
                };
            }
            
        }

        function validateInput() {
            if (viewbag() !== undefined &&
                viewbag().txtSignature !== undefined &&
                viewbag().email !== undefined &&
                viewbag().doPrintForm !== undefined
                ) {
                self.txtSignature = viewbag().txtSignature;
                self.email = viewbag().email;
                self.doPrintForm = viewbag().doPrintForm;
                return true;
            } else {
                viewbag(null);
                return false;
            }
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.canActivate = function () {
            if (!settingHelper.hasDispatchModel()) {
                notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
                return new Redirect("Settings");
            }

            return true;
        }

        ctor.prototype.activate = function (params) {
          
            if (!validateInput()) {
                routerHelper.navigateBack();
                notificationHelper.showError(resx.localize('err_SignatureInfoForFieldServiceRequired'));
                return false;
            }

            bindViewModel(params.workOrderId, params.q);
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
