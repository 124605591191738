define([
    "knockout",
    "helpers/routerHelper",
    "services/documentService",
    "widgets/pages/document_add"
], function (ko, routerHelper, documentService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = false;
            self.employeeId = ko.observable();

            self.dispatchProjectCode = ko.observable('');
            self.dispatchDate = ko.observable('');

            self.actions = {};
            //#endregion
        }
        //#region Public Methods

        ctor.prototype.activate = function (params) {
            var paramsQueryString = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
            self.employeeId(params.employeeId);

            self.dispatchProjectCode(params.dispatchProjectCode);
            self.dispatchDate(params.dailyEntryDate);

            self.actions = {
                save: documentService.saveEmployeePicture.bind(self, self.employeeId())
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
