define([
    "core/resx",
    "helpers/browserHelper"
], function (resx, browserHelper) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.browserHelper = browserHelper;
            //#endregion
        }

        //#region Private Functions

        //#endregion
        

        //#region Public Functions
        ctor.prototype.activate = function() {
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
