define([
    "knockout",
    "core/resx",
    "services/contactService",
    "helpers/phoneHelper",
    "helpers/routerHelper"
], function (ko, resx, contact, hlpPhone, routerHelper) {
    "use strict";

    var widget = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.hlpPhone = hlpPhone;
            self.resx = resx;

            self.id = ko.observable();
            self.contact = ko.observable();
            //#endregion
        }

        //#region Private Methods
        function bindViewModel(widgetSettings) {
            self.id = widgetSettings.id;
        }

        function loadData() {
            return contact.getContact(self.id()).done(function (data) {
                self.contact(data);
            });
        }
        //#endregion

        //#region Public Methods
        ctor.prototype.activate = function (widgetSettings) {
            bindViewModel(widgetSettings);
            return loadData();
        };
        //#endregion

        return ctor;
    })();

    return widget;
});
