import { singleton } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as enumHelper } from "helpers/enumHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as timesheetHelper } from "helpers/timesheetHelper";

import 'widgets/timesheetHeader';
import "widgets/timesheetEmployeeHeader";
import "widgets/lstNoData";
import "widgets/timesheetItem";

PLATFORM.moduleName('pages/templates/timesheet_ListForTeamLeader.html');
PLATFORM.moduleName('pages/templates/timesheet_ListForEmployee.html');

var self = null;


function loadData() {
    var groupType = self.listGroupShown();
    var period = self.listPeriodShown();

    if (self._cache[period][groupType]) {
        self._currentData(self._cache[period][groupType]);
        return jQuery.Deferred().resolve(true);
    }

    return timesheetHelper.loadList(self, groupType, period);
}

function clearCache() {
    self._cache = {};
    self._cache[timesheetHelper.LIST_SHOW_CURRENT] = {};
    self._cache[timesheetHelper.LIST_SHOW_PAST] = {};

    self._currentData({ list: [] });
}


@singleton()
export class TimesheetIndex {
    _cache = {};
    _currentData = ko.observable({});

    resx = resx;
    timesheetHelper = timesheetHelper;
    type = timesheetHelper.PAGE_TYPE_INDEX;
    employee = ko.observable({});
    isTeamLeader = ko.observable(false);
    linkedEquipments = ko.observableArray([]);
    listGroupShown = ko.observable(timesheetHelper.LIST_GROUP_TYPE_EMPLOYEE);
    listPeriodShown = ko.observable(timesheetHelper.LIST_SHOW_CURRENT);
    teamLeaderIsResponsibleForTimeEntry = ko.observable(true);

    list = ko.pureComputed(function () {
        return self._currentData().list;
    });

    timeSheetId = ko.pureComputed(function () {
        return self._currentData().TimeSheetId;
    });

    payPeriodStartDate = ko.pureComputed(function () {
        return self._currentData().PayPeriodStartDate;
    });

    timeSheetStatus = ko.pureComputed(function () {
        return self._currentData().TimeSheetStatus;
    });

    payPeriodIsReadonly = ko.pureComputed(function () {
        return self._currentData().PayPeriodIsReadonly;
    });

    readonly = ko.pureComputed(function () {
        if (self.isTeamLeader()) {
            return self.payPeriodIsReadonly();
        }

        //Current employee ISN'T A TEAM LEADER

        // Current user is a NOT responsible for time entry.
        // Only the teamleader should create/edit entries.
        if (self.teamLeaderIsResponsibleForTimeEntry()) {
            return true;
        }

        // Regular employee can only edit INPROGRESS timesheets.
        // They can't edit COMPLETED or APPROVED timesheets.
        var timesheetInProgress = self.timeSheetStatus() === null ||
            self.timeSheetStatus() === enumHelper.timesheetStatus.INPROGRESS.id;
        return !timesheetInProgress;
    });

    constructor() {
        self = this;
    }

    activate() {
        clearCache();
        return loadData();
    }

    genUrl(route, timesheetId, timesheetEntryId) {
        var url = '';
        if (timesheetId) {
            url = routerHelper.getRelativeUrl(route, timesheetId, timesheetEntryId);
        } else {
            url = routerHelper.getRelativeUrl(route);
        }

        return url +
            routerHelper.addQuerystring({
                CurrentEmployeeIsTeamLeader: self.isTeamLeader(),
                TeamLeaderIsResponsibleForTimeEntry: self.teamLeaderIsResponsibleForTimeEntry(),
                PayPeriodStartDate: self.payPeriodStartDate(),
                Employee: JSON.stringify(self.employee()),
                LinkedEquipments: JSON.stringify(self.linkedEquipments()),
                ListPeriodShown: self.listPeriodShown()
            });
    }

    showPeriod(period) {
        self.listPeriodShown(period);

        loadData();
    }

    showGroup(group) {
        self.listGroupShown(group);

        loadData();
    }

    toggleOpenSection(data) {
        data.isOpened(!data.isOpened());
    }
}