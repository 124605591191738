import { EventAggregator } from "aurelia-event-aggregator";
import Logger from "core/logger";
import { I18N } from "aurelia-i18n";
import Compressor from "compressorjs";

/*global FileReader HTMLCanvasElement*/

define([
    "jquery",
    "knockout",
    "core/val",
    "underscore",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "helpers/documentHelper",
    "helpers/stringHelper",
    "repositories/settingRepository",
    "blueimp-load-image",
    "helpers/browserHelper",
], function(jQuery, ko, val, _, routerHelper, notifier, documentHelper, stringHelper, settingRepository, loadImage, browserHelper) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor(eventAggregator, i18n) {
            self = this;
            self.eventAggregator = eventAggregator;
            self.browserHelper = browserHelper;
            self.i18n = i18n;

            //#region Properties
            self.val = val;
            self.option = "";
            self.entityId = ko.observable();
            self.readonly = false;

            self.isPhotoOnly = false;
            self.fileType = "*";
            self.btnAddLabel = i18n.tr("SelectPicture/Document");
            self.equipmentId = ko.observable();
            self.jpegFormat = "image/jpeg";

            self.files = ko.observableArray(null);

            self.totalFileSize = ko.observable(0);

            self.labelTotalFileSize = ko.computed(function() {
                return i18n.tr("msg_FileSizeTotalToTransfer").replace("[fileSize]", documentHelper.formatDocumentSize(self.totalFileSize()));
            });

            //self.currentPicture = ko.observable(null);
            self.comment = ko.observable("");

            self.actions = {
                save: function(pictureModel) {},
            };

            self.uploadIndex = 0;
            //#endregion

        }
        ctor.inject = [EventAggregator, I18N];

        //#region Private Functions
        function calculateFileSize(data, format) {
            var head = format ? `data:${format};base64,` : "data:image/png;base64,";
            return Math.round(((data.length - head.length) * 3) / 4);
        }

        function updateTotalSize() {
            self.totalFileSize(
                _.reduce(
                    getFileSizeArrayFromSelectedFile(),
                    function(memo, num) {
                        return memo + num;
                    },
                    0
                )
            );
        }

        function getSelectedFile() {
            return _.reject(self.files(), function(file) {
                return !file.selected();
            });
        }

        function getFileSizeArrayFromSelectedFile() {
            return _.map(getSelectedFile(), function(file) {
                return file.fileSize;
            });
        }

        function handleFileSave(nbFiles, cpt, cptSuccess) {
            if (cpt === nbFiles) {
                if (cptSuccess === nbFiles) {
                    routerHelper.navigateBack();
                }

                routerHelper.hideLoading();
            }
        }

        function createImageThumbnail(img, index, fileSize, fileName, fileType) {
            var itemWrapper = jQuery("<div>").attr("class", "");

            var imageWrapper = jQuery("<div>").attr("class", "img-wrapper fa ");
            imageWrapper.attr("data-bind", "click:function(){toggleImage(" + index + ");}, css:{checked:files()[" + index + "].selected}");

            var imageVerticalWrapper = jQuery("<div>").attr("class", "img-vertical-wrapper ");

            var imageElement;

            if (img) {
                imageElement = jQuery("<img>").attr("src", img.src || img.toDataURL());
                imageElement.attr("class", "picture-upload-thumb");
            } else {
                imageElement = jQuery("<span>").attr("class", "fa " + documentHelper.getIconClass(fileType));
            }

            var fileSizeWrapper = jQuery("<div>").attr("class", "file-size");
            fileSizeWrapper.html(documentHelper.formatDocumentSize(fileSize));

            imageVerticalWrapper.html(imageElement);
            imageWrapper.html(imageVerticalWrapper);
            itemWrapper.html(imageWrapper);
            if (fileName) {
                var fileNameWrapper = jQuery("<div>").attr("class", "file-name");
                fileNameWrapper.html(fileName);
                itemWrapper.append(fileNameWrapper);
            }
            itemWrapper.append(fileSizeWrapper);

            jQuery(".picture-container-multiple").append(itemWrapper);

            ko.applyBindings(self, imageWrapper.get(0));
        }

        function getFileMisc(file) {
            var index = self.files().length;

            file.fileSize = file.size;
            file.selected = ko.observable(true);

            var reader = new FileReader();
            reader.onload = function(e) {
                file.fileContent = reader.result;
                self.files.push(file);

                createImageThumbnail(null, index, file.size, file.name, file.type);
                updateTotalSize();
                routerHelper.hideLoading();
            };

            reader.readAsDataURL(file);
        }

        function getImage(file) {
            routerHelper.showLoading();

            var options = {
                maxWidth: 1900,
                maxHeight: 1425,
                canvas: true,
                orientation: undefined,
            };

            var replaceImage = function(img) {
                if (img.type === "error") {
                    notifier.showError(self.i18n.tr("err_LoadImageFailed"));
                    routerHelper.hideLoading();
                } else if (img.src || img instanceof HTMLCanvasElement) {
                    var index = self.uploadIndex;
                    self.uploadIndex ++;
                    var fileSize = calculateFileSize(img.toDataURL(self.jpegFormat), self.jpegFormat);

                    createImageThumbnail(img, index, fileSize, "");

                    self.files()[index].fileSize = fileSize;
                    self.files()[index].fileContent = img.toDataURL(self.jpegFormat);
                    updateTotalSize();
                    routerHelper.hideLoading();
                }
            };

            loadImage.parseMetaData(file, function(data) {
                if (data.exif) {
                    options.orientation = data.exif.get("Orientation");
                }
                if (loadImage(file, replaceImage, options)) {
                    file.selected = ko.observable(true);
                    self.files.push(file);
                } else {
                    jQuery(".picture-container-multiple").html(self.i18n.tr("err_LoadImageNotSupported"));
                }
            });
        }

        async function compressImage(file) {
            var compressedFile;

            compressedFile = await new Promise((resolve) => {
                var options = {
                    strict: false,
                    checkOrientation: false,
                    maxWidth: undefined,
                    maxHeight: undefined,
                    minWidth: 0,
                    minHeight: 0,
                    width: undefined,
                    height: undefined,
                    resize: "none",
                    quality: 0.6,
                    mimeType: "",
                    convertTypes: "image/png",
                    convertSize: 500000,
                    success: resolve,
                };
                const compress = new Compressor(file, options);

            }).then((result) => {
                return result;
            });

            return compressedFile;
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function(widgetSettings) {
            self.option = widgetSettings.option;
            self.contextPrefix = widgetSettings.contextPrefix || "";
            self.entityId(widgetSettings.entityId);
            self.readonly = widgetSettings.readonly;
            self.actions = widgetSettings.actions;
            self.equipmentId(widgetSettings.equipmentId);
            self.files([]);
            updateTotalSize();

            if (widgetSettings.isPhotoOnly) {
                self.fileType = "image/*";
                self.btnAddLabel = self.i18n.tr("TakePicture");
                self.eventAggregator.publish("updateTitle", self.i18n.tr("Pictures"));
            } else {
                self.eventAggregator.publish("updateTitle", self.i18n.tr("Pictures/Documents"));
            }
        };

        ctor.prototype.toggleImage = function(index) {
            self.files()[index].selected(!self.files()[index].selected());
            updateTotalSize();
        };

        ctor.prototype.savePicture = function() {
            if (!getSelectedFile().length) {
                notifier.showWarning(self.i18n.tr("NoPicture/DocumentSelected"));
            } else {
                routerHelper.showLoading();
                var nbFiles = getSelectedFile().length;
                var cpt = 0;
                var cptSuccess = 0;
                _.each(getSelectedFile(), function(file) {
                    var fileModel = {
                        Comment: self.comment() || null,
                        FileData: file.fileContent,
                        Type: file.type,
                        OriginalFileName: file.name,
                    };
                    if (self.option === "fieldservices") {
                        fileModel.TemplateCode = settingRepository.getDispatchTemplate();
                    }
                    Logger.debug(fileModel);

                    self.actions
                        .save(fileModel)
                        .done(function() {
                            cptSuccess++;
                        })
                        .always(function() {
                            cpt++;
                            handleFileSave(nbFiles, cpt, cptSuccess);
                        });
                });
            }
        };

        ctor.prototype.fileAcquired = function(ignore, ev) {
            var files = ev.target.files;

            _.each(files, async function(file) {
                if (file) {
                    if (stringHelper.startsWith(file.type, "image/")) {
                        const result = await compressImage(file);
                        getImage(result);
                    } else {
                        getFileMisc(file);
                    }
                }
            });
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
