import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";
import { InventoryProjectTransferModel } from "api/models/company/inventory-project-transfer-model";
import { InventoryProjectTransferProxy } from "api/proxies/inventory-project-transfer-proxy";

import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { InventoryProjectTransferWorkOrderLookupModel } from "api/models/company/workorder/inventory-project-transfer-work-order-lookup-model";
import { ProjectActivityProxy } from "api/proxies/project-activity-proxy";
import { ProjectActivityModel } from "api/models/company/project/project-activity-model";
import { GroupProxy } from "api/proxies/group-proxy";
import { SiteProxy } from "api/proxies/site-proxy";
import { InventoryProjectTransferItemResponseModel } from "api/models/company/inventory-project-transfer-item-response-model";
import { InventoryProjectTransferItemModel } from "api/models/company/inventory-project-transfer-item-model";
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { WorkOrderActivityType } from "api/enums/work-order-activity-type";

@autoinject()
export class InventoryProjectTransferService {

    constructor(private readonly inventoryProjectTransferProxy: InventoryProjectTransferProxy,
                private readonly projectActivityProxy: ProjectActivityProxy,
                private readonly groupProxy: GroupProxy,
                private readonly siteProxy: SiteProxy) {}

    public async GetTransfers(onlyClose: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferModel[] | null> {
        return this.inventoryProjectTransferProxy.GetTransfers(onlyClose, filter, pagingInfo, requestConfig);
    }

    public async GetTransfer(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferDetailsModel | null> {
        return this.inventoryProjectTransferProxy.GetTransfer(transferId, requestConfig);
    }

    public async CompleteTransfer(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return this.inventoryProjectTransferProxy.Complete(transferId, requestConfig);
    }

    public async DeleteTransfer(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<any> {
        return this.inventoryProjectTransferProxy.DeleteTransfer(transferId, requestConfig);
    }

    public async NewTransfer(requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferDetailsModel | null> {
        return this.inventoryProjectTransferProxy.NewTransfer(requestConfig);
    }

    public async SaveTransfer(tranfer: InventoryProjectTransferDetailsModel, requestConfig?: HttpClientRequestConfig): Promise<number | null> {
        return this.inventoryProjectTransferProxy.SaveTransfer(tranfer, requestConfig);
    }

    public async SaveTransferItems(items: InventoryProjectTransferItemModel[], requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.inventoryProjectTransferProxy.SaveTransferItems(items, requestConfig);
    }

    public async GetProjects(prefix: string, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectBaseModel[] | null> {
        return this.inventoryProjectTransferProxy.GetProjects(prefix ? prefix : null, filter, pagingInfo, requestConfig);
    }

    public async GetWorkOrders(prefix: string, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferWorkOrderLookupModel[] | null> {
        return this.inventoryProjectTransferProxy.GetWorkOrders(prefix ? prefix : null, filter, pagingInfo, requestConfig);
    }

    public async IsWorkOrdersInstalled(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.inventoryProjectTransferProxy.IsWorkOrdersInstalled(requestConfig);
    }

    public async GetActivities(projectCode: string | null, filter?: string | null, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<any[]> {
        const activities = await this.projectActivityProxy.GetProjectActivities(projectCode, [WorkOrderActivityType.Material], filter ? filter : "", pagingInfo, requestConfig);

        if (!activities) {
            return [];
        }

        return activities.filter((act: ProjectActivityModel) => act.AvailableInMobileForMaterial).map((item: any): any => {
            return {
                id: item.Id,
                text: `${item.Id} - ${item.Description}`,
                data: item
            };
        });
    }

    public async GetExpenseGroups(projectId: string | null): Promise<any[]> {
        const groups = await this.groupProxy.GetProjectGroups(projectId);

        if (!groups) {
            return [];
        }

        return groups.map((item: any): any => {
            return {
                id: item.Id,
                text: `${item.Id} - ${item.Description}`,
                data: item
            };
        });
    }

    public async GetSites(prefix: string | null, filter?: string | null, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<any[]> {
        const sites = await this.siteProxy.GetSites(prefix ? prefix : null, filter ? filter : "", pagingInfo, requestConfig);

        if (!sites) {
            return [];
        }

        return sites.map((item: any): any => {
            return {
                id: item.Code,
                text: `${item.Code} - ${item.Description}`,
                data: item
            };
        });
    }

    public async DeleteTransferItem(transactionNumber: number): Promise<void> {
        await this.inventoryProjectTransferProxy.DeleteTransferItem(transactionNumber);
    }

    public async UpdateTransferItems(inventoryProjectTransferDetails: InventoryProjectTransferDetailsModel | null): Promise<InventoryProjectTransferDetailsModel | null> {
        return await this.inventoryProjectTransferProxy.UpdateTransferItems(inventoryProjectTransferDetails);
    }

    public async NewTransferItem(transferId: number, productCode: string, siteId: string, activityId: string, groupId: string, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferItemResponseModel | null> {
        return this.inventoryProjectTransferProxy.NewTransferItem(transferId, productCode, siteId, activityId, groupId, requestConfig);
    }

    public async GetTransferItem(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferItemResponseModel | null> {
        return this.inventoryProjectTransferProxy.GetTransferItem(transactionNumber, requestConfig);
    }

    public async UpdateFromCatalogInventoryItem(transferItem: InventoryProjectTransferItemModel, selectedUnit: CatalogInventoryModel, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferItemModel | null> {
        return this.inventoryProjectTransferProxy.UpdateFromCatalogInventoryItem({Item: transferItem, CatalogInventoryItem: selectedUnit}, requestConfig);
    }

    public async GetTransferHeader(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferDetailsModel | null> {
        return this.inventoryProjectTransferProxy.GetTransferHeader(transferId, requestConfig);

    }

    public async AddReservations(transferId: number, activityId: string, groupId: string, reservations: any): Promise<void> {
        await this.inventoryProjectTransferProxy.AddReservations(transferId, activityId, groupId, reservations);
    }
}
