import { inject } from "aurelia-framework";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { default as AdditionalFieldsService } from "services/additionalFieldsService";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";

@inject(AdditionalFieldsService, EventAggregator, I18N)
export class AdditonnalFieldsServiceCall {
    actions = {};
    dispatchId = 0;
    readonly = true;
    additionalFieldsSource = AdditionalFieldsSourceType.ServiceCall;
    saveAdditionalFieldsButton = false;
    additionalFieldsService;

    constructor(additionalFieldsService, eventAggregator, i18N) {
        this.additionalFieldsService = additionalFieldsService;
        this.eventAggregator = eventAggregator;
        this.i18n = i18N;
    }

    //#region Private Functions
    bindViewModel(dispatchId) {
        this.dispatchId = dispatchId;
        this.eventAggregator.publish("updateTitle", `${this.i18n.tr("pageTitle_Service_Detail_AdditionalFields")} (${this.i18n.tr("Call")})`);
    }

    bindWidget() {
        this.actions = {
            getAll: this.additionalFieldsService.getAllForServiceCall.bind(this, this.dispatchId)
        };
    }
    //#endregion

   //#region Public Functions
   activate = function (params) {
        this.bindViewModel(params.dispatchId);
        this.bindWidget();
    }
       
}
