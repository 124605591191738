import { transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as dateHelper } from "helpers/dateHelper";

function loadWidgetSettings(self, widgetSettings) {
    self.date = widgetSettings.date;
}

@transient()
export class TimesheetHeader {
    constructor() {
        this.resx = resx;

        this.date = ko.observable('');
        this.formattedDate = ko.pureComputed(() => {
            var date = this.date();
            if (date) {
                return dateHelper.getTextDate(date);
            }
            return '';
        });
    }

    activate(widgetSettings) {
        loadWidgetSettings(this, widgetSettings);
    }
}