define([
    "knockout",
    "jquery",
    "core/resx",
    "services/contactService",
    "helpers/routerHelper"
], function (ko, jQuery, resx, contactService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.contacts = ko.observableArray();
            self.dispatchId = ko.observable();
            //#endregion
        }

        //#region Private Functions
        function loadData() {
            self.actions = {
                getContacts: contactService.getClientContactsLookup.bind(null, self.dispatchId())
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.dispatchId(params.dispatchId);
            // TODO: KG - loadData return undefined ici
            return loadData();
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
