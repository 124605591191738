define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "services/requisitionService"
], function (ko, _, resx, dateHelper, notificationHelper, queryStringHelper, routerHelper, requisitionService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.dispatchProjectCode = ko.observable();
            self.dispatchDate = ko.observable();

            self.isProjectResponsible = false;
            self.requisitions = ko.observableArray();
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dispatchDate);
        }

        function loadData() {
            return requisitionService.getRequisitionsForDispatch(self.dispatchProjectCode()).done(loadRequisitions);
        }

        function loadRequisitions(data) {
            var tempReq = [];

            _.each(data,
                function (item) {
                    tempReq.push({
                        id: item.Id,
                        getTitle: function () {
                            return item.ResourceCode + " - " + item.ResourceName + " (" + item.Quantity + ")";
                        },

                        getComment: function () {
                            return item.Comment;
                        },
                        getDateRequired: function () {
                            return resx.localize("RequiredOn") + " " + dateHelper.getTextDate(item.RequestDate);
                        }
                    });
                });

            self.requisitions(tempReq);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
            return loadData();
        };

        ctor.prototype.deleteRequisition = function (requisition) {
            notificationHelper.showDeleteConfirmation()
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        requisitionService.deleteRequisition(self.dispatchProjectCode(), requisition.id)
                            .done(function () {
                                loadData();
                            })
                            .always(function () {
                                routerHelper.hideLoading();
                            });
                    }
                });
        };

        ctor.prototype.gotoPage = function (rel) {
            routerHelper.navigate(routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible }));
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
