define([
        "services/requests",
        "helpers/apiHelper"
],
    function (requests, apiHelper) {
        "use strict";

        var exports = {
            getTimesheetsGroupedByDate: function (previousPayPeriod) {
                var url = apiHelper.getTimesheetBaseUrl() + "/groupedbydate";

                if (previousPayPeriod) {
                    url += "?previousPayPeriod=true";
                }

                return requests.getJson(url);
            },

            getTimesheetsGroupedByEmployee: function (previousPayPeriod) {
                var url = apiHelper.getTimesheetBaseUrl() + "/groupedbyemployee";

                if (previousPayPeriod) {
                    url += "?previousPayPeriod=true";
                }

                return requests.getJson(url);
            },

            getTimesheetsGroupedByType: function (previousPayPeriod) {
                var url = apiHelper.getTimesheetBaseUrl() + "/groupedbytype";

                if (previousPayPeriod) {
                    url += "?previousPayPeriod=true";
                }

                return requests.getJson(url);
            },
            
            getTimesheetEntryToLoad: function(timesheetId, timesheetEntryId) {
                return requests.getJson(apiHelper.getTimesheetBaseUrl() +"/" + timesheetId + "/entries/" + timesheetEntryId);
            },

            getProjectForTimesheet: function (prefix, filter, page) {
                var url = apiHelper.getTimesheetBaseUrl() + "/projects";
                if (prefix) {
                    url += "?prefix=" + prefix;
                }
                url = apiHelper.appendFilterPagingParams(url, filter, page);
                return requests.getJson(url);
            },

            getGroupsforTimesheet: function (filter, page) {
                var url = apiHelper.getTimesheetBaseUrl() + "/groups";
                url = apiHelper.appendFilterPagingParams(url, filter, page);
                return requests.getJson(url);
            },

            getEquipmentForTimesheet: function (employeeId, filter, page) {
                var url = apiHelper.getTimesheetBaseUrl() + "/equipments?employeeId=" + employeeId;
                url = apiHelper.appendFilterPagingParams(url, filter, page);
                return requests.getJson(url);
            },

            getBonusForTimesheet: function (filter, page) {
                var url = apiHelper.getBaseUrl() + "/bonus";
                url = apiHelper.appendFilterPagingParams(url, filter, page);
                return requests.getJson(url);
            },

            getEmployeeForTimesheet: function (payPeriodStart, filter, page) {
                var url = apiHelper.getTimesheetBaseUrl() + "/employees";
                url = apiHelper.appendQueryStringParameters(url, { "payPeriodDate": payPeriodStart });
                return requests.getJson(apiHelper.appendFilterPagingParams(url, filter, page));
            },

            setEntriesForTimesheet: function (data, previousPayPeriod) {
                if (typeof previousPayPeriod === 'undefined' || previousPayPeriod === null) {
                    return requests.post(apiHelper.getTimesheetBaseUrl() + "/entries", data);
                }
                return requests.post(apiHelper.getTimesheetBaseUrl() + "/entries?previousPayPeriod=" + previousPayPeriod, data);
            },

            completeTimesheet: function(tsId) {
                return requests.post(apiHelper.getTimesheetBaseUrl(tsId) + "/complete");
            },

            approveTimesheets: function (timesheetIds) {
                return requests.post(apiHelper.getTimesheetBaseUrl() + "/approve", timesheetIds);
            },

            getEquipmentGroupsforTimesheet: function (filter, page) {
                var url = apiHelper.getTimesheetBaseUrl() + "/equipmentgroups";
                url = apiHelper.appendFilterPagingParams(url, filter, page);
                return requests.getJson(url);
            },

            deleteTimesheetEntry: function (timesheetId, timesheetEntryId) {
                return requests.remove(apiHelper.getTimesheetBaseUrl() + "/" + timesheetId + "/entries/" + timesheetEntryId);
            },

            getAllowedExpenseCompanySelection: function () {
                var url = apiHelper.getTimesheetBaseUrl() + "/allowed-expense-company-selection";
                return requests.getJson(url);
            },
        };

        return exports;
    });