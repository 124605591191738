define([
    "knockout",
    "helpers/routerHelper",
    "services/documentService",
    "helpers/queryStringHelper",
    "helpers/documentHelper"
], function (ko, routerHelper, documentService, queryStringHelper, documentHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = false;
            self.workOrderId = ko.observable();

            self.actions = {};
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(workOrderId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.workOrderId(workOrderId);

            self.actions = {
                getSections: documentService.getSectionsByWorkOrderId.bind(self, workOrderId),
                getSectionDocuments: documentService.getDocumentsByWorkOrderId.bind(self, workOrderId),
                getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
                deleteDocument: documentService.deleteDocument.bind(documentService)
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.q);
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
