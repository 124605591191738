import { AdditionalFieldDefinitionHelper } from "./interfaces/additional-field-definition-helper";
import { AdditionalFieldDefinitionSaveHelper } from "./interfaces/additional-field-definition-save-helper";

export class AdditionalFieldMemoHelper implements AdditionalFieldDefinitionHelper, AdditionalFieldDefinitionSaveHelper {
  public getValue(field: any): any {
    return field.Value;
  }

  public getSavedValue(field: any): any {
    const textarea = document.querySelector(`textarea#${field.Definition.Name}`) as any;
                   
    if (textarea != null) {
        return textarea.value;
    }

    return "";
  }
}
