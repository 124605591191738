import { transient, bindable, computedFrom } from "aurelia-framework";

import { default as _ } from "underscore";
import { default as resx } from "core/resx";

@transient()
export class FieldServiceHeader {
    @bindable id = 0;
    @bindable readonly = false;
    title = "";

    @computedFrom("id")
    get idEncoded() {
        return encodeURIComponent(this.id);
    }

    constructor() {
    }

    bind() {
        this.loadData();
    }

    loadData() {
        let title = "";

        if (this.id) {
            title = resx.localize("WorkOrder") + " " + this.id;

            if (this.readonly) {
                title += " - " + resx.localize("Completed");
            }
        } else {
            title = resx.localize("WorkOrder") + " - " + resx.localize("New");
        }

        this.title = title;
    }
}