import { PurchaseOrderService } from "services/purchase-order-service";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";

@autoinject()
export default class MaterialOrder {

    public serviceType: any = "";
    public dispatchProjectCode: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public projectCode: string = "";
    public actions: { getOrders: any; } | null = null;

    constructor(private readonly i18n: I18N, private readonly purchaseOrderService: PurchaseOrderService) {}

    public async activate(params: any): Promise<any> {
        this.serviceType = params.serviceType;

        this.dispatchProjectCode = params.dispatchProjectCode;

        const paramsQs = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        if (paramsQs && paramsQs.projectCode) {
            this.projectCode = paramsQs.projectCode;
        }

        this.actions = {
            getOrders: this.purchaseOrderService.GetProjectPurchaseOrders.bind(this.purchaseOrderService, this.projectCode)
        };
    }
}
