import { nameof as nameof_ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ValidationController } from "aurelia-validation";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemLaborBase } from "pages/services/quotations/item-labor-base";
import { CatalogService } from "services/catalog-service";
import { ServiceCallActivityService } from "services/service-call-activity-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { ServiceCallOccupationCategoryService } from "services/service-call-occupation-category-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { WageTypeString, nameof as nameof_WageTypeString } from "api/enums/wage-type-string";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { EnumHelper } from "helpers/enum-helper";
import { Dialog } from "interfaces/dialog";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { TradeService } from "services/trade-service";
import { WorkCategoryService } from "services/work-category-service";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { CloneHelper } from "helpers/cloneHelper";
import { SettingRepository } from "repositories/setting-repository";

export interface QuotationItemLaborDialogParameters {
    quotationItem: ServiceCallQuotationItemModel;
    parentQuotationItem: ServiceCallQuotationItemModel | null;
    quotation: ServiceCallQuotationDetailsModel;
}
export interface DropDownItem {
    id: any;
    text: string;
}

@autoinject()
export class QuotationItemLaborDialog extends QuotationItemLaborBase implements Dialog {

    public dialogHeaderTitle: string = "";

    public wageTypeStrings: DropDownItem[] = [];
    public selectedRateTypeText: string = "";
    constructor(
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        serviceCallActivityService: ServiceCallActivityService,
        serviceCallOccupationCategoryService: ServiceCallOccupationCategoryService,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        viewModeHelper: ViewModeHelper,
        dispatchTemplateService: DispatchTemplateService,
        tradeService: TradeService,
        workCategoryService: WorkCategoryService,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        settingRepository: SettingRepository,
        private readonly enumFormatValueConverter: EnumFormatValueConverter,
        private readonly catalogService: CatalogService,
        private readonly dialogController: DialogController
    ) {
        super(i18N, serviceCallQuotationService, serviceCallNonBillingReasonService, serviceCallActivityService, serviceCallOccupationCategoryService, validationHelper, validationController, viewModeHelper, dispatchTemplateService, tradeService, workCategoryService, serviceCallQuotationPriceService, settingRepository);
    }

    public async activate(params: QuotationItemLaborDialogParameters): Promise<void> {
        this.quotationItem = params.quotationItem;
        this.originalQuotationItem = CloneHelper.deepClone(params.quotationItem);
        this.calculationInProgress = true;
        this.profitMargin = this.quotationItem.ProfitMargin;
        this.sellingPrice = this.quotationItem.UnitPrice;
        this.calculationInProgress = false;
        this.parentQuotationItem = params.parentQuotationItem ? params.parentQuotationItem : null;
        this.quotation = params.quotation;
        this.dialogHeaderTitle =  this.enumFormatValueConverter.toView(this.quotationItem.Type, nameof_ServiceCallQuotationItemType);

        await this.initTimeIncrement();
        this.initValidation();
        this.initWageTypes();
    }

    public async ok(): Promise<void> {
        if (!await this.validate()) {
            return;
        }

        this.dialogController.ok(this.quotationItem);
    }

    public cancel(): void {
        Object.assign(this.quotationItem, this.originalQuotationItem);
        this.dialogController.cancel();
    }

    public initWageTypes(): void {

        this.wageTypeStrings = EnumHelper.getStringValues(WageTypeString)
            .filter((x: WageTypeString) => x !== WageTypeString.Bonus && x !== WageTypeString.Equipment)
            .map((x: WageTypeString) => this.createDropDownItem(x));

        this.selectedRateTypeText = this.enumFormatValueConverter.toView(this.quotationItem.RateType, nameof_WageTypeString);
    }

    private createDropDownItem(rateType: WageTypeString): DropDownItem {
        return { id: rateType,
                 text: this.enumFormatValueConverter.toView(rateType, nameof_WageTypeString),
               } as DropDownItem;

    }
}
