import { I18N } from "aurelia-i18n";
import val from "core/val";
import { default as settingHelper } from "helpers/settingHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { PagingInfo } from "api/paging-info";
import { autoinject, bindable } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";
import { ValidationHelper } from "helpers/validation-helper";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";

import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";
import { InventoryProjectTransferWorkOrderLookupModel } from "api/models/company/workorder/inventory-project-transfer-work-order-lookup-model";

@autoinject()
export class InventoryProjectTransferEdit {
    public val: any = val;

    @bindable public option: any = "";
    @bindable public transfer!: InventoryProjectTransferDetailsModel | null;

    @bindable public isMultiPrefix: boolean = false;
    @bindable public isProjectLockedByWorkOrder: boolean = false;
    @bindable public isWorkOrderVisible: boolean = false;

    constructor( private i18n: I18N,
                 private routeRepository: RouteRepository,
                 private readonly inventoryProjectTransferService: InventoryProjectTransferService,
                 private readonly validationHelper: ValidationHelper,
                 private readonly validationController: ValidationController) {}

    public async activate(params: any): Promise<void> {
        this.isMultiPrefix = settingHelper.isMultiPrefix();
        this.transfer = await this.inventoryProjectTransferService.GetTransfer(params.transferId);
        this.isWorkOrderVisible = await this.inventoryProjectTransferService.IsWorkOrdersInstalled();

        this.isProjectLockedByWorkOrder = this.isWorkOrderVisible && !!this.transfer!.WorkOrderId;

        this.initValidation();
    }

    public async getTargetProjects(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ProjectBaseModel[] | null> {
        return await this.inventoryProjectTransferService.GetProjects(this.transfer!.TargetPrefix!, filter, pagingInfo, requestConfig);
    }

    public async getWorkOrders(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<InventoryProjectTransferWorkOrderLookupModel[] | null> {
        return await this.inventoryProjectTransferService.GetWorkOrders(this.transfer!.TargetPrefix!, filter, pagingInfo, requestConfig);
    }

    public onWorkOrderChanged(event: any): void {
        if (!!event.detail) {
            this.transfer!.ProjectNo = event.detail.ProjectNo;
            this.transfer!.ProjectDescription = event.detail.ProjectDescription;
            this.isProjectLockedByWorkOrder = true;
        } else {
            this.isProjectLockedByWorkOrder = false;
            this.transfer!.ProjectNo = "";
            this.transfer!.ProjectDescription = "";
        }
    }

    public async save(): Promise<void> {
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);

        if (isValid !== true) {
            return;
        }

        const hasTransferItems = this.transfer!.Items!.length > 0;

        if (hasTransferItems) {
            const originalProjectNo = this.transfer!.Items![0]!.ProjectNo;
            const isProjectChanged = originalProjectNo !== this.transfer!.ProjectNo;

            if (isProjectChanged) {
                const result = await notificationHelper.showDialogYesNo(this.i18n.tr("ProjectInventoryTransfer_ProjectChanged_confirm_text"));
                if (!result) {
                   return;
               }
            }
        }

        await this.inventoryProjectTransferService.SaveTransfer(this.transfer!).then(() => {
            routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Info.name,  {transferId : this.transfer!.Id});
        });
    }

    private initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;
        this.validateTransfer();
    }

    private validateTransfer(): void {
        ValidationRules
            .ensure((x: InventoryProjectTransferDetailsModel) => x.ProjectNo).required().withMessageKey("err_ProjectRequired")
            .on(this.transfer);
    }
}
