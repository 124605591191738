define([
    "knockout",
    "underscore",
    "core/resx",
    "core/val",
    "moment",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "services/interruptionService",
    "services/projectService"

], function (ko, _, resx, val, moment, dateHelper, notificationHelper, queryStringHelper, routerHelper, interruptionService, projectService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.val = val;

            self.dispatchDate = ko.observable();
            self.dispatchProjectCode = ko.observable();

            self.id = ko.observable();
            self.isProjectResponsible = false;
            self.readonly = ko.observable(false);

            //ValidatedForm
            self.validatedForm = ko.validatedObservable({
                endTime: ko.observable(),
                startTime: ko.observable(),

                name: ko.observable(),
                description: ko.observable(),
                comments: ko.observable(),

                clear: function() {
                    this.endTime(null);
                    this.startTime(null);
                    this.name("");
                    this.description("");
                    this.comments("");
                }
            });

            //Validators
            self.validatedForm().endTime.extend({
                validation: {
                    validator: function(val) {
                        if (!val) {
                            this.message = resx.localize('err_EndTimeRequired');
                            return false;
                        }

                        if (moment(val).isSameOrBefore(moment(self.validatedForm().startTime()))) {
                            this.message = resx.localize('err_EndTimeAfterStartTime');
                            return false;
                        }
                        return true;
                    }
                }
            });

            self.validatedForm().startTime.extend({
                required: {
                    message: resx.localize('err_StartTimeRequired')
                }
            });

            self.validatedForm().name.extend({
                required: {
                    message: resx.localize('err_CodeRequired')
                }
            });

            self.validatedForm().description.extend({
                required: {
                    message: resx.localize('err_DescriptionRequired')
                }
            });
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, id, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.readonly(queryStringHelper.parseReadonly(queryString));

            self.id(id);
            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);

            self.validatedForm().clear();
        }

        function getDateTime(time) {
            var timeArr = time.split(":");
            var timeToReturn = dateHelper.dateFromUTC(self.dispatchDate());
            timeToReturn.setHours(timeArr[0], timeArr[1], timeArr[2]);

            return timeToReturn;
        }

        function loadData() {
            if (self.id()) {
                return interruptionService.getInterruptionsForDispatchProjectCodeAndDate(self.dispatchProjectCode(), self.dispatchDate(), self.id()).done(mapApiData);
            } else {
                return projectService.getProjectWorkSchedule(self.dispatchProjectCode()).done(function (prjSchedule) {
                    self.validatedForm().startTime(getDateTime(prjSchedule.StartHour));
                    self.validatedForm().endTime(getDateTime(prjSchedule.EndHour));
                });
            }
        }

        function buildDto() {
            var valForm = self.validatedForm();
            return {
                LineNumber: self.id() ? self.id(): 0,
                Name: valForm.name(),
                Description: valForm.description(),
                Comment: valForm.comments(),
                StartTime: dateHelper.getTime(valForm.startTime()),
                EndTime: dateHelper.getTime(valForm.endTime())
            };
        }

        function mapApiData(data) {
            self.validatedForm().startTime(data.StartDateTime);
            self.validatedForm().endTime(data.EndDateTime);

            self.validatedForm().name(data.Name);
            self.validatedForm().description(data.Description);
            self.validatedForm().comments(data.Comment);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
            return loadData();
        };

        ctor.prototype.clear = function () {
            self.validatedForm().clear();
        };

        ctor.prototype.isEdit = function () {
            return self.id() !== null;
        };


        ctor.prototype.save = function () {
            if (self.validatedForm.isValid()) {
                routerHelper.showLoading();

                interruptionService.setInterruptionsForDispatchProjectCodeAndDate(self.dispatchProjectCode(), self.dispatchDate(), buildDto())
                .done(routerHelper.navigateBack)
                .always(routerHelper.hideLoading);
            } else {
                notificationHelper.showValidationError(self.validatedForm.errors);
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
