define([
    "knockout",
    "underscore",
    "core/resx",
    "moment",
    "helpers/dateHelper",
    "helpers/enumHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "helpers/timesheetHelper",
    "services/timesheetService",
    "widgets/timesheetHeader",
    "widgets/timesheetEmployeeHeader",
    "widgets/timesheetEmployeeSummary"
], function (ko, _, resx, moment, dateHelper, enumHelper, notificationHelper, queryStringHelper, routerHelper, timesheetHelper, timesheetService) {
    "use strict";

    var ViewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.loadCurrentEmployeeOnly = false;
            self.resx = resx;
            self.timesheetHelper = timesheetHelper;
            self.type = timesheetHelper.PAGE_TYPE_SUMMARY;

            self.employee = ko.observable({});
            self.isTeamLeader = ko.observable(false);
            self.linkedEquipments = ko.observableArray([]);
            self.listGroupShown = ko.observable(timesheetHelper.LIST_GROUP_TYPE_EMPLOYEE);
            self.listPeriodShown = ko.observable(timesheetHelper.LIST_SHOW_CURRENT);
            self.teamLeaderIsResponsibleForTimeEntry = ko.observable(true);
            self.allSelected = ko.observable(false);

            self._cache = {};
            self._currentData = ko.observable({});

            self.list = ko.pureComputed(function() {
                return self._currentData().list;
            });

            self.timeSheetId = ko.pureComputed(function() {
                return self._currentData().TimeSheetId;
            });

            self.payPeriodStartDate = ko.pureComputed(function() {
                return self._currentData().PayPeriodStartDate;
            });

            self.timeSheetStatus = ko.pureComputed(function() {
                return self._currentData().TimeSheetStatus;
            });

            self.payPeriodIsReadonly = ko.pureComputed(function() {
                return self._currentData().PayPeriodIsReadonly;
            });

            self.showComplete = ko.pureComputed(function() {
                return !self.isTeamLeader() && !self.teamLeaderIsResponsibleForTimeEntry();
            });

            self.disableComplete = ko.pureComputed(function() {
                return self.timeSheetStatus() !== enumHelper.timesheetStatus.INPROGRESS.id;
            });

            self.showApprove = ko.pureComputed(function() {
                return self.isTeamLeader() &&
                    _.some(self.list(),
                        function(groupEmployee) {
                            return groupEmployee.showCheckmark;
                        });
            });

            //#endregion
        }

        //#region Private Functions
        function bindViewModel(querystring) {
            self.loadCurrentEmployeeOnly = !queryStringHelper.parseIsTeamLeaderTimesheet(querystring);
            self.listPeriodShown(routerHelper.getQuerystring(querystring).ListPeriodShown);
        }

        function loadData() {
            var groupType = self.listGroupShown();
            var period = self.listPeriodShown(); 

            if (self._cache[period][groupType]) {
                self._currentData(self._cache[period][groupType]);
                return jQuery.Deferred().resolve(true);
            }

            return timesheetHelper.loadList(self, groupType, period);
        }

        function clearCache() {
            self._cache = {};
            self._cache[timesheetHelper.LIST_SHOW_CURRENT] = {};
            self._cache[timesheetHelper.LIST_SHOW_PAST] = {};

            self._currentData({ list: [] });
        }

        function doApprove(timesheetIds) {
            routerHelper.showLoading();

            timesheetService.approveTimesheets(timesheetIds)
                .done(routerHelper.navigateBack)
                .always(routerHelper.hideLoading);
        }

        function doComplete() {
            routerHelper.showLoading();

            timesheetService.completeTimesheet(self.timeSheetId())
                .done(routerHelper.navigateBack)
                .always(routerHelper.hideLoading);
        }

        function getSelectedTimesheetsIds() {
            var timesheetIds = [];

            _.each(self.list(),
                function (grpEmployee) {
                    if (grpEmployee.selected()) {
                        var grpDay = _.first(grpEmployee.Items);
                        var grpProj = _.first(grpDay.Items);
                        var timesheet = _.first(grpProj.Items);
                        timesheetIds.push(timesheet.TimesheetId);
                    }
                });

            return timesheetIds;
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (querystring) {
            bindViewModel(querystring);
            clearCache();
            return loadData(querystring);
        };

        ctor.prototype.attached = function () {
            self.allSelected(_.every(self.list(), function (x) { return _.isFunction(x.selected) && x.selected() === true; }));
        };

        ctor.prototype.showPeriod = function (period) {
            self.listPeriodShown(period);
            loadData();
        };

        ctor.prototype.showGroup = function (group) {
            self.listGroupShown(group);
            loadData();
        };

        ctor.prototype.toggleOpenSection = function (data) {
            data.isOpened(!data.isOpened());
        };

        ctor.prototype.selectEntry = function (data) {
            data.selected(!data.selected());
        };

        ctor.prototype.selectAll = function () {
            _.each(self.list(),
                function (x) {
                    x.selected(!self.allSelected());
                });

            self.allSelected(_.every(self.list(), function (x) { return x.selected() === true; }));
        };

        ctor.prototype.approve = function () {
            var timesheetIds = getSelectedTimesheetsIds();

            if (_.isEmpty(timesheetIds)) {
                notificationHelper.showWarning(resx.localize('err_SelectAtLeastOneTimesheet'));
                return;
            }

            notificationHelper.showDialogYesNo(resx.localize('SignatureConfirmationSummaryLeaderTimesheet')).done(function (data) {
                if (data){
                    doApprove(timesheetIds);
                }
            });
        };

        ctor.prototype.complete = function () {
            notificationHelper.showDialogYesNo(resx.localize('SignatureConfirmationSummaryEmployeeTimesheet')).done(function (data) {
                if (data) {
                    doComplete();
                }
            });
            
        };

        ctor.prototype.genUrl = function () {
            return '#';
        };
        //#endregion

        return ctor;
    })();
    return ViewModel;
});