define([
    "knockout",
    "jquery",
    "core/resx",
    "widgets/pages/contact_details"
], function(ko, jQuery, resx) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.id = ko.observable();
            self.dispatchId = ko.observable();
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.id(params.id);
            self.dispatchId(params.dispatchId);
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
