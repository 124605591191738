import {transient } from "aurelia-framework";

import { default as _ } from "underscore";
import { default as ko } from "knockout";
import { default as resx } from "core/resx";


function loadData(self) {
    var title = "";

    if (self.id) {
        title = resx.localize("WorkOrder");
        title += " ";
        title += self.id;

        if (self.readonly) {
            title += " - ";
            title += resx.localize("Completed");
        }
    } else {
        title = resx.localize("WorkOrder");
        title += ' - ';
        title += resx.localize('New');
    }

    self.title(title);
    self.faIcon(self.readonly ? "fa fa-check" : "ma ma-ongoing");
    self.url("#fieldservice/" + encodeURIComponent(self.id) + "/summary");
}


export class FieldServiceHeader {
    constructor() {
        this.id = 0;
        this.faIcon = ko.observable('');
        this.readonly = false;
        this.title = ko.observable('');
        this.url = ko.observable('');
    }

    activate = function(widgetSettings) {
        this.id = _.isFunction(widgetSettings.id) ? widgetSettings.id() : widgetSettings.id;
        this.readonly = widgetSettings.readonly;

        loadData(this);
    }
}