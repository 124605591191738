define([
    "jquery",
    "knockout",
    "helpers/routerHelper",
    "services/documentService"
], function(jQuery, ko, routerHelper, documentService) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = false;
            self.dispatchId = ko.observable();
            self.equipmentCode = ko.observable();
            self.equipmentId = ko.observable();

            self.actions = {};
            //#endregion
        }

        //#region Public Methods
        ctor.prototype.activate = function(params) {
            var paramsQs = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQs && paramsQs.readonly === "false");
            self.dispatchId(params.dispatchId);
            self.equipmentCode(params.equipmentCode);
            self.equipmentId(paramsQs.equipmentId);
          
            self.actions = {
                save: documentService.saveEquipmentPicture.bind(self, params.serviceType, params.dispatchId, paramsQs.entityId, params.equipmentCode, paramsQs.equipmentId)
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
