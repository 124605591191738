define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {

        getProductionItemsForDailyEntry: function (dispatchProjectCode, dispatchDate) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/productions';
            return requests.getJson(url);
        },

        deleteProductionItem: function (dispatchProjectCode, dispatchDate, id, line) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/productions/' + id + '/' + line;
            return requests.remove(url);
        },

        getProductionItem: function (dispatchProjectCode, dispatchDate, id, line) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/productions/' + id + '/' + line;
            return requests.getJson(url);
        },

        setProductionItem: function (dispatchProjectCode, dispatchDate, data) {
            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + '/productions';
            return requests.post(url, data);
        }
    };

    return exports;
});
