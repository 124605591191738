import { Redirect } from "aurelia-router";
import { CreationSource } from "api/enums/creation-source";

define([
    "underscore",
    "knockout",
    "core/resx",
    "services/attendanceService",
    "services/dispatchService",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "helpers/notificationHelper",
    "helpers/settingHelper",
    "helpers/enumHelper",
    "helpers/attendanceHelper",
    "services/templateService",
    "services/dailyEntryService",
    "widgets/lstNoData",
    "widgets/projectHeader",
    "widgets/addAttendance",
], function (_, ko, resx, attendanceService, dispatchService, dateHelper, routerHelper, queryStringHelper, notificationHelper, settingHelper, enumHelper, attendanceHelper, templateService, dailyEntryService) {
    "use strict";

    PLATFORM.moduleName("pages/projects/dailyEntry/attendance_template.html");
    PLATFORM.moduleName("pages/projects/dailyEntry/attendance_templateEquipment.html");

    var viewModel = (function () {
        var self = null;
        
        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.routerHelper = routerHelper;

            self.dispatchDate = ko.observable();
            self.dispatchModel = "";
            self.dispatchProjectCode = ko.observable();
            self.isResponsible = false;
            self.readonly = false;
            self.isInFuture = false;
            self.teams = ko.observableArray([]);
            self.employees = ko.observableArray([]);
            self.equipments = ko.observableArray([]);
            self.selectedItem = ko.observable({});
            self.totalAttendanceCount = ko.observable(0);
            self.employeesTotalAttendanceCount = ko.observable(0);
            self.employeesPresentAttendanceCount = ko.observable(0);
            self.presentAttendanceCount = ko.observable(0);
            self.selectedFilter = "";
            self.permitted = false;
            self.CreatedByMaestro = CreationSource.Maestro;
            self.canDeleteDailyEntry = false;

            self.loadAvailableAttendances = {
                transport: function(params, success, failure) {
                    self.selectedFilter = params.data.filterSelected;
                    attendanceService.listAvailableAttendanceForProject(self.dispatchProjectCode(),
                            self.dispatchDate(),
                            self.dispatchModel,
                            getSelectedFilterToApi(params.data.filterSelected),
                            params.data.filterChecked,
                            params.data.filter,
                            params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function (item) {
                    const attendanceTypes = enumHelper.attendanceTypes();
                    switch (self.selectedFilter) {
                        case attendanceTypes.EMPLOYEE.id:
                        return {
                            id: 'emp-' + item.EmployeeId,
                            text: item.EmployeeId + ' - ' + item.FirstName + ' ' + item.LastName,
                            type: self.selectedFilter,
                            data: item
                        };
                        case attendanceTypes.TEAM.id:
                        return {
                            id: 'team-' + item.Id,
                            text: item.Id + ' - ' + item.Name,
                            data: item,
                            type: self.selectedFilter
                        };
                        case attendanceTypes.EQUIPMENT.id:
                        return {
                            id: 'equip-' + item.EquipmentId,
                            text: item.EquipmentId + ' - ' + item.EquipmentDescription,
                            data: item,
                            type: self.selectedFilter
                        };
                    }
                }
            };

            //#endregion
        }

        //#region Private Functions
        function bindViewModel({ dispatchProjectCode, dailyEntryDate, q }) {
            self.dispatchModel = settingHelper.getSelectedDispatchModel();
            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dailyEntryDate);
            self.isInFuture = !dateHelper.isTodayOrBefore(dailyEntryDate.replace('-', '/'));
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(q);
            self.isTeamLeader = queryStringHelper.parseIsTeamLeader(q);
            self.readonly = queryStringHelper.parseReadonly(q);
            self.permitted = attendancePermission();
            self.canDeleteDailyEntry = queryStringHelper.parseCanDeleteDailyEntry(q);
        }

        function bindLoadedData(loadedData) {
            self.CreatedByMaestro = CreationSource.Maestro;
            self.totalAttendanceCount(loadedData.length);
            var presentAttendanceCount = _.filter(loadedData, function (item) {
                return item.Presence >= enumHelper.attendance().ONSITE;
            }).length;

            loadedData = _.map(loadedData, function (item) {
                item.attendanceStatus = ko.observable(item.Presence);
                item.isInactive = ko.observable(item.IsInactive);
                return item;
            });

            var groupAndSortAttendances = attendanceHelper.groupAndSortAttendances(loadedData, true);
            var employeesLoadedData = attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(groupAndSortAttendances);

            self.teams(attendanceHelper.getTeamsFromGroupedAndSortedAttendances(groupAndSortAttendances));
            self.employees(employeesLoadedData);
            self.equipments(attendanceHelper.getEquipmentFromAttendances(loadedData));
            self.presentAttendanceCount(presentAttendanceCount);
        }

        function loadData() {
            return attendanceService.listForDate(self.dispatchProjectCode(), self.dispatchDate(), getAllowEquipmentTimeEntryForSimpleUser())
                .done(function(data) {
                    bindLoadedData(data);
                })
                .always(function() {
                    routerHelper.hideLoading();
                });
        }

        function getAllowEquipmentTimeEntryForSimpleUser() {
            return templateService.getCurrentTemplateConfigs().AllowEquipmentTimeEntry;
        }

        function genUrl(rel, additionalParameter, additionalParameter2) {
            var url = '';
            if (additionalParameter) {
                if (additionalParameter2) {
                    url = routerHelper.navigateTo(rel, self.dispatchProjectCode(), self.dispatchDate(), additionalParameter, additionalParameter2);
                } else {
                    url = routerHelper.navigateTo(rel, self.dispatchProjectCode(), self.dispatchDate(), additionalParameter);
                }
            } else {
                url = routerHelper.navigateTo(rel, self.dispatchProjectCode(), self.dispatchDate());
            }

            return url + routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly, isTeamLeader: self.isTeamLeader });
        }

        function updateAttendanceStatus(item, model, validateActivity) {
            if (validateActivity && item.HasTimeEntries) {
                notificationHelper.showConfirmation(resx.localize('msg_AbsentConfirmation'))
                    .done(function (success) {
                        if (success) {
                            updateAttendanceStatusInDatabase(item, model);
                        }
                    });
            } else {
                updateAttendanceStatusInDatabase(item, model);
            }
        }

        function updateAttendanceStatusInDatabase(item, model) {
            routerHelper.showLoading();
            dispatchService.setAttendanceStatus(item.DispatchId, model)
                .done(bindLoadedData)
                .always(function() {
                    item.attendanceStatus(model.Presence);
                    routerHelper.hideLoading();
                });
        }

        function saveInactive(dispatchId, inactive) {
            routerHelper.showLoading();
            dispatchService.setInactive(dispatchId, inactive)
                .done(function (data) {
                    //need to retreive inactive item in case of linked equipment that has been unlinked in the process...
                    var eq = _.find(self.equipments().Attendances, function (equip) {
                        return equip.DispatchId === dispatchId;
                    });
                    eq.isInactive(inactive);
                })
                .always(function () {
                    routerHelper.hideLoading();
                });
        }

        function setInactive(dispatchId, item) {
            if (item.HasTimeEntries) {
                notificationHelper.showConfirmation(resx.localize('msg_AbsentConfirmationEquipment'))
                    .done(function (success) {
                        if (success) {
                            saveInactive(dispatchId, !item.isInactive());
                        }
                    });
            } else {
                saveInactive(dispatchId, !item.isInactive());
            }
        }

        function getSelectedFilterToApi(id) {
            const attendanceTypes = enumHelper.attendanceTypes();
            if (id === attendanceTypes.EMPLOYEE.id) {
                return "employees";
            }
            if (id === attendanceTypes.TEAM.id) {
                return "teams";
            }
            if (id === attendanceTypes.EQUIPMENT.id) {
                return "equipments";
            }
        }

        function attendancePermission() {
            //Backend are setting readonly to True if D.E is closed or in future
            //We don't permit only if the D.E is in the past and is closed
            return self.isInFuture || !self.readonly;
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.canActivate = function () {
            if (!settingHelper.hasDispatchModel()) {
                notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
                return new Redirect("Settings");
            }

            return true;
        }

        ctor.prototype.activate = function (activationParameters) {

            bindViewModel(activationParameters);

            return loadData();
        };

        ctor.prototype.genEmployeeUrl = function (route, employeeId, dispatchId) {
            return genUrl(route, employeeId, dispatchId);
        };

        ctor.prototype.genTimeEntryUrl = function (route) {
            return genUrl(route);
        };

        ctor.prototype.addAttendances = function (list) {
            const attendanceTypes = enumHelper.attendanceTypes();

            routerHelper.showLoading();
            var dataToPost = _.map(list, function (item) {
                
                return {
                    EmployeeId: item.type === attendanceTypes.EMPLOYEE.id ? item.id.replace('emp-', '') : null,
                    EquipmentId: item.type === attendanceTypes.EQUIPMENT.id ? item.id.replace('equip-', '') : null,
                    TeamId: item.type === attendanceTypes.TEAM.id ? item.id.replace('team-', '') : null
                };
            });
            
            attendanceService.addAttendanceForProject(self.dispatchProjectCode(), self.dispatchDate(), dataToPost, self.dispatchModel, true)
                .done(function (data) {
                    if (data.SaveResponse === enumHelper.attendanceAddReturnType().NONE) {
                        notificationHelper.showWarning(resx.localize('msg_AttendanceAdd_TeamNotAvailableAnymore'));
                    } else if (data.SaveResponse === enumHelper.attendanceAddReturnType().PARTIAL) {
                        notificationHelper.showWarning(resx.localize('msg_AttendanceAdd_SomeAllTeamMembersNotAvailableAnymore'));
                    }

                    bindLoadedData(data.List);
                }).always(routerHelper.hideLoading);

        };

        ctor.prototype.removeAttendance = async function (item) {
            let isLastAttendee = false;
            let confirmDeleteAttendee = false;

            const containsTimeEntriesOrBonus = await dispatchService.dipatchProjectHasTimeEntriesAndBonus(item.DispatchId)
            if (containsTimeEntriesOrBonus) {
                notificationHelper.showWarning(resx.localize('WarningProjectDispatchHasTimeEntriesAndBonus'), "", { timeOut: 0 });
                return true;
            }

            isLastAttendee = await dispatchService.dipatchProjectisLastAttendee(item.DispatchId)

            if (isLastAttendee) {
                if (!self.canDeleteDailyEntry) {
                    notificationHelper.showWarning(resx.localize('msg_CannotDeleteLastAttendeeDailyEntryCannotBeDeleted'), "", { timeOut: 0 });
                    return true;
                }
                confirmDeleteAttendee = await notificationHelper.showConfirmation(resx.localize('msg_ProjectDispatchDeleteLastAttendee'));
            } else {
                confirmDeleteAttendee = await notificationHelper.showConfirmation(resx.localize('msg_DeleteAttendanceConfirmation'));
            }

            if (confirmDeleteAttendee) {
                if (isLastAttendee) {
                    self.deleteEntry();
                } else {
                    const presences = await dispatchService.delProjectDispatch(item.DispatchId)
                    if (presences && presences.length > 0) {
                        bindLoadedData(presences);
                    } else {
                        var url = routerHelper.navigateTo('Project_Detail', self.dispatchProjectCode());
                        url += routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly, isTeamLeader: self.isTeamLeader });

                        routerHelper.navigate(url);
                    }
                }
            }
        };

        ctor.prototype.unbindEquipment = function (item) {
            var dfd = new jQuery.Deferred();
            notificationHelper.showConfirmation(resx.localize('msg_UnbindEquipmentConfirmationText'))
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        dispatchService.breakEquipmentLink(item.DispatchId)
                            .done(function (data) {
                                bindLoadedData(data);
                                dfd.resolve();
                            })
                            .always(routerHelper.hideLoading);
                    } else {
                        dfd.reject();
                    }
                });
            return dfd;
        };

        ctor.prototype.updateAttendanceStatus_Absent = function (item) {
            var newStatus = item.attendanceStatus() === enumHelper.attendance().ABSENT ? enumHelper.attendance().UNDEFINED : enumHelper.attendance().ABSENT;

            if (newStatus === enumHelper.attendance().ABSENT) {
                if (item.HasTimeEntries) {
                    notificationHelper.showConfirmation(resx.localize('msg_AbsentConfirmation'))
                        .done(function(success) {
                            if (success) {
                                routerHelper.navigate(routerHelper.getRelativeUrl('absence', item.DispatchId));
                            }
                        });
                } else {
                    routerHelper.navigate(routerHelper.getRelativeUrl('absence', item.DispatchId));
                }
            } else {
                updateAttendanceStatus(item, { Presence: newStatus, AllowEquipmentTimeEntryForSimpleUser: getAllowEquipmentTimeEntryForSimpleUser() }, true);
            }
        };

        ctor.prototype.updateAttendanceStatus_Present = function (item) {
            var newStatus = item.attendanceStatus() >= enumHelper.attendance().ONSITE ? enumHelper.attendance().UNDEFINED : enumHelper.attendance().ONSITE;
            updateAttendanceStatus(item, { Presence: newStatus, AllowEquipmentTimeEntryForSimpleUser: getAllowEquipmentTimeEntryForSimpleUser() }, newStatus === enumHelper.attendance().UNDEFINED);
        };

        ctor.prototype.updateAttendanceStatus_Present_All = function () {
            if (self.presentAttendanceCount() === self.totalAttendanceCount()) {
                return;
            }

            routerHelper.showLoading();
            attendanceService.updateStatusForAllDispatches(self.dispatchProjectCode(), self.dispatchDate(), getAllowEquipmentTimeEntryForSimpleUser())
                .done(bindLoadedData)
                .always(routerHelper.hideLoading);
        };

        ctor.prototype.navigateToTimeEntry = function() {
            var url = self.genTimeEntryUrl('Project_Detail_Daily_Entry_TimeEntry');
            routerHelper.navigate(url, { replace: true, trigger: true });
        };

        ctor.prototype.inactiveClicked = function (item, event) {
            if (item.LinkedDispatchId) {
                var dfd = self.unbindEquipment(item);
                if (dfd.done) {
                    dfd.done(function() {
                        setInactive(item.LinkedEquipment.DispatchId, item);
                    });
                }
            } else {
                setInactive(item.DispatchId, item);
            }
        };

        ctor.prototype.deleteEntry = async function () {
           
            await dailyEntryService.deleteDailyEntry(self.dispatchProjectCode(), self.dispatchDate());

            window.history.go(-2);
        };

        ctor.prototype.cancelAllDispatches =  function () {
            if (!self.canDeleteDailyEntry) {
                notificationHelper.showWarning(resx.localize('msg_DailyEntryHasStarted'), "", { timeOut: 0 });
                return;
            }

            var url = genUrl('Project_Daily_Entry_Cancel_Attendances');
            routerHelper.navigate(url);
        };
              
        //#endregion
        return ctor;
    })();

    return viewModel;
});
