import { bindable, autoinject, observable } from "aurelia-framework";
import val from "core/val";
import { WorkTaskModel } from "api/models/company/work-task-model";
import { WorkOrderWorkTaskDetailsModel } from "api/models/company/workorder/work-order-work-task-details-model";
import { ListDocumentModel } from "api/models/company/document/list-document-model";
import { I18N } from "aurelia-i18n";
import { WorkOrderWorkTaskService } from "services/work-order-work-task-service";
import { RouterHelper } from "helpers/router-helper";
import { WorkOrderWorkTaskDocumentService } from "services/work-order-work-task-document-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { WorkTaskService } from "services/work-task-service";
import { RouteInfo } from "./ma-work-task-index";
import notificationHelper from "helpers/notificationHelper";
import Parse from "helpers/parse";
import { CreationSource } from "api/enums/creation-source";

@autoinject
export class MaWorkTaskEdit {
    public val: any = val;
    public option: any = "";
    public workTask!: WorkOrderWorkTaskDetailsModel;

    public selectedCode: any;
    public isCodeSelected: boolean = false;
    public isNewWorkTask: boolean = true;
    public isFromMaestro: boolean = false;

    public deleteAction!: (id: any) => Promise<boolean>;

    @observable public isCompleted: boolean = false;
    @observable public isDiffered: boolean = false;

    public subscription: any;
    public pictures: ListDocumentModel[] = [];

    @bindable public transactionNumber?: number;
    @bindable public newWorkTask!: () => Promise<WorkOrderWorkTaskDetailsModel>;
    @bindable public documentRouteInfo!: RouteInfo;
    public readonly: boolean = false;

    constructor(private readonly i18n: I18N, private readonly workTaskService: WorkTaskService, private readonly workOrderWorkTaskService: WorkOrderWorkTaskService, private readonly routerHelper: RouterHelper, private readonly workOrderWorkTaskDocumentService: WorkOrderWorkTaskDocumentService, private readonly eventAggregator: EventAggregator) {}

    public async bind(): Promise<void> {
        this.subscription = this.eventAggregator.subscribe("document-upload-finish", () => this.reloadDocuments());

        const transactionNumber = this.transactionNumber ? this.transactionNumber : 0;
        this.isNewWorkTask = !(transactionNumber > 0);

        this.readonly = Parse.Boolean(this.documentRouteInfo.routeParams.readonly);

        if (this.isNewWorkTask) {
           this.workTask = (await this.newWorkTask())!;
        } else {
            this.workTask = (await this.workOrderWorkTaskService.GetWorkTask(transactionNumber))!;
            this.isFromMaestro = this.workTask.CreationSource === CreationSource.Maestro;

            this.initialiseSelection(this.workTask);
        }

        this.deleteAction = this.workOrderWorkTaskDocumentService.DeleteDocument.bind(this.workOrderWorkTaskDocumentService, transactionNumber);
    }

    public async reloadDocuments(): Promise<void> {
        this.pictures = (await this.workOrderWorkTaskDocumentService.GetDocumentsForWorkTask(this.workTask.TransactionNumber, "1"))!;
    }

    public get GetCodes(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.workTaskService.GetTasks(params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: WorkTaskModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: WorkTaskModel): any => {
                return { id: item.Code, text: `${item.Code} - ${item.Description}`, data: item };
            }
        };
    }

    public onCodeSelected(workTask: WorkTaskModel): void {
        this.workTask.TaskCode = workTask.Code;
        this.workTask.TaskDescription = workTask.Description;
        this.workTask.Note = workTask.Note;

        this.isCodeSelected = true;
    }

    public onCodeCleared(): void {
        this.workTask.TaskCode = null;
        this.workTask.TaskDescription = null;
        this.workTask.Note = null;

        this.isCodeSelected = false;
    }

    public isCompletedChanged(newValue: boolean | null, oldValue: boolean | null): void {
        if (newValue !== null && newValue) {
                this.isDiffered = false;
        }

        this.setInfo();
    }

    public isDifferedChanged(newValue: boolean | null, oldValue: boolean | null): void {
        if (newValue !== null && newValue) {
            this.isCompleted = false;
        }

        this.setInfo();
    }

    public async save(): Promise<void> {
        if (this.readonly) {
            return;
        }

        if (!this.workTask.TaskDescription) {
            const dialogMessage = `${this.i18n.tr("ApiError.617")}`;
            notificationHelper.showError(dialogMessage, "", { timeOut: 0, extendedTimeOut: 0 });
            return;
        }

        this.setInfo();

        await this.workOrderWorkTaskService.SaveWorkTask(this.workTask);
        this.routerHelper.navigateBack();
    }

    public setInfo(): void {
        if (this.workTask) {
            this.workTask.IsCompleted = this.isCompleted;
            this.workTask.IsDiffered = this.isDiffered;

            if (!this.isCompleted) {
                this.workTask.DateCompleted = null;
            }
        }
    }

    public async addPicture(): Promise<void> {
        if (this.readonly) {
            return;
        }

        await this.workOrderWorkTaskService.SaveWorkTask(this.workTask);
        this.routerHelper.navigateToRoute(this.documentRouteInfo.routeName, this.documentRouteInfo.routeParams);
    }

    private async initialiseSelection(workTask: WorkOrderWorkTaskDetailsModel): Promise<void> {
        if (workTask.TaskCode) {
            this.selectedCode =  { id: workTask.TaskCode, text: `${workTask.TaskCode} - ${workTask.TaskDescription}`};
            this.isCodeSelected = true;
        }

        this.isCompleted = this.workTask.IsCompleted;
        this.isDiffered = this.workTask.IsDiffered;

        this.reloadDocuments();
    }
}
