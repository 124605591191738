define([
    "underscore",
    "knockout",
    //"plugins/router",
    "core/resx",
    "helpers/routerHelper"
], function (_, ko, /*router,*/ resx, routerHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;
            self.readonly = false;
            self.workOrderId = ko.observable('');
            self.dipatchId = 0;
            self.employeeId = 0;
            //#endregion
        }

        //#region Private Functions
        
        function bindViewModel(workOrderId, employeeId, dispatchId, querystring) {
            var params = routerHelper.getQuerystring(querystring);
            self.readonly = !(params && params.readonly === "false");
            self.workOrderId(workOrderId);
            self.dispatchId = dispatchId;
            self.employeeId = employeeId;
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.employeeId, params.dispatchId, params.q);

            return true;
        };

        ctor.prototype.navigateToActivities = function () {
            var url = routerHelper.navigateTo('FieldService_Hour_Employee', self.workOrderId(), true, self.employeeId);
            url += routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly });
            routerHelper.navigate(url);
        };


        //#endregion
        return ctor;
    })();

    return viewModel;
});
