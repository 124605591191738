import { inject, transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as jQuery } from "jquery";
import { default as _ } from "underscore";

@transient()
export class EvaluationCriteria {
    constructor() {
        //#region Properties
        this.text = "";
        this.value = ko.observable();
        this.list = [];
        this.disable = ko.observable(false);
        //#endregion

        this.setValue = this.setValue.bind(this);
    }

    //#region Public Functions
    activate(widgetSettings) {
        var min = widgetSettings.min || 1;
        var max = widgetSettings.max || 5;

        this.text = widgetSettings.text || "";
        this.value = widgetSettings.value || ko.observable(0);

        this.disable = (widgetSettings.disable === null || widgetSettings.disable === undefined) ? ko.observable(false) : _.isFunction(widgetSettings.disable) ? widgetSettings.disable : ko.observable(widgetSettings.disable);
            
        this.list = [];
        for (var i = min; i <= max; i++) {
            this.list.push(i);
        }
    }

    setValue(val) {
        if (this.value() === val) {
            this.value(0);
        } else {
            this.value(val);
        }
    }
    //#endregion
}