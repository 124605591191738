import { default as routerHelper } from "helpers/routerHelper";
import { default as purchaseOrderService } from "services/purchaseOrderService"
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { observable, computedFrom, autoinject } from "aurelia-framework";

function setSpecificFields(item) {
    item.WorkOrderId = this.workOrderId;
    return item;
}

@autoinject()
export class MaterialOrderEdit {

    @observable workOrderId = "";
    @observable orderId = "";
    @observable extension = "";

    @computedFrom("dispatchDate")
    get documentUrl() {
        return routerHelper.getRelativeUrl("document");
    }

    constructor() {
        this.readonly = false;
    }

    activate(params) {
        this.workOrderId= params.workOrderId;
        this.orderId = params.orderId ? params.orderId : "-1";

        if (params.q) {
            const qsParams = routerHelper.getQuerystring(params.q);
            if (qsParams) {
                this.readonly = queryStringHelper.parseReadonly(params.q);

                this.extension = qsParams.extension ? qsParams.extension : "";

                if (qsParams.dispatchDate) {
                    this.dispatchDate = qsParams.dispatchDate;
                }

                if (qsParams.projectCode) {
                    this.projectCode = qsParams.projectCode;
                }

                if (qsParams.dispatchProjectCode) {
                    this.dispatchProjectCode = qsParams.dispatchProjectCode;
                }
            }
        }

        this.actions = {
            getPurchaseOrder: purchaseOrderService.getPurchaseOrder.bind(this, this.orderId, this.extension),
            savePurchaseOrderService: purchaseOrderService.setPurchaseOrderHeaderForWorkOrder.bind(this, this.workOrderId),
            setSpecificFields: setSpecificFields
        };
    }
}