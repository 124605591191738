define([
    "knockout",
    "jquery",
    "underscore",
    "core/resx",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "helpers/dateHelper",
    "helpers/viewbag",
    "services/dailyEntryService",
    "helpers/labelHelper"
], function (ko, jQuery, _, resx, queryStringHelper, routerHelper, dateHelper, viewbag, dailyEntryService, labelHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;
            self.routerHelper = routerHelper;
            self.dateHelper = dateHelper;
            self.dispatchProjectCode = ko.observable("");
            self.dispatchDate = ko.observable("");
            self.isProjectResponsible = false;
            self.readonly = false;
            self.includeBreaks = false;
            self.includeLunch = false;
            self.list = ko.observableArray([]);

            self.manualBreaksManagementEnabled = false;

            //#endregion
        }

        //#region Private Functions
        function setViewBag() {
            viewbag({
                dispatchProjectCode: self.dispatchProjectCode(),
                dispatchDate: self.dispatchDate(),
                list: self.list()
            });
        }

        function bindViewModel(dispatchProjectCode, dispatchDate, querystring) {
            self.manualBreaksManagementEnabled = queryStringHelper.parseManualBreaksManagementEnabled(querystring);
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
            self.readonly = queryStringHelper.parseReadonly(querystring);
            var qryString = routerHelper.getQuerystring(querystring);

            self.includeLunch = qryString.lunchSelected === "true";
            self.includeBreaks = qryString.breakSelected === "true";

            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);
        }

        function getActivitiesFromData(projects) {
            let activities = [];
            _.each(projects, function (project) {
                activities = activities.concat(
                    _.map(project.TimeEntries,
                    function (timeEntry) {
                        return {
                            code: timeEntry.ActivityCode,
                            description: getDescriptionDisplay(timeEntry),
                            startTimeDisplay: dateHelper.getTime(timeEntry.StartDateTime),
                            endTimeDisplay: dateHelper.getTime(timeEntry.EndDateTime),
                            startTime: timeEntry.StartDateTime,
                            endTime: timeEntry.EndDateTime,
                            isNewLunch: timeEntry.IsNewLunch,
                            isNewBreak: timeEntry.IsNewBreak,
                            isDeleted: ko.observable(timeEntry.IsDeletedForLunch),
                            projectCode: timeEntry.ProjectCode,
                            defaultBonuses: timeEntry.DefaultBonuses,
                            breakNo: timeEntry.BreakNo,
                            breakTaken: timeEntry.BreakTaken,
                            wageType: timeEntry.WageType
                        };
                }));
            });
            return activities;
        }

        function getDescriptionDisplay(timeEntry) {
            let display = ""
            if (timeEntry.IsNewLunch) {
                display = resx.localize("Lunch");
            } else if (timeEntry.ActivityCode === "PAUSE") {
                display = resx.localize("Pause");
            } else {
                display = timeEntry.ActivityDescription;
            }

            return `${display} ${labelHelper.getBreakAndLunchLabel(timeEntry.BreakNo, timeEntry.BreakTaken, timeEntry.IsLunch)}`;
        }

        function mapDataFromApi(data) {
            return _.map(data, function (employee) {
                return {
                    dispatchId: _.first(employee.Projects).DispatchId,
                    resourceId: employee.EmployeeNumber > 0 ? employee.EmployeeNumber : employee.EquipmentCode,
                    resourceDesc: employee.EmployeeNumber > 0 ? (employee.EmployeeFirstName + " " + employee.EmployeeLastName) : employee.EquipmentDescription,
                    activities: getActivitiesFromData(employee.Projects)
                };
            });
        }

        function mapDataToPost() {
            var list = [];
            _.each(self.list(), function (employee) {
                _.each(employee.activities, function (activity) {
                    if ((activity.isNewLunch|| activity.isNewBreak) && !activity.isDeleted()) {
                        var data = {};
                        data.TimeEntryId = activity.TimeEntryId;
                        data.DispatchId = employee.dispatchId;
                        data.DispatchProjectCode = self.dispatchProjectCode();
                        data.ActivityCode = activity.code;
                        data.StartTime = dateHelper.getTime(activity.startTime);
                        data.EndTime = dateHelper.getTime(activity.endTime);
                        data.ProjectCode = activity.projectCode;
                        data.IsNewLunch = activity.isNewLunch;
                        data.IsNewBreak = activity.isNewBreak;
                        data.BreakNo = activity.breakNo;
                        data.BreakTaken = activity.breakTaken;
                        data.WageType = activity.wageType
                        data.DefaultBonuses = activity.defaultBonuses;
                        list.push(data);
                    }
                });
            });
            return list;
        }

        function loadData(dispatchProjectCode, dispatchDate) {
            if (viewbag() && viewbag().dispatchProjectCode === dispatchProjectCode && viewbag().dispatchDate === dispatchDate) {
                self.list(viewbag().list);
                return new jQuery.Deferred().resolve();
            } else {
                return dailyEntryService.getBreakTimes(dispatchProjectCode, dispatchDate, self.includeLunch, self.includeBreaks).done(function (data) {
                    self.list(mapDataFromApi(data));
                });
            }
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.manualBreaksManagementEnabled = params.manualBreaksManagementEnabled;
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
            return loadData(params.dispatchProjectCode, params.dailyEntryDate);
        };

        ctor.prototype.edit = function (id) {
            setViewBag();
            routerHelper.navigate(routerHelper.getRelativeUrl("edit", id) + routerHelper.addQuerystring({
                isProjectResponsible: self.isProjectResponsible,
                readonly: self.readonly,
                manualBreaksManagementEnabled: self.manualBreaksManagementEnabled
            }));
        };

        ctor.prototype.save = function () {
            routerHelper.showLoading();
            dailyEntryService.complete(self.dispatchProjectCode(), self.dispatchDate(), false, mapDataToPost()).done(function () {
                viewbag(null);
                routerHelper.navigateBack();
            }).always(function () {
                routerHelper.hideLoading();
            });
        };

        //#endregion
        return ctor;
    })();

    return viewModel;
});
