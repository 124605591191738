import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { SelectItem } from "models/select-item";

export interface EnumConfig {
    enumName: string;
    language?: string;
}

@autoinject()
export class EnumFormatValueConverter {
    protected readonly i18n: I18N;

    constructor(i18n: I18N) {
        this.i18n = i18n;
    }

    public toView(value: number | string | null, config: EnumConfig | string): string {
        if (!value) {
            return "";
        }
       
        const enumName = typeof config === "string" ? config : (config as EnumConfig).enumName;
        const language = typeof config === "string" ? undefined : (config as EnumConfig).language;

        const i18nKey = `Enums.${enumName}.${value}`;

        return this.i18n.tr(i18nKey, { lng: language });
    }

    public toSelectItem(value: number | string, config: EnumConfig): SelectItem<any> {
        return new SelectItem(
            value,
            (x: number | string): string => x + "",
            (x: number | string): string => this.toView(x, config));
    }
}
