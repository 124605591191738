import { ServiceQuotationContext } from "api/enums/service-quotation-context";
import { nameof as nameof_ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { nameof as nameof_ServiceCallWorkOrderQuotationStatus } from "api/enums/service-call-work-order-quotation-status";
import { autoinject, bindable, transient } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { GridBase } from "components/ag-grid/grids/grid-base";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { LocationSingleLineFormatValueConverter } from "converters/location-single-line-format";
import { ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";
import { UserGridStateService } from "services/user-grid-state-service";
import { EventAggregator } from "aurelia-event-aggregator";

@transient()
@autoinject()
export class QuotationGrid extends GridBase {
   
    @bindable
    public hideCostPrices: boolean = false;
    @bindable
    public hideSellingPrices: boolean = false;

    constructor(
        element: Element,
        i18n: I18N,
        userGridStateService: UserGridStateService,
        eventAggregator: EventAggregator,
        private readonly enumFormatValueConverter: EnumFormatValueConverter,
        private readonly locationSingleLineFormatValueConverter: LocationSingleLineFormatValueConverter
    ) {
        super(element, i18n, userGridStateService, eventAggregator);
    }

    public quotationStatusGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        return params.data.QuotationContext ===  ServiceQuotationContext.WorkOrder ? this.enumFormatValueConverter.toView(params.data.Status, nameof_ServiceCallWorkOrderQuotationStatus) :  this.enumFormatValueConverter.toView(params.data.Status, nameof_ServiceCallQuotationStatus);
    }

    public quotationTypeGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        return this.getterFormat(params.data.TypeId, params.data.TypeDescription);
    }

    public customerTypeGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        if (!params.data.CustomerTypeId) {
            return "";
        }

        return this.getterFormat(params.data.CustomerTypeId, params.data.CustomerTypeDescription);
    }

    public emergencyGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        const id = params.data.IsWorkOrder ? params.data.PriorityId : params.data.EmergencyId;
        const description = params.data.IsWorkOrder ? params.data.PriorityDescription : params.data.EmergencyDescription;

        return this.getterFormat(id, description);
    }

    public contextGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        const display = params.data.QuotationContext ===  ServiceQuotationContext.WorkOrder ? this.i18n.tr("WorkOrder") : this.i18n.tr("ServiceCall");

        return display;
    }

    public customerGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        return this.getterFormat(params.data.CustomerId, params.data.CustomerDescription);
    }

    public locationGetter: (params: any) => any = (params: any) => {
        if (!params.data || !params.data.Address) {
            return null;
        }

        return this.locationSingleLineFormatValueConverter.toView(params.data.Address);
    }

    public ownerUserGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        return this.getterFormat(params!.data!.OwnerUserId, params!.data!.OwnerUserDescription);
    }

    public modificationUserGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        return this.getterFormat(params!.data!.ModificationUserId, params!.data!.ModificationUserDescription);
    }

    public pricingTypeGetter: (params: any) => any = (params: any) => {
        if (!params.data) {
            return null;
        }

        //const pricingType: ServiceCallQuotationPricingType = this.booleanToServiceCallQuotationPricingTypeValueConverter.toView(params.data.IsLumpSum);

        return this.enumFormatValueConverter.toView(params.data.IsLumpSum, "ServiceCallQuotationPricingType");
    }

    private getterFormat(id: string | null, description: string | null): string | null {
        if (!id) { return null; }
        if (!description) { return id; }

        return `${id} - ${description}`;
    }
}
