import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";

export class ProjectMaterialRequisitionEdit {
    public actions: any = {};
    public dispatchProjectCode: string = "";
    public requisitionId: string = "";
    public isProjectResponsible: boolean = false;

    public activate(params: any): any {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.requisitionId = params.requisitionId;
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
    }

    public bind(): any {
        this.actions = {
            getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(materialRequisitionService, this.requisitionId),
            saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForProject.bind(materialRequisitionService, this.dispatchProjectCode),
            setSpecificFields: this.setSpecificFields
        };

    }

    public setSpecificFields(item: any): any {
        item.dispatchProjectCode = this.dispatchProjectCode;
        return item;
    }
}
