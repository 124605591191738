define([
    "knockout",
    "core/resx",
    "services/dispatchService",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "helpers/enumHelper",
    "knockout.validation"
], function (ko, resx, dispatch, notifier, routerHelper, enumHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.serviceType = "";
            self.dispatchId = "";
            self.validatedForm = ko.validatedObservable({
                reason: ko.observable("").extend({
                    required: {
                        params: true,
                        message: resx.localize("err_ReasonRequired")
                    }
                })
            });
            self.enumPresence = enumHelper.servicePresence();
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.serviceType = params.serviceType;
            self.dispatchId = params.dispatchId;
            self.validatedForm().reason('');
        };

        ctor.prototype.canAcceptOrRefuse = function () {
            if (!self.detail()) {
                return false;
            }
            var pres = self.detail().Presence;
            return (pres === self.enumPresence.NEW.id || pres === self.enumPresence.ACCEPTED.id || pres === self.enumPresence.DECLINED.id) && !self.detail().IsReadonly;
        };

        ctor.prototype.save = function () {
            if (self.validatedForm.isValid()) {
                routerHelper.showLoading();
                dispatch.setServiceCallRefuse(self.dispatchId, self.validatedForm().reason())
                    .done(function () {
                        routerHelper.navigateBack();
                    })
                    .always(function () {
                        routerHelper.hideLoading();
                    });
            } else {
                notifier.showValidationError(self.validatedForm.errors);
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
