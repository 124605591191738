import { PLATFORM } from "aurelia-pal";
import { Redirect } from "aurelia-router";

define([
    "underscore",
    "knockout",
    "core/resx",
    "services/attendanceService",
    "services/dispatchService",
    //"plugins/router",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "helpers/notificationHelper",
    "helpers/settingHelper",
    "helpers/enumHelper",
    "helpers/attendanceHelper"
], function (_, ko, resx, attendanceService, dispatchService, /*router,*/ dateHelper, routerHelper, queryStringHelper, notificationHelper, settingHelper, enumHelper, attendanceHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.routerHelper = routerHelper;

            self.workOrderId = ko.observable();
            self.readonly = false;

            self.teams = ko.observableArray([]);
            self.employees = ko.observableArray([]);
            self.equipments = ko.observableArray([]);

            self.selectedItemToAdd = ko.observable({});

            self.totalAttendanceCount = ko.observable(0);
            self.employeesTotalAttendanceCount = ko.observable(0);
            self.employeesPresentAttendanceCount = ko.observable(0);
            self.presentAttendanceCount = ko.observable(0);
            self.selectedFilter = "";

            PLATFORM.moduleName('pages/fieldservices/attendance_template.html');
            PLATFORM.moduleName('pages/fieldservices/attendance_templateEquipment.html');

            self.loadAvailableAttendances = {
                transport: function (params, success, failure) {
                    if (!settingHelper.hasDispatchModel()) {
                        notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
                        routerHelper.navigateToRoute("Settings");
                    } else {
                        self.selectedFilter = params.data.filterSelected;
                        attendanceService.listAvailableAttendancesForWorkorderId(self.workOrderId(),
                                getSelectedFilterToApi(params.data.filterSelected),
                                settingHelper.getSelectedDispatchModel(),
                                params.data.filter,
                                params.data.page || 1)
                            .done(success)
                            .fail(failure);
                    }
                },
                mapResults: function (item) {
                    const attendanceTypes = enumHelper.attendanceTypes();
                    switch (self.selectedFilter) {
                    case attendanceTypes.EMPLOYEE.id:
                        return {
                            id: 'emp-' + item.Id,
                            text: item.Id + ' - ' + item.FirstName + ' ' + item.LastName,
                            type: self.selectedFilter,
                            data: item
                        };
                        case attendanceTypes.TEAM.id:
                        return {
                            id: 'team-' + item.Id,
                            text: item.Id + ' - ' + item.Name,
                            data: item,
                            type: self.selectedFilter
                        };
                        case attendanceTypes.EQUIPMENT.id:
                        return {
                            id: 'equip-' + item.Code,
                            text: item.Code + ' - ' + item.Description,
                            data: item,
                            type: self.selectedFilter
                        };
                    }
                }
            };

            //#endregion
        }

        //#region Private Functions

        function getSelectedFilterToApi(id) {
            const attendanceTypes = enumHelper.attendanceTypes();
            if (id === attendanceTypes.EMPLOYEE.id){
                return "employees";
            }
            if (id === attendanceTypes.TEAM.id){
                return "teams";
            }
            if (id === attendanceTypes.EQUIPMENT.id){
                return "equipments";
            }
        }

        function bindViewModel(workOrderId, querystring) {
            self.workOrderId(workOrderId);
            self.readonly = queryStringHelper.parseReadonly(querystring);
        }

        function bindLoadedData(loadedData) {
            self.totalAttendanceCount(loadedData.length);
            var presentAttendanceCount = _.filter(loadedData, function (item) {
                return item.Presence >= enumHelper.attendance().ONSITE;
            }).length;

            loadedData = _.map(loadedData, function (item) {
                item.attendanceStatus = ko.observable(item.Presence);
                item.isInactive = ko.observable(item.IsInactive);
                return item;
            });

            var groupAndSortAttendances = attendanceHelper.groupAndSortAttendances(loadedData, true);
            var employeesLoadedData = attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(groupAndSortAttendances);

            self.teams(attendanceHelper.getTeamsFromGroupedAndSortedAttendances(groupAndSortAttendances));
            self.employees(employeesLoadedData);
            self.equipments(attendanceHelper.getEquipmentFromAttendances(loadedData));
            self.presentAttendanceCount(presentAttendanceCount);
        }

        function loadData() {
            return attendanceService.listForWorkorderId(self.workOrderId())
                .done(function(data) {
                    bindLoadedData(data);
                })
                .always(function() {
                    routerHelper.hideLoading();
                });
        }

        function genUrl(rel, additionalParameter, additionalParameter2) {
            var url = '';
            if (additionalParameter) {
                if (additionalParameter2) {
                    url = routerHelper.navigateTo(rel, self.workOrderId(), additionalParameter, additionalParameter2);
                } else {
                    url = routerHelper.navigateTo(rel, self.workOrderId(), additionalParameter);
                }
            } else {
                url = routerHelper.navigateTo(rel, self.workOrderId());
            }

            return url + routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly });
        }

        function updateAttendanceStatus(item, model, validateActivity) {
            if (validateActivity && item.HasTimeEntries) {
                notificationHelper.showConfirmation(resx.localize('msg_AbsentConfirmation'))
                    .done(function (success) {
                        if (success) {
                            updateAttendanceStatusInDatabase(item, model);
                        }
                    });
            } else {
                updateAttendanceStatusInDatabase(item, model);
            }
        }

        function updateAttendanceStatusInDatabase(item, model) {
            routerHelper.showLoading();
            dispatchService.setWorkorderAttendanceStatus(self.workOrderId(), item.DispatchId, model)
                .done(loadData)
                .always(function() {
                    item.attendanceStatus(model.Presence);
                    routerHelper.hideLoading();
                });
        }

        function saveInactive(dispatchId, inactive) {
            routerHelper.showLoading();
            dispatchService.setWorkorderInactive(self.workOrderId(), dispatchId, inactive)
                .done(function (data) {
                    //need to retreive inactive item in case of linked equipment that has been unlinked in the process...
                    var eq = _.find(self.equipments().Attendances, function (equip) {
                        return equip.DispatchId === dispatchId;
                    });
                    eq.isInactive(inactive);
                })
                .always(function () {
                    routerHelper.hideLoading();
                });
        }

        function setInactive(dispatchId, item) {
            if (item.HasTimeEntries) {
                notificationHelper.showConfirmation(resx.localize('msg_AbsentConfirmationEquipment'))
                    .done(function (success) {
                        if (success) {
                            saveInactive(dispatchId, !item.isInactive());
                        }
                    });
            } else {
                saveInactive(dispatchId, !item.isInactive());
            }
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.q);

            return loadData();
        };
        
        ctor.prototype.genTimeEntryUrl = function (route) {
            return genUrl(route);
        };

        ctor.prototype.genEmployeeUrl = function (route, employeeId, dispatchId) {
            return genUrl(route, employeeId, dispatchId);
        };


        ctor.prototype.addAttendances = function (list) {
            routerHelper.showLoading();

            var dataToPost = _.map(list, function (item) {
                const attendanceTypes = enumHelper.attendanceTypes();

                return {
                    EmployeeId: item.type === attendanceTypes.EMPLOYEE.id ? item.id.replace('emp-', '') : null,
                    EquipmentId: item.type === attendanceTypes.EQUIPMENT.id ? item.id.replace('equip-', '') : null,
                    TeamId: item.type === attendanceTypes.TEAM.id ? item.id.replace('team-', '') : null
                };
            });

            if (!settingHelper.hasDispatchModel()) {
                notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
                routerHelper.navigateToRoute("Settings");
            }

            attendanceService.addAttendanceForWorkorder(self.workOrderId(), dataToPost, settingHelper.getSelectedDispatchModel())
                .done(function (data) {
                    if (data.SaveResponse === enumHelper.attendanceAddReturnType().NONE) {
                        notificationHelper.showWarning(resx.localize('msg_AttendanceAdd_TeamNotAvailableAnymore'));
                    } else if (data.SaveResponse === enumHelper.attendanceAddReturnType().PARTIAL) {
                        notificationHelper.showWarning(resx.localize('msg_AttendanceAdd_SomeAllTeamMembersNotAvailableAnymore'),null, { timeOut :0});
                    }

                    bindLoadedData(data.List);
                }).always(routerHelper.hideLoading);
        };

        ctor.prototype.removeAttendance = function (item) {
            notificationHelper.showConfirmation(resx.localize('msg_DeleteAttendanceConfirmation'))
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        return dispatchService.delWorkorderDispatch(self.workOrderId(), item.DispatchId)
                            .done(loadData)
                            .always(routerHelper.hideLoading);
                    } else {
                        return true;
                    }
                });
        };

        ctor.prototype.unbindEquipment = function (item) {
            var dfd = new jQuery.Deferred();
            notificationHelper.showConfirmation(resx.localize('msg_UnbindEquipmentConfirmationText'))
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        dispatchService.breakEquipmentLink(item.DispatchId)
                            .done(function () {
                                loadData();
                                dfd.resolve();
                            })
                            .always(routerHelper.hideLoading);
                    } else {
                        dfd.reject();
                    }
                });
            return dfd;
        };

        ctor.prototype.updateAttendanceStatus_Absent = function (item) {
            var newStatus = item.attendanceStatus() === enumHelper.attendance().ABSENT ? enumHelper.attendance().UNDEFINED : enumHelper.attendance().ABSENT;

            if (newStatus === enumHelper.attendance().ABSENT) {
                if (item.HasTimeEntries) {
                    notificationHelper.showConfirmation(resx.localize('msg_AbsentConfirmation'))
                        .done(function(success) {
                            if (success) {
                                routerHelper.navigate(routerHelper.getRelativeUrl('absence', item.DispatchId));
                            }
                        });
                } else {
                    routerHelper.navigate(routerHelper.getRelativeUrl('absence', item.DispatchId));
                }
            } else {
                updateAttendanceStatus(item, { Presence: newStatus }, true);
            }
        };

        ctor.prototype.updateAttendanceStatus_Present = function (item) {
            var newStatus = item.attendanceStatus() >= enumHelper.attendance().ONSITE ? enumHelper.attendance().UNDEFINED : enumHelper.attendance().ONSITE;
            updateAttendanceStatus(item, { Presence: newStatus }, newStatus === enumHelper.attendance().UNDEFINED);
        };

        
        ctor.prototype.inactiveClicked = function (item, event) {
            if (item.LinkedDispatchId) {
                var dfd = self.unbindEquipment(item);
                if (dfd.done) {
                    dfd.done(function() {
                        setInactive(item.LinkedEquipment.DispatchId, item);
                    });
                }
            } else {
                setInactive(item.DispatchId, item);
            }
        };


        //#endregion
        return ctor;
    })();

    return viewModel;
});
