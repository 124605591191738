import urlHelper from "helpers/urlHelper";
import val from "core/val";
import { default as settingHelper } from "helpers/settingHelper";
import { PagingInfo } from "api/paging-info";
import { autoinject, bindable } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";
import { ValidationHelper } from "helpers/validation-helper";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { CompanyService } from "services/company-service";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";

import { CompanyModel } from "api/models/common/company/company-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";
import { InventoryProjectTransferWorkOrderLookupModel } from "api/models/company/workorder/inventory-project-transfer-work-order-lookup-model";

@autoinject()
export class InventoryProjectTransferAdd {
    public val: any = val;

    @bindable public option: any = "";
    @bindable public transfer!: InventoryProjectTransferDetailsModel | null;

    @bindable public isMultiPrefix: boolean = false;
    @bindable public isProjectLockedByWorkOrder: boolean = false;
    @bindable public isWorkOrderVisible: boolean = false;

    constructor(
        private routeRepository: RouteRepository,
        private readonly inventoryProjectTransferService: InventoryProjectTransferService,
        private readonly companyService: CompanyService,
        private readonly validationHelper: ValidationHelper,
        private readonly validationController: ValidationController
    ) {}

    public async activate(): Promise<void> {
        this.transfer = await this.inventoryProjectTransferService.NewTransfer();
        this.isWorkOrderVisible = await this.inventoryProjectTransferService.IsWorkOrdersInstalled();
        this.isMultiPrefix = settingHelper.isMultiPrefix();

        this.initValidation();
    }

    public async getSourceCompanies(): Promise<CompanyModel[] | null> {
        return await this.companyService.getAll();
    }

    public async getTargetProjects(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ProjectBaseModel[] | null> {
        return await this.inventoryProjectTransferService.GetProjects(this.transfer!.TargetPrefix!, filter, pagingInfo, requestConfig);
    }

    public async getWorkOrders(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<InventoryProjectTransferWorkOrderLookupModel[] | null> {
        return await this.inventoryProjectTransferService.GetWorkOrders(this.transfer!.TargetPrefix!, filter, pagingInfo, requestConfig);
    }

    public onWorkOrderChanged(event: any): void {
        if (!!event.detail) {
            this.transfer!.ProjectNo = event.detail.ProjectNo;
            this.transfer!.ProjectDescription = event.detail.ProjectDescription;
            this.isProjectLockedByWorkOrder = true;
        } else {
            this.isProjectLockedByWorkOrder = false;
            this.transfer!.ProjectNo = "";
            this.transfer!.ProjectDescription = "";
        }
    }

    public onTargetCompanyChanged(): void {
        this.clearProjectSelection();
        this.clearWorkOrderSelection();
    }

    public async save(): Promise<void> {
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);

        if (isValid !== true) {
            return;
        }

        await this.inventoryProjectTransferService.SaveTransfer(this.transfer!).then((result: number | null): any => {
            this.replaceBrowsingHistory();
            routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Info.name,  { transferId: result });
        });
    }

    private clearProjectSelection(): void {
        this.isProjectLockedByWorkOrder = false;
        this.transfer!.ProjectNo = "";
        this.transfer!.ProjectDescription = "";
    }

    private clearWorkOrderSelection(): void {
        this.transfer!.WorkOrderId = "";
        this.transfer!.WorkOrderDescription = "";
    }

    private initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;
        this.validateTransfer();
    }

    private validateTransfer(): void {
        ValidationRules
            .ensure((x: InventoryProjectTransferDetailsModel) => x.ProjectNo).required().withMessageKey("err_ProjectRequired")
            .on(this.transfer);
    }

    private replaceBrowsingHistory(): void {
        const baseUrl = urlHelper.getBaseUrlWithDash();
        const route =  this.routeRepository.routes.InventoryProjectTransfer.route.toString();
        window.history.replaceState("", "", baseUrl + route);
    }
}
