import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";

import { WorkOrderWorkTaskService } from "services/work-order-work-task-service";
import RouteRepository from "repositories/routeRepository";
import { WorkOrderWorkTaskDetailsModel } from "api/models/company/workorder/work-order-work-task-details-model";
import { RouteInfo } from "components/others/ma-work-task-index";

@autoinject()
export class WorkTaskEdit {

    public transactionNumber: number = 0;
    public newWorkTask!: () => Promise<WorkOrderWorkTaskDetailsModel>;
    public documentRouteInfo: RouteInfo = { routeName: "", routeParams: {}};

    constructor(private routeRepository: RouteRepository, private readonly workOrderWorkTaskService: WorkOrderWorkTaskService) {}

    public async activate(params: any): Promise<void> {

        this.transactionNumber = params.transactionNumber ? params.transactionNumber : 0;

        this.newWorkTask = (await this.workOrderWorkTaskService.GetNewWorkTaskByWorkOrderId.bind(this.workOrderWorkTaskService, params.id));
        this.documentRouteInfo.routeName = this.routeRepository.routes.FieldService_WorkTask_Documents_Add.name;
        this.documentRouteInfo.routeParams = params;
    }
}
