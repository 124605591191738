import val from "core/val";
import { autoinject, bindable } from "aurelia-framework";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { InventorySiteTransferService } from "services/inventory-site-transfer-service";
import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";

@autoinject()
export class InventorySiteTransferMemo {
    public val: any = val;

    @bindable public transfer!: InventorySiteTransferDetailsModel | null;

    constructor( private routeRepository: RouteRepository,
                 private readonly inventorySiteTransferService: InventorySiteTransferService) {}

    public async activate(params: any): Promise<void> {
        this.transfer = await this.inventorySiteTransferService.GetTransfer(params.transferId);
    }

    public async save(): Promise<void> {
        await this.inventorySiteTransferService.SaveTransfer(this.transfer!).then(() => {
            routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Info.name,  {transferId : this.transfer!.Id});
        });
    }
}
