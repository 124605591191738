define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/queryStringHelper",
    "services/contactService"
], function (ko, _, resx, queryStringHelper, contactService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.readonly = false;
            self.workOrderId = ko.observable();
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(workOrderId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);

            self.workOrderId(workOrderId);
        }

        function loadData() {
            self.actions = {
                getContacts: contactService.getProjectContactsLookupForWorkOrder.bind(contactService, self.workOrderId())
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.workOrderId, params.q);
            return loadData();
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
