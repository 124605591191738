import { transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

import { default as dateHelper } from "helpers/dateHelper";
import { default as labelHelper } from "helpers/labelHelper";

function loadWidgetSettings(self, widgetSettings) {
    self.date = widgetSettings.date;
    self.selectedDays = widgetSettings.selectedDays;
    self.selectedDays([]);
    self.weekDaysSelected = false;
    self.disabled = widgetSettings.disabled;
    self.editionDate = widgetSettings.editionDay;
    self.editionDay = getEditionDay(self.editionDate);
    if (self.disabled) {
        self.selectedDays.push(self.editionDay || 7);
    }
}

function getEditionDay(editionDate) {
    var date = dateHelper.dateFromUTC(editionDate) || dateHelper.getDate();
    return date.getDay();
}

function loadWeekDayTypes(self) {
    var Day = function (id) {
        var dSelf = this;
        dSelf.id = id;
        dSelf.checkedClass = ko.computed(function () {
            return self.isFilled(id);
        }, dSelf);
        dSelf.click = function () {
            self.weekdayClicked(id);
        };
    };

    self.weekDayTypes(_.map(_.range(1, 9),
        function (i) {
            return new Day(i);
        }));
}

@transient()
export class MultiSelectDays {
    constructor() {
        this.resx = resx;
        this.dateHelper = dateHelper;
        this.labelHelper = labelHelper;
        this.weekDayTypes = ko.observableArray([]);
        this.selectedDays = ko.observableArray([]);
        this.weekDaysSelected = false;
        this.disabled = false;
        this.editionDay = 0;
        this.editionDate = "";

        this.weekdayClicked = this.weekdayClicked.bind(this);
    }

    activate(widgetSettings) {
        loadWidgetSettings(this, widgetSettings);
        loadWeekDayTypes(this);
        return true;
    }

    isFilled(id) {
        return _.contains(this.selectedDays(), id);
    }

    weekdayClicked(id) {
        //id = 8 stands for button Mon-Fri to select all weekDays
        if (id === 8) {
            if (this.weekDaysSelected) {
                _.map(this.selectedDays(), (id2) => {
                    this.weekDaysSelected = false;
                    this.selectedDays.pop(id2);
                });
            } else {
                this.weekDaysSelected = true;
                this.selectedDays().length = 0;
                this.selectedDays.push(1, 2, 3, 4, 5);
            }
        } else if (_.contains(this.selectedDays(), id)) {
            this.weekDaysSelected = false;
            this.selectedDays.remove(id);
        } else {
            this.weekDaysSelected = false;
            this.selectedDays.push(id);
        }
    }
}