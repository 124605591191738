import { EventAggregator } from 'aurelia-event-aggregator';
import { inject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";

import resx from "core/resx";


@inject(resx, DialogController, EventAggregator)
export default class DialogWithOptions {
    constructor(resx, dialogController, eventAggregator) {
        this.resx = resx;
        this.dialogController = dialogController;
        this.eventAggregator = eventAggregator;

        this.popupTitle = ko.observable(null);
        this.popupMessage = ko.observable("");
        this.optionsList = ko.observableArray([]);
        this.isErrorBox = ko.observable(false);
        this.selectedOption = ko.observable(null);
        this.singleButton = ko.observable(false);
        this.okLabel = ko.observable("");
        this.cancelLabel = ko.observable("");
    }

    activate(options) {
        this.popupMessage(options.message || "");
        this.popupTitle(options.title || null);
        this.optionsList(options.optionsList);
        this.isErrorBox(options.isErrorBox || false);
        this.singleButton(options.singleButton || false);
        this.selectedValue = ko.isObservable(options.selectedValue) ? options.selectedValue : ko.observable(options.selectedValue);

        this.okLabel(options.okLabel || this.resx.localize("OK"));
        this.cancelLabel(options.cancelLabel || this.resx.localize("Cancel"));

        this.eventAggregator.publish("dialogs:openeddialogs:add", this);
    }

    cancelAction() {
        this.dialogController.cancel(null);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    okAction() {
        this.dialogController.cancel(this.selectedValue());
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }
}

