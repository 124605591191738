import { PLATFORM } from "aurelia-pal";
define([
    "knockout",
    "underscore",
    "jquery",
    "helpers/labelHelper",
    "helpers/routerHelper",
    "helpers/documentHelper",
    "helpers/dateHelper",
    "repositories/settingRepository",
    "services/defaultService",
    "services/employeeService",
    "services/documentService",
    "core/resx",
    "select2"
], function (ko, _, jQuery, labelHelper, routerHelper, documentHelper, dateHelper, settings, defaultValues, employeeService, documentService, resx) {
    "use strict";



    var widget = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            PLATFORM.moduleName('pages/templates/skillCertificate_FilterByName.html');
            PLATFORM.moduleName('pages/templates/skillCertificate_FilterByCertificate.html');
            PLATFORM.moduleName('pages/templates/skillCertificate_FilterBySkill.html');
            self.resx = resx;

            self.FilterByNameID = "name";
            self.FilterByCertificateID = "certificate";
            self.FilterBySkillID = "skill";
            self.DEFAULT_FILTER = self.FilterByNameID;

            self.actions = {};
            self.partialView = ko.observable('');
            self.module = '';

            self.filterBy = ko.observable(self.DEFAULT_FILTER);
            self.filtersList = getFilterList();

            self.data = ko.observableArray();

            self.currentSectionCount = ko.observable();
            self.sectionsByCertificateHasDocument = false;
            self.sectionsByNameHasDocument = false;
            self.sectionsBySkill = null;
            self.sectionsByName = null;
            self.sectionsByCertificate = null;
            //#endregion
        }

        //#region Private Functions
        function getFilterList() {
            return ko.observable([{
                id: self.FilterByNameID,
                text: resx.localize("SortByName")
            }, {
                id: self.FilterByCertificateID,
                text: resx.localize("SortByCertificate")
            }, {
                id: self.FilterBySkillID,
                text: resx.localize("SortBySkill")
            }]);
        }

        function getEmployeeName(emp) {
            return emp.FirstName + " " + emp.LastName;
        }

        function getDownloadUrl(documentId) {
            var documentUrl = documentHelper.getDocumentUrlById(documentId);
            documentUrl += "?access_token=" + settings.getToken();
            return documentUrl;
        }

        function getDocumentIcon(type) {
            return documentHelper.getIconClass(type);
        }

        function getDocumentSize(size) {
            return documentHelper.formatDocumentSize(size);
        }

        function getDocumentType(type) {
            return resx.localize(documentHelper.getLocalizationKey(type));
        }

        function initDropDownLists() {
            jQuery("#ddlFilter")
                .select2({
                    minimumResultsForSearch: defaultValues.getMinimumResultsForSearch(),
                    allowClear: false,
                    placeholder: resx.localize("SelectOoo"),
                    language: labelHelper.getDefaultSelect2Labels(),
                    width: "100%",
                    data: self.filtersList()
                })
            .on("change", onFilterByChanged)
            .val(self.filterBy())
            .trigger("change");
        }

        function loadData() {
            return self.actions.getEmployeesForDispatch().done(function (data) {
                self.data(data);
                initializeSections(self.filterBy());
            });
        }

        function initializeSections(filterBy) {
            switch (filterBy) {
                case self.FilterByNameID:
                    if (self.sectionsByName === null) {
                        initializeSectionsByName(self.data());
                    }
                    self.currentSectionCount(self.sectionsByName.length);
                    break;
                case self.FilterByCertificateID:
                    if (self.sectionsByCertificate === null) {
                        initializeSectionsByCertificate(self.data());
                    }
                    self.currentSectionCount(self.sectionsByCertificate.length);
                    break;
                case self.FilterBySkillID:
                    if (self.sectionsBySkill === null) {
                        initializeSectionsBySkills(self.data());
                    }
                    self.currentSectionCount(self.sectionsBySkill.length);
                    break;
            }
        }

        function initializeSectionsByName(data) {
            var tempSections = [];

            if (!Array.isArray(data)) {
                return;
            }

            _.each(data,
                    function (employee) {
                        var skills = [];
                        var certificates = [];

                        _.each(_.sortBy(employee.Skills, function (x) { return x.Description.toLowerCase(); }), function (skill) {
                            skills.push(formatItemByName(skill, true));
                        });

                        _.each(_.sortBy(employee.Certifications, function (x) { return x.Description.toLowerCase(); }), function (certificate) {
                            certificates.push(formatItemByName(certificate, false));
                        });

                        if (skills.length > 0 || certificates.length > 0) {
                            tempSections.push({
                                name: getEmployeeName(employee),
                                link: self.module === 'project' ? self.actions.getEmployeeUrl(employee) : 'javascript:void(0)',
                                hasLink: self.module === 'project',
                                skills: skills,
                                certificates: certificates
                            });
                        }
                    });

            self.sectionsByName = _.sortBy(tempSections, function (x) { return x.name.toLowerCase(); });
        }

        function getDocument(item, isSkill) {
            return item.DocumentsInfo && item.DocumentsInfo.length > 1 ?
                {
                    name: resx.localize("Documents"),
                    size: '',
                    icon: getDocumentIcon('FOLDER'),
                    type: '',
                    url: (self.actions.addDispatchIdToUrl ? routerHelper.getRelativeUrl(item.DispatchId + "", item.CertificationAssignmentId + "") : routerHelper.getRelativeUrl(item.CertificationAssignmentId + ""))
                        + (self.actions.queryStringToAppend?self.actions.queryStringToAppend:"")
                }
                :
                {
                    id: item.DocumentsInfo && item.DocumentsInfo.length ? item.DocumentsInfo[0].DocumentId : '',
                    name: item.DocumentsInfo && item.DocumentsInfo.length ? item.DocumentsInfo[0].FileName : '',
                    size: item.DocumentsInfo && item.DocumentsInfo.length ? getDocumentSize(item.DocumentsInfo[0].FileSize) : '',
                    icon: item.DocumentsInfo && item.DocumentsInfo.length ? getDocumentIcon(item.DocumentsInfo[0].Type) : isSkill ? 'fa fa-cogs' : 'fa fa-certificate',
                    type: item.DocumentsInfo && item.DocumentsInfo.length ? getDocumentType(item.DocumentsInfo[0].Type) :  '',
                    url: item.DocumentsInfo && item.DocumentsInfo.length ? getDownloadUrl(item.DocumentsInfo[0].DocumentId) : 'javascript:void(0)'
                };
        }

        function formatItemByName(item, isSkill) {
            if (item.DocumentsInfo) {
                self.sectionsByNameHasDocument = true;
            }
            

            return {
                name: item.Description,
                link: item.DocumentsInfo ? getDownloadUrl(item.DocumentsInfo.DocumentId) : '',
                expirationDate: item.ExpirationDate == null ? "" : (resx.localize('Expiration:') + dateHelper.getTextDate(item.ExpirationDate)),
                isExpired: item.ExpirationDate == null ? false : dateHelper.isBeforeToday(item.ExpirationDate),
                document: getDocument(item, isSkill)
            };
        }

        function initializeSectionsByCertificate(data) {
            var tempSections = [];

            if (!Array.isArray(data)) {
                return;
            }

            _.each(data, function (emp) {
                _.each(emp.Certifications, function (cert) {
                    if (cert.DocumentsInfo) {
                        self.sectionsByCertificateHasDocument = true;
                    }

                    var currentSection = _.find(tempSections,
                        function (s) {
                            return s.name === cert.Description;
                        });

                    if (currentSection === undefined || currentSection === null) {
                        currentSection = {
                            name: cert.Description,
                            items: []
                        };
                        tempSections.push(currentSection);
                    }

                    currentSection.items.push({
                        name: getEmployeeName(emp),
                        link: self.module === 'project' ? self.actions.getEmployeeUrl(emp) : 'javascript:void(0)',
                        expirationDate: cert.ExpirationDate == null ? "" : (resx.localize('Expiration:') + dateHelper.getTextDate(cert.ExpirationDate)),                        
                        isExpired: cert.ExpirationDate == null ? false : dateHelper.isBeforeToday(cert.ExpirationDate),
                        hasLink: true,
                        document: getDocument(cert)
                    });
                });
            });

            _.each(tempSections, function (section) {
                section.items = _.sortBy(section.items, function (x) { return x.name.toLowerCase(); });
            });

            self.sectionsByCertificate = _.sortBy(tempSections, function (x) { return x.name.toLowerCase(); });
        }

        function initializeSectionsBySkills(data) {
            var tempSections = [];

            if (!Array.isArray(data)) {
                return;
            }

            _.each(data, function (emp) {
                _.each(emp.Skills, function (skill) {
                    var currentSection = _.find(tempSections,
                        function (section) {
                            return section.sectionName === skill.Description;
                        });

                    if (currentSection === undefined || currentSection === null) {
                        currentSection = {
                            sectionName: skill.Description,
                            items: []
                        };
                        tempSections.push(currentSection);
                    }

                    currentSection.items.push({
                        employeeName: getEmployeeName(emp),
                        employeeLink: self.module === 'project' ? self.actions.getEmployeeUrl(emp) : 'javascript:void(0)',
                    });
                });
            });

            _.each(tempSections, function (section) {
                section.items = _.sortBy(section.items, function (a) { return a.employeeName.toLowerCase(); });
            });

            self.sectionsBySkill = _.sortBy(tempSections, function (x) { return x.sectionName.toLowerCase(); });
        }

        function onFilterByChanged(e) {
            initializeSections(e.target.value);
            self.filterBy(e.target.value);
            self.partialView('views/templates/skillCertificate_FilterBy' + self.filterBy().charAt(0).toUpperCase() + self.filterBy().slice(1));
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (widgetSettings) {
            self.actions = widgetSettings.actions;

            self.isProjectResponsible = widgetSettings.isProjectResponsible || false;
            self.readonly = widgetSettings.isProjectResponsible || false;
            self.module = widgetSettings.module;

            self.sectionsByName = null;
            self.sectionsByCertificate = null;
            self.sectionsBySkill = null;

            return loadData();
        };

        ctor.prototype.compositionComplete = function () {
            initDropDownLists();
        };

        ctor.prototype.navigateToEmployee = function (item) {
            if (item.link !== '') {
                routerHelper.navigate(item.link);
            }
        };
       

        ctor.prototype.checkAndOpenDocument = function (item) {
            documentHelper.openDocument(item.document);
        };
        //#endregion

        return ctor;
    })();

    return widget;
});
