import { EventAggregator } from 'aurelia-event-aggregator';
import { inject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";

import resx from "core/resx";


@inject(resx, DialogController, EventAggregator)
export default class ProjectBreakTimesSelection {
    constructor(resx, dialogController, eventAggregator) {
        this.resx = resx;
        this.dialogController = dialogController;
        this.eventAggregator = eventAggregator;

        this.popupTitle = ko.observable(null);
        this.popupMessage = ko.observable("");
        this.yesLabel = ko.observable("");
        this.noLabel = ko.observable("");
        this.singleButton = ko.observable(false);
        this.isErrorBox = ko.observable(false);

        this.lunchVisible = ko.observable(false);
        this.breakVisible = ko.observable(false);
    }

    activate(options) {
        this.popupMessage(options.message || "");
        this.popupTitle(options.title || null);
        this.yesLabel(options.yesLabel ||this.resx.localize('OK'));
        this.noLabel(options.noLabel || this.resx.localize('Cancel'));
        this.singleButton(options.singleButton || false);
        this.isErrorBox(options.isErrorBox || false);
        this.lunchSelected = options.lunchSelected;
        this.breakSelected = options.breakSelected;
        this.lunchVisible(options.lunchVisible);
        this.breakVisible(options.breakVisible);

        this.eventAggregator.publish("dialogs:openeddialogs:add", this);
    }

    noAction() {
        this.dialogController.cancel(false);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }

    yesAction() {
        this.dialogController.ok(true);
        this.eventAggregator.publish("dialogs:openeddialogs:remove", this);
    }
}
