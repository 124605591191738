import { ProjectModel } from "api/models/company/project/project-model";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import phoneHelper from "helpers/phoneHelper";
import { RouterHelper } from "helpers/router-helper";
import routerHelper from "helpers/routerHelper";
import commonService from "services/commonService";
import projectService from "services/projectService";
import userSettingService from "services/userSettingsService";

@autoinject()
export default class ProjectIndex {
    public onlyActiveDispatch: boolean = false;
    public list: ProjectModel[] = [];
    public hasAtLeastOneResponsibleProject: boolean = false;
    public viewbag: any;

    constructor(private readonly i18n: I18N) {
    }

    public async loadData(): Promise<any> {
        commonService.refreshProjectConfigs();

        const usersetting = await userSettingService.getOnlyActiveDispatchSetting();
        if (usersetting) {
            this.onlyActiveDispatch = usersetting.Value === "true";
        }

        this.list = await projectService.getProjectList();
        this.hasAtLeastOneResponsibleProject = await projectService.isCurrentUserProjectResponsible();
    }
    //#endregion

    //#region Public Functions
    public activate(): any {
        this.loadData();
    }

    public genUrl(isProjectResponsible: boolean, id: string): string {
        const url = routerHelper.getRelativeUrl("", id);
        return url + routerHelper.addQuerystring({ isProjectResponsible: isProjectResponsible});
    }

    public goToHoursSummary(): void {
        this.viewbag = {isHourSummaryToday: true};
        let url = routerHelper.getRelativeUrl("hoursSummary");
        url += routerHelper.addQuerystring({ isProjectResponsible: this.hasAtLeastOneResponsibleProject });

        routerHelper.navigate(url);
    }

    public async setOnlyActiveDispatchesSetting(): Promise<void> {
        this.onlyActiveDispatch = !this.onlyActiveDispatch;
        await userSettingService.setOnlyActiveDispatchSetting(this.onlyActiveDispatch);
        await this.loadData();
    }

}
