import { default as signPad } from "signature_pad"

define([
    "jquery",
    "knockout",
    "core/resx",
    "services/serviceService",
    "helpers/viewbag",
    "services/dailyEntryService",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "knockout.validation",
    "select2",
    "widgets/pages/signature"
], function (jQuery, ko, resx, service, viewbag, dailyEntryService, hlpDate, notifier, routerHelper) {
    "use strict";

    var _emptyCanvas = true;

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.callId = '';
            self.resx = resx;
            self.dispatchDate = ko.observable();
            self.inputBag = ko.observable({});
            self.signature = ko.observable({});
            self.dispatchProjectCode = ko.observable();
            self.signaturePad = undefined;
            self.viewbagValid = ko.observable(false);
            self.validateInput = function() {
                if (viewbag()) {
                    //valid
                    self.inputBag(viewbag());
                    self.viewbagValid(true);
                } else {
                    //invalid
                    self.inputBag({});
                    self.viewbagValid(false);
                }

                viewbag(null);
            };
            self.actions = {};
            self.txtViewbagError = resx.localize('err_SignatureInfoForDispatchRequired');
            //#endregion
        }
        //#region Private Functions

        function bindViewModel(dispatchProjectCode, dispatchDate) {
            self.dispatchProjectCode = dispatchProjectCode;
            self.dispatchDate = dispatchDate;
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate);
            self.validateInput();
            if (!self.viewbagValid()) {
                routerHelper.navigateBack();
                notifier.showError(self.txtViewbagError);
                return false;
            }
            
            self.actions = {
                printForm: dailyEntryService.printform.bind(null, params.dispatchProjectCode, params.dailyEntryDate, self.inputBag().email),
                close: dailyEntryService.setSignature.bind(null, params.dispatchProjectCode, params.dailyEntryDate, self.signature()),
                sign: function (signature) {
                    self.signature().Comment = signature.Comment;
                    self.signature().FileData = signature.FileData;
                }
            };
            return true;
        };

        //#endregion

        return ctor;
    })();

    return viewModel;
});
