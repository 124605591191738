define([
    "jquery",
    "knockout",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "services/documentService",
    "repositories/settingRepository"
], function (jQuery, ko, routerHelper, queryStringHelper, documentService, settingRepository) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.actions = {};
            self.readonly = false;
            self.editId = ko.observable();
            self.workOrderId = ko.observable();
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.readonly = queryStringHelper.parseReadonly(params.q);
            self.editId(params.recommendationId);
            self.workOrderId(params.workOrderId);

            var dispatchTemplateId = settingRepository.getDispatchTemplate();

            self.actions = {
                save: documentService.saveRecommendationPicture.bind(self, params.recommendationId, 'workorder', params.workOrderId, dispatchTemplateId)
            };
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
