import { default as signPad } from "signature_pad"

define([
    "jquery",
    "knockout",
    "core/resx",
    "services/serviceService",
    "helpers/viewbag",
    "services/dispatchService",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "knockout.validation",
    "select2"
], function (jQuery, ko, resx, service, viewbag, dispatch, hlpDate, notifier, routerHelper) {
    "use strict";

    var _emptyCanvas = true;

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.callId = '';
            self.resx = resx;
            self.dispatchId = '';
            self.inputBag = ko.observable({});
            self.serviceType = '';
            self.signaturePad = undefined;
            self.viewbagValid = ko.observable(false);
            self.validateInput = function() {
                if (viewbag() !== undefined &&
                    viewbag().DispatchId !== undefined &&
                    viewbag().ServiceType !== undefined &&
                    viewbag().Date !== undefined &&
                    viewbag().Comment !== undefined &&
                    viewbag().CustomStatus !== undefined &&
                    viewbag().Hour !== undefined &&
                    viewbag().IsComplete !== undefined &&
                    viewbag().IsTechWillReturn !== undefined &&
                    viewbag().Length !== undefined &&
                    viewbag().Workorder !== undefined &&
                    viewbag().EndDate !== undefined &&
                    viewbag().IsClosed !== undefined &&
                    viewbag().txtSignature !== undefined &&
                    viewbag().email !== undefined &&
                    viewbag().DispatchId === self.dispatchId &&
                    viewbag().ServiceType === self.serviceType) {
                    //valid
                    self.inputBag(viewbag());
                    self.viewbagValid(true);
                } else {
                    //invalid
                    self.inputBag({});
                    self.viewbagValid(false);
                }

                viewbag(null);
            };
            self.actions = {};
            self.txtViewbagError = resx.localize('err_SignatureInfoForDispatchRequired');
            //#endregion
        }

        function bindViewModel(serviceType, dispatchId) {
            self.serviceType = serviceType;
            self.dispatchId = dispatchId;
        }

        ctor.prototype.activate = function (params) {
            bindViewModel(params.serviceType, params.dispatchId);
            self.validateInput();
            if (!self.viewbagValid()) {
                routerHelper.navigateBack();
                notifier.showError(resx.localize('err_SignatureInfoForDispatchRequired'));
                return false;
            }

            self.actions = {
                printForm: dispatch.printform.bind(null, self.dispatchId, self.inputBag().email),
                close: dispatch.setServiceCallFinish.bind(dispatch, self.dispatchId, hlpDate.formatDateToSend(self.inputBag().EndDate), self.inputBag()),
                sign: function (signature) {
                    signature.Email = self.inputBag().email;
                    self.inputBag().Signature = signature;
                }
            };
            return true;
        };

        return ctor;
    })();

    return viewModel;
});
