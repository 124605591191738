import { bindable, PLATFORM } from "aurelia-framework";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import labelHelper from "helpers/labelHelper";

export class HoursSummary {

    public readonly singleProject: any = PLATFORM.moduleName("pages/templates/hoursSummary_SingleProject.html");
    public readonly multipleProject: any = PLATFORM.moduleName("pages/templates/hoursSummary_MultipleProject.html");
    public readonly projectSection: any = PLATFORM.moduleName("pages/templates/hoursSummary_ProjectSection.html");

    @bindable public selectedDate!: Date;
    @bindable public list: any;
    @bindable public isSingle: boolean = false;

    public dateHelper: typeof dateHelper = dateHelper;
    public labelHelper: typeof labelHelper = labelHelper;

    public genEmployeeDispatchUrl(dispatchProjectCode: any, dispatchId: any, dispatchDate: any, readonly: boolean): string {
        return routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntryEmployee", dispatchProjectCode, dateHelper.dateToNavigationParameter(dispatchDate), dispatchId) + routerHelper.addQuerystring({ isProjectResponsible: true, readonly: readonly });
    }
}
