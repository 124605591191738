import { JobModelStatus } from "api/enums/job-model-status";
import { ProcoreJobStatus } from "api/models/procore/job_-system/procore-job-status";
import { ProcoreJobProxy } from "api/proxies/procore-job-proxy";
import { autoinject } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";
import { BindingSignaler } from "aurelia-templating-resources";
import { PollingSystem } from "components/polling-system/polling-system";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ProcoreGridItemTransformer } from "services/procore/helpers/procore-grid-item-transformer";
import { ProcoreGridItem } from "../grid/models/procore-grid-item";
import { SideBar } from "../side-bar/side-bar";
import { ProcorePollingTransactions } from "./transformers/procore-polling-transations-transformer";

@autoinject
export class ProcorePollingSystem extends PollingSystem {
    public gridItems: ProcoreGridItem[] | null = [];
    public sidebar?: SideBar;

    private language: string | null;

    constructor(private readonly proxy: ProcoreJobProxy,
                private readonly signaler: BindingSignaler,
                private readonly i18n: I18N,
                private readonly procoreGridItemTransformer: ProcoreGridItemTransformer,
                private readonly procorePollingTransactions: ProcorePollingTransactions) {
        super();

        this.language = localStorage.getItem("maestro_user_profile_LANGUAGE");

        this.action = async (): Promise<void> => {
            if (!this.gridItems) {
                return;
            }

            const flatList = this.getFlatList(this.gridItems);
            const jobIds = this.getJobIds();
            const statuses = await this.getJobStatuses(jobIds);

            this.updateStatusesOfGridItems(flatList, statuses);

            this.signaler.signal("procore-polling");
        };
    }

    //#region privates

    private getJobIds(): number[] {
        const jobIds: number[] = [];

        if (!this.gridItems) {
            return jobIds;
        }

        const runningStatuses = [null,
                                 JobModelStatus.Waiting,
                                 JobModelStatus.Running,
                                 JobModelStatus.Delayed];

        const hasJobIdAndRunning = (jobId?: number | null, jobStatus?: JobModelStatus | null): boolean => !!jobId && (jobStatus == null || runningStatuses.includes(jobStatus));

        const flatList = this.getFlatList(this.gridItems);

        flatList.forEach((item: ProcoreGridItem) => {
            if (hasJobIdAndRunning(item.JobId, item.JobStatus)) {
                jobIds.push(item.JobId!);
            }
        });

        return jobIds;
    }

    private getFlatList(items: ProcoreGridItem[], ignoreDetailsItems: boolean = true): ProcoreGridItem[] {
        const flatList: ProcoreGridItem[] = [];

        if (!items || items.length <= 0) { return []; }

        items.forEach((item: ProcoreGridItem) => {

          if (!ignoreDetailsItems || !item.IsDetails) {
            flatList.push(item);
          }

          const children = this.getFlatList(item.Children);

          children.forEach((child: ProcoreGridItem) => {
            child.Parent = flatList.find((i: ProcoreGridItem) => i.Code === child.ParentCode);
            flatList.push(child);
          });
        });

        return flatList;
    }

    private async getJobStatuses(jobIds: number[]): Promise<ProcoreJobStatus[]> {
        if (jobIds.length <= 0) {
            return [];
        }

        const requestParameters = { showLoadingScreen: false } as HttpClientRequestConfig;

        const statuses = await this.proxy.GetJobStatuses(jobIds.toString(), undefined, undefined, requestParameters);

        if (statuses) {
            return statuses;
        }

        return [];
    }

    private updateStatusesOfGridItems(items: ProcoreGridItem[], statuses: ProcoreJobStatus[]): void {
        const finishedStatuses = [JobModelStatus.Success,
                                  JobModelStatus.Error,
                                  JobModelStatus.CriticalError];

        if (statuses) {
            statuses.forEach((jobStatus: ProcoreJobStatus): void => {
                if (jobStatus) {
                    const item = items.find((i: ProcoreGridItem): boolean => i.JobId === jobStatus.JobId);
                    
                    if (item) {
                        item.JobStatus = jobStatus.Status;
                        item.ProgressBarCurrent = jobStatus.ProgressBarCurrent;
                        item.ProgressBarMax = jobStatus.ProgressBarMax;

                        if (item.JobStatus !== null && item.JobStatus !== undefined && finishedStatuses.includes(item.JobStatus)) {
                            item.IsSynchronizing = false;
                            item.Warnings = this.procoreGridItemTransformer.transformErrors(jobStatus.Warnings, this.language);

                            switch (item.JobStatus) {
                                case JobModelStatus.CriticalError:
                                case JobModelStatus.Error:
                                    item.Errors = this.procoreGridItemTransformer.transformErrors(jobStatus.Errors, this.language);
                                    break;

                                case JobModelStatus.Success:
                                    item.IsSynchronized = true;

                                    if (jobStatus.ServiceType === "Transaction-loading" &&
                                        jobStatus.ResultPayload) {
                                        const transactions = JSON.parse(jobStatus.ResultPayload);

                                        item.Details = this.procorePollingTransactions.transformTransactions(item, transactions);
                                        item.DetailsIsLoading = false;
                                    }
                                    
                                    break;
                            }
                        }

                        if (!item.SynchronizationTried) {
                            item.Warnings = [];
                        }

                        let syncDate;

                        if (jobStatus.ServiceType !== "Transaction-loading" && jobStatus.Status === JobModelStatus.Success || jobStatus.Status === JobModelStatus.Error || jobStatus.Status === JobModelStatus.CriticalError) {
                            syncDate = jobStatus.LastUpdatedDate;

                        }

                        let count = 0;

                        if (jobStatus.ResultPayload && jobStatus.Status === JobModelStatus.Success) {
                             const payload = JSON.parse(jobStatus.ResultPayload);
                             count = payload.EntitiesSynchronizedCount;

                        }
                        
                        item.Message = this.procoreGridItemTransformer.getMessageFromPollingSystem(item.Errors,
                                                                                                   item.Warnings,
                                                                                                   this.sidebar,
                                                                                                   syncDate,
                                                                                                   count);
                    }
                }
            });
        }
    }

    //#endregion
}
