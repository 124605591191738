import { default as documentService } from "services/documentService";
import employeeService from "services/employeeService";
import { SettingRepository } from "repositories/setting-repository";
import { autoinject } from "aurelia-framework";

@autoinject
export class EmployeeProfilePicture {
    public dispatchId: string | null = "";
    public employeeId: any = "";
    public readonly: boolean = false;
    public actions: any;

    public dispatchProjectCode: any;
    public dispatchDate: any;
    public workOrderId: any;

    constructor(private readonly settingRepository: SettingRepository) {
    }

    public async activate(params: any): Promise<any> {
        this.dispatchId = params.dispatchId ? params.dispatchId : this.settingRepository.getDispatchTemplate();
        this.employeeId = params.employeeId ? params.employeeId : await employeeService.getCurrentEmployeeId();

        this.dispatchProjectCode = params.dispatchProjectCode ? params.dispatchProjectCode : null;
        this.dispatchDate = params.dispatchDate ? params.dispatchDate : null;
        this.workOrderId = params.workOrderId ? params.workOrderId : null;

        this.actions = {
            save: documentService.saveEmployeePicture.bind(self, this.employeeId)
        };
    }
}
