define([
    "knockout",
    "underscore",
    "jquery",
    "helpers/labelHelper",
    "helpers/routerHelper",
    "helpers/documentHelper",
    "repositories/settingRepository",
    "services/defaultService",
    "services/employeeService",
    "services/documentService",
    "core/resx",
    "select2"
], function (ko, _, jQuery, labelHelper, routerHelper, documentHelper, settings, defaultValues, employeeService, documentService, resx) {
    "use strict";



    var viewModel = (function () {
        var self = null;


        function ctor() {
            self = this;

            //#region Properties
            self.dispatchProjectCode = ko.observable();
            self.dispatchDate = ko.observable();
            self.isProjectResponsible = false;
            self.readyonly = true;
            //#endregion
        }

        //#region Private Functions
        function getEmployeeUrl(emp) {
            return '#project/' +
                encodeURIComponent(self.dispatchProjectCode()) +
                '/dailyEntry/' +
                self.dispatchDate() +
                '/employee/' +
                emp.Id +
                '/' +
                emp.DispatchId +
                routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly });
        }

        function loadData() {
            self.actions = {
                getEmployeesForDispatch: employeeService.getEmployeesForDispatchForProject.bind(null, self.dispatchProjectCode(), self.dispatchDate()),
                getEmployeeUrl: getEmployeeUrl,
                addDispatchIdToUrl: true,
                queryStringToAppend: routerHelper.addQuerystring({
                    isProjectResponsible: self.isProjectResponsible,
                    readonly: self.readonly
                })
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.dispatchProjectCode(params.dispatchProjectCode);
            self.dispatchDate(params.dailyEntryDate);

            var paramsQueryString = routerHelper.getQuerystring(params.q);
            self.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
            self.readonly = (paramsQueryString && paramsQueryString.readonly === "true") || false;

            return loadData();
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
