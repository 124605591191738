import { singleton } from "aurelia-framework";

import { default as contactService } from "services/contactService";
import { default as dispatchService } from "services/dispatchService";
import { default as recommendationService } from "services/recommendationService";
import { default as serviceService } from "services/serviceService";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";


@singleton()
export class RecommendationEdit {
    dispatchId = "";
    entityId;
    serviceType = "";
    recommendationId = "";
    equipmentId = "";
    readonly = false;

    fetchDefaultCustomer;
    fetchDefaultContact;
    fetchEquipments;
    fetchEquipmentDetails;
    saveRecommendation;

    constructor() {
    }

    activate = function (params) {
        this.bindViewModel(params.serviceType, params.dispatchId, params.recommendationId, params.q);
    };

    bindViewModel(serviceType, dispatchId, recommendationId, querystring) {
        var params = routerHelper.getQuerystring(querystring);
        this.entityId = params.entityId;
        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.recommendationId = recommendationId;
        this.equipmentId = params.equipmentId;

        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }

        this.fetchDefaultCustomer = dispatchService.getClientLookupForDispatch.bind(null, dispatchId);
        this.fetchDefaultContact = contactService.getContactLookupForDispatch.bind(null, dispatchId);
        this.fetchEquipments = serviceService.getEquipments.bind(null, serviceType, dispatchId);
        this.fetchEquipmentDetails = serviceService.getEquipmentDetails.bind(null, serviceType, dispatchId);
        this.saveRecommendation = recommendationService.addRecommendationForDispatch.bind(null, dispatchId);
    }
}

