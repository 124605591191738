import { transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";


import * as enumHelper from "helpers/enumHelper";
import * as dateHelper from "helpers/dateHelper";
import * as labelHelper from "helpers/labelHelper";


function loadData(self, items) {
    self.labors = mapForType(items, enumHelper.timesheetEntryType.LABOR);
    self.equipments = mapForType(items, enumHelper.timesheetEntryType.EQUIPMENT);
    self.primes = mapForType(items, enumHelper.timesheetEntryType.BONUS);
}

function loadWidgetSettings(self, widgetSettings) {
    loadData(self, widgetSettings.items);
}

function mapForType(items, timesheetEntryType) {
    var type = _.find(items, function (i) { return i.Key === timesheetEntryType; });

    if (type) {
        type.Items = _.map(type.Items, mapDayOfWeek);
        type.hours = dateHelper.hoursTohmm(type.TotalHours);
    }

    return type;
}

function mapDayOfWeek(item) {
    item.title = dateHelper.getDayOfWeek(item.Key);
    item.Items = _.map(item.Items, mapProj);
    return item;
}

function mapProj(item) {
    item.title = item.Key.Id + " - " + item.Key.Description;
    item.Items = _.map(item.Items, mapTimeEntry);
    return item;
}

function mapTimeEntry(item) {
    item.activity = item.EmployeeActivity + " - " + item.EmployeeActivityDescription;
    item.equipment = item.EquipmentId + " - " + item.EquipmentDescription;
    item.equipmentActivity = item.EquipmentActivity + " - " + item.EquipmentActivityDescription;
    item.bonus = item.Bonus + " - " + item.BonusDescription;
    item.bonusQuantity = item.BonusUnit ? item.BonusQuantity + " " + item.BonusUnit : item.BonusQuantity;
    item.hours = dateHelper.hoursTohmm(item.Hours);

    item.rateType = labelHelper.getWageTypeLabel(item.RateType);
    item.comment = item.Comment;

    return item;
}

@transient()
export class TimesheetEmployeeSummary {
    constructor() {
        this.resx = resx;

        this.labors = {};
        this.primes = {};
        this.equipments = {};
    }

    activate(widgetSettings) {
        loadWidgetSettings(this, widgetSettings);
        return true;
    }
}