define([
    "jquery",
    "knockout",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "services/documentService",
    "repositories/settingRepository"
], function (jQuery, ko, routerHelper, queryStringHelper, documentService, settingRepository) {
    "use strict";

    PLATFORM.moduleName('widgets/pages/documents_add');

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.actions = {};
            self.readonly = false;
            self.editId = ko.observable();
            self.subTitle = ko.observable("");
            self.option = ko.observable("recommendations");
            self.contextPrefix = ko.observable("recommendation_");
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            var queryStringParams = routerHelper.getQuerystring(params.q);
            self.readonly = queryStringHelper.parseReadonly(params.q);
            self.subTitle(decodeURIComponent(queryStringParams.subTitle));
            self.editId(params.editId);
            self.option(params.option);
            self.contextPrefix(params.contextPrefix);

            var dispatchTemplateId = settingRepository.getDispatchTemplate();

            self.actions = {
                save: documentService.saveRecommendationPicture.bind(self, params.recommendationId, 'recommendation', self.editId(), dispatchTemplateId)
            };
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
