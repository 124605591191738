define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        deleteItem: function(dispatchProjectCode, dispatchDate, templateId, itemId) {
            return requests.remove(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/subcontractors/templates/" + templateId + "/suppliers/" + itemId);
        },

        getAllForTemplate: function (dispatchProjectCode, dispatchDate, templateId) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/subcontractors", templateId);
        },

        getSubcontractorDetail: function(dispatchProjectCode, dispatchDate, itemId) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/subcontractors/suppliers/" + itemId);
        },

        getContactDetail: function (dispatchProjectCode, dispatchDate, itemId) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/subcontractors/contacts/" + itemId);
        },

        getLookup: function(dispatchProjectCode, dispatchDate, filter, page) {

            var url = apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/subcontractors/lookup";

            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        saveSubcontractor: function(dispatchProjectCode, dispatchDate, templateId, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/subcontractors/templates/" + templateId + "/suppliers", data);           
        },

        saveContact: function (dispatchProjectCode, dispatchDate, templateId, data) {
            return requests.post(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) + "/subcontractors/templates/" + templateId + "/contacts", data);
        }
    };

    return exports;
});
