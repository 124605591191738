import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { I18N } from "aurelia-i18n";
import { EventAggregator } from "aurelia-event-aggregator";

define([
    "knockout",
    "services/additionalFieldsTypeService",
    "core/resx",
    "underscore",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "moment"
], function (ko, addFieldsService, resx, _, dateHelper, routerHelper, moment) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        ctor.inject = [I18N, EventAggregator];

        function ctor(i18n, eventAggregator) {
            self = this;
            //#region Properties
            self.actions = {};
            self.workOrderId = ko.observable();
            self.readonly = false;
            self.additionalFieldsSource = AdditionalFieldsSourceType.FieldService;
            self.saveAdditionalFieldsButton = true;
            self.eventAggregator = eventAggregator;
            self.i18n = i18n;
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(workOrderId, queryString) {
            var params = routerHelper.getQuerystring(queryString);
            self.readonly = !(params && params.readonly === "false");

            self.workOrderId(workOrderId);
            self.workOrderType = params.workOrderType;

            self.eventAggregator.publish("updateTitle", `${self.i18n.tr("pageTitle_Service_Detail_AdditionalFields")} (${self.workOrderType})`);
        }

        function bindWidget() {
            self.actions = {
                getAll: addFieldsService.getAllForFieldService.bind(self, self.workOrderId()),
                setAll: addFieldsService.setAllForFieldService.bind(self, self.workOrderId())
        };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            bindViewModel(params.workOrderId, params.q);
            bindWidget();
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
