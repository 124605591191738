define([
    "underscore",
    "knockout",
    "widgets/pages/employee_addNonAvailability"
], function (_, ko) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties

            self.dispatchProjectCode = ko.observable('');
            self.dispatchDate = ko.observable('');
            self.employeeId = ko.observable(0);

            //#endregion
        }

        //#region Private Functions
        
        function bindViewModel(dispatchProjectCode, dispatchDate, employeeId) {
            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dispatchDate);
            self.employeeId(employeeId);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.employeeId);

            return true;
        };

        
        //#endregion
        return ctor;
    })();

    return viewModel;
});
