
import { LocationModel } from "api/models/company/location-model";
import { EquipmentService } from "services/equipment-service";
import { WorkOrderPriorityService } from "services/work-order-priority-service";
import { CreationSource } from "api/enums/creation-source";
import { PrintFormType } from "api/enums/print-form-type";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { ServiceCallQuotationStatus, nameof as nameof_ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { ServiceCallWorkOrderQuotationStatus, nameof as nameof_ServiceCallWorkOrderQuotationStatus } from "api/enums/service-call-work-order-quotation-status";
import { ServiceCallRequiredDateType } from "api/enums/service-call-required-date-type";
import { ServiceCallEmergencyTypeModel } from "api/models/company/service-call/service-call-emergency-type-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { ServiceCallQuotationTypeModel } from "api/models/company/service-call/service-call-quotation-type-model";
import { DialogCloseResult, DialogService } from "aurelia-dialog";
import { autoinject, computedFrom } from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";
import { QuotationItemsGrid } from "components/ag-grid/grids/quotation-items-grid";
import { EnumFormatWithIdValueConverter } from "converters/enums/enum-format-with-id";
import { ValidationAttributeValueConverter } from "converters/misc/validation-attribute-value-converter";
import HostContext from "core/host-context";
import { nameof as nameof_ServiceCallQuotationPricingType, ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";
import { AgGridHelper } from "helpers/ag-grid-helper";
import { Compare } from "helpers/compare";
import { EnumHelper } from "helpers/enum-helper";
import { NotificationHelper } from "helpers/notification-helper";
import { RouterHelper } from "helpers/router-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationEditBase, QuotationEditParameters } from "pages/services/quotations/edit-base";
import { QuotationItemEquipmentDialog, QuotationItemEquipmentDialogParameters } from "pages/services/quotations/item-equipment-dialog";
import { QuotationItemLaborDialog, QuotationItemLaborDialogParameters } from "pages/services/quotations/item-labor-dialog";
import { QuotationItemMaterialDialog, QuotationItemMaterialDialogParameters } from "pages/services/quotations/item-material-dialog";
import { QuotationAddedEquipmentDialog, QuotationAddedEquipmentDialogParameters } from "pages/services/quotations/added-equipment-dialog";
import { CustomerContactService } from "services/customer-contact-service";
import { CustomerService } from "services/customer-service";
import { EmployeeService } from "services/employee-service";
import { ServiceCallContractService } from "services/service-call-contract-service";
import { ServiceCallEmergencyTypeService } from "services/service-call-emergency-type-service";
import { ServiceCallQuotationEquipmentService, ServiceCallQuotationRelatedEquipmentModel } from "services/service-call-quotation-equipment-service";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationTypeService } from "services/service-call-quotation-type-service";
import { SkillService } from "services/skill-service";
import { SalesPersonService } from "services/sales-person-service";
import { ServiceCallService, ServiceCallGenerationOption } from "services/service-call-service";
import { SettingRepository } from "repositories/setting-repository";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { Tab } from "components/layout/tab";
import { CanCloseWindow } from "interfaces/can-close-window";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { I18N } from "aurelia-i18n";
import { NavigationNew } from "repositories/routeRepository";
import { LocationService } from "services/location-service";
import { ContactService } from "services/contact-service";
import { ServiceWorkOrderProjectService } from "services/service-work-order-project-service";
import { ServiceWorkOrderService } from "services/service-work-order-service";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { ServiceCallQuotationEquipmentModel } from "api/models/company/service-call/service-call-quotation-equipment-model";
import { QuotationEquipmentGrid } from "components/ag-grid/grids/quotation-equipment-grid";
import { QuotationClientContractProjectDialog, QuotationClientContractProjectDialogParameters } from "./quotation-client-contract-project-dialog";
import { ServiceCallQuotationCustomerProjetContractModel } from "api/models/company/service-call/service-call-quotation-customer-projet-contract-model";
import moment from "moment";

@autoinject()
export class QuotationFull extends QuotationEditBase implements CanCloseWindow {
    public quotationTypes: ServiceCallQuotationTypeModel[] = [];
    public emergencyTypes: ServiceCallEmergencyTypeModel[] = [];
    public availableQuotationStatusesIncludingCurrent: ServiceCallQuotationStatus[] = [];
    public isHosted: boolean = false;
    public disableDeleteButton: boolean = true;

    public nameof_ServiceCallQuotationStatus: string = nameof_ServiceCallQuotationStatus;
    public nameof_ServiceCallWorkOrderQuotationStatus: string = nameof_ServiceCallWorkOrderQuotationStatus;
    public serviceCallQuotationPricingType: typeof ServiceCallQuotationPricingType = ServiceCallQuotationPricingType;
    public itemsGridViewModel: QuotationItemsGrid | null = null;
    public addedEquipmentGridViewModel: QuotationEquipmentGrid | null = null;
    public readonly PrintFormType: typeof PrintFormType = PrintFormType;
    public pricingTypes: ServiceCallQuotationPricingType[] = EnumHelper.getStringValues(ServiceCallQuotationPricingType) as ServiceCallQuotationPricingType[];
    private navigationParameters: QuotationEditParameters | null = null;
    private lockQuotationPrices: HTMLElement | null = null;

    @computedFrom("unmodifiedQuotation", "unmodifiedQuotation.Status", "securitySettings")
    public get readonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInDesktop(this.unmodifiedQuotation, this.securitySettings);
    }

    @computedFrom("unmodifiedQuotation", "unmodifiedQuotation.Status", "unmodifiedQuotation.Id", "unmodifiedQuotation.CreationUserId")
    public get canDelete(): boolean {
        return this.serviceCallQuotationSecurityService.canDeleteQuotation(this.unmodifiedQuotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Status")
    public get isRefusedStatusSelected(): boolean {
        return !!this.quotation && this.quotation.Status === ServiceCallQuotationStatus.RefusedByTheCustomer;
    }

    @computedFrom("quotation", "quotation.Status")
    public get isAcceptedByCustomerSelected(): boolean {
        return !!this.quotation && this.quotation.Status === ServiceCallQuotationStatus.AcceptedByTheCustomer;
    }

    @computedFrom("quotation", "quotation.ContractId", "unmodifiedQuotation.Status")
    public get canChangeTechnician(): boolean {
        return !this.readonly && !!this.quotation && this.quotation.ContractId !== null;
    }

    @computedFrom("quotation", "quotation.ProjectId", "unmodifiedQuotation.Status")
    public get canChangePersonInCahre(): boolean {
        return !this.readonly && !!this.quotation && this.quotation.ProjectId !== null;
    }

    @computedFrom("unmodifiedQuotation", "quotation", "quotation.ContractId", "quotation.ProjectId", "pricingReadonly")
    public get canAddDeleteItems(): boolean {
        return !this.pricingReadonly && !!this.quotation && (this.quotation.ContractId !== null || this.quotation.ProjectId !== null);
    }

    @computedFrom("quotation", "quotation.Status", "unmodifiedQuotation", "unmodifiedQuotation.Status", "securitySettings")
    public get pricingReadonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationPricingReadOnlyInDesktop(this.quotation, this.securitySettings);
    }

    @computedFrom("unmodifiedQuotation", "unmodifiedQuotation.Status", "securitySettings")
    public get statusReadonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationStatusReadOnlyInDesktop(this.quotation, this.securitySettings);
    }

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get canShowReturnToTechnician(): boolean {
        if (!this.quotation) {
            return false;
        }
        return this.quotation.Status === ServiceCallQuotationStatus.CompletedByTheTechnician && !this.quotation.IsWorkOrder;
    }

    @computedFrom("quotation", "quotation.Items")
    public get containNotInCatalogItem(): boolean {
        if (!this.quotation) {
            return false;
        }
        return this.serviceCallQuotationSecurityService.containNotInCatalogItem(this.quotation);
    }

    protected get initialQuotationStatus(): string {
        // In mobile the quotation is "In progress" initially. In desktop it is "Entered".
        return ServiceCallQuotationStatus.Entered;
    }

    constructor(
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        routerHelper: RouterHelper,
        notificationHelper: NotificationHelper,
        customerService: CustomerService,
        customerContactService: CustomerContactService,
        serviceCallContractService: ServiceCallContractService,
        serviceWorkOrderProjectService: ServiceWorkOrderProjectService,
        skillService: SkillService,
        serviceCallEmergencyTypeService: ServiceCallEmergencyTypeService,
        serviceCallQuotationTypeService: ServiceCallQuotationTypeService,
        employeeService: EmployeeService,
        validationAttributeValueConverter: ValidationAttributeValueConverter,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        salesPersonService: SalesPersonService,
        serviceCallService: ServiceCallService,
        settingRepository: SettingRepository,
        viewModeHelper: ViewModeHelper,
        serviceCallQuotationEquipmentService: ServiceCallQuotationEquipmentService,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        i18N: I18N,
        workOrderPriorityService: WorkOrderPriorityService,
        locationService: LocationService,
        contactService: ContactService,
        serviceWorkOrderService: ServiceWorkOrderService,
        serviceCallContractEquipmentService: ServiceCallContractEquipmentService,
        public readonly enumFormatWithIdValueConverter: EnumFormatWithIdValueConverter,
        private readonly agGridHelper: AgGridHelper,
        private readonly hostContext: HostContext,
        private readonly dialogService: DialogService
    ) {
        super(serviceCallQuotationService, serviceCallQuotationSecurityService, routerHelper, notificationHelper, customerService, customerContactService, serviceCallContractService, serviceWorkOrderProjectService, skillService, serviceCallEmergencyTypeService, serviceCallQuotationTypeService, employeeService, validationAttributeValueConverter, validationHelper, validationController, salesPersonService, serviceCallService, settingRepository, viewModeHelper, serviceCallQuotationEquipmentService, serviceCallQuotationPriceService, locationService, contactService, serviceWorkOrderService, i18N, workOrderPriorityService, serviceCallContractEquipmentService);

        this.filterContractsByPrefix = true;
    }

    public bind(): void {
        this.checkPriceQuotationLocked();
    }

    public async activate(params: QuotationEditParameters): Promise<void> {
        this.isHosted = this.hostContext.isHosted();
        this.navigationParameters = params;

        await Promise.all([
            this.initQuotation(params.quotationId, CreationSource.Maestro, params.isWorkOrder, params.sourceServiceCallId, params.contractEquipmentId, undefined, params.sourceQuotationId, params.sourceWorkOrderId),
            this.agGridHelper.lazyLoadAgGridModule(),
        ]);

        // Attention, doit être fait après les autres car on a besoin de la soumission.
        await this.initSecuritySettings();

        this.initValidation();

        (window as any).CurrentViewModel = this;
    }

    public async printSend(printFormType: PrintFormType): Promise<void> {
        if (!this.quotation) { return; }
        if (!this.hostContext.isHosted()) { return; }

        if (this.containNotInCatalogItem) {
            this.notificationHelper.showWarningKey("msg_QuotationActionNotAllowedContainsNonCatalogItem", "", { timeOut: 0 });
            return;
        }

        if (this.isDirty()) {
            const confirmation = await this.notificationHelper.showDialogYesNo(this.i18N.tr("PrintWithoutSaving"), "", false);
            if (confirmation !== true) { return; }

            const saveSuccess = await this.save();
            if (!saveSuccess) { return; }
        }

        const promise = (this.hostContext as any).viewModel.printForm(this.quotation.Id, printFormType);
        await this.routerHelper.waitFor<void>(promise);
    }

    public async openListGenerator(): Promise<void> {
        if (!this.quotation) { return; }
        if (!this.hostContext.isHosted()) { return; }

        const promise = (this.hostContext as any).viewModel.showListGenerator();
        await this.routerHelper.waitFor<void>(promise);
    }

    public async openShowDocument(): Promise<void> {
        if (!this.quotation) { return; }
        if (!this.hostContext.isHosted()) { return; }

        const promise = (this.hostContext as any).viewModel.showDocument(this.quotation.Id);
        await this.routerHelper.waitFor<void>(promise);
    }

    public async onSelectedEmergencyChanged(event: CustomEvent<ServiceCallEmergencyTypeModel>): Promise<void> {
        if (!this.quotation) { return; }

        if (this.unmodifiedQuotation && this.unmodifiedQuotation.RequiredDate && event.detail && event.detail.NbHour) {
            this.quotation.RequiredDate = moment(this.unmodifiedQuotation.RequiredDate).add(event.detail.NbHour, "h").toDate();
            this.quotation.RequiredDateType = ServiceCallRequiredDateType.Limited.toString();
        } else if (this.unmodifiedQuotation && this.unmodifiedQuotation.RequiredDate) {
            this.quotation.RequiredDate = moment(this.unmodifiedQuotation.RequiredDate).toDate();
        }
    }

    public async onItemGridAddClicked(type: ServiceCallQuotationItemType): Promise<void> {
        await this.addNewItem(type);
    }

    public async onItemGridDeleteClicked(): Promise<void> {
        await this.deleteSelectedItems();
    }

    public async onAddedEquipmentGridAddClicked(): Promise<void> {
        await this.addNewAddedEquipment();
    }

    public async onAddedEquipmentGridDeleteClicked(): Promise<void> {
        await this.deleteSelectedAddedEquipment();
    }

    public async selectedPricingTypeChanged(event: CustomEvent<ServiceCallQuotationPricingType>): Promise<void> {
        if (this.quotation === null) {
            return;
        }

        const selectedPricingType: ServiceCallQuotationPricingType | null = event ? event.detail : null;

        if (this.quotation.IsLumpSum === ServiceCallQuotationPricingType.LumpSumPlus && selectedPricingType === ServiceCallQuotationPricingType.TimeMaterial && this.quotation.Items && this.quotation.Items.length > 0) {

            if (this.quotation.IsLockedQuotation && this.quotation.Items.filter((item: ServiceCallQuotationItemModel) => item.Id <= 0).length === 0) {
                this.quotation.IsLumpSum = selectedPricingType;
                this.quotation.LumpSum = null;
                this.lumpSumPlusProfitMargin = 0;
                this.quotation.ProfitMargin = 0;
                return;
            }

            const confirmation = await this.notificationHelper.showDialogOkCancel(this.i18N.tr("ServiceQuotationSwitchFromLumpSumPlusToTimeMaterial"), "");
            if (confirmation !== true) {
                this.selectedPricingType = this.quotation.IsLumpSum;
                return;
            } else {
                this.quotation.IsLumpSum = selectedPricingType;
                this.quotation.LumpSum = null;
                this.lumpSumPlusProfitMargin = 0;
                this.quotation.ProfitMargin = 0;
                this.quotation.Items = await this.serviceCallQuotationService.GetQuotationItemsFromContract(this.quotation, true);
                this.serviceCallQuotationPriceService.resetPrices(this.quotation);

                this.itemsGridViewModel!.setRowData(this.quotation.Items);
                this.itemsGridViewModel!.refresh();
            }
        } else {
            if (this.quotation.IsLumpSum === ServiceCallQuotationPricingType.TimeMaterial || selectedPricingType === ServiceCallQuotationPricingType.TimeMaterial) {
                this.quotation.IsLumpSum = selectedPricingType;
                this.quotation.Items = await this.serviceCallQuotationService.GetQuotationItemsFromContract(this.quotation, true);
            } else {
                this.quotation.IsLumpSum = selectedPricingType;
            }

            if (this.quotation.IsLumpSum !== ServiceCallQuotationPricingType.LumpSum) {
                this.quotation.LumpSum = null;
            }

            if (this.quotation.IsLumpSum !== ServiceCallQuotationPricingType.LumpSumPlus) {
                this.lumpSumPlusProfitMargin = 0;
                this.quotation.ProfitMargin = 0;
            }

            this.serviceCallQuotationPriceService.resetPrices(this.quotation);

            this.itemsGridViewModel!.setRowData(this.quotation.Items);
            this.itemsGridViewModel!.refresh();
        }
    }

    public onRowSelected(grid: any, data: any): void {
        if (!data || this.readonly) {
            return;
        }

        this.disableDeleteButton = data.length <= 0;
    }

    public onItemGridRowDoubleClicked(grid: any, data: ServiceCallQuotationItemModel): void {
        if (!data || this.pricingReadonly) {
            return;
        }

        switch (data.Type) {
            case ServiceCallQuotationItemType.Material:
                this.editMaterialItem(data);
                break;
            case ServiceCallQuotationItemType.BillingItem:
                this.editMaterialItem(data);
                break;
            case ServiceCallQuotationItemType.Labor:
                this.editLaborItem(data);
                break;
            case ServiceCallQuotationItemType.Equipment:
                this.editEquipmentItem(data);
                break;
        }
    }

    public onDocumentGridAddClicked(): void {
        //this.addNewDocument();
    }

    public onDocumentGridDeleteClicked(): void {
        //this.deleteSelectedDocuments();
    }

    public onContactSignatureChanged(event: CustomEvent): void {
        if (!this.quotation) {
            return;
        }

        const selectedContact = event.detail as ContactLookupModel | null;

        if (selectedContact) {
            this.quotation.SignatureName = this.truncateContactName(selectedContact);
            this.quotation.SignatureEmail = selectedContact.Email || "";
        } else {
            this.quotation.SignatureName = "";
            this.quotation.SignatureEmail = "";
        }
    }

    public async onProfitChanged(newValue: any): Promise<void> {
        if (!this.quotation) {
            return;
        }

        if (newValue === null) {
            newValue = 0;
        }

        if (this.quotation.ProfitMargin === newValue) {
            return;
        }

        this.quotation.ProfitMargin = newValue;

        if (this.quotation.Items && this.quotation.Items.length > 0) {
            if (this.quotation.IsLockedQuotation && this.quotation.Items.filter((item: ServiceCallQuotationItemModel) => item.Id <= 0).length === 0) {
                return;
            }

            const confirmation = await this.notificationHelper.showDialogYesNo(this.i18N.tr("ServiceQuotationLumpSumPlusApplyProfitMarginOnAllItem"), "");
            if (confirmation === true) {
                this.serviceCallQuotationPriceService.resetPrices(this.quotation);

                this.itemsGridViewModel!.setRowData(this.quotation.Items);
                this.itemsGridViewModel!.refresh();
            }
        }
    }

    public async cancel(): Promise<void> {
        if (!this.navigationParameters) { return; }

        if (!await this.confirmCancel()) { return; }

        await this.initQuotation(this.navigationParameters.quotationId, CreationSource.Maestro, this.navigationParameters.isWorkOrder, this.navigationParameters.sourceServiceCallId, this.navigationParameters.contractEquipmentId, undefined, undefined, this.navigationParameters.sourceWorkOrderId);

        await Promise.all([
            this.initSecuritySettings(),
            this.initQuotationEquipments()
        ]);

        this.initValidation();
    }

    public async save(): Promise<boolean> {
        if (!this.quotation) {
            return false;
        }

        if ((this.quotation.Status === ServiceCallQuotationStatus.SentToCustomer || this.quotation.Status === ServiceCallQuotationStatus.AcceptedByTheCustomer || this.quotation.Status === ServiceCallQuotationStatus.RefusedByTheCustomer) && this.containNotInCatalogItem) {
            this.notificationHelper.showWarningKey("msg_QuotationActionNotAllowedContainsNonCatalogItem", "", { timeOut: 0 });
            return false;
        }

        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);
        if (isValid !== true) {
            return false;
        }

        if (this.quotation.Status === ServiceCallQuotationStatus.AcceptedByTheCustomer) {

            const dateAcceptedOrToday = this.quotation.DateAccepted ? this.quotation.DateAccepted : moment().toDate();

            if (this.quotation.RequiredDate && moment(this.quotation.RequiredDate).isBefore(moment(dateAcceptedOrToday), "day")) {
                this.notificationHelper.showWarning(this.i18N.tr("WarningQuotationRequiredDateIsPassed"), "", { timeOut: 0 });
                return false;
            }
        }

        if (this.quotation.Status === ServiceCallQuotationStatus.ManuallyClosed) {
            const confirmationManuallyClosed = await this.notificationHelper.showDialogYesNo(this.i18N.tr("ManuallyClosedPermanently"), "", false);
            if (confirmationManuallyClosed !== true) {
                return false;
            }

            if (this.quotation.GeneratedServiceCallId !== 0) {
                const warning = this.i18N.tr("WarningQuotationServiceCallGenerated");
                this.notificationHelper.showWarning(warning.replace("{0}", this.quotation.GeneratedServiceCallId.toString()), "", { timeOut: 0 });
            }
        }

        this.updateServiceQuotationEquipments();

        const quotationId = await this.serviceCallQuotationService.saveQuotation(this.quotation, true, true);
        await this.initQuotation(quotationId, CreationSource.Maestro, this.quotation.IsWorkOrder);

        this.initValidation();

        await this.initSecuritySettings();

        return true;
    }

    public async canClose(): Promise<void> {
        if (this.isDirty()) {
            const confirmation = await this.notificationHelper.showDialogYesNo(this.i18N.tr("ExitWithoutSaving"), "", false, { thirdButton: true, otherLabel: this.i18N.tr("Cancel") });
            if (confirmation === true) {
                const saveSuccess = await this.save();
                if (saveSuccess) {
                    (this.hostContext as any).viewModel.closeWindow();
                }
            } else if (confirmation === false) {
                (this.hostContext as any).viewModel.closeWindow();
            }
        } else {
            (this.hostContext as any).viewModel.closeWindow();
        }
    }

    public async selectedTabChanged(event: CustomEvent<Tab>): Promise<void> {
        if (event.detail.tabId === "Items" && this.quotation !== null && this.quotation.Items !== null && this.quotation.Items.length > 0) {
            this.userHasAccessTabItems = true;

            this.itemsGridViewModel!.refresh();
        }

        if (event.detail.tabId === "AddedEquipments" && this.quotation !== null && this.quotation.EquipmentsToAdd !== null && this.quotation.EquipmentsToAdd.length > 0) {
            this.addedEquipmentGridViewModel!.refresh();
        }
    }

    public async createServiceCall(): Promise<void> {
        if (!this.quotation) {
            return;
        }

        if (this.containNotInCatalogItem) {
            this.notificationHelper.showWarningKey("msg_QuotationActionNotAllowedContainsNonCatalogItem", "", { timeOut: 0 });
            return;
        }

        if (this.quotation.IsContractMiscCustomer) {
            this.notificationHelper.showWarning(this.i18N.tr("msg_QuotationCannotCreateServiceCallWithMiscCustomerContract"), "", { timeOut: 0 });
            return;
        }

        if (this.isDirty()) {
            const confirmation = await this.notificationHelper.showDialogYesNo(this.i18N.tr("CreateServiceCallWithoutSaving"), "", false);
            if (confirmation !== true) { return; }

            const saveSuccess = await this.save();
            if (!saveSuccess) { return; }
        }

        let dispatchTemplate = this.settingRepository.getDispatchTemplate();

        if (dispatchTemplate === "null" || dispatchTemplate === "undefined") {
            dispatchTemplate = null;
        }

        await this.serviceCallService.createServiceCall(ServiceCallGenerationOption.NoWorkingDateScheduled, new Date(Date.now()), 0, 0, null, null, this.quotation, dispatchTemplate, false);

        await this.initQuotation(this.quotation.Id, CreationSource.Maestro, this.quotation.IsWorkOrder);

        await this.initSecuritySettings();

        if (this.quotation.GeneratedServiceCallId !== 0) {
            let success = this.i18N.tr("msg_ServiceCallCreatedSuccessfully");
            success = success.replace("{0}", this.quotation.GeneratedServiceCallId.toString());

            await this.notificationHelper.showSuccess(success, "");
        }
    }

    public async createWorkOrder(): Promise<void> {
        if (!this.quotation) {
            return;
        }

        if (this.containNotInCatalogItem) {
            this.notificationHelper.showWarningKey("msg_QuotationActionNotAllowedContainsNonCatalogItem", "", { timeOut: 0 });
            return;
        }

        let dispatchTemplate = this.settingRepository.getDispatchTemplate();

        if (dispatchTemplate === "null" || dispatchTemplate === "undefined") {
            dispatchTemplate = null;
        }

        await this.serviceWorkOrderService.createWorkOrder(ServiceCallGenerationOption.NoWorkingDateScheduled, new Date(Date.now()), 0, 0, null, null, this.quotation, dispatchTemplate, false);

        await this.initQuotation(this.quotation.Id, CreationSource.Maestro, this.quotation.IsWorkOrder);

        await this.initSecuritySettings();

        if (this.quotation.GeneratedWorkOrderId !== "") {
            let success = this.i18N.tr("msg_ServiceWorkOrderCreatedSuccessfully");
            success = success.replace("{0}", this.quotation.GeneratedWorkOrderId!);

            await this.notificationHelper.showSuccess(success, "");
        }
    }

    public async onClickIsLockedQuotation(): Promise<void> {
        if (!this.quotation || this.readonly) {
            return;
        }

        this.quotation.IsLockedQuotation = !this.quotation.IsLockedQuotation;

        this.checkPriceQuotationLocked();

        if (this.unmodifiedQuotation) {
            this.unmodifiedQuotation.IsLockedQuotation = this.quotation.IsLockedQuotation;
        }

        await this.serviceCallQuotationService.saveIsLockedQuotation(this.quotation.Id, this.quotation.IsLockedQuotation);
    }

    public async copyCurrentQuotation(): Promise<void> {
        if (!this.quotation) {
            return;
        }

        (this.hostContext as any).viewModel.toNewChildWindow(this.quotation.Id);
    }

    public async returnToTechnician(): Promise<void> {
        if (!this.quotation) {
            return;
        }
        this.quotation.Status = ServiceCallQuotationStatus.InProgressByTheTechnician;
    }

    public async addNewAddedEquipment(): Promise<void> {

        const model = {
            quotation: this.quotation,
        };

        this.dialogService.open({ viewModel: QuotationAddedEquipmentDialog, model: model, lock: false, overlayDismiss: false }).whenClosed(async (result: DialogCloseResult) => {
            if (!result.wasCancelled && this.addedEquipmentGridViewModel && this.quotation) {
                const item = result.output as ServiceCallQuotationEquipmentModel;

                this.quotation.EquipmentsToAdd!.push(item);

                this.addedEquipmentGridViewModel.setRowData(this.quotation.EquipmentsToAdd);
                this.addedEquipmentGridViewModel.refresh();
            }
        });
    }

    public async createCustomerProjectContract(): Promise<void> {
        if (!this.quotation) {
            return;
        }

        const isSaveCompleted = await this.save();
        if (!isSaveCompleted) { return; }

        const model: QuotationClientContractProjectDialogParameters = {
            quotationId: this.quotation.Id,
        };

        this.dialogService.open({ viewModel: QuotationClientContractProjectDialog, model: model, lock: false, overlayDismiss: false }).whenClosed(async (dialogResult: DialogCloseResult) => {
            if (!dialogResult.wasCancelled && this.quotation) {
                const result = dialogResult.output as ServiceCallQuotationDetailsModel;
                result.ServiceContractProjectId ? this.notificationHelper.showSuccess(this.i18N.tr("msg_SucessCreateContactClientProject").replace("{0}", result.CustomerId!).replace("{1}", result.ServiceContractProjectId).replace("{2}", result.ContractId!), "", { timeOut: 0 }) : this.notificationHelper.showSuccess(this.i18N.tr("msg_SucessCreateContactClient").replace("{0}", result.CustomerId!!).replace("{1}", result.ContractId!), "", { timeOut: 0 });
                await this.initQuotation(this.quotation.Id, CreationSource.Maestro, this.quotation.IsWorkOrder);
            }
        });
    }

    public async openCustomerManagement(): Promise<void> {
        if (!this.quotation) { return; }
        if (!this.hostContext.isHosted()) { return; }
        if (!this.quotation.CustomerId) { return; }

        const promise = (this.hostContext as any).viewModel.showCustomerManagement(this.quotation.CustomerId);
        await this.routerHelper.waitFor<void>(promise);
    }

    public async openProjectManagement(): Promise<void> {
        if (!this.quotation) { return; }
        if (!this.hostContext.isHosted()) { return; }
        if (!this.quotation.ServiceContractProjectId) { return; }

        const promise = (this.hostContext as any).viewModel.showProjectManagement(this.quotation.ServiceContractProjectId);
        await this.routerHelper.waitFor<void>(promise);
    }

    public async openServiceContractManagement(): Promise<void> {
        if (!this.quotation) { return; }
        if (!this.hostContext.isHosted()) { return; }
        if (!this.quotation.ContractId) { return; }

        const promise = (this.hostContext as any).viewModel.showServiceContractManagement(this.quotation.ContractId);
        await this.routerHelper.waitFor<void>(promise);
    }

    private truncateContactName(contact: ContactLookupModel | null): string {
        if (!contact) {
            return "";
        }

        const maxlengthContactName = this.validationAttributeValueConverter.toView("maxLength", "service.serviceCall.quotation.contact.name");
        let contactName = contact.FullName || "";
        if (contactName && contactName.length > maxlengthContactName) {
            contactName = contactName.substring(0, maxlengthContactName);
        }

        return contactName;
    }

    private async initSecuritySettings(): Promise<void> {
        if (!this.unmodifiedQuotation) {
            return;
        }

        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
        this.availableQuotationStatusesIncludingCurrent = (this.securitySettings.Statuses as ServiceCallQuotationStatus[]).slice(0);
        this.availableQuotationStatusesIncludingCurrent = this.GetValidServiceCallQuotationStatusInDesktopMode(this.availableQuotationStatusesIncludingCurrent);
        if (!this.availableQuotationStatusesIncludingCurrent.includes(this.unmodifiedQuotation.Status as ServiceCallQuotationStatus)) {
            this.availableQuotationStatusesIncludingCurrent.push(this.unmodifiedQuotation.Status as ServiceCallQuotationStatus);

            if (this.unmodifiedQuotation.Status === ServiceCallQuotationStatus.CallGenerated) {
                this.availableQuotationStatusesIncludingCurrent = this.availableQuotationStatusesIncludingCurrent.filter((x: ServiceCallQuotationStatus) => x === ServiceCallQuotationStatus.CallGenerated || x === ServiceCallQuotationStatus.ManuallyClosed);
            }
        }

        this.availableQuotationStatusesIncludingCurrent = this.availableQuotationStatusesIncludingCurrent.sort((a: ServiceCallQuotationStatus, b: ServiceCallQuotationStatus) => Compare.NullableStrings(a, b));
    }

    private GetValidServiceCallQuotationStatusInDesktopMode(availableQuotationStatusesIncludingCurrent: ServiceCallQuotationStatus[]): ServiceCallQuotationStatus[] {
        return availableQuotationStatusesIncludingCurrent.filter((x: ServiceCallQuotationStatus) => x !== ServiceCallQuotationStatus.InProgressByTheTechnician && x !== ServiceCallQuotationStatus.CompletedByTheTechnician && x !== ServiceCallQuotationStatus.CallGenerated);
    }

    private initValidation(): void {

        ValidationRules
            .ensure((x: QuotationEditBase) => x.selectedContractId).required().withMessageKey("err_ContractRequired")
            .on(this);

        ValidationRules
            .ensure((x: ServiceCallQuotationDetailsModel) => x.RefusalReason).required().when((x: ServiceCallQuotationDetailsModel) => x.Status === ServiceCallQuotationStatus.RefusedByTheCustomer).withMessageKey("err_ReasonForRefusalRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.CustomerId).required().when((x: ServiceCallQuotationDetailsModel) => !this.isResidential && !x.IsWorkOrder).withMessageKey("err_CustomerRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.Description).required().withMessageKey("err_BriefDescriptionRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.Address).required().withMessageKey("err_WorkLocationRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.ContactName).required().withMessageKey("err_ContactNameRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.ContactEmail).required().withMessageKey("err_ContactEmailRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.ContactEmail).email().withMessageKey("err_InvalidEmail")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.Date).required().withMessageKey("err_DateRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.RequiredDate).required().withMessageKey("err_RequiredDateRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.LumpSum).satisfies((value: number | null) => value !== 0 && value !== null).withMessageKey("err_LumpSumRequired").when((x: ServiceCallQuotationDetailsModel) => x.IsLumpSum === ServiceCallQuotationPricingType.LumpSum)
            .ensure((x: ServiceCallQuotationDetailsModel) => x.SignatureName).required().when((x: ServiceCallQuotationDetailsModel) => x.Status === ServiceCallQuotationStatus.AcceptedByTheCustomer).withMessageKey("err_SignatureNameRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.SalespersonId).satisfies((value: string | null) => value !== null && parseInt(value) !== 0 && value !== "").when((x: ServiceCallQuotationDetailsModel) => this.isResidential && x.Status === ServiceCallQuotationStatus.AcceptedByTheCustomer && !x.IsWorkOrder).withMessageKey("err_SellerRequired")
            .on(this.quotation);
    }

    private async addNewItem(type: ServiceCallQuotationItemType): Promise<void> {
        if (!this.itemsGridViewModel || !this.quotation || !this.quotation.Items) {
            return;
        }

        const x = type + "";

        const item: ServiceCallQuotationItemModel = this.serviceCallQuotationService.createNewQuotationItem(this.quotation.Id, type, this.quotation.ProfitMargin);

        switch (type) {
            case ServiceCallQuotationItemType.Material:
                this.editMaterialItem(item);
                break;
            case ServiceCallQuotationItemType.BillingItem:
                this.editMaterialItem(item);
                break;
            case ServiceCallQuotationItemType.Labor:
                this.editLaborItem(item);
                break;
            case ServiceCallQuotationItemType.Equipment:
                this.editEquipmentItem(item);
                break;
        }
    }

    private async deleteSelectedItems(): Promise<void> {
        if (!this.itemsGridViewModel || !this.quotation || !this.quotation.Items) {
            return;
        }

        let selectedMiscFeeItems: any[] = [];
        let selectedItems: any[] = [];

        if (this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.LumpSum || this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.LumpSumPlus) {
            selectedItems = this.itemsGridViewModel.getSelectedItems();
        } else {
            selectedMiscFeeItems = this.itemsGridViewModel.getSelectedItems().filter((itemToDelete: ServiceCallQuotationItemModel) => itemToDelete.Type === ServiceCallQuotationItemType.MiscCost);

            selectedItems = this.itemsGridViewModel.getSelectedItems().filter((itemToDelete: ServiceCallQuotationItemModel) => itemToDelete.Type !== ServiceCallQuotationItemType.MiscCost);
        }

        //remove also linked Items
        selectedItems.forEach((itemToDelete: ServiceCallQuotationItemModel) => {
            if (this.quotation != null && this.quotation.Items != null) {
                const linkedItems = this.quotation.Items.filter((x: ServiceCallQuotationItemModel) => x.ParentId === itemToDelete.Id);
                linkedItems.forEach((linkedItemToDelete: ServiceCallQuotationItemModel) => {
                    if (!selectedItems.includes(linkedItemToDelete)) {
                        selectedItems.push(linkedItemToDelete);
                    }
                });
            }
        });

        this.itemsGridViewModel.removeItems(selectedItems);
        this.quotation.Items = this.quotation.Items.filter((x: ServiceCallQuotationItemModel) => !selectedItems.includes(x));

        this.quotation.Items = await this.serviceCallQuotationService.UpdateQuotationItems(this.quotation.Items);

        await this.serviceCallQuotationPriceService.updatePrices(this.quotation);

        if (selectedMiscFeeItems.length > 0) {
            this.notificationHelper.showWarningKey("msgSelectionContainMiscFee", "");
        }

    }

    private async deleteSelectedAddedEquipment(): Promise<void> {
        if (this.addedEquipmentGridViewModel && this.quotation && this.quotation.EquipmentsToAdd) {
            const selectedItems: ServiceCallQuotationEquipmentModel[] = this.addedEquipmentGridViewModel.getSelectedItems();

            this.quotation.EquipmentsToAdd = this.quotation.EquipmentsToAdd.filter((item: ServiceCallQuotationEquipmentModel) => {
                return !selectedItems.includes(item);
            });

            this.addedEquipmentGridViewModel.setRowData(this.quotation.EquipmentsToAdd);
            this.addedEquipmentGridViewModel.refresh();
        }
    }

    private editMaterialItem(item: ServiceCallQuotationItemModel): void {
        if (!this.quotation || !this.quotation.Items) {
            return;
        }

        // TODO: Meilleure gestion des linked items passés au dialogue.
        if (item.Type === ServiceCallQuotationItemType.Material) {
            item.LinkedItems = this.quotation.Items.filter((x: ServiceCallQuotationItemModel) => x.ParentId === item.Id && item.Id !== 0);
        }

        this.editItem(QuotationItemMaterialDialog, item);
    }

    private editLaborItem(item: ServiceCallQuotationItemModel): void {
        this.editItem(QuotationItemLaborDialog, item);
    }

    private editEquipmentItem(item: ServiceCallQuotationItemModel): void {
        this.editItem(QuotationItemEquipmentDialog, item);
    }

    private editItem<TVM>(viewModel: new (...params: any[]) => TVM , item: ServiceCallQuotationItemModel): void {
        if (!this.quotation || !this.quotation.Items) {
            return;
        }

        // Create the model to pass to the dialog.
        const parentQuotationItem = this.quotation.Items.find((x: ServiceCallQuotationItemModel) => x.Id === item.ParentId && item.ParentId !== 0);

        const model = {
            quotationItem: item,
            parentQuotationItem: parentQuotationItem ? parentQuotationItem : null,
            quotation: this.quotation,
        };

        this.dialogService.open({ viewModel: viewModel, model: model, lock: false, overlayDismiss: false }).whenClosed(async (result: DialogCloseResult) => {
            await this.onEditItemDialogClosed(result);
        });
    }

    private async onEditItemDialogClosed(result: DialogCloseResult): Promise<void> {
        if (!result.wasCancelled && this.quotation && this.quotation.Items) {
            const item = result.output as ServiceCallQuotationItemModel;

            if (!this.quotation.Items.includes(item)) {
                this.quotation.Items.push(item);
            } else {
                this.quotation.Items = this.quotation.Items.filter((x: ServiceCallQuotationItemModel) => x.ParentId !== item.Id);
            }

            this.quotation.Items = await this.serviceCallQuotationService.UpdateQuotationItems(this.quotation.Items);

            await this.serviceCallQuotationPriceService.updatePrices(this.quotation);

            this.itemsGridViewModel!.setRowData(this.quotation.Items);
        }

        this.itemsGridViewModel!.refresh();
    }

    private async onAddNewLocationDialogClosed(result: DialogCloseResult): Promise<void> {
        if (!result.wasCancelled && this.quotation) {
            const item = result.output as LocationModel;

            this.quotation.Address = item;
        }
    }

    private async confirmCancel(): Promise<boolean> {
        return await this.notificationHelper.showConfirmationKey("msg_UnsavedChangedWillBeLostConfirmation");
    }

    private checkPriceQuotationLocked(): void {
        if (!this.quotation || this.isNew) {
            return;
        }

        this.lockQuotationPrices!.classList.toggle("fa-lock", this.quotation.IsLockedQuotation);
        this.lockQuotationPrices!.classList.toggle("fa-unlock", !this.quotation.IsLockedQuotation);
    }
}
