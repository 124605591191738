import { default as routeRepository, NavigationNew } from "repositories/routeRepository";
import UserAccessService from "services/user-access-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationHistoryService } from "services/service-call-quotation-history-service";
import { WorkOrderQuotationHistoryService } from "services/work-order-quotation-history-service";
import { CallType } from "api/enums/call-type";

define([
    "knockout",
    "core/resx",
    "services/serviceService",
    "services/defaultService",
    "helpers/labelHelper",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "helpers/enumHelper"
], function (ko, resx, services, defaultService, labelHelper, hlpDate, routerHelper, queryStringHelper, enumHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;
        var serviceTypeEnum = { "serviceCall": "S", "workOrder": "W" };

        ctor.inject = [routeRepository, UserAccessService, ServiceCallQuotationSecurityService, ServiceCallQuotationHistoryService, WorkOrderQuotationHistoryService];

        function ctor(routeRepository, userAccessService, serviceCallQuotationSecurityService, serviceCallQuotationHistoryService, workOrderQuotationHistoryService) {
            self = this;
            this.routeRepository = routeRepository;
            this.userAccessService = userAccessService;
            this.serviceCallQuotationSecurityService = serviceCallQuotationSecurityService;
            this.serviceCallQuotationHistoryService = serviceCallQuotationHistoryService;
            this.workOrderQuotationHistoryService = workOrderQuotationHistoryService;

            //#region Public Functions
            self.hlpDate = hlpDate;
            self.labelHelper = labelHelper;
            self.enumHelper = enumHelper;
            self.resx = resx;

            self.readonly = false;
            self.dispatchId = ko.observable();
            self.history = ko.observableArray([]);
            self.visitsList = ko.observableArray([]);
            self.serviceType = '';
            self.serviceCallId = null;

            self.search = ko.observable("");
            self.equipmentCode = "";
            self.equipmentId = "";
            self.resultsVisible = ko.observable(false);
            self.buttonCreateQuoteVisible = ko.observable(false);
            self.canCreateQuotation = ko.observable(false);

            self.delayedSearch = ko.pureComputed(self.search).extend({
                rateLimit: { method: "notifyWhenChangesStop", timeout: 400 }
            });
            self.delayedSearch.subscribe(function(val) {
                    clear();

                    getData(self.page, val).done(function(data) {
                        getDataDone(data);
                    });
                },
                this);

            self.resultsCount = ko.observable(0);
            self.page = 1;
            self.scrollMessageText = ko.observable(resx.localize('ScrollForMore'));
            self.showScrollMessage = ko.observable(false);
            self.showVisits = ko.observable(false);
            self.showQuotations = ko.observable(false);
            self.loadingStatus =
                ko.observable({}); // True if the end of the infinite scrolling is reached for current tab

            self.callType = CallType;
            //#endregion
        }
        //#region Private Functions

        function getQuerystringData(querystring) {
            if (querystring) {
                var json = routerHelper.getQuerystring(querystring);
                if (json.equipmentCode) {
                    self.equipmentCode = decodeURIComponent(json.equipmentCode);
                }
                if (json.equipmentId) {
                    self.equipmentId = decodeURIComponent(json.equipmentId);
                    self.buttonCreateQuoteVisible(true);
                }
                if (json.serviceCallId) {
                    self.serviceCallId = decodeURIComponent(json.serviceCallId);
                }
                self.readonly = queryStringHelper.parseReadonly(querystring);
            }
        }

        function bindViewModel(serviceType, dispatchId, querystring) {
            self.equipmentCode = "";
            self.equipmentId = "";
            self.serviceCallId = null;
            getQuerystringData(querystring);
            self.dispatchId(dispatchId);
            self.serviceType = serviceType;
            if (serviceType === serviceTypeEnum.serviceCall && !self.equipmentId && !self.equipmentCode) {
                self.showVisits(true);
            } else {
                self.showVisits(false);
                //if (serviceType !== serviceTypeEnum.serviceCall) {
                //    self.canCreateQuotation(false);
                //}
            }
        }

        function clear() {
            self.page = 1;
            self.history([]);
            self.visitsList([]);
        }

        function getDataDone(data) {
            var ls = self.showVisits() ? self.visitsList() : self.history();
            ls = ls.concat(data);
            self.resultsCount(ls.length);
            if (data.length >= defaultService.getPageSize()) {
                self.loadingStatus(false);
                initScroll();
            } else {
                self.loadingStatus(true);
                disposeScroll();
            }
            self.resultsVisible(true);
            if (self.showVisits()) {
                return self.visitsList(ls);
            } else {
                return self.history(ls);
            }
        }

        function disposeAll() {
            self.history([]);
            disposeScroll();
        }

        async function getData(page, search) {
            disposeScroll();
            var dfd = new jQuery.Deferred();
            if (page === 1) {
                self.resultsVisible(false);
            }

            if (self.showQuotations()) {

                if (self.serviceType === serviceTypeEnum.serviceCall) {
                    return await self.serviceCallQuotationHistoryService.getHistory(self.dispatchId(), !search ? null : search, !self.equipmentId && !self.equipmentCode ? null : self.equipmentId, page);
                }
                return await self.workOrderQuotationHistoryService.getHistory(self.dispatchId(), !search ? null : search, page);
            } else {
                routerHelper.showLoading();
                getHistory(self.showVisits(), self.showQuotations(), page, search)
                    .done(function (data) {
                        return dfd.resolve(data);
                    })
                    .fail(function (data) {
                        dfd.reject(data);
                        disposeAll();
                    })
                    .always(routerHelper.hideLoading);
                return dfd;
            }
        }

        function getHistory(showVisits, showQuotations, page, search) {
            if (showVisits) {
                return services.getCallVisitsHistory(self.serviceType,
                    self.dispatchId(),
                    page,
                    search,
                    self.equipmentId);
            }
            else {
                return services.getSameAdressCallsHistory(self.serviceType, self.dispatchId(), page, search, self.equipmentId);
            }
        }

        function loadData() {
            clear();
            self.search("");
            return getData(self.page, "")
                .done(function (data) {
                    getDataDone(data);
                });
        }

        function initScroll() {
            jQuery(document).scroll(scrollHandler);
            showScrollMessage("scroll");
        }

        function disposeScroll() {
            jQuery(document).off("scroll", scrollHandler);
            hideScrollMessage();
        }

        function scrollHandler() {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                disposeScroll();
                showScrollMessage("loading");
                self.page += 1;
                getData(self.page, self.delayedSearch())
                    .done(function(data) {
                        getDataDone(data);
                    });
            }
        }

        function showScrollMessage(msgId) {
            if (msgId === "loading") {
                self.scrollMessageText(resx.localize("LoadingMoreResults"));
            } else {
                self.scrollMessageText(resx.localize("ShowMoreEntries"));
            }

            self.showScrollMessage(true);
        }

        function hideScrollMessage() {
            self.showScrollMessage(false);
        }

        async function initSecuritySettings() {
            const securitySettings = await self.serviceCallQuotationSecurityService.getSecuritySettings();
            self.canCreateQuotation(self.serviceCallQuotationSecurityService.canCreateQuotationInMobile(securitySettings));
            self.recommandationOptionEnabled = self.userAccessService.isOptionEnabledSync(enumHelper.userOptions.SERVICERECOMMENDATION);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = async function (params) {
            await initSecuritySettings();

            bindViewModel(params.serviceType, params.dispatchId, params.q);
            return loadData();
        };

        ctor.prototype.deactivate = function () {
            disposeScroll(self);
        };

        ctor.prototype.goToSummary = async function (item) {
            const histoItem = item;

            let dispatchId = 0;
            if (histoItem.DispatchId) {
                dispatchId = histoItem.DispatchId;
            }

            let url = "";
            if (dispatchId !== 0) {
                url = routerHelper.navigateTo('Service_Detail', histoItem.IsWorkOrder ? "W" : "S", dispatchId) + routerHelper.addQuerystring({ fromHistory: true, readonly: true });
                routerHelper.navigate(url);
            } else {
                url = routerHelper.navigateTo("Service_Detail_Summary", histoItem.IsWorkOrder ? "W" : "S", histoItem.Id)
                    + routerHelper.addQuerystring({ id: histoItem.Id, assignedDate: hlpDate.dateFromUTC(histoItem.AssignedDate, "YYYY-MM-DD"), fromHistory: true, equipmentCode: self.equipmentCode });
            }
            routerHelper.navigate(url);
        }

        ctor.prototype.goToQuotationSummary = function (item) {
            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Summary.name, { quotationId: item.Id, showSignature: "false" });
        }

        ctor.prototype.goToVisit = function (dispatchId) {
            let url = routerHelper.navigateTo('Service_Detail', 'S', dispatchId) + routerHelper.addQuerystring({ fromHistory: true, readonly: true });
            routerHelper.navigate(url);
        };
        //#endregion

        ctor.prototype.gotoPage = function () {
            var url = routerHelper.navigateTo("Service_Detail_Recommendation_Edit", self.serviceType, self.dispatchId()) +
            routerHelper.addQuerystring({ entityId: self.dispatchId(), equipmentId : self.equipmentId });
            routerHelper.navigate(url);
        };

        ctor.prototype.gotoQuotation = function () {
            var equipId = self.equipmentId;
            if (equipId === "") {
                equipId = null;
            }

            let sourceServiceCalId = self.serviceCallId;
            let sourceWorkOrderId = null;
            let isWorkOrder = false;

            if (self.serviceType === serviceTypeEnum.workOrder) {
                sourceWorkOrderId = self.serviceCallId;
                sourceServiceCalId = null;
                isWorkOrder = true;
            }

            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Details.name, { quotationId: NavigationNew, sourceServiceCallId: sourceServiceCalId, contractEquipmentId: equipId, dispatchId: self.dispatchId(), sourceWorkOrderId: sourceWorkOrderId, isWorkOrder: isWorkOrder});
        };

        ctor.prototype.show = function (section) {
            clear();
            self.showVisits(section === "visits");
            self.showQuotations(section === "addressQuotations");
            self.resultsCount(0);
            loadData();
        };

        ctor.prototype.manualLoadNewPage = function() {
            disposeScroll();
            showScrollMessage("loading");
            self.page += 1;
            getData(self.page, self.delayedSearch())
                .done(function(data) {
                    getDataDone(data);
                });
        }

        return ctor;
    })();

    return viewModel;
});
