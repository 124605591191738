define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "services/materialService",
    "services/dailyEntryService",
], function (ko, _, resx, queryStringHelper, routerHelper, materialService, dailyEntryService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = false;
            self.dispatchProjectCode = ko.observable("");
            self.dispatchDate = ko.observable("");
            self.isProjectResponsible = false;
            //#endregion
        }

        //#region Private Functions
        function gotoPage(material) {
            var url = (material && material.LineNo)
                    ? routerHelper.getRelativeUrl("edit", material.LineNo) :
                    routerHelper.getRelativeUrl("edit", -1);

            routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: self.readonly, isProjectResponsible: self.isProjectResponsible }));
        }

        function bindViewModel(dispatchProjectCode, dispatchDate, querystring) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
            self.readonly = queryStringHelper.parseReadonly(querystring);

            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);

            self.actions = {
                deleteMaterial: materialService.deleteForProjectDailyEntry.bind(null, self.dispatchProjectCode(), self.dispatchDate()),
                getMaterials: materialService.listForProjectDailyEntry.bind(null, self.dispatchProjectCode(), self.dispatchDate()),
                goToPage: gotoPage,
                getReserved: getReserved,
                setReservedMaterials: materialService.setReservedMaterials.bind(null, self.dispatchProjectCode(), self.dispatchDate())
            };
        }

        function getReserved() {
            return dailyEntryService.getIsReservedMaterialsForProject(self.dispatchProjectCode(), self.dispatchDate());
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
