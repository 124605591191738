import { AgGridHelper } from "helpers/ag-grid-helper";
import EstimateVariableService from "services/estimateVariableService";
import EstimateTakeoffVariableImportService from "services/estimateTakeoffVariableImportService";
import { default as resx } from "core/resx";
import { default as settingRepository } from "repositories/settingRepository"
import { default as routerHelper } from "helpers/routerHelper";

import { default as _ } from "underscore";
import { inject, observable, TaskQueue } from "aurelia-framework";
import { Router } from "aurelia-router";
import { default as notifier } from "helpers/notificationHelper";

@inject(TaskQueue, Router, AgGridHelper, EstimateVariableService, EstimateTakeoffVariableImportService)
export class TakeoffQuantityImportConfig {
    estimateId = null;
    companyName = null;
    importableFields = [];
    customFormatMapping = {};
    fields = [];
    @observable importFileArray;

    constructor(taskQueue, router, agGridHelper, estimateVariableService, estimateTakeoffVariableImportService) {
        this.taskQueue = taskQueue;
        this.router = router;
        this.resx = resx;
        this.routerHelper = routerHelper;
        this.agGridHelper = agGridHelper;
        this.estimateVariableService = estimateVariableService;
        this.estimateTakeoffVariableImportService = estimateTakeoffVariableImportService;
        this._isDirty = false;
    }

    get isDirty() { return this._isDirty; }
    set isDirty(value) { this._isDirty = value; }

    importFileArrayChanged(newValue, oldValue) {
        const file = newValue[0];

        if (!file)
            return;

        const dataPromise = this.estimateTakeoffVariableImportService.getFieldNamesFromFile(file);
        dataPromise.then((data) => {
            this.importableFields = data;
            this.loadImportableFields();
        });
    }

    activate(params, routeConfig) {
        this.estimateId = params.estimateId;
        this.companyName = settingRepository.getCompanyName();

        const columnDefs = [
            { headerName: this.resx.localize("Field"),         field: "Field",       width: 120, editable: false, enableRowGroup: false, cellStyle: this.agGridHelper.cellStyleLeftAlign,   rowGroup: false, menuTabs: [] },
            { headerName: this.resx.localize("Description"),   field: "Description", width: 180, editable: false, enableRowGroup: false, cellStyle: this.agGridHelper.cellStyleLeftAlign,   rowGroup: false, menuTabs: [] },
            { headerName: this.resx.localize("Type"),          field: "Type",        width: 170, editable: false, enableRowGroup: false, cellStyle: this.agGridHelper.cellStyleLeftAlign,   rowGroup: false, menuTabs: [] },
            { headerName: this.resx.localize("Length"),        field: "Length",      width: 100, editable: false, enableRowGroup: false, cellStyle: this.agGridHelper.cellStyleRightAlign,  rowGroup: false, menuTabs: [] },
            { headerName: this.resx.localize("ImportedField"), field: "SourceField", width: 160, editable: true,  enableRowGroup: false, cellStyle: this.agGridHelper.cellStyleLeftAlign,   rowGroup: false, menuTabs: [] }
        ];

        this.gridOptions = this.agGridHelper.createDefaultGridOptions(columnDefs);
        this.gridOptions.rowGroupPanelShow = "never";
        this.gridOptions.enableSorting = false;
        this.gridOptions.editType = null;
        this.gridOptions.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.gridOptions.getRowNodeId = function (data) { return data.TargetField; };
        this.gridOptions.onCellValueChanged = () => {
            this.isDirty = true;
        };

        this.customFormatMapping = {};
        return this.estimateTakeoffVariableImportService.getCustomImportFormat().then(data => {
            for (var i = 0; i < data.Items.length; i++) {
                let item = data.Items[i];
                this.customFormatMapping[item.TargetColumn] = item.SourceColumn;
            }
        });
    }

    async attached() {
        this.grid = await this.aAgGridHelper.createGrid(this.gridElement, this.gridOptions);

        this.loadRows();
        this.loadImportableFields();
    }

    loadImportableFields() {
        var col = this.gridOptions.columnApi.getColumn("SourceField");
        var colDef = col.getColDef();
        colDef.cellEditorParams = { values: this.importableFields };
        this.gridOptions.api.refreshHeader();
    }

    loadRows() {
        this.fields = [
            { Field: this.resx.localize("Counter"), Description: this.resx.localize("Counter"), Type: this.resx.localize("Integer"), Length: 4, SourceField: this.customFormatMapping.Counter, TargetField: "Counter" },
            { Field: this.resx.localize("VariableName"), Description: this.resx.localize("VariableName"), Type: this.resx.localize("Text"), Length: 100, SourceField: this.customFormatMapping.VariableName, TargetField: "VariableName", Required: true },
            { Field: this.resx.localize("Quantity"), Description: this.resx.localize("Quantity"), Type: this.resx.localize("NumberWithDecimals"), Length: 8, SourceField: this.customFormatMapping.Quantity, TargetField: "Quantity" , Required: true },
            { Field: this.resx.localize("Unit"), Description: this.resx.localize("Unit"), Type: this.resx.localize("Text"), Length: 5, SourceField: this.customFormatMapping.Unit, TargetField: "Unit", Required: true  },
            { Field: this.resx.localize("Drawing"), Description: this.resx.localize("Drawing"), Type: this.resx.localize("Text"), Length: "100", SourceField: this.customFormatMapping.Drawing, TargetField: "Drawing" },
            { Field: this.resx.localize("Sheet"), Description: this.resx.localize("Sheet"), Type: this.resx.localize("Text"), Length: "100", SourceField: this.customFormatMapping.Sheet, TargetField: "Sheet" },
            { Field: this.resx.localize("Description"), Description: this.resx.localize("Description"), Type: this.resx.localize("Text"), Length: 100, SourceField: this.customFormatMapping.Description, TargetField: "Description" },
            { Field: this.resx.localize("Category"), Description: this.resx.localize("Category"), Type: this.resx.localize("Text"), Length: "100", SourceField: this.customFormatMapping.Category, TargetField: "Category" },
            { Field: this.resx.localize("Notes"), Description: this.resx.localize("Notes"), Type: this.resx.localize("Text"), Length: "", SourceField: this.customFormatMapping.Notes, TargetField: "Notes" }
        ];

        this.gridOptions.api.setRowData(this.fields);
    }

    save() {
        var data = { Items: [] };

        for (var i = 0; i < this.fields.length; i++) {
            data.Items.push({ SourceColumn: this.fields[i].SourceField, TargetColumn: this.fields[i].TargetField });
        }

        return this.estimateTakeoffVariableImportService.saveCustomImportFormat(data)
            .then(() => {
                this.isDirty = false;
                this.exit();
            });
    }

    exit() {
        if (this.isDirty) {
            notifier.showDialogYesNo(resx.localize('ExitWithoutSaving'), "", false, { thirdButton: true, otherLabel: resx.localize("Cancel") })
                .done((success) => {
                    if (success === true) {
                        this.save().then(() => {
                            routerHelper.navigateBack();
                        });
                    } else if (success === false) {
                        routerHelper.navigateBack();
                    }
                });
        } else {
            this.routerHelper.navigateBack();
        }
    }

    getContextMenuItems(params) {
        const result = [
            "copy",
            "destroyColumnComps"
        ];

        return result;
    }
}

