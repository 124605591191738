define(["knockout"],
    function (ko) {
        "use strict";

        // Round the duration to the nearest increment.
        ko.extenders.duration = function (target, settings) {
            var result = ko.pureComputed({
                read: target,
                write: function (newValue) {
                    var current = target();

                    var coefficient = 60 / settings.increment,
                        valueToWrite = newValue !== null ? (Math.round(newValue * coefficient) / coefficient) : null;

                    if (valueToWrite > settings.max) {
                        valueToWrite = settings.max;
                    }

                    //only write if it changed
                    if (valueToWrite !== current) {
                        target(valueToWrite);
                    } else if (newValue !== current) {
                        //if the rounded value is the same, but a different value was written, force a notification for the current field
                        target.notifySubscribers(valueToWrite);
                    }
                }
            }).extend({ notify: 'always' });

            //initialize with current value to make sure it is rounded appropriately
            result(target());

            //return the new computed observable
            return result;
        };

        return ko.extenders;
    });
