import { EventAggregator } from "aurelia-event-aggregator";
import { PLATFORM } from "aurelia-framework";
import { DefaultBonusApplyOption } from "api/enums/default-bonus-apply-option";

import { default as ProjectTimeEntryHelper } from "helpers/project-time-entry-helper";
import { default as settingHelper } from "helpers/settingHelper";
import { DispatchProjectTimeEntryService } from "services/dispatch-project-time-entry-service";

define([
    "knockout",
    "core/resx",
    "core/val",
    "jquery",
    "underscore",
    "helpers/dateHelper",
    "helpers/labelHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "helpers/enumHelper",
    "moment",
    "services/bonusService",
    "services/commonService",
    "services/projectService",
    "services/dispatchTimesheetService",
    "services/tradesService",
    "services/templateService",
    "helpers/wageSettingHelper",
    "services/defaultService",
    "helpers/koExtenders",
    "widgets/wageTypeSelect"
], function (ko, resx, val, jQuery, _, dateHelper, labelHelper, notificationHelper, queryStringHelper, routerHelper, enumHelper, moment, bonusService, commonService,
    projectService, dispatchTimesheetService, tradesService, templateService, wageSettingHelper, defaultService) {
    "use strict";

    var PAUSE_ACTIVITY_CODE = "PAUSE";

    var viewModel = (function () {
        var self = null;

        ctor.inject = [EventAggregator, ProjectTimeEntryHelper, DispatchProjectTimeEntryService];

        function ctor(eventAggregator, projectTimeEntryHelper, dispatchProjectTimeEntryService) {
            this.projectTimeEntryHelper = projectTimeEntryHelper;
            this.dispatchProjectTimeEntryService = dispatchProjectTimeEntryService;
            this.eventAggregator = eventAggregator;
            self = this;

            self.employeeTimeEntryCards = [];

            //#region Properties
            self._ = _;
            self.labelHelper = labelHelper;
            self.resx = resx;
            self.val = val;
            self.allShifts = [];

            self.defaultTrade = ko.observable();
            self.dispatchProjectCode = ko.observable("");
            self.dispatchDate = ko.observable("");
            self.dispatches = ko.observableArray([]);
            self.duration = ko.observable(null);
            self.formattedDuration = ko.observable(null);
            self.timeIncrement = ko.observable();
            self.isProjectResponsible = false;
            self.maxDate = "";
            self.subscriptions = [];
            self.readonly = true;
            self.showSubProject = ko.observable(false);
            self.timeEntryId = ko.observable();
            self.wageTypes = ko.observableArray();
            self.templateResult = PLATFORM.moduleName("pages/templates/maSelectTemplates/dailyEntry_timeEntry_edit_activity_result.html");
            self.templateSelection = tSelection;
            self.message = ko.observable("");
            self.projectWorkSchedule = null;
            self.activityAvailable = ko.observable(false);
            self.showShift = ko.observable(false);
            self.defaultShift = 0;
            self.defaultActivityCode = null;
            self.wage = ko.observableArray();
            self.templateConfigs = ko.observable();
            self.dispatchTemplateID = "";
            self.comment = "";
            self.timeEntryDispatchId = null;

            self.applyDefaultBonus = false;
            self.bonuses = ko.observableArray();
            self.defaultBonuses = ko.observableArray();

            self.activityOldValue = ko.observable();
            self.tradeOldValue = ko.observable();
            self.shiftOldValue = ko.observable();
            self.durationOldValue = ko.observable();
            self.cancelChange = ko.observable(false);

            self.showEquipmentSection = ko.observable(false);
            self.mustEnterTimeSeparatelyFlag = false;

            self.validatedForm = ko.validatedObservable({
                activity: ko.observable(),
                endTime: ko.observable(),
                subProject: ko.observable(),
                startTime: ko.observable(),
                trade: ko.observable(),
                wageType: ko.observable(),
                shift: ko.observable(),
                bonusListUpdatedFromInterface: ko.observable(),
                equipment: ko.observable(),
                equipmentActivity: ko.observable(),
                equipmentWorkTime: ko.observable(),

                clear: function() {
                    this.activity(null);
                    this.endTime(null);
                    this.subProject(null);
                    this.startTime(null);
                    this.trade(null);
                    this.shift(null);
                    this.wageType(0);
                    this.bonusListUpdatedFromInterface(false);
                    this.equipment(null);
                    this.equipmentActivity(null);
                    this.equipmentWorkTime(null);
                }
            });

            //Validation

            self.validatedForm().endTime.extend({
                validation: {
                    validator: function(val) {
                        if (!val) {
                            this.message = resx.localize('err_EndTimeRequired');
                            return false;
                        }

                        if (moment(val).isSameOrBefore(moment(self.validatedForm().startTime()))) {
                            this.message = resx.localize('err_EndTimeAfterStartTime');
                            return false;
                        }
                        return true;
                    }
                }
            });

            self.validatedForm().startTime.extend({
                required: {
                    message: resx.localize('err_StartTimeRequired')
                }
            });

            self.validatedForm().equipmentActivity.extend({
                validation: {
                    validator: function(val) {
                        if (!val && self.validatedForm().equipment()) {
                            this.message = resx.localize('err_EquipmentActivityRequired');
                            return false;
                        }
                        return true;
                    }
                }
            });

            self.validatedForm().equipmentWorkTime.extend({
                validation: {
                    validator: function(val) {
                        if (!val && self.validatedForm().equipment()) {
                            this.message = resx.localize('err_EquipmentTimeSpentRequired');
                            return false;
                        }
                        return true;
                    }
                }
            });

            //#endregion
        }

        //#region Private Functions
        function updateTitle() {
            if (self.dispatches().length > 1) {
                self.eventAggregator.publish("updateTitle", resx.localize('pageTitle_Project_Detail_Daily_Entry_TimeEntry_Edit_multipleSelected'));
            } else {
                self.eventAggregator.publish("updateTitle", resx.localize('pageTitle_Project_Detail_Daily_Entry_TimeEntry_Edit_singleSelected'));
            }
        }

        function bindViewModel(dispatchProjectCode, dispatchDate, timeEntryId, queryString) {
            self.activityAvailable(!templateService.getCurrentTemplateConfigs().ActivityNotAllowedInMobility);
            self.showShift(templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode !== 1);
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);
            self.readonly = queryStringHelper.parseReadonly(queryString);
            var dispatches = JSON.parse(decodeURIComponent(routerHelper.getQuerystring(queryString).dispatchIds));
            self.dispatches(dispatches);
            self.dispatchTemplateID = self.dispatches()[0].DispatchTemplateID;
            wageSettingHelper.getDispatchTemplateConfigs("Project", self.dispatchTemplateID).done(function (templateConfigs) {
                self.templateConfigs(templateConfigs);
                self.wage(wageSettingHelper.getWageFromTemplateconfigs("Project", self.templateConfigs()));
            });

            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);
            self.timeEntryId(timeEntryId);

            if (dispatches.length > 1){
                self.message(resx.localize("msg_DailyEntry_EnteringTimeForXEmployeesSelected").replace("{0}", dispatches.length));
            } else {
                self.message(dispatches[0].name);
            }
            self.maxDate = moment(dateHelper.addDay(dispatchDate, 1)).format("YYYY-MM-DD");
        }

        async function loadData() {
            var dfd = new jQuery.Deferred();
            var tsService;

            var hasSubProjects = projectService.hasSubProjects(self.dispatchProjectCode());
            self.getAllShifts();
            self.getDefaultShift();
            loadBonusConfig();

            if (self.timeEntryId()) {
                tsService = dispatchTimesheetService.getById(self.timeEntryId());
                return jQuery.when(hasSubProjects, tsService).done(function (hasPrj, timeEntry) {
                    initializeProjects(hasPrj);
                    loadTimeEntry(timeEntry);
                    loadBonuses(self);
                });
            }

            var schedule = projectService.getProjectWorkSchedule(self.dispatchProjectCode());
            var activityTimeService = dispatchTimesheetService.getDefaultTimes(self.dispatchProjectCode(), self.dispatchDate(), _.pluck(self.dispatches(), "id"));
            var lastProjectUsed = projectService.getLastProjectUsed(self.dispatchProjectCode(), self.dispatchDate());
            var date = dateHelper.getDate();

            //if list of seperate equipment > 0 then show
            var seperateTimeEntryEquipment = await self.dispatchProjectTimeEntryService.GetSeperateTimeLinkedEquipmentDispatches(self.dispatches()[0].id);
            self.showEquipmentSection(seperateTimeEntryEquipment.length > 0);

            var trades = undefined;
            if (self.dispatches().length === 1 && self.dispatches()[0].isEmployee) {
                trades = tradesService.getTradeForDispatch(self.dispatches()[0].id);
            }

            var userIncrement = templateService.getUserTemplateIncrement();

            jQuery.when(hasSubProjects, activityTimeService, trades, lastProjectUsed, userIncrement, schedule).done(function (hasPrj, time, trade, lastPrjUsed, userIncrementResult, prjSchedule) {
                self.timeIncrement(userIncrementResult);

                self.duration = self.duration.extend({ duration: { increment: self.timeIncrement(), max: 48.00 } });

                initializeProjects(hasPrj, lastPrjUsed);

                if (time.StartTime) {
                    time.StartTime = dateHelper.roundTime(time.StartTime, self.timeIncrement());
                    self.validatedForm().startTime(time.StartTime);
                }

                if (time.EndTime && moment(time.StartTime).diff(time.EndTime, 'minutes') < 0) {
                    time.EndTime = dateHelper.roundTime(time.EndTime, self.timeIncrement());
                    self.validatedForm().endTime(time.EndTime);
                    updateDuration();
                } else if (!time.EndTime && time.StartTime) {
                    time.EndTime = dateHelper.roundTime(time.StartTime, self.timeIncrement());
                    self.validatedForm().endTime(time.EndTime);
                    updateDuration();
                }

                self.projectWorkSchedule = prjSchedule;

                if (trade) {
                    self.validatedForm().trade({ "id": trade.Code, "text": trade.Code + " - " + trade.Description });
                    dfd.resolve();
                } else {
                    dfd.resolve();
                }

            });

            return dfd;
        }

        function loadBonusConfig() {
            var config = commonService.getProjectConfigs();
            self.applyDefaultBonus = config.ApplyDefaultBonus === DefaultBonusApplyOption.DuringTransactionEntry;
        }

        function loadBonuses() {
            if(self.applyDefaultBonus) {
                if (self.dispatches().length === 1 && self.dispatches()[0].isEmployee) {
                    var bonuses = undefined;
                    bonuses = bonusService.getBonusesForTimeEntry(self.timeEntryId());

                    jQuery.when(bonuses).done(function (bonuses) {
                        self.bonuses(bonuses.BonusesToDisplay);
                        self.defaultBonuses(bonuses.DefaultBonuses);
                    });
                }
            }
        }

        function buildBonusParameters() {
            return {
                Activity: self.validatedForm().activity() ? self.validatedForm().activity().id : null,
                Shift: self.validatedForm().shift() ? self.validatedForm().shift().id : null,
                TradeCode: self.validatedForm().trade() ? self.validatedForm().trade().id : null,
                HoursSimple: self.validatedForm().wageType() === enumHelper.wageType().SIMPLE ? self.duration() : 0,
                HoursHalf: self.validatedForm().wageType() === enumHelper.wageType().OVER ? self.duration() : 0,
                HoursDouble: self.validatedForm().wageType() === enumHelper.wageType().DOUBLE ? self.duration() : 0
            };
        }

        function buildTimeEntryDto() {
            return _.map(self.dispatches(),
                function (dispatch) {
                    var data = {};

                    data.TimeEntryId = self.timeEntryId() ? self.timeEntryId() : -1;

                    data.Comment = self.comment;

                    data.DispatchId = self.timeEntryDispatchId ? self.timeEntryDispatchId : dispatch.id;

                    if (self.validatedForm().subProject()) {
                        //Set the project code if we have it
                        data.ProjectCode = self.validatedForm().subProject().id;
                    } else {
                        //Else pass the dispatchProjectCode and find the construction project server side
                        data.DispatchProjectCode = self.dispatchProjectCode();
                    }

                    if (self.validatedForm().activity()) {
                        data.ActivityCode = self.validatedForm().activity().id;
                        data.ActivityDescription = self.validatedForm().activity().text;
                    }

                    if (self.validatedForm().trade()) {
                        data.TradeCode = self.validatedForm().trade().id;
                        data.TradeDescription = self.validatedForm().trade().text;
                    }

                    data.StartTime = dateHelper.getTime(self.validatedForm().startTime());

                    if (self.validatedForm().endTime()) {
                        data.EndTime = dateHelper.getTime(self.validatedForm().endTime());
                    }

                    data.WageType = self.validatedForm().wageType();

                    if (self.validatedForm().shift()) {
                        data.Shift = self.validatedForm().shift().id;
                    }

                    if (self.defaultBonuses() && self.defaultBonuses().length > 0) {
                        data.DefaultBonuses = self.defaultBonuses();
                    }

                    return data;
                });
        }

        function buildEmployeeEquipmentTimeEntry() {
            let data = {}
            data.TimeEntryId = -1;

            data.DispatchId = self.validatedForm().equipment().id;

            data.DispatchProjectCode = self.dispatchProjectCode;
            data.ActivityCode = self.validatedForm().equipmentActivity().id;

            if (self.selectedSubProject) {
                data.ProjectCode = self.selectedSubProject.id;
            } else {
                data.DispatchProjectCode = self.dispatchProjectCode;
            }

            data.StartTime = dateHelper.getTime(self.validatedForm().startTime());
            if (self.validatedForm().endTime()) {
                data.EndTime = getEndTime(data.StartTime, getWorkTime(self, self.validatedForm().equipmentWorkTime()));
            }

            data.WageType = enumHelper.wageType().SIMPLE;

            data.Shift = self.selectedShift;

            return data
        }

        function getEndTime(strStartTime, strDurationInHours) {
            return dateHelper.getTime(dateHelper.addHour(getDateTime(strStartTime), strDurationInHours));
        }

        function getWorkTime(self, time) {
            if (time) {
                return moment(time).diff(moment(dateHelper.getDate().toISOString()), "minutes") / 60;
            } else {
                return 0;
            }
        }

        function getDateTime(time) {
            let dateTime = new Date();
            dateTime.setHours(time.split(":")[0]);
            dateTime.setMinutes(time.split(":")[1]);
            return dateTime;
        }

        function clear() {
            _.each(self.subscriptions,
                function (s) {
                    s.dispose();
                });

            self.validatedForm().clear();
            self.duration(null);
            self.formattedDuration(null);
            self.showSubProject(false);
            self.defaultActivityCode = null;
        }

        function loadTimeEntry(data) {
            //HACK: The api return no description for Pauses

            self.comment = data.Comment;

            self.timeEntryDispatchId = data.DispatchId;

            if (data.ActivityCode) {
                var actDesc = (data.ActivityCode === PAUSE_ACTIVITY_CODE ? resx.localize("Pause") : data.ActivityDescription);
                var act = { id: data.ActivityCode, text: data.ActivityCode + " - " + actDesc };
                self.validatedForm().activity(act);
            }

            if (data.ProjectCode) {
                self.validatedForm().subProject({ id: data.ProjectCode, text: data.ProjectCode + " - " + data.ProjectDescription });
            }

            if (data.TradeCode) {
                self.validatedForm().trade({ id: data.TradeCode, text: data.TradeCode + " - " + data.TradeDescription });
            }

            var shiftId = data.Shift;
            if (!self.showShift()) {
                self.validatedForm().shift({ id: shiftId, text: "" });
            }
            else {
                setShiftFromList(shiftId);
            }

            self.validatedForm().startTime(data.StartDateTime);
            self.validatedForm().endTime(data.EndDateTime);

            self.validatedForm().wageType(data.WageType);

            self.mustEnterTimeSeparatelyFlag = data.MustEnterTimeSeparatelyFlag;

            updateDuration();
        }

        function setShiftFromList(shiftId) {
            var shift = self.allShifts.find((s) => s.Id === shiftId);
            if (shift) {
                self.validatedForm().shift({ id: shift.Id, text: shift.Id + " - " + shift.Description });
            }
        }

        function initializeProjects(hasPrj, lastPrjUsed) {
            self.showSubProject(hasPrj);
            if (lastPrjUsed) {
                self.validatedForm().subProject({ id: lastPrjUsed.Id, text: lastPrjUsed.Id + ' - ' + lastPrjUsed.Description });
            }
        }

        function validateMidnightSwitch(isStart) {
            var duration = dateHelper.getTimeSpanInHour(self.validatedForm().startTime(), self.validatedForm().endTime());

            if (duration < 0) {
                if (isStart) {
                    self.validatedForm().startTime(dateHelper.addDay(self.validatedForm().startTime(), -1));
                } else {
                    self.validatedForm().endTime(dateHelper.addDay(self.validatedForm().endTime(), 1));
                }
                return true;
            }
            if (duration >= 24) {
                if (isStart) {
                    self.validatedForm().startTime(dateHelper.addDay(self.validatedForm().startTime(), 1));
                } else {
                    self.validatedForm().endTime(dateHelper.addDay(self.validatedForm().endTime(), -1));
                }
                return true;
            }
            return false;
        }

        function initialiseSubscription() {
            var mySelf = self;
            self.subscriptions.push(
                self.validatedForm().startTime.subscribe(function () {
                    if (!validateMidnightSwitch(true)) {
                        updateDuration();
                    }
                }));

            self.subscriptions.push(
                self.validatedForm().endTime.subscribe(function (value) {
                    if (!validateMidnightSwitch(false)) {
                        updateDuration();
                    }
                }));

            self.subscriptions.push(
                self.duration.subscribe(function (value) {
                    if (mySelf.validatedForm().startTime() && value) {
                        mySelf.validatedForm().endTime(dateHelper.addHour(mySelf.validatedForm().startTime(), mySelf.duration()));

                        if (!self.timeEntryId() || self.validatedForm().bonusListUpdatedFromInterface()) {
                            updateBonuses(self);
                        } else {
                            updateBonusesQtiesHours(self);
                        }
                    }
                }));

            self.subscriptions.push(
                self.validatedForm().subProject.subscribe(async function () {
                    mySelf.validatedForm().activity(null);
                    updateBonuses(self);
                    await mySelf.setDefaultActivity();
                }));

            self.subscriptions.push(
                self.validatedForm().wageType.subscribe(function () {
                    if (!self.timeEntryId() || self.validatedForm().bonusListUpdatedFromInterface()) {
                        updateBonuses(self);
                    } else {
                        updateBonusesQtiesHours(self);
                    }
                }));

            self.subscriptions.push(
                self.validatedForm().activity.subscribe(async function () {
                    if (self.applyDefaultBonus && self.dispatches().length === 1 && self.dispatches()[0].isEmployee && self.validatedForm().activity() !== null) {
                        if ((self.activityOldValue() !== null && self.validatedForm().activity().id !== self.activityOldValue().id) || (self.validatedForm().activity() !== null && self.activityOldValue() === null)) {
                            const success = await notificationHelper.showDialogOkCancel(resx.localize("WarningBonusWillBeModified"), "", false);
                            if (success === true) {
                                updateBonuses(self);
                            } else {
                                self.cancelChange(true);
                                self.validatedForm().activity(self.activityOldValue());
                            }
                        }
                    }
                }));

            self.subscriptions.push(
                self.validatedForm().trade.subscribe(async function () {
                    if (self.applyDefaultBonus && self.dispatches().length === 1 && self.dispatches()[0].isEmployee) {
                        if ((self.validatedForm().trade() !== null && self.tradeOldValue() !== null && self.validatedForm().trade().id !== self.tradeOldValue().id) || (self.validatedForm().trade() === null && self.tradeOldValue() !== null) || (self.validatedForm().trade() !== null && self.tradeOldValue() === null)) {
                            if (!self.activityAvailable()) {
                                self.validatedForm().activity(null);

                                if (self.validatedForm().trade().data.DefaultActivityCode) {
                                    self.validatedForm().activity({ id: self.validatedForm().trade().data.DefaultActivityCode })
                                }

                                const success = await notificationHelper.showDialogOkCancel(resx.localize("WarningBonusWillBeModified"), "", false);
                                if (success === true) {
                                    updateBonuses(self);
                                } else {
                                    self.cancelChange(true);
                                    self.validatedForm().trade(self.tradeOldValue());
                                }
                            }
                        }
                    }
                }));

            self.subscriptions.push(
                self.validatedForm().shift.subscribe(async function () {
                    if (self.applyDefaultBonus && self.dispatches().length === 1 && self.dispatches()[0].isEmployee && self.validatedForm().activity() !== null) {
                        if ((self.validatedForm().shift() !== null && self.shiftOldValue() !== null && self.validatedForm().shift().id !== self.shiftOldValue().id) || (self.validatedForm().shift() === null && self.shiftOldValue() !== null) || (self.validatedForm().shift() !== null && self.shiftOldValue() === null)) {
                            const success = await notificationHelper.showDialogOkCancel(resx.localize("WarningBonusWillBeModified"), "", false);
                            if (success === true) {
                                updateBonuses(self);
                            } else {
                                self.cancelChange(true);
                                self.validatedForm().shift(self.shiftOldValue());
                            }
                        }
                    }
                }));

            self.subscriptions.push(
                self.validatedForm().activity.subscribe(function (oldValue) {
                    if (!self.cancelChange()) {
                        self.activityOldValue(oldValue);
                    }
                    self.cancelChange(false);
                }, self, "beforeChange"));

            self.subscriptions.push(
                self.validatedForm().trade.subscribe(function (oldValue) {
                    if (!self.cancelChange()) {
                        self.tradeOldValue(oldValue);
                    }
                    self.cancelChange(false);
                }, self, "beforeChange"));

            self.subscriptions.push(
                self.validatedForm().shift.subscribe(function (oldValue) {
                    if (!self.cancelChange()) {
                        self.shiftOldValue(oldValue);
                    }
                    self.cancelChange(false);
                }, self, "beforeChange"));

            self.subscriptions.push(
                self.validatedForm().equipment.subscribe(async function (newValue) {
                    if (!self.cancelChange()) {
                        var activities = await this.getActivities();
                        const defaultActivity = activities.find(x => x.Id === newValue.data.DefaultActivityId);
                        if (defaultActivity) {
                            self.validatedForm().equipmentActivity({id: defaultActivity.Id, text: defaultActivity.Id + " - " + defaultActivity.Description});
                        }
                    }
                    self.cancelChange(false);
                }, self, "change"));
        }

        function updateDuration() {
            if (self.validatedForm().startTime() && self.validatedForm().endTime()) {
                var duration = dateHelper.getTimeSpanInHour(self.validatedForm().startTime(),
                    self.validatedForm().endTime());

                if (parseFloat(self.duration()) !== duration) {
                    self.duration(duration);
                }
                self.formattedDuration(duration.toFixed(2));
            } else {
                self.duration(null);
                self.formattedDuration(null);
            }
        }

        function containsEquipement() {
            return _.filter(self.dispatches(), function (disp) { return !disp.isEmployee; }).length > 0;
        }

        function containsEmployee() {
            return _.filter(self.dispatches(), function (disp) { return disp.isEmployee; }).length > 0;
        }

        function tSelection(item) {
            if (item.data) {
                return item.data.Id + " - " + item.data.Description;
            }
            return "";
        }

        function initActivityValidation() {
            if (self.activityAvailable()) {
                self.validatedForm().activity.extend({
                    required: {
                        message: resx.localize('err_ActivityRequired')
                    }
                });
            }
        }

        function initShiftValidation() {
            if (self.showShift() && self.allShifts.length > 0) {
                self.validatedForm().shift.extend({
                    validation: {
                        validator: function (val) {
                            if (!val) {
                                return false;
                            }
                            return true;
                        },
                        message: resx.localize("SelectAShift")
                    }
                });
            }
        }

        function getDefaultQueryStringParameters(self) {
            var params = {};

            params.isProjectResponsible = self.isProjectResponsible;
            params.readonly = self.readonly;

            return params;
        }

        function updateBonusesQtiesHours(self) {
            if (!self.applyDefaultBonus || self.dispatches().length !== 1 || !self.dispatches()[0].isEmployee || !self.validatedForm().activity() || self.timeEntryId() === undefined) {
                return;
            }

            var params = buildBonusParameters();

            var bonuses = undefined;

            bonuses = bonusService.updateBonusesQtiesHoursForTimeEntry(self.timeEntryId(), params);

            jQuery.when(bonuses).done(function (bonuses) {
                self.bonuses(bonuses.BonusesToDisplay);
                self.defaultBonuses(bonuses.DefaultBonuses);
            });

        }

        function updateBonuses(self) {

            if (!self.applyDefaultBonus || self.dispatches().length !== 1 || !self.dispatches()[0].isEmployee) {
                return;
            }

            var params = buildBonusParameters();

            var bonuses = undefined;

            if (self.dispatches()[0].id) {
                bonuses = bonusService.getInterfaceBonusesForDispatch(self.dispatches()[0].id, params);

                jQuery.when(bonuses).done(function (bonuses) {
                    self.bonuses(bonuses.BonusesToDisplay);
                    self.defaultBonuses(bonuses.DefaultBonuses);
                    self.validatedForm().bonusListUpdatedFromInterface(true);
                });
            }

        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = async function (params) {
            clear();
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
            updateTitle();
            await loadData();
            if (!params.id) {
                updateBonuses(self)
            }
            if (!params.id) {
                await this.setDefaultActivity();
            }
        };

        ctor.prototype.attached = function () {

            initActivityValidation();
            initShiftValidation();
            if (!self.validatedForm().shift()) {
                setShiftFromList(self.defaultShift);
            }
            initialiseSubscription();
        };

        ctor.prototype.getActivity = {
            transport: function (params, success, failure) {
                if (!self.showSubProject()) {
                    projectService.getActivitiesForDispatchProject(self.dispatchProjectCode(), params.data.filter, params.data.page || 1)
                        .done(function (data) {
                            return success(data);
                        })
                        .fail(failure);
                } else if (self.validatedForm().subProject()) {
                    projectService.getActivitiesForProject(self.validatedForm().subProject().id, params.data.filter, params.data.page || 1)
                        .done(function (data) {
                            return success(data);
                        })
                        .fail(failure);
                }
            },
            mapResults: function (item) {
                return { id: item.Id, text: item.Id + ' - ' + item.Description, data: item, disabled: !item.AvailableInMobileForEquipment && containsEquipement() && containsEmployee() };
            }
        };

        ctor.prototype.getActivities = async function () {
            let data = [];
            if (!self.showSubProject()) {
                data = await projectService.getActivitiesForDispatchProject(self.dispatchProjectCode());

            } else if (self.validatedForm().subProject()) {
                data = await projectService.getActivitiesForProject(self.validatedForm().subProject().id);
            }

            return data.map((item) => {
                let activity = {};

                activity.Id = item.Id;
                activity.Description = item.Description;

                return activity;
            });
        };

        ctor.prototype.getDefaultShift = function() {
            dispatchTimesheetService.getDefaultShift(self.dispatchProjectCode())
                .done((data) => {
                    if (data) {
                        self.defaultShift = data;
                    }
                });
        };

        ctor.prototype.getAllShifts = function() {
            commonService.getShifts()
                .done((data) => { self.allShifts = data; })
                .fail(() => { self.allShifts = []; });
        };

        ctor.prototype.getDefaultDate = function () {
            if (self.validatedForm().startTime()) {
                return self.validatedForm().startTime();
            } else {
                return self.dispatchDate();
            }
        };

        ctor.prototype.getDefaultTime = function () {
            var st = self.validatedForm().startTime();
            return st ? dateHelper.getTime(st) : "00:00";
        };

        ctor.prototype.getShifts = {
            transport: function (params, success, failure) {
                return success(self.allShifts);
            }
        };

        ctor.prototype.getProjects = {
            transport: function (params, success, failure) {
                projectService.getSubProjects(self.dispatchProjectCode(), params.data.filter, params.data.page)
                    .done(function (data) {
                        return success(data);
                    })
                    .fail(failure);
            }
        };

        ctor.prototype.getTrades = {
            transport: function (params, success, failure) {
                tradesService.getTradesForDispatch(_.first(self.dispatches()).id, params.data.filter, params.data.page || 1)
                    .done(function (data) {
                        return success(_.map(data, function (x) {
                            x.Id = x.Code;
                            return x;
                        }));
                    })
                    .fail(failure);
            }
        };

        ctor.prototype.lookupEquipment = {
            transport: function(params, success) {
                self.dispatchProjectTimeEntryService.GetSeperateTimeLinkedEquipmentDispatches(_.first(self.dispatches()).id, params.data.filter, params.data.page)
                .done(function (data) {
                    return success(data);
                });
            },
            mapResults: function(item) {
                return { id: item.DispatchId, text: `${item.Code} - ${item.Description}`, data: item };
            }
        };

        ctor.prototype.save = async function (forceSave) {
            if (!self.validatedForm.isValid()) {
                notificationHelper.showValidationError(self.validatedForm.errors);
                return;
            }

            const dispatchTemplateId = settingHelper.getSelectedDispatchModel();

            const dataToSend = buildTimeEntryDto();

            if (self.showEquipmentSection() && self.validatedForm().equipment()) {
                var equipmentEntry = buildEmployeeEquipmentTimeEntry();
                dataToSend.push(equipmentEntry)
            }

            const result = await dispatchTimesheetService.setTimeEntries(dataToSend, forceSave, dispatchTemplateId, self.dispatchProjectCode(), self.dispatchDate());

            if (!result.HasConflictsOnCurrentProject && !result.HasConflictsOnOtherProjects) {
                routerHelper.navigateBack();
            } else {
                const conflictsMessage = this.projectTimeEntryHelper.buildConflictsMessage(result.Entries);

                const success = await notificationHelper.showDialogYesNo(conflictsMessage, resx.localize("War_ValuesEnteredInTheSameRange"), false);
                if (success === true) {
                    this.save(true); //with force save
                }
            }
        };

        ctor.prototype.resetBonuses = async function () {
            const success = await notificationHelper.showDialogYesNo(resx.localize("WarningResetBonus"), "", false);
            if (success === true) {
                updateBonuses(self);
            }
        }

        ctor.prototype.wageTypeClicked = function (id) {
            self.validatedForm().wageType(id);
        };

        ctor.prototype.updateFromFormattedDuration = function (data, event) {
            if (self.formattedDuration()) {
                self.duration(self.formattedDuration());
            }
        };
        //#endregion

        ctor.prototype.getAddBonusUrl = function (id) {
            var params = getDefaultQueryStringParameters(self);
            params.dispatchIds = [self.dispatches()[0].id].join();

            var queryString = routerHelper.addQuerystring(params);
            var url = id
                ? routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Bonus", self.dispatchProjectCode(), self.dispatchDate(), id)
                : routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Bonus", self.dispatchProjectCode(), self.dispatchDate());

            return url + queryString;
        };

        ctor.prototype.deleteBonus = function (bonusId) {
            notificationHelper.showDeleteConfirmation()
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        bonusService.deleteBonus(bonusId)
                            .done(function () {
                                loadData();
                            })
                            .always(function () {
                                routerHelper.hideLoading();
                            });
                    }
                });
        };

        ctor.prototype.getBonusText = function (bonus) {
            var commonText =  bonus.BonusCode + ' - ' + bonus.BonusDescription + ' ';
            var textDependOnQuantity = bonus.Quantity == 0 ? resx.localize('N/A') : bonus.Quantity + ' ' + bonus.BonusUnit;
            return commonText + textDependOnQuantity;
        };

        ctor.prototype.setDefaultActivity = async function () {
            if (self.dispatches().length === 1 && !self.dispatches()[0].isEmployee && self.activityAvailable()) {
                self.validatedForm().activity(null);

                var activities = await this.getActivities();

                if (self.dispatches()[0].DefaultEquipmentActivityCode) {
                    self.validatedForm().activity(this.createDefaultMaSelectElement(self.dispatches()[0].DefaultEquipmentActivityCode, activities));
                }
            } else if (self.dispatches().length === 1 && self.dispatches()[0].isEmployee && self.activityAvailable()) {
                self.validatedForm().activity(null);

                var activitiesEmp = await this.getActivities();

                if (self.dispatches()[0].DefaultLaborActivityCode) {
                    self.validatedForm().activity(this.createDefaultMaSelectElement(self.dispatches()[0].DefaultLaborActivityCode, activitiesEmp));
                }
            }
        };

        ctor.prototype.createDefaultMaSelectElement = function(id, list) {
            if (!id) { return null; }

            const item = list.find((item) => item.Id === id);
            if (!item) {
                return null
            }

            return { id: item.Id, text: item.Id + " - " + item.Description };
        };

        return ctor;
    })();

    return viewModel;
});
