import { CallType } from "api/enums/call-type";

define([
    "knockout",
    "core/resx",
    "services/serviceService",
    //"plugins/router",
    "helpers/routerHelper",
    "services/serviceService",
    "repositories/settingRepository",
    "helpers/enumHelper",
    "helpers/dateHelper",
], function (ko, resx, service, /*router, */routerHelper, serviceService, settingRepository, enumHelper, dateHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

//#region Properties
            self.resx = resx;
            self.list = ko.observableArray([]);
            self.dispatchId = ko.observable();
            self.serviceType = "";
            self.enumPresence = enumHelper.servicePresence();
            self.routerHelper = routerHelper;
            self.dateHelper = dateHelper;
            self.callType = CallType;
//#endregion
        }

//#region Private Functions
        function bindViewModel(serviceType, dispatchId, querystring) {
            self.dispatchId(dispatchId);
            self.serviceType = serviceType;
        }

        function loadData() {
            routerHelper.showLoading();
            return serviceService.getSameAddressCalls(settingRepository.getDispatchTemplate(), self.dispatchId(), self.serviceType)
                .done(initialise)
                .fail(clearList())
                .always(routerHelper.hideLoading);
        }

        function initialise(data) {
            self.list(data.Calls);
        }

        function clearList() {
            self.list([]);
        }
//#endregion

//#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.serviceType, params.dispatchId, params.q);
            return loadData();
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});