define([
    "knockout",
    "core/resx",
    "core/val",
    "jquery",
    "underscore",
    "helpers/routerHelper",
    "helpers/labelHelper",
    "helpers/dateHelper",
    "services/serviceService",
    "services/dispatchService",
    "helpers/notificationHelper",
    "services/defaultService",
    "moment",
    "helpers/viewbag",
    "knockout.validation",
    "select2"
], function (ko, resx, val, jQuery, _, routerHelper, labelHelper, dateHelper, service, dispatch, notifier, defaultValues, moment, viewbag) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.val = val;
            self.resx = resx;
            self.serviceType = ko.observable();
            self.dispatchId = ko.observable();
            self.dateHelper = dateHelper;
            self.moment = moment;
            self.increment = ko.observable();
            self.client = ko.observable({});
            self.dispatchStatus = ko.observable({});
            self.isDispatchFinished = ko.observable(false);
            self.isFollowUpScheduled = ko.observable(false);
            self.statusList = ko.observableArray([]);
            self.statusDisabled = false;
            self.signatureAvailable = false;
            self.completeAvailable = false;
            self.showSignature = ko.observable(false);
            self.showComplete = ko.observable(false);
            self.isLead = ko.observable(false);
            self.isMultiVisitForm = ko.observable(false);
            self.isLastVisit = ko.observable(false);
            self.isMultiAndLastVisit = ko.observable(false);
            self.oneDayWorkDuration = false;
            self.dateFormat = 'time';
            self.isMaintenanceWorkOrder = ko.observable(false);

            self.validatedForm = ko.validatedObservable({
                dateFrom: ko.observable(),
                selectedStatus: ko.observable({}),
                externalWO: ko.observable(''),
                poNumber: ko.observable(''),
                estimatedLength: ko.observable(dateHelper.getVeryShortDateLocal(moment())),
                followUpDate: ko.observable(dateHelper.getVeryShortDateLocal(moment())),
                assignedDate: ko.observable(),
                isDefaultContactSelected: ko.observable(false),
                init: function() {
                    var validatedFormSelf = this;

                    validatedFormSelf.estimatedLength.extend({
                        required: {
                            onlyIf: function() {
                                return !self.isDispatchFinished() && self.isFollowUpScheduled() && (self.isLead() || self.isMultiVisitForm());
                            },
                            message: resx.localize('err_EstimatedLengthMissing')
                        },
                        validation: {
                            validator: function(val) {
                                if (!self.isDispatchFinished() && self.isFollowUpScheduled()) {
                                    return dateHelper.toHourDecimal(val) > 0;
                                }
                                return true;
                            },
                            message: resx.localize('err_EstimatedLengthMinLength')
                        }
                    });

                    validatedFormSelf.selectedStatus.extend({
                        required: {
                            onlyIf: function() {
                                return !self.statusDisabled;
                            },
                            message: validatedFormSelf.isDefaultContactSelected()
                                ? resx.localize('err_DefaultStatusUnavailable')
                                : resx.localize('err_StatusRequired')
                        }
                    });

                    validatedFormSelf.followUpDate.extend({
                        validation: {
                            validator: function(val) {
                                var followUpDate = moment(validatedFormSelf.followUpDate());
                                var assignedDate = moment(validatedFormSelf.assignedDate());

                                if (!self.isDispatchFinished() &&
                                    self.isFollowUpScheduled() &&
                                    !followUpDate.isSameOrAfter(assignedDate)) {
                                    return false;
                                }

                                return true;
                            },
                            message: resx.localize('err_FollowUpDateMustBeGreaterThanAssignedDate')
                        }
                    });

                    validatedFormSelf.dateFrom.extend({
                        date: true,
                        validation: {
                            validator: function(val) {
                                var startDate = moment(validatedFormSelf.assignedDate());
                                var endDate = moment(val);

                                if (startDate.isAfter(endDate)) {
                                    this.message = resx.localize("ApiError.531");
                                    return false;
                                }
                                return true;
                            }
                        }
                    });
                },
                clear: function() {
                    var validatedFormSelf = this;

                    validatedFormSelf.dateFrom();
                    validatedFormSelf.selectedStatus({});
                    validatedFormSelf.externalWO('');
                    validatedFormSelf.estimatedLength(dateHelper.getVeryShortDateLocal(moment()));
                    validatedFormSelf.followUpDate(moment());
                    validatedFormSelf.assignedDate();
                    validatedFormSelf.isDefaultContactSelected(false);
                }
            });

            self.statusPlaceHolder = ko.computed(function() {
                if (self.dispatchStatus().CustomStatus !== "") {
                    self.validatedForm().isDefaultContactSelected(true);
                    return self.dispatchStatus().CustomStatus + ' - ' + self.dispatchStatus().CustomStatusDescription;
                } else {
                    self.validatedForm().isDefaultContactSelected(false);
                    return self.statusDisabled ? resx.localize('NoStatusAvailable') : resx.localize("SelectOoo");
                }
            });

            self.isDispatchFinished.subscribe(function (newValue) {
                updateShowSignatureAndComplete();
                self.isFollowUpScheduled(false);
            });
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(serviceType, dispatchId, assignedDate, dateFrom, increment, queryString) {
            var params = routerHelper.getQuerystring(queryString);
            if (params.isMaintenanceWorkOrder) {
                self.isMaintenanceWorkOrder(params.isMaintenanceWorkOrder == 'true');
            }

            self.serviceType(serviceType);
            self.dispatchId(dispatchId);
            self.validatedForm().dateFrom(dateFrom);
            self.validatedForm().assignedDate(assignedDate);
            self.oneDayWorkDuration = !dateHelper.isAfter(dateFrom, assignedDate);
            self.dateFormat = self.oneDayWorkDuration ? 'time' : 'datetime';
            self.increment(increment);

            if (self.isMaintenanceWorkOrder()) {
                self.isDispatchFinished(true) 
            }
        }

        function updateShowSignatureAndComplete() {
            self.showSignature(self.signatureAvailable && (!self.isMultiVisitForm() || self.isDispatchFinished()));
            self.showComplete(self.completeAvailable || !self.showSignature());
        }

        function bindLoadedData(status, client, dispatchStatus) {
            if (client === null) {
                self.client({ MobilitySignatureRequiredness: 0 });
            } else {
                self.client(client);
            }

            status = _.map(status, function (tmpData) {
                return {
                    id: tmpData.Id,
                    text: tmpData.Id + ' - ' + tmpData.Description
                };
            });

            self.statusList(status);
            self.isLead(dispatchStatus.IsLead);
            self.isMultiVisitForm(dispatchStatus.IsMultiVisitForm);
            self.isLastVisit(dispatchStatus.IsLastVisit);
            self.isMultiAndLastVisit(self.isMultiVisitForm() && self.isLastVisit());
            self.statusDisabled = self.statusList().length === 0;
            //if client not specified we allow both signature and complete
            if (self.client()) {
                self.signatureAvailable = self.client().MobilitySignatureRequiredness !== 2;
                self.completeAvailable = self.client().MobilitySignatureRequiredness !== 1;
            } else {
                self.signatureAvailable = true;
                self.completeAvailable = true;
            }

            if (self.isMultiVisitForm()) {
                //if it's a multi-visit, signature is only avalaible if required and that there are no more planned visits.
                self.signatureAvailable = self.signatureAvailable && self.isLastVisit();
                self.completeAvailable = self.completeAvailable || !self.isLastVisit();
            }

            updateShowSignatureAndComplete();

            self.validatedForm().externalWO(dispatchStatus.Workorder);
            self.validatedForm().poNumber(dispatchStatus.OrderNumber);

            self.dispatchStatus(dispatchStatus);
            if (self.dispatchStatus().CustomStatus !== "" && _.find(self.statusList(), function (item) { return item.id === self.dispatchStatus().CustomStatus; })) {
                self.validatedForm().selectedStatus(self.dispatchStatus().CustomStatus);
            }

            self.validatedForm().init();
        }

        function buildSaveData() {
            return {
                DispatchId: self.dispatchId(),
                ServiceType: self.serviceType(),
                Comment: "",
                CustomStatus: self.validatedForm().selectedStatus() !== undefined
                    ? self.validatedForm().selectedStatus()
                    : '',
                Date: self.validatedForm().followUpDate(),
                Hour: dateHelper.getTime(moment()).replace(':', ''),
                IsComplete: self.isDispatchFinished(),
                IsTechWillReturn: self.isFollowUpScheduled(),
                Length: dateHelper.toHourDecimal(self.validatedForm().estimatedLength()),
                Workorder: self.validatedForm().externalWO(),
                EndDate: dateHelper.formatDateToSend(self.validatedForm().dateFrom()),
                OrderNumber: self.validatedForm().poNumber(),
                IsClosed: true
            };
        }

        function loadData() {
            var dfdStatus = service.getCustomStatus(self.serviceType());
            var dfdClient = dispatch.getClient(self.dispatchId());
            var dfdDispatchStatus = service.getStatus(self.serviceType(), self.dispatchId());

            return jQuery.when(dfdStatus, dfdClient, dfdDispatchStatus).done(function (status, client, dispatchStatus) {
                bindLoadedData(status, client, dispatchStatus);
            });
        }

        function initDropDownList() {
            jQuery("#ddlStatus").select2({
                minimumResultsForSearch: defaultValues.getMinimumResultsForSearch(),
                placeholder: self.statusPlaceHolder(),
                width: "100%",
                language: labelHelper.getDefaultSelect2Labels(),
                data: self.statusList()

            }).on("change", function (e) {
                self.validatedForm().selectedStatus(e.target.value);
            }).val(self.validatedForm().selectedStatus()).trigger("change");
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.validatedForm().clear();
            bindViewModel(params.serviceType, params.dispatchId, decodeURIComponent(params.assignedDate), decodeURIComponent(params.dateFrom), params.increment, params.q);
            return loadData();
        };

        ctor.prototype.attached = function () {
            initDropDownList(self);
        };

        ctor.prototype.saveNotLead = function () {
            if (self.validatedForm.isValid()) {
                routerHelper.showLoading();
                dispatch.setServiceCallFinish(self.dispatchId(), dateHelper.formatDateToSend(self.validatedForm().dateFrom()), {})
                        .done(function () {
                            routerHelper.navigateBack();
                        })
                        .always(routerHelper.hideLoading);
            } else {
                notifier.showValidationError(self.validatedForm.errors);
            }
        };

        ctor.prototype.save = function (signatureRequired) {
            if (self.validatedForm.isValid()) {
                var data = buildSaveData();

                if (signatureRequired) {
                    viewbag(data);
                    var url = 'service/' + self.serviceType() + '/' + self.dispatchId() + '/summary';
                    url += routerHelper.addQuerystring({ readonly: false });

                    routerHelper.navigate(url, {
                        replace: true,
                        trigger: true
                    });

                    notifier.showWarning(resx.localize('RequisitionCompleteSignatureRequiredText'));
                } else {
                    routerHelper.showLoading();
                    dispatch.setServiceCallFinish(self.dispatchId(), dateHelper.formatDateToSend(self.validatedForm().dateFrom()), data)
                        .done(function () {
                            if (!self.isMultiVisitForm() || (self.isMultiAndLastVisit() && self.isDispatchFinished())) {
                                if (!self.isMaintenanceWorkOrder()) {
                                    dispatch.printform(self.dispatchId(), '');
                                }
                            }
                            routerHelper.navigateBack();
                        }).fail(function (xhr) {
                            var t = xhr;
                        })
                        .always(routerHelper.hideLoading);
                }
            } else {
                notifier.showValidationError(self.validatedForm.errors);
            }
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
