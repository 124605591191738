import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";

import { default as apiHelper } from "helpers/apiHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

import { default as templateService } from "services/templateService";
import { default as purchaseOrderService } from "services/purchaseOrderService";

@inject(I18N)
export default class MaterialOrderItem {

    workOrderId;
    lineNo = '';
    orderDate = null;
    orderId = '';
    extension = '';
    showNotInCatalog = false;
    readonly = false;

    constructor(i18n) {
        this.i18n = i18n;
    }

    setSpecificFields(item) {
        item.DispatchId = 0;
        return item;
    }

    bindComponentActions() {
        this.actions = {
            setSpecificFields: item => this.setSpecificFields(item),
            savePurchaseOrderItem: (orderId, lineNo, item) => purchaseOrderService.setPurchaseOrderItemForWorkOrder(this.workOrderId, orderId, lineNo, item)
        };
    }

    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }
        return true;
    }

    activate(params) {
        this.workOrderId = params.workOrderId;
        this.requisitionDate = dateHelper.dateFromNavigationParameter(params.orderDate);
        this.orderId = params.orderId;
        this.catalogBaseUrl = apiHelper.getBaseUrl() +
            "/catalog/fieldservices/materialrequisitions?dispatchTemplateId=" +
            settingHelper.getSelectedDispatchModel();
        this.lineNo = params.lineNo;

        if (params.q) {
            this.readonly = queryStringHelper.parseReadonly(params.q);

            const extension = queryStringHelper.parse(params.q, "extension");
            this.projectId =  queryStringHelper.parse(params.q, "projectCode") !== "null" ? queryStringHelper.parse(params.q, "projectCode") : undefined;

            this.extension = extension ? extension : "";
        }
        this.bindComponentActions();

        return templateService.getUserTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialFieldService;
        });
    }
}