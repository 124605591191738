define([
    "underscore",
    "knockout",
    "helpers/routerHelper",
    "moment",
    "widgets/pages/employee_addNonAvailability"
], function (_, ko, routerHelper, moment) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties

            self.dispatchProjectCode = ko.observable('');
            self.dispatchDate = ko.observable('');
            self.employeeId = ko.observable(0);

            self.startTime = ko.observable(moment());
            self.endTime = ko.observable(moment());

            //#endregion
        }

        //#region Private Functions

        function bindViewModel(params) {
            //var params = routerHelper.getQuerystring(queryString);

            self.startTime(params.startTime);
            self.endTime(params.endTime);
            self.employeeId(params.employeeId);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params);

            return true;
        };

        //#endregion
        return ctor;
    })();

    return viewModel;
});
