import { EmployeeModelBase } from "api/models/company/employee/employee-model-base";
import { PagingInfo } from "api/paging-info";
import { EmployeeProxy } from "api/proxies/employee-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class EmployeeService {
    private employeeProxy: EmployeeProxy;

    constructor(employeeProxy: EmployeeProxy) {
        this.employeeProxy = employeeProxy;
    }

    public async getEmployeesLookup(templateCode: string | null, filter?: string, pagingInfo?: PagingInfo, httpClientRequestConfig?: HttpClientRequestConfig): Promise<EmployeeModelBase[] | null>  {
        return await this.employeeProxy.GetEmployeesLookup(templateCode, filter, pagingInfo, httpClientRequestConfig);
    }

    public async getTechnicians(filter?: string, pagingInfo?: PagingInfo, httpClientRequestConfig?: HttpClientRequestConfig): Promise<EmployeeModelBase[] | null> {
        return await this.employeeProxy.GetTechnicians(filter, pagingInfo, httpClientRequestConfig);
    }

}
