define([
    "helpers/queryStringHelper",
    "services/materialRequisitionService"
], function (queryStringHelper, materialRequisitionService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.actions = {};
            self.dispatchProjectCode = "";
            self.isProjectResponsible = false;
            //#endregion
        }

        //#region Private Function
        function bindViewModel(dispatchProjectCode, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.dispatchProjectCode = dispatchProjectCode;
        }

        function bindWidget() {
            self.actions = {
                getRequisitions: materialRequisitionService.getMaterialRequisitionsForProject.bind(self, self.dispatchProjectCode)
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.q);
            bindWidget();
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
