import { autoinject } from "aurelia-framework";
import { RouterHelper } from "helpers/router-helper";

import { InspectionService } from "services/inspection-service";
import { InspectionSignatureService } from "services/inspection-signature-service";

import { DocumentModel } from "api/models/company/document/document-model";
import { InspectionModel } from "api/models/company/inspection/inspection-model";

@autoinject
export class InspectionSignature {

    public inspection!: InspectionModel;

    public signatureNo: number = 0;
    public signatureName: string = "";

    public signature!: DocumentModel;

    public signatureTxt: string = "";
    public errorTxt: any;
    public actions: any;

    constructor(private readonly inspectionSignatureService: InspectionSignatureService, private readonly inspectionService: InspectionService, private readonly routerHelper: RouterHelper) {
    }

    public async activate(params: any): Promise<void> {
        this.inspection = (await this.inspectionService.GetInspection(params.inspectionId))!;

        this.signatureNo = Number.parseInt(params.signatureNo);
        this.signatureName = params.signatureName;

        this.actions = {
            close: (): JQueryDeferred<any> => {
                const dfd = jQuery.Deferred();
                this.inspectionSignatureService.AddSignatureForInspection(this.inspection.Id, this.signatureNo, this.signature).then(() => {
                    return dfd.resolve();
                });
                return dfd;
             },
            sign: (signature: DocumentModel): any => {
                this.signature = signature;
                this.signature.Comment = this.signatureName;
            },
            printForm: (): any => {
                //No Print
            }
        };
    }
}
