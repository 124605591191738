define([
    "knockout",
    "underscore",
    "jquery",
    "services/documentService",
    "helpers/routerHelper",
    "helpers/documentHelper",
    "select2"
], function (ko, _, jQuery, documentService, routerHelper, documentHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = true;
            self.workOrderId = ko.observable();
            self.dispatchDate = ko.observable();
            self.dipatchId = ko.observable();

            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            var paramsQs = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQs && paramsQs.readonly === "false");
            self.dipatchId(params.dispatchId);
            self.workOrderId(params.workOrderId);
            self.actions = {
                getSections: documentService.getCertificatesSectionsByDispatchProjectDispatchId.bind(self, params.dispatchId, true),
                getSectionDocuments: documentService.getCertificatesDispatchDocumentsByDispatchProjectDispatchIdSectionId.bind(self, params.dispatchId, true),
                getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
                sectionToOpen: params.certificationAssignmentId,
                deleteDocument: documentService.deleteDocument(documentService)
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
