define([
    "knockout",
    "underscore",
    "jquery",
    "services/documentService",
    "helpers/documentHelper",
    "select2"
], function (ko, _, jQuery, documentService, documentHelper) {
    "use strict";



    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.dispatchId = ko.observable();
            self.serviceType = ko.observable();
            self.isProjectResponsible = false;
            self.readyonly = true;
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.readonly = true;
            self.dispatchId(params.dispatchId);
            self.actions = {
                getSections: documentService.getCertificatesSectionsByDispatchId.bind(self, params.serviceType, params.dispatchId),
                getSectionDocuments: documentService.getCertificatesDispatchDocumentsBySectionId.bind(self, params.serviceType, params.dispatchId),
                getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
                sectionToOpen: params.certificationAssignmentId,
                deleteDocument: documentService.deleteDocument.bind(documentService)
            };



        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
