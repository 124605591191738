/****************************************************************************************
 * IMPORTANT - KNOWN ISSUE
 * In one run (no refresh), if a dispatch is accessed from the mobile site,
 * then deleted in maestro* and a new dispatch is added to the same person,
 * the header won't be refreshed if you access the new dispatch since they have the 
 * same dispatchId.
 * 
 * This is cause by maestro* which attributes the first available number as dispatchId
 * instead of using an auto-increment identity field.
 ****************************************************************************************/
import { singleton } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as dispatchService } from "services/dispatchService";
import { default as projectService } from "services/projectService";
import { default as dateHelper } from "helpers/dateHelper";

function updateValues(self, dispatchProjectCode, dispatchDate, description) {
    if (dispatchDate) {
        self.title(dateHelper.getFullTextDate(dispatchDate));
    } else {
        self.title('');
    }

    self.subtitle(dispatchProjectCode + " - " + description);
    self.url("#project/" + encodeURIComponent(dispatchProjectCode) + "/information");

    self.previousDispatchProjectCode = dispatchProjectCode;
    self.previousDescription = description;
}

function loadData(self, dispatchProjectCode, dispatchDate) {
    if (dispatchProjectCode !== self.previousDispatchProjectCode) {
        projectService.getHeader(dispatchProjectCode).done(function (data) {
            updateValues(self, dispatchProjectCode, dispatchDate, data.Description);
        });
    } else {
        updateValues(self, dispatchProjectCode, dispatchDate, self.previousDescription);
    }
}

@singleton()
export class ProjectHeader {
    constructor() {
        this.previousDispatchProjectCode = '';
        this.previousDescription = '';

        this.hideInfo = false;
        this.title = ko.observable('');
        this.subtitle = ko.observable('');
        this.url = ko.observable('');
    }

    activate(widgetSettings) {
        this.hideInfo = widgetSettings.hideInfo || false;
        loadData(this, widgetSettings.dispatchProjectCode(), widgetSettings.dispatchDate ? widgetSettings.dispatchDate() : undefined);
    }
}