import { default as routerHelper } from "helpers/routerHelper";
import { default as purchaseOrderService } from "services/purchaseOrderService"
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { observable, computedFrom, autoinject } from "aurelia-framework";

function setSpecificFields(item) {
    return item;
}

@autoinject()
export class MaterialOrderEdit {

    @observable dispatchProjectCode = "";
    @observable dispatchDate = "";
    @observable projectCode = "";
    @observable orderId = "";
    @observable extension = "";

    @computedFrom("dispatchDate")
    get documentUrl() {
        const queryString = routerHelper.addQuerystring({
            dispatchProjectDate: this.dispatchDate
        });

        return `${routerHelper.getRelativeUrl("document")}${queryString}`;
    }

    constructor() {
        this.isProjectResponsible = false;
        this.readonly = false;
    }

    activate(params) {
        this.dispatchProjectCode= params.dispatchProjectCode;

        this.orderId = params.orderId ? params.orderId : "-1";
        this.extension= params.extension ? params.extension : "";

        if (params.q) {
            const qsParams = routerHelper.getQuerystring(params.q);
            if (qsParams) {
                this.readonly = queryStringHelper.parseReadonly(params.q);
            }

            if (qsParams && qsParams.dispatchDate) {
                this.dispatchDate = qsParams.dispatchDate;
            }

            if (qsParams && qsParams.projectCode) {
                this.projectCode= qsParams.projectCode;
            }

            if (qsParams && qsParams.extension) {
                this.extension = qsParams.extension ? qsParams.extension : "";
            }

        }

        this.actions = {
            getPurchaseOrder: purchaseOrderService.getPurchaseOrder.bind(this, this.orderId, this.extension),
            savePurchaseOrderService: purchaseOrderService.setPurchaseOrderHeaderForProject.bind(this, this.projectCode),
            setSpecificFields: setSpecificFields
        };
    }
}