define([
    "underscore",
    "knockout",
    "core/resx",
    "services/activityService",
    "helpers/dateHelper",    
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper"
], function (_, ko, resx, activityService, dateHelper, routerHelper, notificationHelper, queryStringHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.dispatchDate = ko.observable();
            self.dispatchProjectCode = ko.observable();
            self.isProjectResponsible = false;
            self.readonly = false;
            self.resx = resx;
            self.activities = ko.observableArray([]);
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);

            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dispatchDate);
        }        

        function loadData() {
            return activityService.getActivitiesProgressForDailyEntry(self.dispatchProjectCode(), self.dispatchDate())
                .done(function (data) {
                    self.activities(data);
                });
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);

            return loadData();
        };

        ctor.prototype.goToUrl = function (rel) {
            if (!self.readonly) {
                routerHelper.navigate(self.genUrl(rel));
            }
        };

        ctor.prototype.genUrl = function (rel) {
            var url = routerHelper.getRelativeUrl(rel);
            url += routerHelper.addQuerystring({
                isProjectResponsible: self.isProjectResponsible,
                readonly: self.readonly
            });

            return url;
        };

        ctor.prototype.gotoAddPage = function() {
            routerHelper.navigate(self.genUrl('edit', 0));
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
