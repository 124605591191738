import { autoinject, observable } from "aurelia-framework";

import notificationHelper from "helpers/notificationHelper";

import { ProcoreConfigurationService } from "services/procore/procore-configuration-service";
import { ProcoreConnectionInfoModel } from "api/models/company/procore/procore-connection-info-model";
import { I18N } from "aurelia-i18n";
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { ProcoreConfiguration } from "../configuration";

@autoinject()
export class ConfigurationAuthentification {
  public urlSelected: any = this.urlList[0];
  
  @observable({ changeHandler: "setDirtyTrue" })
  public isCustomUrl: boolean = false;
  @observable({ changeHandler: "setDirtyTrue" })
  public urlCustom: string = "";

  @observable({ changeHandler: "setDirtyTrue" })
  public companyId?: number;
  @observable({ changeHandler: "setDirtyTrue" })
  public procoreCompanyName: string | null = "";

  @observable({ changeHandler: "setDirtyTrue" })
  public clientId: string | null = "";
  @observable({ changeHandler: "setDirtyTrue" })
  public clientSecret: string | null = "";

  public connectionTestWaitingHtmlElement?: HTMLElement;
  public connectionTestSuccessHtmlElement?: HTMLElement;
  public connectionTestFailedHtmlElement?: HTMLElement;

  constructor(public configurations: ProcoreConfiguration,
              private readonly procoreConfigurationService: ProcoreConfigurationService,
              private readonly i18n: I18N) { }

  public Load(data: ProcoreCompanyConfigurationModel): void {
    this.companyId = data.ProcoreCompanyId;
    this.procoreCompanyName = data.ProcoreCompanyName;

    this.clientId = data.ProcoreApiClientId;
    this.clientSecret = data.ProcoreApiClientSecret;

    if (!data.ProcoreApiUrl ||
        this.urlList.some((url: any) => url.text === data.ProcoreApiUrl)) { // Is in List
      this.isCustomUrl = false;
      this.urlSelected = this.urlList.find((url: any) => url.text === data.ProcoreApiUrl);
      
    } else { // Custom Url
      this.isCustomUrl = true;
      this.urlCustom = data.ProcoreApiUrl;
    }
  }

  public async connexionTest(): Promise<void> {
    if (!this.connectionTestWaitingHtmlElement ||
        !this.connectionTestSuccessHtmlElement ||
        !this.connectionTestFailedHtmlElement) {

      notificationHelper.showError(this.i18n.tr(`Procore.Config_Load_Error`), "", { isErrorBox: true });
      return;
    }

    this.displayHtmlElement(this.connectionTestWaitingHtmlElement); // Display spinner icon

    const url = this.isCustomUrl ? this.urlCustom
                                 : this.urlSelected.text;

    const companyId = !!this.companyId ? this.companyId : 0;

    const info = {
      ProcoreCompanyId: companyId,
      ProcoreApiUrl: url,
      ProcoreApiClientId: this.clientId,
      ProcoreApiClientSecret: this.clientSecret
    } as ProcoreConnectionInfoModel;

    const results = await this.procoreConfigurationService.TestProcoreConnection(info);

    if (!results) {

      notificationHelper.showError(this.i18n.tr(`Procore.Config_Load_Error`), "");
      return;
    }

    if (results.Success) {
      this.displayHtmlElement(this.connectionTestSuccessHtmlElement); // Display success icon
      this.procoreCompanyName = results.ProcoreCompanyName;

    } else {
      if (!results.Error) {
        return;
      }

      this.procoreCompanyName = "";

      this.displayHtmlElement(this.connectionTestFailedHtmlElement); // Display failed icon

      const language = localStorage.getItem("maestro_user_profile_LANGUAGE");

      const message = language === "fr" ? results.Error.MessageFr
        : results.Error.MessageEn;

      if (message) {
        notificationHelper.showDialogOk(message);
      }
    }
  }

  public trimId(): void {
    if (this.clientId) {
      this.clientId = this.clientId.trim();
    }
  }

  public trimSecret(): void {
    if (this.clientSecret) {
      this.clientSecret = this.clientSecret.trim();
    }
  }

  public trimUrl(): void {
    if (this.urlCustom) {
      this.urlCustom = this.urlCustom.trim();
    }
  }

  private displayHtmlElement(htmlElement: HTMLElement): void {
    if (!this.connectionTestWaitingHtmlElement ||
        !this.connectionTestSuccessHtmlElement ||
        !this.connectionTestFailedHtmlElement) {

      notificationHelper.showError(this.i18n.tr(`Procore.Config_Load_Error`), "");
      return;
    }

    this.connectionTestWaitingHtmlElement.setAttribute("hidden", "");
    this.connectionTestSuccessHtmlElement.setAttribute("hidden", "");
    this.connectionTestFailedHtmlElement.setAttribute("hidden", "");

    htmlElement.removeAttribute("hidden");
  }

  private get urlList(): any[] {
    return [
      { id: 1, text: "https://api-monthly.procore.com" },
      { id: 2, text: "https://sandbox.procore.com" },
      { id: 3, text: "https://api.procore.com" }
    ];
  }

  private setDirtyTrue(): void {
    this.configurations.isDirty = true;
  }

  private setDirtyFalse(): void {
    this.configurations.isDirty = false;
  }
}
