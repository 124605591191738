
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { default as serviceService } from "services/serviceService";
import RouteRepository from "repositories/routeRepository";

import { WorkOrderWorkTaskService } from "services/work-order-work-task-service";
import { RouteInfo } from "components/others/ma-work-task-index";

@autoinject
export class WorkTaskIndex {
    public dispatchId: any;

    public getWorkTask!: () => Promise<any>;
    public addRouteInfo: RouteInfo = { routeName: "", routeParams: {}};
    public editRouteInfo: RouteInfo = { routeName: "", routeParams: {}};
    public id: any;
    public readonly: boolean = false;

    constructor(private routeRepository: RouteRepository, private readonly workOrderWorkTaskService: WorkOrderWorkTaskService) {}

    public async activate(params: any): Promise<void> {
        this.dispatchId = params.id;

        const detailsPromise = await serviceService.getDetail(params.serviceType, this.dispatchId).promise();
        const readonly = detailsPromise.IsReadonly || detailsPromise.IsClosed;

        this.getWorkTask = (await this.workOrderWorkTaskService.GetWorkTasksByDispatchId.bind(this.workOrderWorkTaskService, this.dispatchId));
        this.addRouteInfo.routeName = this.routeRepository.routes.Service_Detail_WorkTask_Add.name;
        this.addRouteInfo.routeParams = { id: this.dispatchId , serviceType: params.serviceType};
        this.editRouteInfo.routeName = this.routeRepository.routes.Service_Detail_WorkTask_Edit.name;
        this.editRouteInfo.routeParams = { id: this.dispatchId , serviceType: params.serviceType, readonly: readonly};
        this.id = this.dispatchId;
    }
}
