import { autoinject } from "aurelia-framework";
import { DailyEntryLinkEquipmentService } from "services/daily-entry-link-equipment-service";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { RouterHelper } from "helpers/router-helper";

@autoinject()
export class DalyEntryAddLinkEquipment {
    public selectedEquipment: EquipmentModel | null = null;
    public dispatchProjectCode: string  = "";
    public dispatchDate: any = null;
    public dispatchId: number = 0;
    public qParams: any;

    constructor(
        private readonly routerHelper: RouterHelper,
        private readonly dailyEntryLinkEquipmentService: DailyEntryLinkEquipmentService
    ) {
    }

    public async activate(params: any): Promise<void> {
        this.dispatchId = params.id;
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.qParams = this.routerHelper.getQuerystring(params.q);
    }

    public async selectedEquipmentChanged(event: CustomEvent<EquipmentModel>): Promise<void> {
        this.selectedEquipment = event ? event.detail : null;
    }

    public async populateAddedEquipments(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<EquipmentModel[] | null> {
        return await this.dailyEntryLinkEquipmentService.getDailyEntryEquipments(this.dispatchProjectCode, this.dispatchDate, filter, pagingInfo, requestConfig);
    }

    public async save(): Promise<void> {
        await this.dailyEntryLinkEquipmentService.saveNewDailyEntryLinkEquipment(this.dispatchId, this.selectedEquipment);
        this.routerHelper.navigateBack();
    }
}
