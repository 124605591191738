define([
    "knockout",
    "jquery",
    "helpers/queryStringHelper",
    "services/serviceService",
    "services/purchaseOrderService",
    "widgets/pages/material"
], function (ko, jQuery, queryStringHelper, services, purchaseOrderService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.dispatchId = ko.observable();
            self.readonly = false;
            self.serviceType = "";
            self.fromHistory = false;
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(serviceType, dispatchId, querystring) {
            self.dispatchId(dispatchId);
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.serviceType = serviceType;
            self.fromHistory = queryStringHelper.parse(querystring, "fromHistory");
            if (!self.fromHistory) {
                self.fromHistory = false;
            } else if (self.fromHistory === "true") {
                self.fromHistory = true;
            }

            self.actions = {
                deleteMaterial: services.deleteMaterial.bind(null, self.serviceType, self.dispatchId()),
                getMaterials: services.getMaterials.bind(null, self.serviceType, self.dispatchId(), self.fromHistory),
                getReserved: getReserved,
                setReservedMaterials: services.setReservedMaterials.bind(null, self.serviceType, self.dispatchId())
            };
        }

        function getReserved() {
            return services.getIsReservedMaterials(self.serviceType, self.dispatchId());
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function( params ) {
            bindViewModel(params.serviceType, params.dispatchId, params.q);
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
