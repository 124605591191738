define([
    "jquery",
    "knockout",
    "underscore"
], function (jQuery, ko, _) {
    "use strict";

    function buildAjaxData(maSelect, params) {
        //hack for filterSelected
        var filter = params.term ? params.term : "";
        var filterSelected = "";

        if (maSelect.filterSelected && _.isFunction(maSelect.filterSelected)) {
            filterSelected = maSelect.filterSelected();
        }

        return {
            filter: encodeURIComponent(filter),
            page: params.page,
            filterSelected: filterSelected,
            filterChecked: maSelect.checkBoxFilter()
        };
    }
    function processResults(maSelect, data, params) {
        if (!params || (!params.term && (params.page <= 1))) {
            // Enable search only if there is more than x items
            maSelect.searchEnabled(data.length >= maSelect.minimumResultsForSearch);
        }

        params.page = params.page || 1;

        var selectedItems = maSelect.selectedItem();
        if (!selectedItems) { selectedItems = []; }
        data = _.map(data, function (item) {
            var processedItem = maSelect.ajaxOptions.mapResults(item);
            processedItem.selected = maSelect.multiSelect
                ? ko.observable(_.any(selectedItems, function (selectedItem) { return selectedItem.id === processedItem.id; }))
                : false;
            processedItem.data = item;
            return processedItem;
        });
        
        return {
            results: data,
            pagination: { more: data.length >= maSelect.pageSize }
        };
    }

    function defaultMapResults(item) {
        return {
            id: item.Id,
            text: item.Description ? item.Id + " - " + item.Description : item.Id
        };
    }
    function fetchData(maSelect, params, callback) {
        var options = _.extend({
            type: 'GET'
        }, maSelect.ajaxOptions);

        if (_.isFunction(options.url)) {
            options.url = options.url.call(maSelect, params);
        }

        if (_.isFunction(options.data)) {
            options.data = options.data.call(maSelect, params);
        }

        function request() {
            options.transport(options, function (data) {
                var results = maSelect.ajaxOptions.processResults(data, params);
                callback(results);
            });
        }

        if (maSelect.ajaxOptions.delay && params.term) {
            if (maSelect._queryTimeout) {
                window.clearTimeout(maSelect._queryTimeout);
            }

            maSelect._queryTimeout = window.setTimeout(request, maSelect.ajaxOptions.delay);
        } else {
            request();
        }
    }

    var exports = {
        initAjax: function (ajax) {
            var maSelect = this;

            maSelect._isLoadingObservables = [];
            maSelect._cancellationToken = null;

            var defaultAjax = {
                delay: 250,
                data: function (params) { return buildAjaxData(maSelect, params); },
                processResults: function (data, params) { return processResults(maSelect, data, params); },
                mapResults: defaultMapResults
            };

            _.extend(defaultAjax, ajax);

            maSelect.ajaxOptions = defaultAjax;
        },

        loadMoreItems: function (isLoadingObservable, clear) {
            var maSelect = this;
            var dfd = jQuery.Deferred();

            if (!_.contains(maSelect._isLoadingObservables, isLoadingObservable)) {
                maSelect._isLoadingObservables.push(isLoadingObservable);
            }

            if (maSelect._cancellationToken) {
                maSelect._cancellationToken(true);
            }

            var _cancellationToken = ko.observable(false);
            maSelect._cancellationToken = _cancellationToken;

            if (clear) {
                maSelect.state.page = 1;
                maSelect.items([]);
            }

            var params = {
                page: maSelect.state.page,
                term: maSelect.searchInput()
            };
                       
            isLoadingObservable(true);
            fetchData(maSelect, params, function (results) {
                if (_cancellationToken()) {
                    return dfd.reject();
                }

                maSelect.state.hasMoreResults(results.pagination.more);
                maSelect.addItems(results.results);
                maSelect.state.page += 1;
                maSelect._cancellationToken = null;
                var observable = maSelect._isLoadingObservables.pop();
                while (observable) {
                    observable(false);
                    observable = maSelect._isLoadingObservables.pop();
                }

                return dfd.resolve(results);
            });

            return dfd.promise();
        }
    };

    return exports;
});
