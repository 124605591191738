define([
    "underscore",
    "knockout",
    "core/resx",
    "helpers/routerHelper",
    "widgets/pages/employee"
], function (_, ko, resx, routerHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.dispatchProjectCode = ko.observable('');
            self.dispatchDate = ko.observable('');
            self.dipatchId = 0;
            self.employeeId = 0;
            self.employee = ko.observable({});
            self.resx = resx;
            self.isProjectResponsible = ko.observable(false);
            //#endregion
        }
        //#region Private Functions
        
        function bindViewModel(dispatchProjectCode, dispatchDate, employeeId, dispatchId, querystring) {
            var params = routerHelper.getQuerystring(querystring);
            self.isProjectResponsible = (params && params.isProjectResponsible === "true") || false;
            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dispatchDate);
            self.dispatchId = dispatchId;
            self.employeeId = employeeId;
        }

        
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.employeeId, params.dispatchId, params.q);

            return true;
        };

        ctor.prototype.navigateToActivities = function () {
            var url = routerHelper.navigateTo('Project_Detail_Daily_Entry_TimeEntryEmployee', self.dispatchProjectCode(), self.dispatchDate(), self.dispatchId);
            url += routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly });
            routerHelper.navigate(url);
        };

        
        //#endregion
        return ctor;
    })();

    return viewModel;
});
