// GENERATED FILE - DO NOT MODIFY
export enum LocationType {
    Contract = "U",
    Alternative = "A",
    Contact = "B",
    Customer = "J",
    CustomerSite = "D",
    Project = "P",
    Company = "M",
    Supplier = "F",
    SupplierSite = "G",
}

export let nameof = "LocationType";
