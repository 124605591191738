define([
    "knockout",
    "helpers/routerHelper",
    "services/documentService"
], function (ko, routerHelper, documentService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = false;
            self.employeeId = ko.observable();

            self.workOrderId = ko.observable('');
            self.actions = {};
            //#endregion
        }

        //#region Public Methods

        ctor.prototype.activate = function (params ) {
            var paramsQs = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQs && paramsQs.readonly === "false");
            self.employeeId(params.employeeId);

            self.workOrderId(params.workOrderId);

            self.actions = {
                save: documentService.saveEmployeePicture.bind(self, self.employeeId())
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
