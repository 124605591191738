// GENERATED FILE - DO NOT MODIFY
export enum CatalogItemStatus {
    Inactive = "0",
    General = "1",
    Sale = "2",
    Inventory = "3",
    NonInventoryPurchase = "4",
    GeneralNoValidation = "5",
    RentalEquipment = "8",
    InactiveRentalEquipment = "9",
}

export let nameof = "CatalogItemStatus";
