define([
    "repositories/settingRepository",
    "services/requests",
    "core/storage",
    "jquery"
], function (settings, requests, storage, jQuery) {
    "use strict";

    var exports = {
        isLogged: function () {
            var token = settings.getToken();
            return token !== null && token !== "";
        },

        login: function (username, password) {
            var dfd = new jQuery.Deferred();
            var query = "grant_type=password&username=" + username + "&password=" + encodeURIComponent(password);
            var options = {
                contentType: "application/x-www-form-urlencoded"
            };

            var newLogin = requests.post("token", query, options).fail(function (xhr) { xhr.handled = true; });

            jQuery.when(newLogin)
                .then(function (newToken) {
                    settings.setUser(username);
                    settings.setRoles(newToken.roles);
                    settings.setToken(newToken.access_token);
                    dfd.resolve();
                })
                .fail(function (xhr) {
                    dfd.reject(xhr);
                });
            return dfd;
        },

        changePassword: function (username, password, newpassword) {
            var that = this;
            var dfd = new jQuery.Deferred();
            var query = {
                Username: username,
                OldPassword: password,
                NewPassword: newpassword
            };
            requests.post("api/Account/ResetPassword", query)
                .done(function (data) {
                    if (data) {
                        that.login(username, newpassword).done(function () {
                            dfd.resolve();
                        });
                    } else {
                        dfd.reject();
                    }
                })
                .fail(function (xhr) {
                    dfd.reject(xhr);
                });
            return dfd;
        },

        logout: function () {
            settings.clearAll();
            exports.storageClear();
        },

        storageClear: function () {
            storage.remove("ERROR");
            storage.remove("INCREMENT");
            storage.remove("SERVICELASTSHIFTUSED");
            storage.remove("INVENTORYSITE");
            storage.remove("PROJECTLASTGEOPOST");
            storage.remove("PROJECTCONFIGS");
            storage.remove("LASTURLSHOWN");
            storage.remove("maestro_timesheet_default_values");

            window.location.reload(true);
        },

        getLanguage: function () {
            return settings.getLanguage();
        },

        getAllUsersLookup: function (filter, page) {
            var url = "api/Account/all-users";

            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        }
    };

    return exports;
});
