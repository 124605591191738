import { default as _ } from "underscore";
import { default as val } from "core/val";

import { default as enumHelper } from "helpers/enumHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as materialService } from "services/materialService";
import { default as serviceService } from "services/serviceService";
import { default as purchaseOrderService } from "services/purchaseOrderService";
import { default as templateService } from "services/templateService";
import { default as apiHelper } from "helpers/apiHelper";
import { default as Parse } from "helpers/parse";

export default class MaterialEdit {
    actions = {};
    dispatchId = null;
    lineNo = 0;
    readonly = false;
    showNotInCatalog = false;
    billingItem = false;
    isBilling = false;

    constructor() { }

    activate(params) {
        this.bindViewModel(params.serviceType, params.dispatchId, params.isBilling, params.lineNo, params.q);

        return templateService.getCurrentDispatchTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialService;
        });
    }

    bindViewModel(serviceType, dispatchId, isBilling, lineNo, querystring) {
        this.noBillCatalogBaseUrl = serviceService.getCatalogBaseUrl(serviceType, dispatchId) + "/material";
        this.billCatalogBaseUrl = serviceService.getCatalogBaseUrl(serviceType, dispatchId) + "/materialbilling";

        this.catalogBaseUrl = Parse.Boolean(isBilling) === true ? this.billCatalogBaseUrl : this.noBillCatalogBaseUrl;

        this.dispatchId = dispatchId;
        this.isBilling = Parse.Boolean(isBilling) === true;
        this.lineNo = lineNo;
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.serviceType = serviceType;

        this.actions = {
            getActivities: serviceService.getActivitiesByDispatch.bind(this, "W", this.dispatchId, enumHelper.workOrderActivityType().MATERIAL),
            getMaterial: serviceService.getMaterial.bind(null, this.serviceType, this.dispatchId, this.lineNo, this.isBilling),
            setMaterial: serviceService.setMaterial.bind(this, this.serviceType, this.dispatchId),
            getLookupPoNumber: purchaseOrderService.getByDispatchIdAndStatus.bind(this, this.dispatchId, enumHelper.purchaseOrderStatus.RESERVED)
        };

        this.validationValues = {
            description: {
                isBilling: {
                    maxLength: serviceType === "W" ? val.get('service.workOrder.material.description.isBilling', 'maxLength') : val.get('service.serviceCall.material.description', 'maxLength')
                },
                isNotBilling: {
                    maxLength: serviceType === "W" ? val.get('service.workOrder.material.description.isNotBilling', 'maxLength') : val.get('service.serviceCall.material.description', 'maxLength')
                }
            }
        };
    }
}