import { inject, transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as resx } from "core/resx";

@transient()
export class BreakTimePicker {
    constructor() {
        this.resx = resx;
        this.label = ko.observable('');
        this.startTime = null;
        this.endTime = null;
        this.readonly = ko.observable(false);
        this.deleteFunc = null;
    }

    activate(widgetSettings) {
        this.label(widgetSettings.label);
        this.startTime = widgetSettings.startTime;
        this.endTime = widgetSettings.endTime;
        this.readonly(widgetSettings.readonly);
        this.deleteFunc = widgetSettings.delete;
    }
}