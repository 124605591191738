import { ServiceCallQuotationEquipmentModel } from "api/models/company/service-call/service-call-quotation-equipment-model";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { ServiceCallQuotationEquipmentProxy } from "api/proxies/service-call-quotation-equipment-proxy";
import { autoinject } from "aurelia-framework";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";

export interface ServiceCallQuotationRelatedEquipmentModel extends ServiceCallQuotationEquipmentModel {
    Selected: boolean;
    CustomerReference: string | null;
    WarrantyStartDate: Date | null;
    MaterialWarrantyEndDate: Date | null;
    LabourWarrantyEndDate: Date | null;
    DateEffective: Date | null;
    InactivityStartDate: Date | null;
    ServedSector: string | null;
    OrderId: string | null;
    Extension: string | null;
}

@autoinject()
export class ServiceCallQuotationEquipmentService {

    private static buildFromRelatedEquipment(relatedEquipment: ServiceCallQuotationRelatedEquipmentModel): ServiceCallQuotationEquipmentModel {
        return {
            Id: -1,
            Brand: relatedEquipment.ContractEquipmentId === 0 ? relatedEquipment.Brand : null,
            Description: relatedEquipment.ContractEquipmentId === 0 ? relatedEquipment.Description : null,
            QuotationId: 0,
            LineNumber: -1,
            ContractEquipmentId: relatedEquipment.ContractEquipmentId,
            EquipmentId: relatedEquipment.ContractEquipmentId === 0 ? relatedEquipment.EquipmentId : null,
            EquipmentCount: relatedEquipment.ContractEquipmentId === 0 ? relatedEquipment.EquipmentCount : 0,
            Location: relatedEquipment.ContractEquipmentId === 0 ? relatedEquipment.Location : null,
            Model: relatedEquipment.ContractEquipmentId === 0 ? relatedEquipment.Model : null,
            SerialNumber: relatedEquipment.ContractEquipmentId === 0 ? relatedEquipment.SerialNumber : null,
            UnitType: relatedEquipment.ContractEquipmentId === 0 ? relatedEquipment.UnitType : null
        };
    }

    private static buildFromContractEquipment(contractEquipment: ServiceCallContractEquipmentModel, quotationId: number): ServiceCallQuotationRelatedEquipmentModel {
        const model: ServiceCallQuotationRelatedEquipmentModel = {
            Id: -1,
            Brand: contractEquipment.Brand,
            Description: contractEquipment.Description,
            QuotationId: quotationId,
            LineNumber: -1,
            ContractEquipmentId: contractEquipment.Id,
            EquipmentId: contractEquipment.Code,
            EquipmentCount: contractEquipment.Quantity,
            Location: contractEquipment.Location,
            Model: contractEquipment.Model,
            SerialNumber: contractEquipment.Serial,
            UnitType: contractEquipment.UnitType,
            ServedSector: contractEquipment.ServedSector,
            OrderId: contractEquipment.OrderId,
            Extension: contractEquipment.Extension,
            WarrantyStartDate: contractEquipment.WarrantyStartDate,
            MaterialWarrantyEndDate: contractEquipment.MaterialWarrantyEndDate,
            LabourWarrantyEndDate: contractEquipment.LabourWarrantyEndDate,
            DateEffective: contractEquipment.DateEffective,
            InactivityStartDate: contractEquipment.InactivityStartDate,
            CustomerReference: contractEquipment.CustomerReference,
            Selected: false,
        };

        Object.defineProperty(model, "Selected", { enumerable: false });

        return model;
    }

    constructor(
        private readonly serviceCallQuotationEquipmentProxy: ServiceCallQuotationEquipmentProxy,
        private readonly serviceCallContractEquipmentService: ServiceCallContractEquipmentService
    ) {}

    public async saveQuotationEquipment(quotationEquipment: ServiceCallQuotationEquipmentModel): Promise<void> {
        await this.serviceCallQuotationEquipmentProxy.SaveQuotationEquipment(quotationEquipment);
    }

    public buildQuotationEquipmentsFromRelatedEquipments(relatedEquipments: ServiceCallQuotationRelatedEquipmentModel[]): ServiceCallQuotationEquipmentModel[] {
        const quotationEquipments: ServiceCallQuotationEquipmentModel[] = [];
        for (const relatedEquipmentModel of relatedEquipments) {
            quotationEquipments.push(ServiceCallQuotationEquipmentService.buildFromRelatedEquipment(relatedEquipmentModel));
        }

        return quotationEquipments;
    }

    public async saveQuotationEquipments(quotationId: number, relatedEquipments: ServiceCallQuotationRelatedEquipmentModel[]): Promise<void> {
        const quotationEquipments = this.buildQuotationEquipmentsFromRelatedEquipments(relatedEquipments);
        await this.serviceCallQuotationEquipmentProxy.SaveQuotationEquipments(quotationId, quotationEquipments, false);
    }

    public async deleteQuotationEquipment(quotationEquipmentId: number): Promise<void> {
        await this.serviceCallQuotationEquipmentProxy.DeleteQuotationEquipment(quotationEquipmentId);
    }

    public async getQuotationEquipment(quotationEquipmentId: number): Promise<ServiceCallQuotationEquipmentModel | null> {
        return await this.serviceCallQuotationEquipmentProxy.GetQuotationEquipment(quotationEquipmentId);
    }

    public async getQuotationEquipments(quotationId: number, equipmentToAdd: boolean): Promise<ServiceCallQuotationEquipmentModel[] | null> {
        return await this.serviceCallQuotationEquipmentProxy.GetQuotationEquipments(quotationId, equipmentToAdd);
    }

    public async getRelatedEquipments(quotationId: number, contractId: string, jobDate: Date | null, filter?: string): Promise<ServiceCallQuotationRelatedEquipmentModel[]> {
        const contractEquipments = (await this.serviceCallContractEquipmentService.getContractEquipments(contractId, jobDate, filter)) || [];
        const quotationEquipments = (await this.serviceCallQuotationEquipmentProxy.GetQuotationEquipments(quotationId, false)) || [];

        const returnValue: ServiceCallQuotationRelatedEquipmentModel[] = [];

        for (const contractEquipment of contractEquipments) {
            returnValue.push(ServiceCallQuotationEquipmentService.buildFromContractEquipment(contractEquipment, quotationId));
        }

        for (const quotationEquipment of quotationEquipments) {
            const match = returnValue.find((x: ServiceCallQuotationRelatedEquipmentModel) => x.ContractEquipmentId === quotationEquipment.ContractEquipmentId);

            if (match !== undefined) {
                match.Id = quotationEquipment.Id;
                match.Selected = true;
                continue;
            }

            // TODO JL: Append quotationEquipment to return value.
            // For now we only manage contract equipment
        }

        return returnValue;
    }
}
