import Logger from "core/logger";
define([
    "knockout",
    //"durandal/system",
    "core/resx",
    "services/serviceService",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "knockout.validation"
], function (ko, /*system,*/ resx, services, routerHelper, notifier) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.id = '';
            self.readonly = false;
            self.btnText = ko.observable();
            self.serviceType = '';
            self.resx = resx;
            self.timeStamp = '';
            self.dispatchId = ko.observable();
            self.validatedForm = ko.validatedObservable({
                note: ko.observable('').extend({
                    required: {
                        params: true,
                        message: resx.localize('err_VisitDetailsRequired')
                    }
                })
            });
            //#endregion
        }

        //#region Private Functions
        function loadData() {
            if (self.id === -1) {
                self.btnText(resx.localize("Add"));
                self.validatedForm().note('');
                return true;
            }

            self.btnText(resx.localize("Save"));
            
            return services.getNote(self.serviceType, self.dispatchId(), self.id).done(function (data) {
                self.validatedForm().note(data.Note);
                self.timeStamp = data.VisitTimestamp || data.WorkOrderTimestamp;
            });
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            var paramsQs = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQs && paramsQs.readonly === "false"); 
            self.serviceType = params.serviceType;
            self.dispatchId(params.dispatchId);
            self.id = params.rowNumber || -1;
            self.serviceType = params.serviceType;
            self.timeStamp = paramsQs.ts;

            return loadData(self);
        };
        
        ctor.prototype.setNote = function () {
            if (self.validatedForm.isValid()) {
                routerHelper.showLoading();
                var data = {
                    RowNumber: (self.id === undefined || self.id === "") ? -1 : self.id,
                    Note: self.validatedForm().note()
                };

                if (self.serviceType === "S") {
                    data.VisitTimestamp = self.timeStamp;
                } else {
                    data.WorkOrderTimestamp = self.timeStamp;
                }

                services.setNotes(self.serviceType, self.dispatchId(), data)
                    .done(function () {
                        routerHelper.navigateBack();
                    })
                    .concurrence(routerHelper.navigateBack)
                    .always(function() {
                        routerHelper.hideLoading();
                    });
            } else {
                notifier.showValidationError(self.validatedForm.errors);
                Logger.error(self.validatedForm.errors());
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
