import UserAccessService from "services/user-access-service";
import { ProgressionType } from "api/enums/progression-type"

define([
    "knockout",
    "core/resx",
    "underscore",
    "helpers/routerHelper",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "services/dailyEntryService",
    "helpers/viewbag",
    "services/defaultService",
    "helpers/enumHelper",
    "services/templateService",
    "widgets/projectsCloseDayButton"
   ], function (ko, resx, _, routerHelper, dateHelper, notificationHelper, dailyEntryService, viewbag, defaultService, enumHelper, templateService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        ctor.inject = [UserAccessService, ProgressionType];

        function ctor(userAccessService, progressionType) {
            self = this;
            //#region Properties
            self.resx = resx;
            self.routerHelper = routerHelper;
            self.dispatchProjectCode = ko.observable("");
            self.dispatchDate = ko.observable("");
            self.entry = ko.observable({});
            self.counts = ko.observable();
            self.isAccessAllowed = ko.observable();
            self.isProjectResponsible = false;
            self.isTeamLeader = false;
            self.readonly = ko.observable(false);
            self.future = ko.observable(false);
            self.userAccessService = userAccessService;
            self.progressionType = progressionType;
            self.enumHelper = enumHelper;
            self.projectCode = "";
            self.isOpened = ko.observable(false);

            self.allowEmployeeBreaksEntry = ko.observable(false);
            self.manualBreaksManagementEnabled = ko.observable(false);

            self.evaluationCriteriasExists = ko.observable(false);

            self.dayWeather = ko.observable({ weatherType: 0, windForce: 0, degree: 0 });
            self.eveningWeather = ko.observable({ weatherType: 0, windForce: 0, degree: 0 });
            self.nightWeather = ko.observable({ weatherType: 0, windForce: 0, degree: 0 });

            self.displayBidItems = ko.observable(false);

            self.dailyEntryStarted = ko.pureComputed(function() {
                if (self.entry()) {
                    if (self.entry().ReadyToComplete.AttendancesWithTimeEntryStartedCount > 0 ||
                        self.entry().Description.WorkDescription ||
                        containsValue(self.counts())) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });

            self.dispatchTemplateCode = ko.observable("");
            self.transactionCpt = ko.observable(0);
            //#endregion
        }
        //#region Private Functions

        function containsValue(list) {
            return !_.isMatch(list,
                {
                    InterruptionsCount: 0,
                    ProductionsCount: 0,
                    SubcontractorsCount: 0,
                    MaterialsCount: 0,
                    EquipmentCountersCount: 0
                });
        }

        function mapWeatherDataFromApi(data) {
            if (data.TemperatureTypeAm) {
                self.dayWeather().weatherType = +data.TemperatureTypeAm;
                self.dayWeather().windForce = -data.TemperatureWindAm;
                self.dayWeather().degree = Math.round(data.TemperatureDegreeAm);
            } else {
                self.dayWeather({ weatherType: 0, windForce: 0, degree: 0 });
            }

            if (data.TemperatureTypeEvening) {
                self.eveningWeather().weatherType = +data.TemperatureTypeEvening;
                self.eveningWeather().windForce = -data.TemperatureWindEvening;
                self.eveningWeather().degree = Math.round(data.TemperatureDegreeEvening);
            } else {
                self.eveningWeather({ weatherType: 0, windForce: 0, degree: 0 });
            }

            if (data.TemperatureTypeNight) {
                self.nightWeather().weatherType = +data.TemperatureTypeNight;
                self.nightWeather().windForce = -data.TemperatureWindNight;
                self.nightWeather().degree = Math.round(data.TemperatureDegreeNight);
            } else {
                self.nightWeather({ weatherType: 0, windForce: 0, degree: 0 });
            }
        }
        function mapDataFromApi(entry) {
            self.entry(entry);
            self.readonly(entry.IsReadOnly);
            self.future(false);

            defaultService.setCurrentDispatchTemplateCode(entry.DispatchTemplate);
            defaultService.setInventorySite(entry.InventorySite);

            self.dispatchTemplateCode(entry.DispatchTemplate);
            self.transactionCpt(entry.Description.Id);

            if (!dateHelper.isTodayOrBefore(dateHelper.formatDate(null, self.dispatchDate()))) {
                notificationHelper.showWarning(resx.localize("FutureEntryReadonly"));
                self.readonly(true);
                self.future(true);
            }

            if (entry.Contract) {
                self.displayBidItems(self.isProjectResponsible);
            } else {
                self.displayBidItems(false);
            }

            mapWeatherDataFromApi(entry.Weather);
        }

        function loadData(dispatchProjectCode, dispatchDate) {
            var desCount = dailyEntryService.getItemsCount(dispatchProjectCode, dispatchDate);
            var des = dailyEntryService.get(dispatchProjectCode, dispatchDate, getTimeEntryMode(), getInChargeRequireIndicateWhenEmployeeLeftWorksite(), getAllowEquipmentTimeEntryForSimpleUser());

            return jQuery.when(desCount, des).done(function (counts, entry) {
                mapDataFromApi(entry);
                self.counts(counts);
                self.evaluationCriteriasExists(entry.EmployeeEvaluationStatus !== enumHelper.employeeEvaluationStatus.NOTAVAILABLE);
                self.isTeamLeader = entry.IsTeamLeader;
                self.projectCode = entry.ProjectCode;
                self.isOpened(entry.Status !== enumHelper.DailyEntryStatus.COMPLETE && entry.Status !== enumHelper.DailyEntryStatus.TRANSFERRED)

                self.allowEmployeeBreaksEntry(entry.AllowEmployeeBreaksEntry);
                self.manualBreaksManagementEnabled(entry.ManualBreaksManagementEnabled);
            });
        }

        function getTimeEntryMode() {
            return templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
        }

        function getInChargeRequireIndicateWhenEmployeeLeftWorksite() {
            return templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
        }

        function getAllowEquipmentTimeEntryForSimpleUser() {
            return templateService.getCurrentTemplateConfigs().AllowEquipmentTimeEntry;
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            dailyEntryService.logPositionIfNeeded(params.dispatchProjectCode, params.dailyEntryDate);

            var paramsQueryString = routerHelper.getQuerystring(params.q);
            self.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
            self.dispatchDate(params.dailyEntryDate);
            self.dispatchProjectCode(params.dispatchProjectCode);

            return loadData(params.dispatchProjectCode, params.dailyEntryDate);
        };

        ctor.prototype.editDescription = function (description) {
            routerHelper.navigate(self.genUrl("work/edit"));
        };

        ctor.prototype.editWeather = function (periodSelected) {
            viewbag({
                periodSelected: periodSelected,
                value: {
                    dayWeather: self.dayWeather(),
                    eveningWeather: self.eveningWeather(),
                    nightWeather: self.nightWeather()
                }
            });
            routerHelper.navigate(self.genUrl("weather/edit"));
        };

        ctor.prototype.genUrl = function (rel) {
            return routerHelper.getRelativeUrl(rel) + (rel === "subcontractor/edit" ? "/0" : "") +
                routerHelper.addQuerystring({
                    isProjectResponsible: self.isProjectResponsible,
                    readonly: self.readonly(),
                    isTeamLeader: self.isTeamLeader,
                    dispatchDate: self.dispatchDate(),
                    dispatchProjectCode: self.dispatchProjectCode(),
                    projectCode: self.projectCode,
                    canDeleteDailyEntry: !self.dailyEntryStarted() && self.entry().CanDeleteDailyEntry,
                    isClosingDayMode: false,
                    dispatchTemplateCode: self.dispatchTemplateCode(),
                    transactionCpt: self.transactionCpt()
                });
        };

        ctor.prototype.deleteEntry = function () {
            notificationHelper.showConfirmation(resx.localize('msg_DeleteEntryConfirmationText'))
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        dailyEntryService.deleteDailyEntry(self.dispatchProjectCode(), self.dispatchDate())
                            .done(function() {
                                routerHelper.navigateBack();
                            })
                            .always(function () {
                            routerHelper.hideLoading();
                        });
                    }
                });
        };

        //#endregion
        return ctor;
    })();

    return viewModel;
});
