import val from "core/val";
import { autoinject, bindable } from "aurelia-framework";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";

import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";

@autoinject()
export class InventoryProjectTransferMemo {
    public val: any = val;

    @bindable public transfer!: InventoryProjectTransferDetailsModel | null;

    constructor( private routeRepository: RouteRepository,
                 private readonly inventoryProjectTransferService: InventoryProjectTransferService) {}

    public async activate(params: any): Promise<void> {
        this.transfer = await this.inventoryProjectTransferService.GetTransfer(params.transferId);
    }

    public async save(): Promise<void> {
        await this.inventoryProjectTransferService.SaveTransfer(this.transfer!).then(() => {
            routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Info.name,  {transferId : this.transfer!.Id});
        });
    }
}
