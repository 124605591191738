define([
    "knockout",
    "core/resx",
    "services/dispatchService",
    "helpers/queryStringHelper",
    "helpers/routerHelper"
], function (ko, resx, dispatch, queryStringHelper, routerHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.btnText = ko.observable();
            self.comment = ko.observable();
            self.dispatchId = ko.observable();
            self.id = '';
            self.serviceType = '';
            self.readonly = false;
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(serviceType, dispatchId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);

            self.dispatchId(dispatchId);
            self.serviceType = serviceType;
        }

        function loadData() {
            return dispatch.getComment(self.dispatchId())
                .done(function (data) {
                    self.comment(data);
                    self.btnText(resx.localize('Save'));
                });
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.serviceType, params.dispatchId, params.q);
            return loadData();
        };

        ctor.prototype.setComment = function () {
            routerHelper.showLoading();
            dispatch.setComment(self.dispatchId(), self.comment())
                .done(routerHelper.navigateBack)
                .always(routerHelper.hideLoading);
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
