// GENERATED FILE - DO NOT MODIFY
import { CatalogInventoryModel } from "api/models/company/catalog/catalog-inventory-model";
import { DocumentModel } from "api/models/company/document/document-model";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";
import { ListDocumentModel } from "api/models/company/document/list-document-model";
import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";
import { InventoryProjectTransferItemModel } from "api/models/company/inventory-project-transfer-item-model";
import { InventoryProjectTransferItemResponseModel } from "api/models/company/inventory-project-transfer-item-response-model";
import { InventoryProjectTransferModel } from "api/models/company/inventory-project-transfer-model";
import { InventoryProjectTransferUpdateFromCatalogInventoryModel } from "api/models/company/inventory-project-transfer-update-from-catalog-inventory-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { InventoryProjectTransferWorkOrderLookupModel } from "api/models/company/workorder/inventory-project-transfer-work-order-lookup-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class InventoryProjectTransferProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async SaveTransfer(inventoryProjectTransferDetails: InventoryProjectTransferDetailsModel | null, requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/inventory-project-transfer",
            null,
            null
        );

        const data: number = await this.post<InventoryProjectTransferDetailsModel | null, number>(uri, inventoryProjectTransferDetails, requestConfig);
        return data;
    }

    public async SaveTransferItems(items: Array<InventoryProjectTransferItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/inventory-project-transfer/items",
            null,
            null
        );

        const data: boolean = await this.post<Array<InventoryProjectTransferItemModel | null> | null, boolean>(uri, items, requestConfig);
        return data;
    }

    public async NewTransfer(requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferDetailsModel | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/new",
            null,
            null
        );

        const data: InventoryProjectTransferDetailsModel | null = await this.get<InventoryProjectTransferDetailsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferDetailsModel");
        return data;
    }

    public async NewTransferItem(transferId: number, productCode: string | null, siteId: string | null, activityId: string | null, groupId: string | null, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferItemResponseModel | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/{transferId}/item/new",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            { productCode: (productCode === null || productCode === undefined) ? undefined : productCode, siteId: (siteId === null || siteId === undefined) ? undefined : siteId, activityId: (activityId === null || activityId === undefined) ? undefined : activityId, groupId: (groupId === null || groupId === undefined) ? undefined : groupId }
        );

        const data: InventoryProjectTransferItemResponseModel | null = await this.get<InventoryProjectTransferItemResponseModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferItemResponseModel");
        return data;
    }

    public async UpdateFromCatalogInventoryItem(updateModel: InventoryProjectTransferUpdateFromCatalogInventoryModel | null, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferItemModel | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/item/update-catalog-inventory-item",
            null,
            null
        );

        const data: InventoryProjectTransferItemModel | null = await this.post<InventoryProjectTransferUpdateFromCatalogInventoryModel | null, InventoryProjectTransferItemModel | null>(uri, updateModel, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferItemModel");
        return data;
    }

    public async AddReservations(transferId: number, activityId: string | null, groupId: string | null, selectedReservations: Array<CatalogInventoryModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferDetailsModel | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/{transferId}/items/add-reservations",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            { activityId: (activityId === null || activityId === undefined) ? undefined : activityId, groupId: (groupId === null || groupId === undefined) ? undefined : groupId }
        );

        const data: InventoryProjectTransferDetailsModel | null = await this.post<Array<CatalogInventoryModel | null> | null, InventoryProjectTransferDetailsModel | null>(uri, selectedReservations, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferDetailsModel");
        return data;
    }

    public async UpdateTransferItems(inventoryProjectTransferDetails: InventoryProjectTransferDetailsModel | null, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferDetailsModel | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/update/items",
            null,
            null
        );

        const data: InventoryProjectTransferDetailsModel | null = await this.post<InventoryProjectTransferDetailsModel | null, InventoryProjectTransferDetailsModel | null>(uri, inventoryProjectTransferDetails, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferDetailsModel");
        return data;
    }

    public async GetTransferItem(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferItemResponseModel | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/item/{transactionNumber}",
            { transactionNumber: (transactionNumber === null || transactionNumber === undefined) ? undefined : transactionNumber.toString() },
            null
        );

        const data: InventoryProjectTransferItemResponseModel | null = await this.get<InventoryProjectTransferItemResponseModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferItemResponseModel");
        return data;
    }

    public async DeleteTransferItem(transactionNumber: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/inventory-project-transfer/item/{transactionNumber}",
            { transactionNumber: (transactionNumber === null || transactionNumber === undefined) ? undefined : transactionNumber.toString() },
            null
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async GetTransfers(onlyClose: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferModel[] | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer",
            null,
            { onlyClose: (onlyClose === null || onlyClose === undefined) ? undefined : onlyClose.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: InventoryProjectTransferModel[] | null = await this.get<InventoryProjectTransferModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferModel");
        return data;
    }

    public async GetTransfer(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferDetailsModel | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/{transferId}",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            null
        );

        const data: InventoryProjectTransferDetailsModel | null = await this.get<InventoryProjectTransferDetailsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferDetailsModel");
        return data;
    }

    public async GetTransferHeader(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferDetailsModel | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/header/{transferId}",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            null
        );

        const data: InventoryProjectTransferDetailsModel | null = await this.get<InventoryProjectTransferDetailsModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.InventoryProjectTransferDetailsModel");
        return data;
    }

    public async DeleteTransfer(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/inventory-project-transfer/{transferId}",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            null
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async GetProjects(prefix: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ProjectBaseModel[] | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/projects",
            null,
            { prefix: (prefix === null || prefix === undefined) ? undefined : prefix, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ProjectBaseModel[] | null = await this.get<ProjectBaseModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Project.ProjectBaseModel");
        return data;
    }

    public async GetWorkOrders(prefix: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<InventoryProjectTransferWorkOrderLookupModel[] | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/work-orders/lookup",
            null,
            { prefix: (prefix === null || prefix === undefined) ? undefined : prefix, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: InventoryProjectTransferWorkOrderLookupModel[] | null = await this.get<InventoryProjectTransferWorkOrderLookupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Workorder.InventoryProjectTransferWorkOrderLookupModel");
        return data;
    }

    public async Complete(transferId: number, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/inventory-project-transfer/{transferId}/complete",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            null
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async IsWorkOrdersInstalled(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/inventory-project-transfer/work-orders/installed",
            null,
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async GetByReceiptId(transferId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ListDocumentModel[] | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/{transferId}/documents",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ListDocumentModel[] | null = await this.get<ListDocumentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.ListDocumentModel");
        return data;
    }

    public async GetSections(transferId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel[] | null> {
        const uri = this.buildUri(
            "/inventory-project-transfer/{transferId}/documents/sections",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DocumentSectionModel[] | null = await this.get<DocumentSectionModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.DocumentSectionModel");
        return data;
    }

    public async AddDocumentForTransfer(transferId: number, models: Array<DocumentModel | null> | null, dispatchTemplateId: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/inventory-project-transfer/{transferId}/documents",
            { transferId: (transferId === null || transferId === undefined) ? undefined : transferId.toString() },
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId }
        );

        const data: boolean = await this.post<Array<DocumentModel | null> | null, boolean>(uri, models, requestConfig);
        return data;
    }
}
