import { transient, inject } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

import { default as routerHelper } from "helpers/routerHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { AdditionalFieldsHelper } from "helpers/additional-fields-helper";
import { default as RouteRepository } from "repositories/routeRepository";

import { default as dailyEntryService } from "services/dailyEntryService";
import { default as viewbag } from "helpers/viewbag";
import { default as defaultService } from "services/defaultService";
import { default as templateService } from "services/templateService";

import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";

function genUrl(self, param) {
    return routerHelper.navigateTo("Project_Detail_Daily_Entry", self.dispatchProjectCode, self.dispatchDate, param) + routerHelper.addQuerystring({ isProjectResponsible: true, readonly: false, lunchSelected: self.lunchSelected(), breakSelected: self.breakSelected() });
}

function mapDataFromApi(self, entry) {
    self.entry(entry);
    self.readonly(entry.IsReadOnly);

    defaultService.setCurrentDispatchTemplateCode(entry.DispatchTemplate);
}

function loadData(self, dispatchProjectCode, dispatchDate) {
    return dailyEntryService.get(dispatchProjectCode, dispatchDate, getTimeEntryMode(), getInChargeRequireIndicateWhenEmployeeLeftWorksite(), false).done(function (entry) {
        mapDataFromApi(self, entry);
    });
}

function handleAnswer(self, success) {
    if (success) {
        viewbag(null);
        if (!self.lunchSelected() && !self.breakSelected()) {
            callComplete(self, true);
        } else {
            if (self.navigateBackOnSave) {
                routerHelper.navigate(genUrl(self, "break"), { replace: true, trigger: true });
            } else {
                routerHelper.navigate(genUrl(self, "break"));
            }
        }
    } else {
        callComplete(self, true);
    }
}

function showSingleBreaksChoice(self) {
    var msg = self.isNewLunch ? "WantAddLunchTime" : "WantAddBreakTime";
    notificationHelper.showDialogYesNo(resx.localize(msg))
        .done((data) => handleAnswer(self, data));
}

function showMultipleBreaksChoice(self) {
    notificationHelper.showDialogProjectBreakTimesSelection(
        resx.localize("WantAddBreakAndLunchTime"),
        "",
        {
            lunchSelected: self.lunchSelected, breakSelected: self.breakSelected,
            lunchVisible: self.isNewLunch, breakVisible: self.isNewBreak
        }).done((data) => handleAnswer(self, data));
}

function handleBreakHours(self, data) {
    self.isNewLunch = data.GotLunchHours;
    self.isNewBreak = data.GotBreakHours;

    if (self.isNewLunch && self.isNewBreak) {
        showMultipleBreaksChoice(self);
    } else {
        showSingleBreaksChoice(self);
    }

}

function callComplete(self, forceSave) {
    dailyEntryService.complete(self.dispatchProjectCode, self.dispatchDate, forceSave, null).done(function (data) {
        if (data.GotBreakHours || data.GotLunchHours) {
            if (self.entry().ManualBreaksManagementEnabled) {
                routerHelper.navigateToRoute(self.routeRepository.routes.Project_Detail_Daily_Entry_Manage_Lunch_Breaks.name, {
                    readonly: self.readonly,
                    dispatchDate: self.dispatchDate,
                    dailyEntryDate: self.dispatchDate,
                    dispatchProjectCode: self.dispatchProjectCode,
                    isClosingDayMode: true
                });
            } else {
                handleBreakHours(self, data);
            }

        } else {
            if (data.Status === "5") {
                if (self.navigateBackOnSave) {
                    routerHelper.navigateBack();
                } else {
                    routerHelper.navigateSelf();
                }
            }
        }
    });
}

function showNextStepToDo(self) {
    var status = self.entry().ReadyToComplete;
    var message = "";

    if(!status.AllRequiredAdditionalFieldsAreEntered) {
        message = self.additionalFieldsHelper.getRequiredRessourceBySourceType(AdditionalFieldsSourceType.DailyEntry);
    } else if (status.UndefinedPresenceAttendancesCount) {
        message = resx.localize("msg_DailyEntry_TakeAttendanceForXEmployees").replace("{0}", status.UndefinedPresenceAttendancesCount);
    } else if (!status.AttendancesWithTimeEntryStartedCount) {
        message = resx.localize("msg_DailyEntry_EnterTimeForXEmployees").replace("{0}", status.PresentAttendancesCount);
    } else if (status.CompletedAttendancesCount < status.PresentAttendancesCount) {
        if (self.isInSummary) {
            message = resx.localize("msg_DailyEntry_QuitEmployeeToClose");
        } else {
            message = resx.localize("msg_DailyEntry_GoToHoursSummrayToQuitEmployee");
        }
    }

    notificationHelper.showWarning(message, null, { timeOut: 0 });
}

function reload(self) {
    loadData(self, self.dispatchProjectCode, self.dispatchDate);
}

function getTimeEntryMode() {
    return templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
}

function getInChargeRequireIndicateWhenEmployeeLeftWorksite() {
    return templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
}

@transient()
@inject(AdditionalFieldsHelper, RouteRepository)
export class ProjectsCloseDayButton {
    constructor(additionalFieldsHelper, routeRepository) {
        this.resx = resx;
        this.dispatchProjectCode = "";
        this.dispatchDate = "";
        this.entry = ko.observable({});
        this.readonly = ko.observable(false);
        this.navigateBackOnSave = false;
        this.isInSummary = false;
        this.lunchSelected = ko.observable(true);
        this.breakSelected = ko.observable(true);
        this.isNewLunch = false;
        this.isNewBreak = false;
        this.complete = this.complete.bind(this);
        this.uncomplete = this.uncomplete.bind(this);
        this.additionalFieldsHelper = additionalFieldsHelper;
        this.routeRepository = routeRepository;
    }

    activate = function (widgetOptions) {
        this.dispatchDate = widgetOptions.dispatchDate;
        this.dispatchProjectCode = widgetOptions.dispatchProjectCode;
        this.allowEmployeeBreaksEntry = widgetOptions.allowEmployeeBreaksEntry || false;
        this.navigateBackOnSave = widgetOptions.navigateBackOnSave || false;
        this.isInSummary = widgetOptions.isInSummary || false;
        this.lunchSelected(true);
        this.breakSelected(true);

        if (widgetOptions.reloadCallBack) {
            widgetOptions.reloadCallBack(reload.bind(this, this));
        }

        if (!widgetOptions.entry) {
            return loadData(this, widgetOptions.dispatchProjectCode, widgetOptions.dispatchDate);
        } else {
            mapDataFromApi(this, widgetOptions.entry);
            return new jQuery.Deferred().resolve();
        }
    }

    complete () {
        if (this.entry().ReadyToComplete.IsReadyToComplete) {
            callComplete(this, false);
        } else {
            showNextStepToDo(this);
        }
    }

    uncomplete() {
        dailyEntryService.uncomplete(this.dispatchProjectCode, this.dispatchDate).done((data) =>  {
            if (data.Status === "2") {
                routerHelper.navigateSelf();
            }
        });
    }
}
