define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "services/equipmentTypeService",
    "services/requisitionService",
    "services/skillsService",
    "services/tradesService",
    "repositories/settingRepository"

], function (ko, _, resx, dateHelper, notificationHelper, queryStringHelper, routerHelper, equipmentTypeService, requisitionService, skillsService, tradesService, settingRepository) {
    "use strict";

    var LABOR_TYPE = 'Trade';
    var EQUIPMENT_TYPE = 'EquipmentType';

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.btnText = ko.observable();
            self.dispatchDate = ko.observable();
            self.dispatchProjectCode = ko.observable();
            self.id = ko.observable();
            self.isProjectResponsible = false;
            self.type = ko.observable(LABOR_TYPE);
            //ValidatedForm
            self.validatedForm = ko.validatedObservable({
                //Labor
                trade: ko.observable(null),
                skill: ko.observable(null),
                //Equipment
                equipmentType: ko.observable(null),
                //Shared
                quantity: ko.observable(1),
                requiredDate: ko.observable(null),
                comment: ko.observable(''),
                templateCode: ko.observable(''),

                clear: function() {
                    self.validatedForm().trade(null);
                    self.validatedForm().skill(null);

                    self.validatedForm().equipmentType(null);

                    self.validatedForm().quantity(1);
                    self.validatedForm().requiredDate(dateHelper.addHour(dateHelper.getNow(), 24));
                    self.validatedForm().comment('');
                    self.validatedForm().templateCode('');
                }
            });

            //Validators
            self.validatedForm().trade.extend({
                required: {
                    onlyIf: function() {
                        return self.type() === LABOR_TYPE;
                    },
                    message: resx.localize('err_TradeRequired')
                }
            });

            self.validatedForm().equipmentType.extend({
                required: {
                    onlyIf: function() {
                        return self.type() === EQUIPMENT_TYPE;
                    },
                    message: resx.localize('err_EquipmentTypeRequired')
                }
            });

            self.validatedForm().quantity.extend({
                required: { message: resx.localize('err_QuantityRequired') }
            });

            self.validatedForm().quantity.extend({
                min: {
                    params: 1,
                    message: resx.localize('err_MinQuantity')
                }
            });

            self.validatedForm().requiredDate.extend({
                required: { message: resx.localize('err_RequiredDateRequired') }
            });

            self.equipmentTypeClass = ko.pureComputed(function() {
                var cls = self.type() === EQUIPMENT_TYPE ? "active" : "";
                if (self.isEdit()) {
                    cls += " disabled";
                }
                return cls;
            });

            self.laborTypeClass = ko.pureComputed(function() {
                var cls = self.type() === LABOR_TYPE ? "active" : "";
                if (self.isEdit()) {
                    cls += " disabled";
                }
                return cls;
            });
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, id, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.id(id);
            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);

            self.clear();
            self.btnText(resx.localize("Save"));
        }

        function loadData() {
            if (self.isEdit()) {
                requisitionService.getRequisition(self.dispatchProjectCode(), self.id()).done(loadRequisition);
            }

            self.validatedForm().templateCode(settingRepository.getDispatchTemplate());
        }

        function buildRequisitionDto() {
            var dto = {};

            if (self.isEdit()) {
                dto.Id = self.id();
            }

            dto.ResourceType = self.type();
            if (self.type() === LABOR_TYPE) {
                dto.ResourceCode = self.validatedForm().trade().id;
                if (self.validatedForm().skill()) {
                    dto.SkillCode = self.validatedForm().skill().id;
                }
            } else if (self.type() === EQUIPMENT_TYPE) {
                dto.ResourceCode = self.validatedForm().equipmentType().id;
            }

            dto.Quantity = self.validatedForm().quantity();
            dto.RequestDate = self.validatedForm().requiredDate();
            dto.Comment = self.validatedForm().comment();
            dto.TemplateCode =self.validatedForm().templateCode();

            return dto;
        }

        function loadRequisition(data) {
            self.validatedForm().trade(null);
            self.validatedForm().equipmentType(null);
            self.type(data.ResourceType);

            if (data.ResourceType === LABOR_TYPE) {
                self.validatedForm().trade({ id: data.ResourceCode, text: data.ResourceCode + " - " + data.ResourceName });
                if (data.SkillCode) {
                    self.validatedForm().skill({ id: data.SkillCode, text: data.SkillCode + " - " + data.SkillDescription });
                } else {
                    self.validatedForm().skill(null);
                }
            }
            else if (data.ResourceType === EQUIPMENT_TYPE) {
                self.validatedForm().equipmentType({ id: data.ResourceCode, text: data.ResourceCode + " - " + data.ResourceName });
            }

            self.validatedForm().quantity(data.Quantity);
            self.validatedForm().requiredDate(data.RequestDate);
            self.validatedForm().comment(data.Comment);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
            return loadData();
        };

        ctor.prototype.clear = function () {
            self.type(LABOR_TYPE);
            self.validatedForm().clear();
        };

        ctor.prototype.isEdit = function () {
            return self.id() !== null && self.id() !== undefined;
        };

        ctor.prototype.getEquipmentTypes = {
            transport: function (params, success, failure) {
                equipmentTypeService.getEquipmentTypeForCompany(params.data.filter, params.data.page || 1)
                .done(function (data) {
                    return success(data);
                })
                .fail(failure);
            }
        };

        ctor.prototype.getSkills = {
            transport: function (params, success, failure) {
                skillsService.getSkillsForCompany(params.data.filter, params.data.page || 1)
                .done(function (data) {
                    return success(data);
                })
                .fail(failure);
            }
        };

        ctor.prototype.getTrades = {
            transport: function (params, success, failure) {
                tradesService.getTradesForCompany(params.data.filter, params.data.page || 1)
                .done(function (data) {
                    return success(_.map(data, function (x) {
                        x.Id = x.Code;
                        return x;
                    }));
                })
                .fail(failure);
            }
        };

        ctor.prototype.equipmentTabClick = function() {
            self.type(EQUIPMENT_TYPE);
        };

        ctor.prototype.laborTabClick = function () {
            self.type(LABOR_TYPE);
        };

        ctor.prototype.save = function() {
            if (self.validatedForm.isValid()) {
                routerHelper.showLoading();

                requisitionService.saveRequisition(self.dispatchProjectCode(), self.dispatchDate(), buildRequisitionDto())
                    .done(function() {
                        routerHelper.navigateBack();
                    })
                    .always(function() {
                        routerHelper.hideLoading();
                    });
            } else {
                notificationHelper.showValidationError(self.validatedForm.errors);
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
