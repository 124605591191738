import { observable, computedFrom, bindable, inject } from "aurelia-framework";
import { ValidationRules, ValidationControllerFactory, validateTrigger } from "aurelia-validation";

import { I18N } from "aurelia-i18n";

import { default as val } from "core/val";
import { default as _ } from "underscore";
import { default as notifier } from "helpers/notificationHelper";
import { default as routerHelper } from "helpers/routerHelper";

import { default as defaultValues } from "services/defaultService";
import { default as enumHelper } from "helpers/enumHelper";

const defaultScaleFactor = 7;

@inject(ValidationControllerFactory, I18N)
export class MaterialBillingEditMultiCustomElement {
    @observable selectedItem;
    @observable inventoryScaleFactor = 0;
    @observable description = "";
    @observable description2 = "";
    @observable description3 = "";
    @observable quantity = 1;

    @bindable actions;
    @bindable validationValues = {};
    @bindable entityId = 0;
    @bindable lineNo;
    @bindable catalogBaseUrl = "";
    @bindable readonly = false;
    @bindable section;
    @bindable showNotInCatalog = false;
    @bindable serviceType;
    @bindable isBilling = false;

    @computedFrom("serviceType")
    get isReservation() {
        return this.serviceType === "S";
    }

    @computedFrom("section")
    get predefinedSentencesOption() {
        return this.section;
    }

    @computedFrom("selectedItem")
    get lookupPoNumberEnable() {
        return (!this.selectedItem ||
            (!this.selectedItem.data.ReservationNumber &&
                !this.selectedItem.data.IsBillingItem)) &&
            + this.id <= 0;
    }

    @computedFrom("site", "predefinedSentencesOption", "billingItem")
    get showSite() {
        return (this.site &&
            _.contains(["projects", "service_calls"], this.predefinedSentencesOption) &&
            !this.billingItem);
    }

    constructor(validationControllerFactory, i18n) {
        this.templateResult = PLATFORM.moduleName("pages/templates/maSelectTemplates/material_edit_template.html");
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.manual;
        this.i18n = i18n;

        this.descriptionMaxLength = val.get('materialBillingEdit.description', 'maxLength');
        this.id = 0;
        this.isNew = false;
        this.descriptionreadonly = false;
        this.subscriptions = [];
        this.unitDescription = "";
        this.site = "";
        this.templateResult = "";
        this.showCodeInput = false;
        this.outCatalogCode = "";
        this.isBilling = false;
        this.readonlyQty = false;
        this.materialBillingItemCards = [];
        this.cardIndex = 0;
    }

    lookupActivity = {
        transport: (params, success, failure) => {
            this.actions.getActivities(params.data.filter, params.data.page || 1).done(success).fail(failure);
        }
    }

    lookupPoNumber = {
        transport: (params, success, failure) => {
            this.actions.getLookupPoNumber(params.data.filter, params.data.page || 1).done(success)
                .fail(failure);
        },
        mapResults: (item) => {
            var desc = item.Id +
                (item.Extension ? "-" + item.Extension : "") +
                " - " +
                item.SupplierCode +
                " " +
                item.Supplier;
            return { id: item.Id, text: desc, data: item };
        }
    }

    selectedItemChanged(newValue, oldValue) {
        this.inventoryScaleFactor = defaultScaleFactor;
        if (newValue && newValue.data) {
            if (newValue.data.Code !== "!") {
                this.description = newValue.data.ClientDescription1;
                this.description2 = newValue.data.ClientDescription2;
                this.description3 = newValue.data.ClientDescription3;
            } else if (!this.readonly) {
                this.descriptionreadonly = false;
                this.description = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description;
                this.description2 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description2;
                this.description3 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description3;
            }
            if (newValue.data.IsBillingItem && this.serviceType === "W") {
                this.descriptionMaxLength = this.validationValues.description.isBilling.maxLength;
            } else {
                this.descriptionMaxLength = this.validationValues.description.isNotBilling.maxLength;
            }
            if (newValue.data.OrderNumber) {
                this.poNumber = { id: newValue.data.OrderNumber, text: newValue.data.OrderNumber };
            } else {
                this.poNumber = null;
            }
            this.unitDescription = newValue.data.UnitDescription;
            this.site = newValue.data.Site;

            

            if(!this.isBilling) {
                this.showCodeInput = newValue.data.Code === "!" || newValue.data.Code === "";
                this.outCatalogCode = this.showCodeInput ? newValue.data.OutCatalogCode : "";
                if (this.showCodeInput) {
                    this.selectedItem.id = "!";
                }
            }

            if (newValue.data.InventoryScaleFactor) {
                this.inventoryScaleFactor = newValue.data.InventoryScaleFactor;
            }

            if (newValue.data.Code !== "!" && newValue.data.Balance && newValue.data.Balance !== 0) {
                this.quantity = newValue.data.Balance;
            }
        }
    }

    clearForm() {
        this.description = "";
        this.description2 = "";
        this.description3 = "";
        this.selectedItem = undefined;
        this.activity = null;
        this.quantity = 1;
        this.previousBilling = false;
        this.poNumber = null;
    }

    loadData() {
        return this.actions.getMaterial().done((data) => { this.initialize(data); }).fail(routerHelper.navigateBack);
    }

    initialize(data) {
        var selectedItem = {
            id: data.Id,
            text: (data.Id === "" || data.Id === null || data.Id === "!")
                ? this.i18n.tr("AddItemNotInCatalog")
                : data.Id,
            data: data
        };
        this.selectedItem = selectedItem;
        this.showNotInCatalog = (this.showNotInCatalog && data.Id === "!");
        this.description = data.Comment;
        this.description2 = data.Description2;
        this.description3 = data.Description3;
        this.previousBilling = data.IsBillingItem;
        this.quantity = data.QuantityUsed;
        this.poNumber = (data.OrderNumber ? { id: data.OrderNumber, text: data.OrderNumber + (data.OrderSupplierCode ? " - " + data.OrderSupplierCode : "") } : null);
        this.showCodeInput = (data.Id === "!" && !this.isNew && !this.isBilling);
        this.outCatalogCode = data.OutCatalogCode;
        this.inventoryScaleFactor = data.InventoryScaleFactor;

        this.timestamp = data.Timestamp;

        if (this.serviceType === "W") {
            if (data.ActivityCode) {
                this.activity = { id: data.ActivityCode, text: data.ActivityDescription };
            } else {
                this.activity = null;
            }

        }

        this.readonlyQty = this.readonly;
        this.readonly = (this.readonly || (data.IsSelf === false));

        this.descriptionreadonly = this.readonly;
        if (this.serviceType === "W" && data.Id !== null && !data.IsBillingItem && !this.readonly) {
            this.descriptionreadonly = true;
        }

        this.site = selectedItem.data.Site;
    }

    initialiseViewModel(lineNo) {
        if (!lineNo || lineNo === "-1") {
            this.id = 0;
            this.isNew = true;
            this.clearForm();
            return jQuery.Deferred().resolve();
        } else {
            this.id = lineNo;
            this.isNew = false;
            return this.loadData();
        }
    }

    getSelectedExtension() {
        if (this.selectedItem.data.Extension) {
            return this.selectedItem.data.Extension;
        }
        if (this.poNumber && this.poNumber.id && this.poNumber.data) {
            return this.poNumber.data.data.Extension;
        }
        return "";
    }

    bind() {

        if (this.section !== "fieldservices") {
            ValidationRules
                .ensure(x => x.selectedItem).required().withMessageKey("err_ItemRequired")
                .ensure(x => x.quantity).required().withMessageKey("err_QuantityRequired")
                .ensure(x => x.quantity).satisfies(value => value > 0).withMessageKey("err_MinQuantity")
                .ensure(x => x.description).required().withMessageKey("err_DescriptionRequired")
                .on(this);
        } else {
            ValidationRules
                .ensure(x => x.selectedItem).required().withMessageKey("err_ItemRequired")
                .ensure(x => x.quantity).required().withMessageKey("err_QuantityRequired")
                .ensure(x => x.description).required().withMessageKey("err_DescriptionRequired")
                .on(this);
        }

        if (this.serviceType === "W" && !this.billingItem && !this.readonly) {
            this.descriptionreadonly = true;
        } else {
            this.descriptionreadonly = this.readonly;
        }

        this.site = defaultValues.getInventorySite();

        return this.initialiseViewModel(this.lineNo);
    }

    async save() {
        if(this.materialBillingItemCards.length > 0)
        {
            var dataList = this.materialBillingItemCards.map(card =>  card.model);

            this.actions.setMaterial(dataList).done(() => {
                routerHelper.navigateBack();
            })
            .concurrence(this.loadData)
            .always(() => {
                routerHelper.hideLoading();
            });

            return;
        }

        const result = await this.validationController.validate();

        if (result.valid) {
            routerHelper.showLoading();
            var data = [
                {
                    Id: this.selectedItem.id,
                    Comment: this.description,
                    IsBillingItem: this.selectedItem.data.IsBillingItem,
                    QuantityUsed: this.quantity,
                    LineNo: this.id,
                    OrderNumber: (this.poNumber ? this.poNumber.id : ""),
                    OrderRow: this.selectedItem.data.OrderRow,
                    ReservationNumber: this.selectedItem.data.ReservationNumber,
                    ActivityCode: ((this.serviceType === "W" && this.activity) ? this.activity.id : ""),
                    Site: this.selectedItem.data.Site || "",
                    PreviousIsBilling: this.previousBilling,
                    Extension: this.getSelectedExtension(),
                    Timestamp: this.id ? this.timestamp : undefined,
                    OutCatalogCode: this.outCatalogCode
                }
            ];

            this.actions.setMaterial(data).done(() => {
                routerHelper.navigateBack();
            })
                .concurrence(this.loadData)
                .always(() => {
                    routerHelper.hideLoading();
                });
        } else {
            const errors = _.chain(result.results).filter((result) => { return !result.valid }).pluck("message").value();
            notifier.showValidationError(errors);
        }
    }

    async add() {
        const result = await this.validationController.validate();

        if (result.valid) {
            var data = [
                {
                    Id: this.selectedItem.id,
                    Comment: this.description,
                    IsBillingItem: this.selectedItem.data.IsBillingItem,
                    QuantityUsed: this.quantity,
                    LineNo: this.id,
                    OrderNumber: (this.poNumber ? this.poNumber.id : ""),
                    OrderRow: this.selectedItem.data.OrderRow,
                    ReservationNumber: this.selectedItem.data.ReservationNumber,
                    ActivityCode: ((this.serviceType === "W" && this.activity) ? this.activity.id : ""),
                    Site: this.selectedItem.data.Site || "",
                    PreviousIsBilling: this.previousBilling,
                    Extension: this.getSelectedExtension(),
                    Timestamp: this.id ? this.timestamp : undefined,
                    OutCatalogCode: this.outCatalogCode
                }
            ];

            this.materialBillingItemCards.push(this.createCard(data[0], this.description2, this.description3));
            this.clearForm();
        }
    }

    delete(cardIndex) {
        this.materialBillingItemCards = this.materialBillingItemCards.filter((card) => {
            return card.id !== cardIndex;
        });
    }

    createCard(item, desc2, desc3) {
        this.cardIndex++;

        const card = {
            id: this.cardIndex,
            model: item,
            displayLines: [
                { display: `${item.Id === "!" ? item.Id + item.OutCatalogCode : item.Id} - ${this.formatDescription(item.Comment, desc2, desc3)}`, class: "text-primary font-weight-bold"},
                { display: `${this.i18n.tr("Quantity")}: ${item.QuantityUsed}`, class: "font-weight-bold" },
            ],
            actionItems: [{id: "delete", icon: "fa fa-trash text-danger", action: this.delete.bind(this, this.cardIndex) }],
        };

        return card;
    }

    formatDescription(desc1, desc2, desc3) {
        var descArray = [desc1, desc2, desc3];
        descArray = _.filter(descArray, function(n){
            return n !== "";
        });

        return descArray.join(" | ");
    }
}