import { transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

@transient()
export class LstNoData {
    constructor() {

        this.isVisible = ko.observable(false);
        this.txtHeader = ko.observable('');
        this.txtBody = ko.observable('');
        this.isReadOnly = ko.observable(false);
        this.showBody = ko.observable(false);
    }

    activate(widgetSettings) {
        this.isVisible(widgetSettings.isVisible);
        this.showBody(widgetSettings.showBody === undefined ? true : widgetSettings.showBody);
        this.isReadOnly(widgetSettings.isReadOnly === undefined ? false : widgetSettings.isReadOnly);

        if (widgetSettings.text !== undefined) {
            if (_.isFunction(widgetSettings.text)) {
                this.txtHeader = widgetSettings.text;
            } else {
                this.txtHeader(widgetSettings.text);
            }
            this.txtBody('');
        } else if (widgetSettings.context !== undefined) {
            this.txtHeader(resx.localize('lstNoData_' + widgetSettings.context + '_Header'));
            if (this.showBody()) {
                this.txtBody(resx.localize('lstNoData_' + widgetSettings.context + '_Body'));
            }
        } else {
            this.txtHeader(resx.localize('lstNoData_Generic_Header'));
            if (this.showBody()) {
                this.txtBody(resx.localize('lstNoData_Generic_Body'));
            }
        }
    }
}