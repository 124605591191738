import { inject, transient } from "aurelia-framework";

import GuidHelper from "helpers/guidHelper";
import { default as ko } from "knockout";
import { default as moment } from "moment";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as dateHelper } from "helpers/dateHelper";
import { default as labelHelper } from "helpers/labelHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as defaultService } from "services/defaultService";
import { default as templateService } from "services/templateService";
import { SettingRepository } from "repositories/setting-repository";

import "select2";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/i18n/datepicker-fr-CA";
import "widgets/pageBottom";

function loadtimeList(increment) {
    var times = [];
    for (var hours = 0; hours < 24; hours++) {
        for (var minutes = 0; minutes <= 59; minutes++) {
            if (minutes % increment === 0) {
                times.push(("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2));
            }
        }
    }
    return times;
}

function initCal(self, isRefresh) {
    jQuery("#" + self.id).each(function () {
        var $container = jQuery(this);
        var $button = $container.find(".button").first();
        var $selected_title = $container.find("[data-id='selected']");
        var $calendar = $container.find(".date-input-calendar");
        var date_internal_format = "yy-mm-dd";

        var refresh_date = function (self) {
            var date = $calendar.datepicker("getDate");
            var visible_date = date ? dateHelper.getFullTextDate(date) : "";
            self.date(date);

            $selected_title.text(visible_date);
            updateValue(self);
        };

        if (!isRefresh) {
            $calendar.datepicker({
                dateFormat: date_internal_format,
                onSelect: function (dateText, instance) {
                    refresh_date(self);
                    $container.removeClass("open");
                    if (self.actionOnSelect) {
                        self.actionOnSelect.save(dateText);
                    }
                },
                minDate: self.minDate(),
                maxDate: self.maxDate()
            });

            $button.click(function (e) {
                $container.toggleClass("open");
            });

            // Clicking outside the calendar closes it
            jQuery(document).click(function (e) {
                var $target = jQuery(e.target);
                if (!$target.is($container) && $target.closest($container).length === 0 && $target.children().filter(".ui-icon").length === 0) {
                    $container.removeClass("open");
                }
            });
        }

        $calendar.datepicker( "option", jQuery.datepicker.regional[ self.SettingRepository.getLanguage() ]);

        $calendar.datepicker("setDate", self.date());
        refresh_date(self);
    });
}

function initTime(self) {
    var select = jQuery("#time-" + self.id).select2({
        minimumResultsForSearch: 999,
        width: "100%",
        language: labelHelper.getDefaultSelect2Labels(),
        data: self.timeList,
        allowClear: self.allowClear,
        placeholder: resx.localize("SelectOoo")
    }).on("select2:unselect", function (e) {
        self.clear(self, e);
    }).on("change", function (e) {
        self.time(e.target.value);
        manageNullDate(self);
        updateValue(self);
    });

    select.val(self.time()).trigger("change");
}

function manageNullDate(self) {
    // Assign the default date if required
    if (!self.date() && self.isTime && !!self.time() && !!self.actions && !!self.actions.getDefaultDate) {
        var defaultDate = moment(self.actions.getDefaultDate()).toDate();
        defaultDate.setHours(0, 0, 0);
        self.date(defaultDate);
    }
}

function refreshUi(self, newValue) {
    if (newValue) {
        if (!(newValue instanceof Date)) {
            newValue = dateHelper.dateFromUTC(newValue);
        }

        if (!self.oldValue || newValue.getTime() !== self.oldValue.getTime()) {
            //update calendar if needed
            if (newValue !== self.date()) {
                self.date(newValue);
                jQuery("#" + self.id).find('span[data-id=selected]').text(dateHelper.getFullTextDate(self.date()));
                jQuery("#" + self.id).find('.date-input-calendar').datepicker("setDate", self.date());
            }
            //update time picker if needed
            var time = dateHelper.formatDate("HH:mm", dateHelper.roundTime(newValue, self.increment));
            if (time !== self.time()) {
                self.time(time);
                jQuery("#time-" + self.id).val(self.time()).trigger("change");
            }
        }
    } else {
        jQuery("#" + self.id).find('span[data-id=selected]').text("");
    }
}

function updateValue(self) {
    if (self.date() instanceof Date) {
        self.dateValue = self.date();
        if (self.isTime) {
            if (self.time()) {
                self.dateValue.setHours(self.time().split(":")[0]);
                self.dateValue.setMinutes(self.time().split(":")[1]);
            } else {
                self.dateValue.setHours(0);
                self.dateValue.setMinutes(0);
            }
        }
        self.value(dateHelper.formatDateToSend(self.dateValue));
    } else {
        self.dateValue = '';
        self.value(null);
    }
}

function bindViewModel(self, increment, widgetSettings) {
    self.increment = increment;
    if (widgetSettings.value()) {
        widgetSettings.value(dateHelper.roundTime(widgetSettings.value(), self.increment));
    }

    self.id = widgetSettings.id || self.GuidHelper.createGuid();
    self.timeList = loadtimeList(self.increment);
    self.format = widgetSettings.format || "DateTime";

    self.disabled = widgetSettings.disable === null || widgetSettings.disable === undefined ? false : widgetSettings.disable;

    self.actionOnSelect = widgetSettings.onSelect || false;

    self.hideSupDates = widgetSettings.hideSupDates || false;
    self.allowClear = widgetSettings.allowClear || false;
    self.value = widgetSettings.value;
    self.dateValue = dateHelper.dateFromUTC(widgetSettings.value());
    self.date = ko.observable(widgetSettings.value());
    self.time = ko.observable(dateHelper.formatDate("HH:mm", widgetSettings.value()));

    self.minDate = _.isFunction(widgetSettings.minDate) ? widgetSettings.minDate : ko.observable(widgetSettings.minDate);
    self.maxDate = _.isFunction(widgetSettings.maxDate) ? widgetSettings.maxDate : ko.observable(widgetSettings.maxDate);

    self.isDate = self.format.toLowerCase().indexOf("date") > -1;
    self.isTime = self.format.toLowerCase().indexOf("time") > -1;

    self.oldValue = undefined;
    self.subsubscriptionBefore = self.value.subscribe(function (oldValue) {
        if (oldValue && oldValue instanceof Date) {
            self.oldValue = oldValue;
        } else {
            self.oldValue = dateHelper.dateFromUTC(oldValue);
        }
    }, null, "beforeChange");

    self.subsubscription = self.time.subscribe(function (newValue) {
        if (newValue === "") {
            return;
        }
        var newdate = self.date() ? self.date() : new Date();
        newdate.setHours(self.time().split(":")[0]);
        newdate.setMinutes(self.time().split(":")[1]);
        newdate.setSeconds(0);
        refreshUi(self, newdate);
    });

    self.subsubscription = self.value.subscribe(function (newValue) {
        refreshUi(self, newValue);
    });
}

@transient()
@inject(GuidHelper, SettingRepository)
export class DateTimePicker{

        constructor(GuidHelper, SettingRepository) {
            this.GuidHelper = GuidHelper;
            this.SettingRepository = SettingRepository;

            this.actions = {
                "getDefaultDate": function() { return new Date(); },
                "getDefaultTime": function() {}
            };

            this.increase = this.increase.bind(this);
            this.decrease = this.decrease.bind(this);
            this.clear = this.clear.bind(this);
            this.compositionCompleteCallback = this.compositionCompleteCallback.bind(this);
        }

        activate(widgetSettings) {
            this.actions = widgetSettings.actions;

            var dfd = new jQuery.Deferred();
            if (!widgetSettings.increment) {
                dfd = templateService.getUserTemplateIncrement();
            } else {
                dfd.resolve(widgetSettings.increment);
            }

            return dfd.done( (increment) => {
                bindViewModel(this, increment, widgetSettings);
            });
        }

        compositionCompleteCallback() {
            initCal(this, false);
            initTime(this);

            this.isCompositionComplete = true;
        }

        // Detached ne devrait plus être nécessaire si les viewmodels sont recréés à chaque fois?
        //detached() {
        //    this.subsubscription.dispose();
        //    this.subsubscriptionBefore.dispose();
        //}

        increase() {
            if (!this.time()) {
                this.time(this.timeList[0]);
            } else {
                var index = _.indexOf(this.timeList, this.time());
                if (index < this.timeList.length - 1) {
                    this.time(this.timeList[index + 1]);
                }
            }
            jQuery("#time-" + this.id).val(this.time()).trigger("change");
            updateValue(this);
        }

        decrease() {
            if (!this.time()) {
                this.time(this.timeList[0]);
            } else {
                var index = _.indexOf(this.timeList, this.time());
                if (index > 0) {
                    this.time(this.timeList[index - 1]);
                }
            }
            jQuery("#time-" + this.id).val(this.time()).trigger("change");
            updateValue(this);

        }

        clear(dtPicker, event) {
            event.stopImmediatePropagation();

            this.date('');
            this.time('');

            updateValue(this);

            jQuery("#" + this.id).find('span[data-id=selected]').text('');
            jQuery("#" + this.id).find('.date-input-calendar').datepicker("setDate", null);
            jQuery("#time-" + this.id).val("").trigger("change");
        }
 }