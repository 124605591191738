import { inject, transient } from "aurelia-framework";

import GuidHelper from "helpers/guidHelper";
import { default as ko } from "knockout";
import { default as resx } from "core/resx";
import { default as weatherHelper } from "helpers/weatherHelper";

@transient()
@inject(GuidHelper)
export class WeatherPicker {

    constructor(guidHelper) {
        this.GuidHelper = guidHelper;

        this.resx = resx;
        this.id = "";
        this.readonly = ko.observable(false);
        this.weatherType = ko.observable(0);
        this.windForce = ko.observable(0);
        this.degree = ko.observable(0);
        this.temperatureUnit = weatherHelper.getUserTemperatureUnit();
        this.valueToReload = ko.observable({});
        this.title = '';

        this.weatherType.subscribe(() => {
            this.updateValue();
        });
        this.windForce.subscribe(() => {
            this.updateValue();
        });
        this.degree.subscribe(() => {
            this.updateValue();
        });

        this.clearGrid = this.clearGrid.bind(this);
    }

    //#region Private Functions
    mapFromValue(value) {
        this.weatherType(value.weatherType);
        this.windForce(value.windForce);
        this.degree(weatherHelper.toUserUnitFromCelsius(value.degree));
    }

    updateValue() {
        this.value({
            weatherType: this.weatherType(),
            windForce: this.windForce(),
            degree: weatherHelper.fromUserUnitFromCelsius(this.degree())
        });
    }
    //#endregion

    //#region Public Functions
    activate(widgetSettings) {
        var value = widgetSettings.value();

        this.id = widgetSettings.id || this.GuidHelper.createGuid();
        this.readonly(widgetSettings.readonly);
        this.title = widgetSettings.title;
        this.value = widgetSettings.value;
        this.valueToReload = widgetSettings.valueToReload;
        this.valueToReload.subscribe((value) => {
            this.mapFromValue(value);
        });

        this.mapFromValue(value);
    }

    clearGrid() {
        this.weatherType(0);
        this.windForce(0);
        this.degree(0);
    }
        
    //#endregion
}
