/****************************************************************************************
 * IMPORTANT - KNOWN ISSUE
 * In one run (no refresh), if a dispatch is accessed from the mobile site,
 * then deleted in maestro* and a new dispatch is added to the same person,
 * the header won't be refreshed if you access the new dispatch since they have the 
 * same dispatchId.
 * 
 * This is cause by maestro* which attributes the first available number as dispatchId
 * instead of using an auto-increment identity field.
 ****************************************************************************************/
import { singleton } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as dispatch } from "services/dispatchService";
import { default as dateHelper } from "helpers/dateHelper";
import { default as labelHelper } from "helpers/labelHelper";


function loadData(self, id, forceRefresh) {
    if (id !== self.previousId || forceRefresh) {
        dispatch.getHeader(id).done(function (data) {
            setDisplayValues(self, data.AssignedDate, data.ServiceType, data.ServiceCallType, data.Id);

            self.previousId = id;
            self.previousTitle = self.title();
            self.previousSubtitle = self.subtitle();
        });
    } else {
        self.title(self.previousTitle);
        self.subtitle(self.previousSubtitle);
    }
}

function setDisplayValues(self, assignedDate, serviceType, serviceCallType, id) {
    self.title(assignedDate ? dateHelper.getFullTextDate(assignedDate) : "");
    self.subtitle((serviceType === 'S' ? labelHelper.getCallTypeLabel(serviceCallType) : labelHelper.getLabel(serviceType)) + " - " + id);
}

@singleton()
export class ServiceHeader {
    constructor() {
        this.previousId = '';
        this.previousTitle = '';
        this.previousSubtitle = '';

        this.title = ko.observable('');
        this.subtitle = ko.observable('');
    }

    activate(widgetSettings) {
        if (widgetSettings.id && widgetSettings.id()) {
            loadData(this, widgetSettings.id(), widgetSettings.forceRefresh);
        } else if (widgetSettings.assignedDate) {
            setDisplayValues(this, 
                widgetSettings.assignedDate,
                widgetSettings.serviceType,
                widgetSettings.serviceCallType,
                widgetSettings.serviceId
            );
        }
    }
}