import { DocumentService } from "services/document-service";

define([
    "knockout",
    "helpers/routerHelper",
    "services/documentService",
    "helpers/documentHelper"
], function (ko, routerHelper, documentService, documentHelper) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        ctor.inject = [DocumentService];

        function ctor(docService) {
            self = this;
            this.docService = docService;

            //#region Properties
            self.readonly = false;
            self.dispatchProjectCode = ko.observable();
            self.dailyEntryDate = ko.observable();

            self.actions = {};
            //#endregion
        }

        //#region Public Methods
        ctor.prototype.activate = function(params) {
            var paramsQueryString = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
            self.dispatchProjectCode(params.dispatchProjectCode);
            self.dailyEntryDate(params.dailyEntryDate);

            self.actions = {
                getSections: documentService.getSectionsByDispatchProjectDate.bind(self, params.dispatchProjectCode, params.dailyEntryDate),
                getSectionDocuments: documentService.getDocumentsByDispatchProjectDate.bind(self, params.dispatchProjectCode, params.dailyEntryDate),
                getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
                deleteDocument: self.docService.DeleteDocument.bind(self.docService)
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
