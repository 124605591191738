define([
    "jquery",
    "services/requests",
    "helpers/apiHelper"
], function (jQuery, requests, apiHelper) {
    "use strict";

    var exports = {
        mapWindForceValue: function (value) {
            if (value > 0 && value <= 1) {
                return 0;
            }
            if (value > 1 && value <= 20) {
                return 1;
            }
            if (value > 20 && value <= 50) {
                return 2;
            }
            if (value > 50 && value <= 75) {
                return 3;
            }
            if (value > 75) {
                return 4;
            }
            return 0;
        },

        mapWeatherTypeValue: function (value) {
            if (value === "clear-day" || value === "clear-night") {
                return 1;
            }
            if (value === "partly-cloudy-day"||value === "partly-cloudy-night"||value === "wind"||value === "fog"||value==="cloudy") {
                return 2;
            }
            if (value === "rain"||value==="hail") {
                return 3;
            }
            if (value === "tornado" || value === "thunderstorm") {
                return 4;
            }
            if (value === "snow" || value === "sleet") {
                return 5;
            }
            return 0;
        },

        getCurrentTemperature: function (position) {
            var self = this;
            var dfd = new jQuery.Deferred();
            requests.getExternalJson(apiHelper.getExternalWeatherApiUrl(position)).done(function (data) {
                dfd.resolve({
                    weatherType: self.mapWeatherTypeValue(data.currently.icon),
                    windForce: self.mapWindForceValue(data.currently.windSpeed),
                    degree: Math.round(data.currently.temperature)
                });
            });
            return dfd;
        }

    };

    return exports;
});
