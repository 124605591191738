import { autoinject, bindingMode, DOM } from "aurelia-framework";
import { bindable, observable } from "aurelia-typed-observable-plugin";
import { Key } from "ts-keycode-enum";

import { FormControl } from "components/form-controls/form-control";
import Parse from "helpers/parse";

@autoinject()
export class NumberPicker extends FormControl {
    @bindable.string
    public unit: string | null = null;
    
    @bindable.nullable_number
    public min: number | null = null;
    @bindable.nullable_number
    public max: number | null = null;
    @bindable.nullable_number
    public step: number | null = 0.01;

    @bindable.nullable_number({ defaultBindingMode: bindingMode.twoWay })
    @observable public scale: number | null = 2;

    @bindable.nullable_number({ defaultBindingMode: bindingMode.twoWay })
    public value: number | null = null;

    @observable.string
    public internalValue: string = "";

    @observable.number
    public scaleInternalValue: number | null = 2;

    private inputNumberElementRef!: HTMLElement;
    private readonly onKeyDownEventHandlerPointer!: EventListener;

    constructor(element: Element) {
        super(element);

        this.onKeyDownEventHandlerPointer = (e: KeyboardEvent): void => {
            this.onKeyDownEventHandler(e);
        };
    }

    public bind(): void {
        this.valueChanged();
        this.scaleChanged();
        this.inputNumberElementRef.addEventListener("keydown", this.onKeyDownEventHandlerPointer, true);
    }
    
    public internalValueChanged(): void {
        this.value = Parse.NullableDecimal(this.internalValue);
        
        const valueChangedEvent = DOM.createCustomEvent("value-changed", { detail: { value: this.value }, bubbles: true });
        this.element.dispatchEvent(valueChangedEvent);
    }

    public valueChanged(): void {
        this.internalValue = this.value ? this.value.toString() : "";
    }

    public scaleChanged(): void {
        this.scaleInternalValue = this.scale;
    }

    private onKeyDownEventHandler(e: KeyboardEvent): void {

        const enterHandler = (): void => {
            e.preventDefault();
        };

        switch (e.keyCode) {
            case Key.E:
                return enterHandler();
            default:
                return;
        }
    }

}
