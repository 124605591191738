import { customElement, inject, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";
import { I18N } from "aurelia-i18n";
import { GridBase } from "components/ag-grid/grids/grid-base";
import { UserGridStateService } from "services/user-grid-state-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { CustomEventHelper } from "helpers/custom-event-helper";

@transient()
@inject(Element, I18N, UserGridStateService, EventAggregator)
@customElement("quotation-equipment-grid")
export class QuotationEquipmentGrid extends GridBase {

    @bindable.boolean
    public readonly: boolean = false;

    @bindable.boolean
    public hideSelection: boolean = false;

    constructor(element: Element, i18n: I18N, userGridStateService: UserGridStateService, eventAggregator: EventAggregator) {
        super(element, i18n, userGridStateService, eventAggregator);
    }

    public onAddClicked(type: ServiceCallQuotationItemType): void {
        CustomEventHelper.dispatchEvent(this.element, "add-clicked", { grid: this, itemType: type }, true, false);
    }

    public onDeleteClicked(): void {
        CustomEventHelper.dispatchEvent(this.element, "delete-clicked", { grid: this }, true, false);
    }
}
