define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/queryStringHelper",
    "helpers/dateHelper",
    "helpers/viewbag",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "moment",
    "widgets/breakTimePicker"
], function (ko, _, resx, queryStringHelper, dateHelper, viewbag, notificationHelper, routerHelper, moment) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.dispatchProjectCode = ko.observable("");
            self.dispatchDate = ko.observable("");
            self.dispatchId = ko.observable(0);
            self.isProjectResponsible = false;
            self.readonly = false;
            self.lunchEntry = ko.observable({});
            self.breakEntries = ko.observableArray([]);

            self.manualBreaksManagementEnabled = false;

            self.entry = {};

            self.validatedForm = ko.validatedObservable({
                lunchStartTime: ko.observable(null),
                lunchEndTime: ko.observable(null),
                lunchDescription: ko.observable(null),
                breaks: [],

                init: function() {
                    var validatedFormSelf = this;

                    validatedFormSelf.lunchEndTime = validatedFormSelf.lunchEndTime.extend({
                        validation: {
                            validator: function(val) {
                                var startTime = moment(validatedFormSelf.lunchStartTime());
                                var endTime = moment(validatedFormSelf.lunchEndTime());
                                return endTimeValidator(val, startTime, endTime);
                            },
                            message: resx.localize("err_LunchTimeCanNotExceed4Hours")
                        }
                    });

                    validatedFormSelf.breaks = [];
                    _.each(self.breakEntries(),
                        function(brk, i) {
                            validatedFormSelf.breaks.push({
                                breakLabel: ko.observable(brk.description),
                                breakStartTime: ko.observable(brk.startTime),
                                breakEndTime: ko.observable(brk.endTime)
                            });
                            validatedFormSelf.breaks[i].breakEndTime = validatedFormSelf.breaks[i].breakEndTime.extend({
                                validation: {
                                    validator: function(val) {
                                        var startTime = moment(validatedFormSelf.breaks[i].breakStartTime());
                                        var endTime = moment(validatedFormSelf.breaks[i].breakEndTime());
                                        return endTimeValidator(val, startTime, endTime);
                                    },
                                    message: resx.localize("err_BreakTime" + (i + 1) + "CanNotExceed4Hours")
                                }
                            });
                        });
                }
            });
            //#endregion
        }

        //#region Private Functions

        function endTimeValidator(val, startTime, endTime) {
            var duration = moment.duration(endTime.diff(startTime));

            function syncStartEndTime() {
                if (endTime < startTime) {
                    endTime.add(1, 'days');
                    duration = moment.duration(endTime.diff(startTime));
                }

                if (duration.asHours() > 24) {
                    endTime.add(-1, 'days');
                    duration = moment.duration(endTime.diff(startTime));
                }

            }
            syncStartEndTime();

            if (duration.asHours() > 4) {
                return false;
            }

            return true;
        }

        function bindViewModel(dispatchProjectCode, dispatchDate, dispatchId, querystring) {
            self.manualBreaksManagementEnabled = queryStringHelper.parseManualBreaksManagementEnabled(querystring);
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);
            self.readonly = queryStringHelper.parseReadonly(querystring) || self.manualBreaksManagementEnabled;

            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchId(dispatchId);
        }

        function findEmployeeEntry() {
            var entry = _.find(viewbag().list,
                    function (item) {
                        return item.dispatchId === +self.dispatchId();
                    });
            self.entry.resourceDesc = entry.resourceDesc;
            self.entry.resourceId = entry.resourceId;
            self.entry.dispatchId = entry.dispatchId;
            return entry;
        }

        function findLunchEntry(entry) {
            var selForm = self.validatedForm();
            self.lunchEntry(_.find(entry.activities,
                   function (activity) {
                       return activity.isNewLunch;
                   }));

            if (self.lunchEntry()) {
                selForm.lunchStartTime(self.lunchEntry().startTime);
                selForm.lunchEndTime(self.lunchEntry().endTime);
                selForm.lunchDescription(self.lunchEntry().description);
            }
        }

        function findBreakEntries(entry) {
            var selForm = self.validatedForm();
            self.breakEntries(_.filter(entry.activities,
                   function (activity) {
                       return activity.isNewBreak && !activity.isDeleted();
                   }));

        }

        function findLunchAndBreaksEntry(entry) {
            findLunchEntry(entry);
            findBreakEntries(entry);
        }

        function loadData() {
            if (viewbag() && viewbag().dispatchProjectCode === self.dispatchProjectCode() && viewbag().dispatchDate === self.dispatchDate()) {
                var entry = findEmployeeEntry();

                findLunchAndBreaksEntry(entry);

                if (!self.lunchEntry() && self.breakEntries().length === 0) {
                    routerHelper.navigateBack();
                    return false;
                }

                self.validatedForm().init();
                return true;
            } else {
                routerHelper.navigateBack();
                return false;
            }
        }

        function updateVal(entry, startTime, endTime) {
            entry.startTime = startTime;
            entry.endTime = endTime;

            entry.startTimeDisplay = dateHelper.getTime(startTime);
            entry.endTimeDisplay = dateHelper.getTime(endTime);

            entry.isDeleted(false);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.dispatchId, params.q);
            return loadData();
        };

        ctor.prototype.updateValues = function () {
            if (self.validatedForm.isValid()) {
                if (self.lunchEntry() && !self.lunchEntry().isDeleted()) {
                    updateVal(
                        self.lunchEntry(),
                        self.validatedForm().lunchStartTime(),
                        self.validatedForm().lunchEndTime()
                        );
                }

                _.each(self.breakEntries(), function (brk, i) {
                    if (!brk.isDeleted()) {
                        updateVal(
                            brk,
                            self.validatedForm().breaks[i].breakStartTime(),
                            self.validatedForm().breaks[i].breakEndTime()
                            );
                    }
                });

                routerHelper.navigateBack();
            } else {
                notificationHelper.showValidationError(self.validatedForm.errors);
            }
        };

        ctor.prototype.deleteLunch = function () {
            self.lunchEntry().isDeleted(true);
        };

        ctor.prototype.deleteBreak = function (index) {
            self.breakEntries()[index].isDeleted(true);
        };

        //#endregion
        return ctor;
    })();

    return viewModel;
});
