define([
    "jquery",
    "knockout",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "services/documentService"
], function (jQuery, ko, routerHelper, queryStringHelper, documentService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.actions = {};
            self.readonly = false;
            self.editId = ko.observable();
            self.dispatchId = ko.observable();
            self.serviceType = "";
            //#endregion
        }

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            var paramsQs = routerHelper.getQuerystring(params.q);
            self.readonly = queryStringHelper.parseReadonly(params.q);
            self.editId(params.recommendationId);
            self.dispatchId(params.dispatchId);
            self.serviceType = params.serviceType;

            self.actions = {
                save: documentService.saveRecommendationPictureForDispatch.bind(self, self.dispatchId(), params.recommendationId, (params.serviceType === 'W' ? 'workorder' : 'serviceCall'), paramsQs.entityId)
            };
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
