import { inject, observable } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { ValidationRules, ValidationController, validateTrigger } from "aurelia-validation";
import { I18N } from "aurelia-i18n";
import { bindable } from "aurelia-typed-observable-plugin";

import { default as _ } from "underscore";
import { default as routeRepository } from "repositories/routeRepository";
import { default as routerHelper } from "helpers/routerHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as projectService } from "services/projectService";
import { default as dailyEntryService } from "services/dailyEntryService";
import { default as defaultService } from "services/defaultService";
import { default as dispatchService } from "services/dispatchService";
import { default as templateService } from "services/templateService";

@inject(ValidationController, I18N, routeRepository)

export default class dailyEntryExisting {
    i18n;
    validationController;
    routeRepository;
    routerHelper = routerHelper;
    dateHelper = dateHelper;
    dispatchProjectCode = "";
    list = [];
    closeList = [];
    isProjectResponsible = false;
    dailyEntryDate = "";
    noDataText = "";
    moreEntriesLoading = false;
    enableMoreEntries = true;
    displayMoreEntries = true;
    lblShowMoreEntries;
    page = 1;
    @observable maxDate;
    boundScrollHandler;


    constructor(validationController, i18n, routeRepository) {
        this.i18n = i18n;
        this.validationController = validationController;
        this.validationController.validateTrigger = validateTrigger.manual;
        this.routeRepository = routeRepository;

        this.lblShowMoreEntries = this.i18n.tr("ShowMoreEntries");

        this.boundScrollHandler = this.scrollHandler.bind(this);

        ValidationRules
            .ensure(x => x.maxDate).satisfies(() => this.validateDate()).withMessageKey("err_DateDaiLyEntryNoDateInFuture")
            .on(this);
    }

    validateDate() {
        if (this.maxDate > dateHelper.parseDate(dateHelper.getNow())) {
            return false;
        }
        return true;
    }

    initializeMaxDate() {
        this.maxDate = dateHelper.parseDate(dateHelper.getNow());
    }

    bindViewModel(dispatchProjectCode, querystring) {
        var params = routerHelper.getQuerystring(querystring);
        this.isProjectResponsible = (params && params.isProjectResponsible === "true") || false;
        this.dailyEntryDate = params.dailyEntryDate;
        this.closeList = [];
        this.dispatchProjectCode = dispatchProjectCode;
        this.initializeMaxDate();
    }

    loadDispatches(dispatchProjectCode, maxDate) {
        routerHelper.showLoading();

        this.page = 1;

        // PBI 17976 : On veut avoir toutes les entrées journalières triées en desc.
        return dispatchService.getListByDispatchProjectCode(dispatchProjectCode, null, null, false, false, this.page, dateHelper.formatDateToSend(maxDate))
            .done((data) => {
                this.scrolling(data);
            }).always(() => {
                routerHelper.hideLoading();
            });
    }

    scrolling(data) {
        var list = data;
        this.closeList = list;
        if (!list.length) {
            this.noDataText = this.i18n.tr("NoHistoricData");
            this.disposeScroll();
            this.noDataText = this.i18n.tr("NoClosedDailyEntryToCopy");
            this.displayMoreEntries = false;
        } else {
            if (list.length === defaultService.getPageSize()) {
                this.initScroll();
            } else {
                this.disposeScroll();
            }
            this.displayMoreEntries = list.length >= defaultService.getPageSize();
            this.enableMoreEntries = list.length >= defaultService.getPageSize();
        }

        this.list = list;
    }

    loadData(dispatchProjectCode) {
        return this.loadDispatches(dispatchProjectCode, this.maxDate);
    }

    initScroll() {
        window.addEventListener("scroll", this.boundScrollHandler);
    }

    disposeScroll() {
        window.removeEventListener("scroll", this.boundScrollHandler);
    }

    scrollHandler() {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.disposeScroll();
            this.showMoreEntries();
        }
    }

    askForAddingRessourcesAndHours(sourceDateTime) {
        var selectedChoice = enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.id;
        var options = {
            selectedValue: selectedChoice,
            optionsList: [
                {
                    value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.id,
                    text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.label
                },
                {
                    value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.id,
                    text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.label
                },
                {
                    value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDSUBCONTRACTORS.id,
                    text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDSUBCONTRACTORS.label
                }
            ]
        };
        notificationHelper.showOptionsDialog(this.i18n.tr("msg_ChoicePrecisionToCopy"), "", options)
            .done((choice) => {
                if (choice) {
                    selectedChoice = choice;
                    if (selectedChoice === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.id) {
                        this.copyDailyEntry(sourceDateTime, false, false);
                    } else if (selectedChoice === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.id)  {
                        this.copyDailyEntry(sourceDateTime, true, false);
                    } else {
                        this.copyDailyEntry(sourceDateTime, true, true);
                    }
                } else {
                    routerHelper.navigateBack();
                }
            });
    }

    copyDailyEntry(sourceDateTime, copyTimeEntries, copySubContractors) {
        routerHelper.showLoading();
        var url = routerHelper.navigateTo(this.routeRepository.routes.Project_Detail_Daily_Entry.name, this.dispatchProjectCode, this.dailyEntryDate);
        var queryString = routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible });
        dailyEntryService.addCopyDailyEntry(this.dispatchProjectCode, sourceDateTime, this.dailyEntryDate, copyTimeEntries, this.getTimeEntryMode(), this.getInChargeRequireIndicateWhenEmployeeLeftWorksite(), copySubContractors)
            .done(() => {
                routerHelper.navigate(url + queryString,
                    {
                        replace: true,
                        trigger: true
                    });
            }).always(() => {
                routerHelper.hideLoading();
            });
    }

    showMoreEntriesInner(ls) {
        var actualLs = this.closeList;
        actualLs = actualLs.concat(ls);
        this.lblShowMoreEntries = this.i18n.tr("ShowMoreEntries");
        this.moreEntriesLoading = false;
        this.closeList = actualLs;

        if (ls.length >= defaultService.getPageSize()) {
            this.initScroll();
        } else {
            this.disposeScroll();
        }

        this.list = actualLs;
    }

    getTimeEntryMode() {
        return templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
    }

    getInChargeRequireIndicateWhenEmployeeLeftWorksite() {
        return templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
    }

    //#region Public Functions
    activate(params) {
        this.bindViewModel(params.dispatchProjectCode, params.q);
        return this.loadData(params.dispatchProjectCode);
    }

    maxDateChanged(newValue) {
        this.validationController.validate().then(result => {
            if (!result.valid) {
                const error = _.pluck(_.filter(result.results, res => !res.valid), "message");
                notificationHelper.showValidationError(error);
                return;
            }

            this.loadDispatches(this.dispatchProjectCode, newValue);
        });
    }

    deactivate() {
        this.disposeScroll();
    }

    showMoreEntries() {
        this.lblShowMoreEntries = this.i18n.tr("LoadingMoreEntries");
        this.moreEntriesLoading = true;
        this.page = this.page + 1;

        // PBI 17976 : On veut avoir toutes les entrées journalières.
        dispatchService.getListByDispatchProjectCode(this.dispatchProjectCode, null, null, false, false, this.page, dateHelper.formatDateToSend(this.maxDate))
            .done((lst) => {
                this.showMoreEntriesInner(lst);
                this.enableMoreEntries = lst.length >= defaultService.getPageSize();
            })
            .fail(() => {
                this.list = [];
            });
    }

    createDailyEntryCopy(sourceDateTime) {
        if (dateHelper.isTodayOrBefore(dateHelper.formatDate(null, this.dailyEntryDate))) {
            this.askForAddingRessourcesAndHours(sourceDateTime.Date);
        }
        else {
            this.copyDailyEntry(sourceDateTime.Date, false, false);
        }
    }
    //#endregion
}
