define([
    "jquery",
    "core/storage",
    "services/requests",
    "knockout",
    "helpers/apiHelper"
], function (jQuery, storage, requests, ko, apiHelper) {
    "use strict";

    var exports = {
        projectConfigStorageKey: "PROJECTCONFIGS",

        // TODO: KG - ShowLoading: False - Transport
        getDefinedText: function (option, context, entityId, filter, pages) {
            var url = apiHelper.getBaseUrl() + "/definedtexts";
            var data = { 'option': option, 'context': context };

            if (entityId !== undefined && entityId !== '') {
                data.entityId = entityId;
            }

            url = apiHelper.appendQueryStringParameters(url, data);
            url = apiHelper.appendFilterPagingParams(url, filter, pages);

            return requests.getJson(url);
        },

        getShifts: function () {
            return requests.getJson(apiHelper.getBaseUrl() + "/shift");
        },

        //TODO: KG - Ne semble pas utilisé
        getProjectConfigs: function () {
            if (!storage.get(this.projectConfigStorageKey)) {
                return this.refreshProjectConfigs();
            } else {
                return JSON.parse(storage.get(this.projectConfigStorageKey));
            }
        },

        // TODO: KG - ShowLoading: False
        refreshProjectConfigs: function () {
            var self = this;
            var dfd = new jQuery.Deferred();
                requests.getJson(apiHelper.getBaseUrl() + "/projects/config").done(function (config) {
                    storage.set(self.projectConfigStorageKey, JSON.stringify(config));
                    dfd.resolve(config);
                });
            return dfd;
        }
    };

    return exports;
});
