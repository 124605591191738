import { default as val } from "core/val";
import { ServiceCallDispatchOrderService } from "services/service-call-dispatch-order-service"
import queryStringHelper from "helpers/queryStringHelper";

define([
    "knockout",
    "jquery",
    "helpers/routerHelper",
    "core/resx",
    "services/serviceService",
    "helpers/labelHelper",
    "helpers/phoneHelper",
    "helpers/dateHelper",
    "services/defaultService",
    "underscore",
    "helpers/enumHelper",
    "helpers/notificationHelper",
    "select2"
], function (ko, jQuery, routerHelper, resx, services, labelHelper, phoneHelper, hlpDate, defaultValues, _, enumHelper, notifier) {
    "use strict";

    var viewModel = (function(){
        var self = null;

        function ctor(serviceCallDispatchOrderService, queryStringHelper) {
            this.serviceCallDispatchOrderService = serviceCallDispatchOrderService;
            this.queryStringHelper = queryStringHelper;
            self = this;

            //#region Properties
            self.hlpDate = hlpDate;
            self.labelHelper = labelHelper;
            self.resx = resx;
            self.routerHelper = routerHelper;
            self.phoneHelper = phoneHelper;

            self.dispatchId = ko.observable();
            self.informations = ko.observable({});
            self.notes = ko.observable([]);
            self.serviceType = ko.observable();
            self.statusDisabled = ko.observable(true);
            self.statusList = ko.observableArray([]);
            self.selectedStatus = ko.observable();
            self.dispatchStatus = ko.observable({});
            self.readonly = ko.observable(false);
            self.enumPresence = enumHelper.servicePresence();
            self.detail = ko.observable({});
            self.fromHistory = false;
            //#endregion

            self.serviceTypeEnum = { "serviceCall": "S", "workOrder": "W" };


            self.statusPlaceHolder = ko.computed(function () {
                if (self.dispatchStatus().CustomStatus !== "") {
                    return self.dispatchStatus().CustomStatus + ' - ' + self.dispatchStatus().CustomStatusDescription;
                } else {
                    return self.statusDisabled() ? resx.localize('NoStatusAvailable') : resx.localize("SelectOoo");
                }
            });

            self.orderNoMaxLength = val.get('service.serviceCall.OrderNo', 'maxLength');
        }

        ctor.inject = [ServiceCallDispatchOrderService, queryStringHelper];

        //#region Private Functions
        function bindViewModel(serviceType, dispatchId) {
            self.dispatchId(dispatchId);
            self.serviceType(serviceType);
        }

        function loadData() {
            var dfdStatus = services.getCustomStatus(self.serviceType());
            var dfdDispatchStatus = services.getStatus(self.serviceType(), self.dispatchId());
            var dfdInfos = services.getInformation(self.serviceType(), self.dispatchId());
            var dfdNotes = services.getNotes(self.serviceType(), self.dispatchId());
            var details = services.getDetail(self.serviceType(), self.dispatchId(), self.fromHistory);

            return jQuery.when(dfdInfos, dfdNotes, dfdStatus, dfdDispatchStatus, details).done(
            function (infos, notes, status, dispatchStatus, details) {
                self.detail(details);
                self.informations(infos);
                self.notes(notes);

                if (!self.readonly()) {
                    self.readonly(self.detail().IsClosed || self.detail().Presence === self.enumPresence.COMPLETED.id);
                }
                
                status = _.map(status, function (tmpData) {
                    return {
                        id: tmpData.Id,
                        text: tmpData.Id + ' - ' + tmpData.Description
                    };
                });

                self.statusList(status);
                self.statusDisabled(self.statusList().length === 0 || self.readonly());

                self.dispatchStatus(dispatchStatus);
                if (self.dispatchStatus().CustomStatus !== "" && _.find(self.statusList(), function (item) { return item.id === self.dispatchStatus().CustomStatus; })) {
                    self.selectedStatus(self.dispatchStatus().CustomStatus);
                }
            });
        }

        function initDropDownList() {
            jQuery("#ddlStatus").select2({
                minimumResultsForSearch: defaultValues.getMinimumResultsForSearch(),
                placeholder: self.statusPlaceHolder(),
                width: "100%",
                language: labelHelper.getDefaultSelect2Labels(),
                data: self.statusList()

            }).on("change", function (e) {
                self.selectedStatus(e.target.value);
                }).val(self.selectedStatus()).trigger("change");
        }


        //#endregion
        
        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.readonly(queryStringHelper.parseReadonly(params.q));

            self.fromHistory = queryStringHelper.parse(params.q, "fromHistory");
            if (!self.fromHistory) {
                self.fromHistory = false;
            } else if (self.fromHistory === "true") {
                self.fromHistory = true;
            }
            
            bindViewModel(params.serviceType, params.dispatchId);
            return loadData(self);
        };

        ctor.prototype.attached = function () {
            initDropDownList(self);
        };


        ctor.prototype.save = async function () {
            if (!self.statusDisabled() && self.selectedStatus() === '') {
                notifier.showWarning(resx.localize("err_StatusRequired"));
            } else {
                routerHelper.showLoading();
                services.setStatus(self.serviceType(), self.dispatchId(), self.selectedStatus())
                    .done(function () {
                        routerHelper.navigateBack();
                    }).fail(function (xhr) {
                        var t = xhr;
                    })
                    .always(routerHelper.hideLoading);                
            }

            if (self.serviceType() === self.serviceTypeEnum.serviceCall) {
                await self.serviceCallDispatchOrderService.UpdateOrderNo(self.dispatchId(), self.informations().OrderNumber);
            }
         };



        //#endregion

        return ctor;
    })();

    return viewModel;
});
